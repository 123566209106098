import { trans } from 'Rentlio/helper/translation'

export function notEmptyConstraint(value) {
  let isEmpty = typeof value === 'undefined' || value === null

  isEmpty = isEmpty || (Array.isArray(value) && value.length === 0)
  isEmpty = isEmpty || (typeof value === 'string' && (value === '' || value.trim() === ''))

  return isEmpty ? trans('This value cannot be empty') : null
}
