import React from 'react'
import PropTypes from 'prop-types'

const DiscoverCardIcon = ({ width, height }) => (
  <svg width={width} height={height} viewBox='0 0 72 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.24228 0.348999H0V11.6792H3.22803C4.94537 11.6792 6.18527 11.273 7.26841 10.368C8.5582 9.29912 9.32779 7.68867 9.32779 6.02121C9.32067 2.67917 6.8266 0.348999 3.24228 0.348999ZM5.82185 8.85731C5.13064 9.48439 4.22565 9.75518 2.80048 9.75518H2.20903V2.26586H2.80048C4.22565 2.26586 5.09501 2.5224 5.82185 3.1851C6.58432 3.86206 7.04751 4.9167 7.04751 6.00696C7.04751 7.09722 6.59145 8.18035 5.82185 8.86444V8.85731Z'
      fill='#261F20'
    />
    <path d='M12.5479 0.348999H10.3389V11.6792H12.5479V0.348999Z' fill='#261F20' />
    <path
      d='M17.9488 4.69596C16.6234 4.20427 16.2315 3.88361 16.2315 3.27078C16.2315 2.55819 16.9227 2.01663 17.8776 2.01663C18.5403 2.01663 19.0819 2.28741 19.6591 2.93587L20.8135 1.42518C19.8657 0.591448 18.7256 0.171021 17.4857 0.171021C15.4833 0.171021 13.9512 1.56057 13.9512 3.4133C13.9512 4.97387 14.6638 5.77197 16.7374 6.52019C17.5997 6.8266 18.0415 7.02613 18.2624 7.16864C18.7042 7.45368 18.9251 7.86698 18.9251 8.33729C18.9251 9.25653 18.1983 9.93349 17.2078 9.93349C16.1531 9.93349 15.3051 9.40617 14.7992 8.4228L13.374 9.7981C14.393 11.2945 15.6116 11.9572 17.2933 11.9572C19.5878 11.9572 21.1983 10.4323 21.1983 8.23753C21.1983 6.43468 20.45 5.62233 17.9417 4.70309L17.9488 4.69596Z'
      fill='#261F20'
    />
    <path
      d='M21.9053 6.02139C21.9053 9.34919 24.5205 11.9288 27.8839 11.9288C28.8316 11.9288 29.6511 11.7435 30.6559 11.2732V8.67223C29.7723 9.55584 28.9884 9.91213 27.9908 9.91213C25.7675 9.91213 24.1856 8.30168 24.1856 6.00714C24.1856 3.83374 25.8174 2.1164 27.891 2.1164C28.9457 2.1164 29.7438 2.49408 30.663 3.39194V0.790989C29.6939 0.299302 28.8958 0.0926514 27.948 0.0926514C24.5989 0.0926514 21.9195 2.72923 21.9195 6.02139H21.9053Z'
      fill='#261F20'
    />
    <path
      d='M48.1783 7.95232L45.1569 0.348999H42.7412L47.5512 11.9642H48.7341L53.6296 0.348999H51.2353L48.1783 7.95232Z'
      fill='#261F20'
    />
    <path
      d='M54.627 11.672H60.8977V9.75517H56.836V6.69817H60.7481V4.7813H56.836V2.26586H60.8977V0.348999H54.627V11.672Z'
      fill='#261F20'
    />
    <path
      d='M69.6482 3.69104C69.6482 1.56753 68.1874 0.348999 65.6363 0.348999H62.3584V11.6792H64.5674V7.12572H64.8596L67.9166 11.6792H70.6316L67.0615 6.90482C68.7289 6.5699 69.6411 5.42976 69.6411 3.69104H69.6482ZM65.2159 5.56515H64.5674V2.13047H65.2444C66.6197 2.13047 67.3679 2.70767 67.3679 3.81218C67.3679 4.9167 66.6197 5.56515 65.2088 5.56515H65.2159Z'
      fill='#261F20'
    />
    <path
      d='M71.4083 0.940569C71.4083 0.741044 71.2729 0.634155 71.0306 0.634155H70.71V1.63178H70.9451V1.24698L71.223 1.63178H71.5152L71.1874 1.21848C71.3299 1.18285 71.4083 1.07596 71.4083 0.940569ZM70.9879 1.07596H70.9451V0.812303H70.9879C71.109 0.812303 71.166 0.855058 71.166 0.940569C71.166 1.02608 71.1019 1.07596 70.9879 1.07596Z'
      fill='#261F20'
    />
    <path
      d='M71.0735 0.263672C70.5889 0.263672 70.2041 0.64847 70.2041 1.13303C70.2041 1.61759 70.596 2.00239 71.0735 2.00239C71.5509 2.00239 71.9357 1.61047 71.9357 1.13303C71.9357 0.655596 71.5509 0.263672 71.0735 0.263672ZM71.0735 1.85275C70.6887 1.85275 70.3822 1.53208 70.3822 1.14016C70.3822 0.748232 70.6887 0.427567 71.0735 0.427567C71.4583 0.427567 71.7647 0.755358 71.7647 1.14016C71.7647 1.52495 71.4583 1.85275 71.0735 1.85275Z'
      fill='#261F20'
    />
    <path
      d='M43.3682 6C43.3682 9.31354 40.6817 12 37.3682 12C34.0546 12 31.3682 9.31354 31.3682 6C31.3682 2.68646 34.0618 0 37.3753 0C40.6888 0 43.3753 2.68646 43.3753 6H43.3682Z'
      fill='url(#paint0_linear_3458_3724)'
    />
    <path
      style={{ mixBlendMode: 'multiply' }}
      opacity='0.65'
      d='M43.3682 6C43.3682 9.31354 40.6817 12 37.3682 12C34.0546 12 31.3682 9.31354 31.3682 6C31.3682 2.68646 34.0618 0 37.3753 0C40.6888 0 43.3753 2.68646 43.3753 6H43.3682Z'
      fill='url(#paint1_linear_3458_3724)'
    />
    <g style={{ mixBlendMode: 'multiply' }} opacity='0.5'>
      <mask
        id='mask0_3458_3724'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='31'
        y='0'
        width='13'
        height='12'
      >
        <path
          d='M43.3701 6C43.3701 9.31354 40.6837 12 37.3701 12C34.0566 12 31.3701 9.31354 31.3701 6C31.3701 2.68646 34.0566 0 37.3701 0C40.6837 0 43.3701 2.68646 43.3701 6Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_3458_3724)'>
        <path
          d='M37.3985 -0.0927276C40.7762 -0.0927276 43.5196 2.64361 43.5196 6.02129C43.5196 9.39896 40.7762 12.1424 37.3985 12.1424C34.0208 12.1424 31.2773 9.40608 31.2773 6.02129C31.2773 2.64361 34.0208 -0.0998535 37.3985 -0.0998535V-0.0927276Z'
          fill='#010000'
        />
        <path
          d='M37.4063 -0.0854492C40.784 -0.0854492 43.5274 2.64377 43.5274 6.02144C43.5274 9.39911 40.784 12.1497 37.4063 12.1497C34.0286 12.1497 31.2852 9.41336 31.2852 6.03569C31.2852 2.65802 34.0286 -0.0854492 37.4063 -0.0854492Z'
          fill='#030100'
        />
        <path
          d='M37.406 -0.0784912C40.7837 -0.0784912 43.5343 2.65073 43.5343 6.0284C43.5343 9.40607 40.7837 12.1567 37.406 12.1567C34.0283 12.1567 31.2849 9.42032 31.292 6.04265C31.292 2.66498 34.0355 -0.0784912 37.406 -0.0713653V-0.0784912Z'
          fill='#040100'
        />
        <path
          d='M37.4129 -0.0713973C40.7905 -0.0713973 43.5411 2.65069 43.5411 6.02837C43.5411 9.40604 40.7905 12.1566 37.4129 12.1566C34.0352 12.1566 31.2917 9.42029 31.2988 6.04262C31.2988 2.66494 34.0423 -0.0785232 37.4129 -0.0713973Z'
          fill='#050100'
        />
        <path
          d='M37.4197 -0.056993C40.7974 -0.056993 43.548 2.65797 43.548 6.03564C43.548 9.41332 40.7974 12.171 37.4197 12.171C34.042 12.171 31.2986 9.43469 31.3057 6.05702C31.3057 2.67935 34.0491 -0.0641189 37.4197 -0.056993Z'
          fill='#070200'
        />
        <path
          d='M37.4275 -0.0499129C40.8052 -0.0499129 43.5558 2.66505 43.5558 6.04272C43.5558 9.4204 40.7981 12.1781 37.4275 12.1781C34.057 12.1781 31.3064 9.44177 31.3135 6.0641C31.3135 2.68643 34.057 -0.0570388 37.4275 -0.0499129Z'
          fill='#080200'
        />
        <path
          d='M37.4275 -0.0428328C40.8052 -0.0428328 43.5629 2.66501 43.5629 6.04268C43.5629 9.42035 40.8052 12.1852 37.4275 12.1852C34.0498 12.1852 31.3064 9.44885 31.3135 6.07118C31.3135 2.69351 34.057 -0.0499587 37.4275 -0.0428328Z'
          fill='#090300'
        />
        <path
          d='M37.4343 -0.0357069C40.812 -0.0357069 43.5697 2.67213 43.5697 6.04268C43.5697 9.41322 40.812 12.1852 37.4343 12.1852C34.0567 12.1852 31.3132 9.44885 31.3203 6.07118C31.3203 2.69351 34.0638 -0.0499587 37.4343 -0.0428328V-0.0357069Z'
          fill='#0B0300'
        />
        <path
          d='M37.4422 -0.0284285C40.8198 -0.0213026 43.5775 2.67228 43.5775 6.04996C43.5775 9.42763 40.8127 12.1996 37.4422 12.1996C34.0716 12.1996 31.321 9.46326 31.3281 6.08559C31.3281 2.70791 34.0716 -0.0355544 37.4422 -0.0284285Z'
          fill='#0C0300'
        />
        <path
          d='M37.4422 -0.0213026C40.8198 -0.0141768 43.5775 2.67228 43.5775 6.04996C43.5775 9.42763 40.8127 12.1996 37.4422 12.1996C34.0716 12.1996 31.321 9.46326 31.3281 6.08559C31.3353 2.70791 34.0716 -0.0355544 37.4422 -0.0284285V-0.0213026Z'
          fill='#0D0400'
        />
        <path
          d='M37.4487 -0.0142822C40.8193 -0.00715634 43.5913 2.6793 43.5913 6.04985C43.5913 9.4204 40.8264 12.2066 37.4487 12.2066C34.0711 12.2066 31.3276 9.47028 31.3419 6.09973C31.349 2.72206 34.0853 -0.0214081 37.4487 -0.00715634V-0.0142822Z'
          fill='#0F0400'
        />
        <path
          d='M37.4556 -0.00708008C40.8261 4.58132e-05 43.5981 2.67938 43.5981 6.05705C43.5981 9.43473 40.8261 12.2138 37.4556 12.2138C34.085 12.2138 31.3344 9.47748 31.3487 6.10693C31.3558 2.72926 34.0993 -0.014206 37.4556 4.58173e-05V-0.00708008Z'
          fill='#100400'
        />
        <path
          d='M37.4634 -6.67942e-05C40.8339 0.0070591 43.6059 2.68639 43.6059 6.05694C43.6059 9.42749 40.8339 12.2137 37.4634 12.2137C34.0928 12.2137 31.3422 9.47737 31.3565 6.10682C31.3636 2.72915 34.1071 -0.0143186 37.4634 -6.67942e-05Z'
          fill='#110500'
        />
        <path
          d='M37.4633 0.00701328C40.8339 0.0212651 43.613 2.68635 43.613 6.0569C43.613 9.42744 40.841 12.2208 37.4633 12.2208C34.0857 12.2208 31.3493 9.48445 31.3565 6.1139C31.3636 2.73623 34.1071 -0.0072385 37.4633 0.00701328Z'
          fill='#130500'
        />
        <path
          d='M37.4702 0.0214176C40.8407 0.0356694 43.6198 2.69363 43.6198 6.0713C43.6198 9.44897 40.8407 12.2352 37.4702 12.2352C34.0996 12.2352 31.3562 9.49885 31.3633 6.12831C31.3775 2.75063 34.1139 0.0071658 37.4702 0.0214176Z'
          fill='#140500'
        />
        <path
          d='M37.476 0.0284977C40.8466 0.0427494 43.6257 2.70071 43.6257 6.07125C43.6257 9.4418 40.8466 12.2423 37.476 12.2423C34.1055 12.2423 31.362 9.50593 31.3692 6.13539C31.3834 2.75771 34.1197 0.0142459 37.476 0.0284977Z'
          fill='#150600'
        />
        <path
          d='M37.476 0.0356235C40.8466 0.0498753 43.6257 2.70071 43.6257 6.07125C43.6257 9.4418 40.8466 12.2423 37.476 12.2423C34.1055 12.2423 31.362 9.50593 31.3692 6.13539C31.3834 2.75771 34.1197 0.0142459 37.476 0.0284977V0.0356235Z'
          fill='#170600'
        />
        <path
          d='M37.4839 0.0427036C40.8544 0.0569554 43.6406 2.70066 43.6406 6.07121C43.6406 9.44175 40.8544 12.2494 37.4839 12.2494C34.1133 12.2494 31.3698 9.52014 31.377 6.14247C31.3912 2.76479 34.1276 0.021326 37.4839 0.0355777V0.0427036Z'
          fill='#180700'
        />
        <path
          d='M37.4914 0.0499268C40.862 0.0641785 43.6482 2.70788 43.6482 6.07843C43.6482 9.44898 40.862 12.2566 37.4914 12.2566C34.1209 12.2566 31.3774 9.52736 31.3917 6.15682C31.4059 2.77914 34.1423 0.035675 37.4914 0.0570527V0.0499268Z'
          fill='#190700'
        />
        <path
          d='M37.4983 0.0568848C40.8688 0.0711365 43.655 2.70772 43.655 6.07826C43.655 9.44881 40.8688 12.2635 37.4983 12.2635C34.1277 12.2635 31.3842 9.53432 31.3985 6.16377C31.4127 2.7861 34.1491 0.042633 37.4983 0.0640107V0.0568848Z'
          fill='#1B0700'
        />
        <path
          d='M37.4983 0.064211C40.8688 0.0784628 43.655 2.71504 43.655 6.07846C43.655 9.44188 40.8617 12.2637 37.4983 12.2637C34.1348 12.2637 31.3842 9.53452 31.3985 6.16397C31.4127 2.7863 34.1491 0.0428333 37.4983 0.064211Z'
          fill='#1C0800'
        />
        <path
          d='M37.5051 0.0712911C40.8756 0.0926688 43.669 2.715 43.669 6.08554C43.669 9.45609 40.8756 12.2708 37.5051 12.2708C34.1345 12.2708 31.3911 9.5416 31.4053 6.17105C31.4196 2.79338 34.1559 0.0499134 37.5051 0.0712911Z'
          fill='#1D0800'
        />
        <path
          d='M37.5129 0.0783712C40.8834 0.0997488 43.6768 2.71495 43.6768 6.0855C43.6768 9.45604 40.8834 12.2779 37.5129 12.2779C34.1424 12.2779 31.3989 9.54868 31.4131 6.17813C31.4274 2.80046 34.1709 0.0569935 37.5129 0.0783712Z'
          fill='#1F0800'
        />
        <path
          d='M37.5129 0.0925086C40.8834 0.113886 43.6768 2.72909 43.6768 6.09251C43.6768 9.45593 40.8763 12.2849 37.5129 12.2849C34.1495 12.2849 31.3989 9.55569 31.4131 6.18514C31.4274 2.80035 34.1709 0.0711309 37.5129 0.0853827V0.0925086Z'
          fill='#200900'
        />
        <path
          d='M37.5207 0.099787C40.8913 0.121165 43.6917 2.72924 43.6917 6.09979C43.6917 9.47033 40.8913 12.2993 37.5207 12.2993C34.1502 12.2993 31.4067 9.5701 31.421 6.19955C31.4423 2.81475 34.1787 0.0855352 37.5207 0.099787Z'
          fill='#210900'
        />
        <path
          d='M37.5266 0.106913C40.89 0.128291 43.6976 2.73637 43.6976 6.09979C43.6976 9.46321 40.8971 12.2993 37.5266 12.2993C34.156 12.2993 31.4126 9.5701 31.4268 6.19955C31.4482 2.81475 34.1845 0.0855352 37.5266 0.099787V0.106913Z'
          fill='#220900'
        />
        <path
          d='M37.5334 0.113947C40.8968 0.135325 43.7044 2.73627 43.7044 6.09969C43.7044 9.46311 40.8968 12.3063 37.5334 12.3063C34.17 12.3063 31.4194 9.57713 31.4336 6.21371C31.455 2.82891 34.1914 0.0996954 37.5334 0.113947Z'
          fill='#240A00'
        />
        <path
          d='M37.5332 0.121216C40.8966 0.142593 43.7042 2.73642 43.7042 6.10696C43.7042 9.47751 40.8966 12.3136 37.5332 12.3136C34.1698 12.3136 31.4192 9.5844 31.4406 6.22098C31.4619 2.83618 34.1983 0.106964 37.5332 0.128342V0.121216Z'
          fill='#250A00'
        />
        <path
          d='M37.541 0.128296C40.9044 0.149674 43.7192 2.7435 43.7192 6.10692C43.7192 9.47034 40.9116 12.3207 37.541 12.3207C34.1705 12.3207 31.427 9.59148 31.4484 6.22806C31.4697 2.84326 34.2061 0.114044 37.541 0.135422V0.128296Z'
          fill='#260A00'
        />
        <path
          d='M37.5488 0.135257C40.9122 0.156634 43.727 2.74333 43.727 6.10675C43.727 9.47017 40.9122 12.3205 37.5488 12.3205C34.1854 12.3205 31.4348 9.59131 31.4562 6.22789C31.4776 2.8431 34.2139 0.113879 37.5488 0.135257Z'
          fill='#280B00'
        />
        <path
          d='M37.5488 0.142581C40.9122 0.171084 43.727 2.74353 43.727 6.11408C43.727 9.48462 40.9122 12.3279 37.5488 12.3279C34.1854 12.3279 31.4348 9.59864 31.4562 6.23522C31.4776 2.85042 34.2139 0.121203 37.5488 0.142581Z'
          fill='#290B00'
        />
        <path
          d='M37.5557 0.149661C40.9191 0.178164 43.7409 2.75061 43.7409 6.11403C43.7409 9.47745 40.9262 12.3349 37.5557 12.3349C34.1851 12.3349 31.4416 9.60572 31.463 6.2423C31.4844 2.8575 34.2207 0.128283 37.5557 0.149661Z'
          fill='#2A0C00'
        />
        <path
          d='M37.5625 0.156787C40.9259 0.18529 43.7478 2.75061 43.7478 6.11403C43.7478 9.47745 40.9259 12.3349 37.5625 12.3349C34.1991 12.3349 31.4485 9.60572 31.4699 6.2423C31.4912 2.8575 34.2347 0.128283 37.5625 0.149661V0.156787Z'
          fill='#2C0C00'
        />
        <path
          d='M37.5693 0.171145C40.9327 0.199649 43.7546 2.76497 43.7546 6.12839C43.7546 9.49181 40.9327 12.3564 37.5693 12.3564C34.2059 12.3564 31.4553 9.6272 31.4767 6.26378C31.4981 2.87898 34.2415 0.149768 37.5693 0.171145Z'
          fill='#2D0C00'
        />
        <path
          d='M37.5693 0.178271C40.9327 0.206775 43.7546 2.76497 43.7546 6.12839C43.7546 9.49181 40.9327 12.3564 37.5693 12.3564C34.2059 12.3564 31.4553 9.6272 31.4767 6.26378C31.5052 2.87898 34.2415 0.149768 37.5693 0.171145V0.178271Z'
          fill='#2E0D00'
        />
        <path
          d='M37.5769 0.185303C40.9404 0.213806 43.7693 2.76488 43.7693 6.1283C43.7693 9.49172 40.9404 12.3635 37.5769 12.3635C34.2135 12.3635 31.4629 9.63423 31.4914 6.27794C31.5199 2.89314 34.2563 0.163925 37.5769 0.192429V0.185303Z'
          fill='#300D00'
        />
        <path
          d='M37.5838 0.192261C40.9472 0.220764 43.7762 2.77183 43.7762 6.13525C43.7762 9.49867 40.9472 12.3704 37.5838 12.3704C34.2204 12.3704 31.4698 9.64832 31.4983 6.2849C31.5268 2.9001 34.2631 0.170883 37.5838 0.199387V0.192261Z'
          fill='#310D00'
        />
        <path
          d='M37.5838 0.199585C40.9472 0.228089 43.7762 2.77203 43.7762 6.13545C43.7762 9.49887 40.9472 12.3777 37.5838 12.3777C34.2204 12.3777 31.4698 9.65564 31.4983 6.29222C31.5268 2.90742 34.2631 0.178207 37.5838 0.206711V0.199585Z'
          fill='#320E00'
        />
        <path
          d='M37.5906 0.206642C40.954 0.235146 43.783 2.77909 43.783 6.13538C43.783 9.49168 40.9469 12.3777 37.5906 12.3777C34.2343 12.3777 31.4766 9.65557 31.5051 6.29215C31.5336 2.90736 34.2699 0.178139 37.5906 0.206642Z'
          fill='#340E00'
        />
        <path
          d='M37.5984 0.213722C40.9547 0.249352 43.798 2.77904 43.798 6.14246C43.798 9.50588 40.9618 12.3847 37.5984 12.3847C34.235 12.3847 31.4844 9.66265 31.5129 6.29923C31.5414 2.91443 34.2778 0.185219 37.5984 0.213722Z'
          fill='#350E00'
        />
        <path
          d='M37.6053 0.220924C40.9616 0.256554 43.8048 2.77912 43.8048 6.14254C43.8048 9.50596 40.9687 12.3919 37.6053 12.3919C34.2418 12.3919 31.4912 9.66986 31.5198 6.30644C31.5483 2.92164 34.2846 0.192421 37.6053 0.220924Z'
          fill='#360F00'
        />
        <path
          d='M37.6053 0.22805C40.9616 0.26368 43.8048 2.78625 43.8048 6.14254C43.8048 9.49883 40.9616 12.3919 37.6053 12.3919C34.249 12.3919 31.4912 9.66986 31.5198 6.30644C31.5483 2.92164 34.2846 0.192421 37.6053 0.220924V0.22805Z'
          fill='#380F00'
        />
        <path
          d='M37.6131 0.242088C40.9694 0.277718 43.8197 2.79316 43.8197 6.14945C43.8197 9.50575 40.9765 12.406 37.6131 12.406C34.2497 12.406 31.4991 9.68389 31.5276 6.32047C31.5561 2.93568 34.2924 0.206459 37.6131 0.234962V0.242088Z'
          fill='#391000'
        />
        <path
          d='M37.6197 0.24939C40.976 0.285019 43.8264 2.79333 43.8264 6.15675C43.8264 9.52017 40.976 12.4133 37.6197 12.4133C34.2634 12.4133 31.5057 9.6912 31.5414 6.3349C31.577 2.9501 34.3133 0.220886 37.6197 0.256516V0.24939Z'
          fill='#3A1000'
        />
        <path
          d='M37.6197 0.256592C40.976 0.292221 43.8264 2.80053 43.8264 6.15683C43.8264 9.51312 40.976 12.4205 37.6197 12.4205C34.2634 12.4205 31.5057 9.6984 31.5414 6.3421C31.577 2.9573 34.3133 0.228088 37.6197 0.263718V0.256592Z'
          fill='#3C1000'
        />
        <path
          d='M37.6266 0.263651C40.9829 0.29928 43.8332 2.80047 43.8332 6.15676C43.8332 9.51306 40.9829 12.4204 37.6266 12.4204C34.2703 12.4204 31.5126 9.69833 31.5482 6.34204C31.5838 2.95724 34.3202 0.228021 37.6266 0.263651Z'
          fill='#3D1100'
        />
        <path
          d='M37.6344 0.270853C40.9907 0.306482 43.8482 2.80767 43.8482 6.16396C43.8482 9.52026 40.9907 12.4276 37.6344 12.4276C34.2781 12.4276 31.5204 9.70553 31.556 6.34924C31.5916 2.96444 34.328 0.235223 37.6344 0.270853Z'
          fill='#3E1100'
        />
        <path
          d='M37.6412 0.277933C40.9975 0.313562 43.855 2.80762 43.855 6.16392C43.855 9.52021 40.9975 12.4347 37.6412 12.4347C34.2849 12.4347 31.5272 9.71261 31.5628 6.35632C31.5985 2.97152 34.3348 0.242303 37.6412 0.277933Z'
          fill='#401100'
        />
        <path
          d='M37.6412 0.285059C40.9975 0.327814 43.855 2.80762 43.855 6.16392C43.855 9.52021 40.9975 12.4347 37.6412 12.4347C34.2849 12.4347 31.5272 9.71261 31.5628 6.35632C31.5985 2.97152 34.3348 0.242303 37.6412 0.277933V0.285059Z'
          fill='#411200'
        />
        <path
          d='M37.6471 0.292215C41.0034 0.33497 43.8609 2.81478 43.8609 6.17108C43.8609 9.52737 40.9963 12.449 37.6471 12.449C34.2979 12.449 31.5331 9.7269 31.5687 6.3706C31.6043 2.9858 34.3407 0.256586 37.6471 0.292215Z'
          fill='#421200'
        />
        <path
          d='M37.6559 0.299341C41.0122 0.342096 43.8768 2.81478 43.8768 6.17108C43.8768 9.52737 41.0122 12.449 37.6559 12.449C34.2996 12.449 31.5419 9.7269 31.5775 6.3706C31.6131 2.9858 34.3495 0.256586 37.6559 0.292215V0.299341Z'
          fill='#441200'
        />
        <path
          d='M37.6559 0.306421C41.0122 0.349176 43.8768 2.82186 43.8768 6.17103C43.8768 9.5202 41.0122 12.4561 37.6559 12.4561C34.2996 12.4561 31.5419 9.73398 31.5775 6.37768C31.6131 2.99288 34.3495 0.263666 37.6559 0.299295V0.306421Z'
          fill='#451300'
        />
        <path
          d='M37.6626 0.320557C41.0189 0.363312 43.8835 2.82887 43.8835 6.18516C43.8835 9.54146 41.0117 12.4702 37.6626 12.4702C34.3134 12.4702 31.5486 9.74811 31.5913 6.39894C31.6269 3.01414 34.3633 0.284927 37.6626 0.327683V0.320557Z'
          fill='#461300'
        />
        <path
          d='M37.6694 0.327759C41.0257 0.370514 43.8974 2.82895 43.8974 6.18524C43.8974 9.54154 41.0257 12.4774 37.6694 12.4774C34.3131 12.4774 31.5554 9.75531 31.5981 6.40614C31.6409 3.02135 34.3701 0.292129 37.6694 0.334885V0.327759Z'
          fill='#481400'
        />
        <path
          d='M37.6762 0.334846C41.0254 0.377602 43.9043 2.83603 43.9043 6.1852C43.9043 9.53437 41.0325 12.4774 37.6762 12.4774C34.3199 12.4774 31.5622 9.75527 31.605 6.40611C31.6477 3.02131 34.3841 0.292091 37.6762 0.334846Z'
          fill='#491400'
        />
        <path
          d='M37.6762 0.34217C41.0254 0.384926 43.9043 2.83623 43.9043 6.19253C43.9043 9.54882 41.0254 12.4847 37.6762 12.4847C34.3271 12.4847 31.5622 9.7626 31.605 6.41343C31.6477 3.02863 34.3841 0.299415 37.6762 0.34217Z'
          fill='#4A1400'
        />
        <path
          d='M37.6841 0.349128C41.0332 0.39901 43.9121 2.83606 43.9121 6.19236C43.9121 9.54865 41.0332 12.4916 37.6841 12.4916C34.3349 12.4916 31.57 9.77668 31.6128 6.42039C31.6555 3.03559 34.3919 0.306373 37.6841 0.349128Z'
          fill='#4C1500'
        />
        <path
          d='M37.6919 0.356254C41.041 0.406136 43.927 2.84319 43.927 6.19236C43.927 9.54153 41.0482 12.4916 37.6919 12.4916C34.3356 12.4916 31.5779 9.77668 31.6206 6.42039C31.6634 3.03559 34.3997 0.306373 37.6919 0.349128V0.356254Z'
          fill='#4D1500'
        />
        <path
          d='M37.6919 0.363411C41.041 0.413292 43.927 2.84322 43.927 6.19952C43.927 9.55581 41.041 12.5059 37.6919 12.5059C34.3427 12.5059 31.5779 9.79096 31.6206 6.43467C31.6634 3.04987 34.3997 0.320655 37.6919 0.363411Z'
          fill='#4E1500'
        />
        <path
          d='M37.6977 0.370537C41.0469 0.420418 43.9329 2.85035 43.9329 6.19952C43.9329 9.54868 41.0469 12.5059 37.6977 12.5059C34.3486 12.5059 31.5837 9.79096 31.6265 6.43467C31.6692 3.04987 34.4056 0.320655 37.6977 0.363411V0.370537Z'
          fill='#501600'
        />
        <path
          d='M37.7044 0.377563C41.0536 0.427445 43.9467 2.85025 43.9467 6.19942C43.9467 9.54859 41.0607 12.513 37.7044 12.513C34.3482 12.513 31.5904 9.79799 31.6403 6.44882C31.6831 3.06402 34.4194 0.334808 37.7044 0.384689V0.377563Z'
          fill='#511600'
        />
        <path
          d='M37.7132 0.391846C41.0624 0.441727 43.9555 2.8574 43.9555 6.2137C43.9555 9.56999 41.0624 12.5272 37.7132 12.5272C34.3641 12.5272 31.5992 9.81227 31.6491 6.46311C31.6919 3.07831 34.4282 0.34909 37.7132 0.398972V0.391846Z'
          fill='#521600'
        />
        <path
          d='M37.7132 0.399048C41.0624 0.448929 43.9555 2.86461 43.9555 6.21377C43.9555 9.56294 41.0624 12.5344 37.7132 12.5344C34.3641 12.5344 31.5992 9.81948 31.6491 6.47031C31.6919 3.08551 34.4282 0.356293 37.7132 0.406174V0.399048Z'
          fill='#541700'
        />
        <path
          d='M37.7201 0.406069C41.0692 0.45595 43.9624 2.8645 43.9624 6.21367C43.9624 9.56284 41.0692 12.5343 37.7201 12.5343C34.3709 12.5343 31.6061 9.81937 31.6559 6.4702C31.7058 3.0854 34.435 0.356187 37.7201 0.406069Z'
          fill='#551700'
        />
        <path
          d='M37.7269 0.413194C41.0761 0.463076 43.9763 2.87163 43.9763 6.21367C43.9763 9.55571 41.0761 12.5343 37.7269 12.5343C34.3777 12.5343 31.6129 9.81937 31.6628 6.4702C31.7127 3.0854 34.449 0.356187 37.7269 0.406069V0.413194Z'
          fill='#561800'
        />
        <path
          d='M37.7269 0.420473C41.0761 0.47748 43.9763 2.87178 43.9763 6.22095C43.9763 9.57012 41.0761 12.5487 37.7269 12.5487C34.3777 12.5487 31.6129 9.83377 31.6628 6.48461C31.7127 3.09981 34.449 0.370592 37.7269 0.420473Z'
          fill='#581800'
        />
        <path
          d='M37.7326 0.427599C41.0818 0.484606 43.982 2.87178 43.982 6.22095C43.982 9.57012 41.0818 12.5487 37.7326 12.5487C34.3834 12.5487 31.6257 9.83377 31.6685 6.48461C31.7183 3.09981 34.4547 0.370592 37.7326 0.420473V0.427599Z'
          fill='#591800'
        />
        <path
          d='M37.7404 0.434557C41.0896 0.491564 43.9898 2.87874 43.9898 6.22078C43.9898 9.56282 41.0824 12.5557 37.7404 12.5557C34.3984 12.5557 31.6335 9.84073 31.6763 6.49156C31.7262 3.10677 34.4625 0.37755 37.7404 0.427431V0.434557Z'
          fill='#5A1900'
        />
        <path
          d='M37.7492 0.441772C41.0984 0.49878 44.0057 2.87883 44.0057 6.228C44.0057 9.57716 41.0984 12.5629 37.7492 12.5629C34.4 12.5629 31.6423 9.84795 31.6851 6.50591C31.7349 3.12111 34.4713 0.391891 37.7492 0.448898V0.441772Z'
          fill='#5C1900'
        />
        <path
          d='M37.7481 0.448975C41.0901 0.505982 44.0046 2.8789 44.0046 6.22807C44.0046 9.57724 41.0973 12.5701 37.7481 12.5701C34.3989 12.5701 31.6412 9.85515 31.6911 6.51311C31.741 3.12831 34.4773 0.399093 37.7481 0.4561V0.448975Z'
          fill='#5D1900'
        />
        <path
          d='M37.7549 0.456077C41.097 0.513084 44.0115 2.88601 44.0115 6.22805C44.0115 9.57009 41.097 12.5701 37.7549 12.5701C34.4129 12.5701 31.648 9.85513 31.6979 6.51308C31.7478 3.12829 34.4842 0.39907 37.7549 0.456077Z'
          fill='#5E1A00'
        />
        <path
          d='M37.7618 0.470337C41.1038 0.527344 44.0254 2.89314 44.0254 6.24231C44.0254 9.59148 41.1109 12.5915 37.7618 12.5915C34.4126 12.5915 31.6549 9.87651 31.7048 6.53447C31.7546 3.14967 34.491 0.420456 37.7618 0.477463V0.470337Z'
          fill='#5F1A00'
        />
        <path
          d='M37.7618 0.477318C41.1038 0.534325 44.0254 2.90012 44.0254 6.24216C44.0254 9.58421 41.1109 12.5913 37.7618 12.5913C34.4126 12.5913 31.6549 9.87637 31.7048 6.53433C31.7618 3.14953 34.491 0.42031 37.7618 0.477318Z'
          fill='#611A00'
        />
        <path
          d='M37.7686 0.484443C41.1107 0.541451 44.0323 2.90012 44.0323 6.24216C44.0323 9.58421 41.1107 12.5913 37.7686 12.5913C34.4266 12.5913 31.6617 9.87637 31.7116 6.53433C31.7686 3.14953 34.4978 0.42031 37.7686 0.477318V0.484443Z'
          fill='#621B00'
        />
        <path
          d='M37.7774 0.491722C41.1194 0.555855 44.0411 2.90027 44.0411 6.24944C44.0411 9.59861 41.1194 12.6057 37.7774 12.6057C34.4354 12.6057 31.6705 9.89077 31.7204 6.54873C31.7774 3.16393 34.5066 0.434715 37.7774 0.491722Z'
          fill='#631B00'
        />
        <path
          d='M37.7842 0.498848C41.1263 0.562981 44.055 2.9074 44.055 6.24944C44.055 9.59149 41.1334 12.6057 37.7842 12.6057C34.4351 12.6057 31.6773 9.8979 31.7272 6.54873C31.7842 3.16393 34.5206 0.434715 37.7842 0.491722V0.498848Z'
          fill='#651B00'
        />
        <path
          d='M37.7842 0.505806C41.1263 0.569939 44.055 2.90723 44.055 6.24927C44.055 9.59132 41.1263 12.6127 37.7842 12.6127C34.4422 12.6127 31.6773 9.90486 31.7272 6.55569C31.7842 3.17089 34.5206 0.441673 37.7842 0.49868V0.505806Z'
          fill='#661C00'
        />
        <path
          d='M37.79 0.513062C41.132 0.577195 44.0608 2.90736 44.0608 6.25653C44.0608 9.6057 41.132 12.6199 37.79 12.6199C34.448 12.6199 31.6831 9.91211 31.7401 6.57007C31.7971 3.18527 34.5335 0.456054 37.79 0.520187V0.513062Z'
          fill='#671C00'
        />
        <path
          d='M37.7978 0.520264C41.1399 0.584397 44.0686 2.91456 44.0686 6.25661C44.0686 9.59865 41.1399 12.6272 37.7978 12.6272C34.4558 12.6272 31.6909 9.91931 31.7479 6.57727C31.8049 3.19247 34.5413 0.463257 37.7978 0.52739V0.520264Z'
          fill='#691D00'
        />
        <path
          d='M37.7978 0.527353C41.1399 0.591486 44.0757 2.91453 44.0757 6.25657C44.0757 9.59861 41.1399 12.6271 37.7978 12.6271C34.4558 12.6271 31.6909 9.91928 31.7479 6.57723C31.8049 3.19244 34.5413 0.46322 37.7978 0.527353Z'
          fill='#6A1D00'
        />
        <path
          d='M37.8056 0.541626C41.1477 0.605759 44.0835 2.9288 44.0835 6.27084C44.0835 9.61288 41.1477 12.6485 37.8056 12.6485C34.4636 12.6485 31.6987 9.94068 31.7557 6.59863C31.8128 3.21383 34.5491 0.484619 37.8056 0.548752V0.541626Z'
          fill='#6B1D00'
        />
        <path
          d='M37.8125 0.548593C41.1545 0.612726 44.0904 2.92864 44.0904 6.27068C44.0904 9.61273 41.1545 12.6484 37.8125 12.6484C34.4704 12.6484 31.7056 9.94052 31.7626 6.59847C31.8196 3.21368 34.5559 0.48446 37.8125 0.548593Z'
          fill='#6D1E00'
        />
        <path
          d='M37.8183 0.555719C41.1604 0.619852 44.1034 2.92864 44.1034 6.27068C44.1034 9.61273 41.1604 12.6484 37.8183 12.6484C34.4763 12.6484 31.7114 9.94052 31.7684 6.59847C31.8326 3.21368 34.5618 0.48446 37.8183 0.548593V0.555719Z'
          fill='#6E1E00'
        />
        <path
          d='M37.8183 0.562997C41.1604 0.634256 44.1034 2.93592 44.1034 6.27796C44.1034 9.62 41.1604 12.6628 37.8183 12.6628C34.4763 12.6628 31.7114 9.95492 31.7684 6.61288C31.8326 3.22808 34.5618 0.498864 37.8183 0.562997Z'
          fill='#6F1E00'
        />
        <path
          d='M37.8261 0.570123C41.1611 0.641382 44.1112 2.93592 44.1112 6.27796C44.1112 9.62 41.1682 12.6628 37.8261 12.6628C34.4841 12.6628 31.7192 9.95492 31.7763 6.61288C31.8404 3.22808 34.5696 0.498864 37.8261 0.562997V0.570123Z'
          fill='#711F00'
        />
        <path
          d='M37.8339 0.577148C41.1688 0.648407 44.1189 2.94294 44.1189 6.27786C44.1189 9.61278 41.1688 12.6698 37.8339 12.6698C34.499 12.6698 31.727 9.96195 31.7911 6.62703C31.8553 3.24223 34.5845 0.513015 37.8339 0.584274V0.577148Z'
          fill='#721F00'
        />
        <path
          d='M37.8339 0.58425C41.1688 0.655509 44.126 2.94292 44.126 6.27784C44.126 9.61275 41.1759 12.6698 37.8339 12.6698C34.4918 12.6698 31.727 9.96192 31.7911 6.62701C31.8553 3.24221 34.5916 0.512991 37.8339 0.58425Z'
          fill='#731F00'
        />
        <path
          d='M37.8407 0.591431C41.1756 0.66269 44.1329 2.94297 44.1329 6.28502C44.1329 9.62706 41.1828 12.6841 37.8407 12.6841C34.4987 12.6841 31.7338 9.97623 31.798 6.64131C31.8621 3.25651 34.5984 0.527298 37.8407 0.598557V0.591431Z'
          fill='#752000'
        />
        <path
          d='M37.8466 0.598654C41.1815 0.669913 44.1387 2.9502 44.1387 6.28512C44.1387 9.62003 41.1815 12.6842 37.8466 12.6842C34.5117 12.6842 31.7397 9.97633 31.8038 6.64141C31.868 3.25661 34.6043 0.527395 37.8466 0.598654Z'
          fill='#762000'
        />
        <path
          d='M37.8544 0.61286C41.1893 0.684119 44.1466 2.95728 44.1466 6.2922C44.1466 9.62711 41.1893 12.6912 37.8544 12.6912C34.5195 12.6912 31.7475 9.98341 31.8116 6.64849C31.8758 3.26369 34.6121 0.534476 37.8544 0.605734V0.61286Z'
          fill='#772100'
        />
        <path
          d='M37.8544 0.619895C41.1893 0.691154 44.1537 2.95719 44.1537 6.29923C44.1537 9.64127 41.1893 12.7054 37.8544 12.7054C34.5195 12.7054 31.7475 9.99757 31.8116 6.66265C31.8758 3.27785 34.6121 0.548636 37.8544 0.619895Z'
          fill='#792100'
        />
        <path
          d='M37.8622 0.627021C41.1971 0.698279 44.1615 2.96431 44.1615 6.29923C44.1615 9.63415 41.1971 12.7054 37.8622 12.7054C34.5273 12.7054 31.7553 9.99757 31.8195 6.66265C31.8836 3.27785 34.6199 0.548636 37.8622 0.619895V0.627021Z'
          fill='#7A2100'
        />
        <path
          d='M37.87 0.634345C41.2049 0.71273 44.1693 2.96451 44.1693 6.29943C44.1693 9.63434 41.2049 12.7127 37.87 12.7127C34.5351 12.7127 31.7631 10.0049 31.8273 6.66997C31.8985 3.28518 34.6277 0.55596 37.87 0.627219V0.634345Z'
          fill='#7B2200'
        />
        <path
          d='M37.87 0.641235C41.2049 0.71962 44.1764 2.9714 44.1764 6.30632C44.1764 9.64124 41.2049 12.7196 37.87 12.7196C34.5351 12.7196 31.7631 10.0118 31.8273 6.68399C31.8985 3.29919 34.6277 0.569976 37.87 0.648361V0.641235Z'
          fill='#7D2200'
        />
        <path
          d='M37.8749 0.64856C41.2098 0.726944 44.1813 2.9716 44.1813 6.30652C44.1813 9.64143 41.2098 12.7269 37.8749 12.7269C34.5399 12.7269 31.768 10.0191 31.8392 6.69132C31.9105 3.30652 34.6397 0.577301 37.8749 0.655685V0.64856Z'
          fill='#7E2200'
        />
        <path
          d='M37.8837 0.655578C41.2186 0.733963 44.1901 2.97149 44.1901 6.30641C44.1901 9.64133 41.2186 12.7268 37.8837 12.7268C34.5487 12.7268 31.7768 10.0261 31.848 6.69121C31.9193 3.30641 34.6485 0.577193 37.8837 0.655578Z'
          fill='#7F2300'
        />
        <path
          d='M37.8905 0.662598C41.2254 0.740982 44.1969 2.97851 44.1969 6.31343C44.1969 9.64835 41.2183 12.741 37.8905 12.741C34.5627 12.741 31.7836 10.0403 31.8549 6.70535C31.9261 3.32056 34.6625 0.591339 37.8905 0.669724V0.662598Z'
          fill='#812300'
        />
        <path
          d='M37.8905 0.66986C41.2254 0.748245 44.204 2.97865 44.204 6.31357C44.204 9.64848 41.2254 12.7411 37.8905 12.7411C34.5556 12.7411 31.7836 10.0404 31.8549 6.70549C31.9261 3.32069 34.6625 0.591475 37.8905 0.66986Z'
          fill='#822300'
        />
        <path
          d='M37.8983 0.676986C41.2332 0.755371 44.2118 2.98577 44.2118 6.31357C44.2118 9.64136 41.2332 12.7411 37.8983 12.7411C34.5634 12.7411 31.7914 10.0404 31.8627 6.70549C31.9339 3.32069 34.6703 0.591475 37.8983 0.66986V0.676986Z'
          fill='#832400'
        />
        <path
          d='M37.9042 0.691222C41.2319 0.769607 44.2177 2.99289 44.2177 6.3278C44.2177 9.66272 41.2319 12.7625 37.9042 12.7625C34.5764 12.7625 31.7973 10.0618 31.8685 6.72685C31.9398 3.34205 34.6761 0.612838 37.9042 0.691222Z'
          fill='#852400'
        />
        <path
          d='M37.9042 0.698348C41.232 0.783859 44.2248 2.99289 44.2248 6.3278C44.2248 9.66272 41.2391 12.7625 37.9042 12.7625C34.5692 12.7625 31.7973 10.0618 31.8685 6.72685C31.9398 3.34205 34.6761 0.612838 37.9042 0.691222V0.698348Z'
          fill='#862500'
        />
        <path
          d='M37.911 0.70555C41.2388 0.791061 44.2317 3.00009 44.2317 6.32788C44.2317 9.65567 41.2459 12.7697 37.911 12.7697C34.5761 12.7697 31.8041 10.069 31.8754 6.73405C31.9466 3.34926 34.683 0.62004 37.911 0.698425V0.70555Z'
          fill='#872500'
        />
        <path
          d='M37.9198 0.712524C41.2476 0.798035 44.2404 2.99994 44.2404 6.33485C44.2404 9.66977 41.2476 12.7767 37.9198 12.7767C34.592 12.7767 31.8129 10.0759 31.8913 6.74815C31.9697 3.36336 34.6989 0.63414 37.9198 0.71965V0.712524Z'
          fill='#892500'
        />
        <path
          d='M37.9266 0.719604C41.2544 0.805115 44.2473 2.99989 44.2473 6.33481C44.2473 9.66972 41.2544 12.7837 37.9266 12.7837C34.5988 12.7837 31.8197 10.083 31.8981 6.75523C31.9765 3.37044 34.7057 0.64122 37.9266 0.72673V0.719604Z'
          fill='#8A2600'
        />
        <path
          d='M37.9266 0.726687C41.2544 0.812198 44.2544 3.00697 44.2544 6.33476C44.2544 9.66255 41.2615 12.7837 37.9266 12.7837C34.5917 12.7837 31.8197 10.083 31.8981 6.75519C31.9765 3.37039 34.7057 0.641177 37.9266 0.726687Z'
          fill='#8B2600'
        />
        <path
          d='M37.9325 0.734009C41.2603 0.819519 44.2603 3.00717 44.2603 6.34209C44.2603 9.677 41.2603 12.7981 37.9325 12.7981C34.6047 12.7981 31.8256 10.0974 31.904 6.76964C31.9823 3.38484 34.7116 0.655624 37.9325 0.741135V0.734009Z'
          fill='#8D2600'
        />
        <path
          d='M37.9393 0.741092C41.2671 0.826602 44.2671 3.01425 44.2671 6.34204C44.2671 9.66983 41.2671 12.7981 37.9393 12.7981C34.6115 12.7981 31.8324 10.0974 31.9108 6.7696C31.9892 3.3848 34.7184 0.655581 37.9393 0.741092Z'
          fill='#8E2700'
        />
        <path
          d='M37.9393 0.748218C41.2671 0.833728 44.2671 3.01425 44.2671 6.34204C44.2671 9.66983 41.2671 12.7981 37.9393 12.7981C34.6115 12.7981 31.8324 10.0974 31.9108 6.7696C31.9892 3.3848 34.7255 0.655581 37.9393 0.741092V0.748218Z'
          fill='#8F2700'
        />
        <path
          d='M37.9481 0.762378C41.2759 0.847888 44.283 3.02129 44.283 6.34908C44.283 9.67687 41.2759 12.8123 37.9481 12.8123C34.6203 12.8123 31.8412 10.1115 31.9196 6.78376C31.998 3.39896 34.7343 0.669741 37.9481 0.755252V0.762378Z'
          fill='#912700'
        />
        <path
          d='M37.9549 0.769702C41.2827 0.862339 44.2898 3.02861 44.2898 6.3564C44.2898 9.68419 41.2827 12.8196 37.9549 12.8196C34.6271 12.8196 31.848 10.1189 31.9264 6.79108C32.0048 3.40628 34.7411 0.677065 37.9549 0.762576V0.769702Z'
          fill='#922800'
        />
        <path
          d='M37.9608 0.776733C41.2886 0.86937 44.2957 3.02852 44.2957 6.35631C44.2957 9.6841 41.2886 12.8266 37.9608 12.8266C34.633 12.8266 31.8539 10.1259 31.9394 6.80524C32.0178 3.42044 34.7542 0.691223 37.9608 0.783859V0.776733Z'
          fill='#932800'
        />
        <path
          d='M37.9608 0.783907C41.2886 0.876543 44.3028 3.03569 44.3028 6.35635C44.3028 9.67702 41.2886 12.8267 37.9608 12.8267C34.633 12.8267 31.8539 10.1259 31.9394 6.80528C32.0249 3.42049 34.7542 0.69127 37.9608 0.783907Z'
          fill='#952900'
        />
        <path
          d='M37.9686 0.791016C41.2964 0.883652 44.3107 3.03567 44.3107 6.36346C44.3107 9.69125 41.2964 12.8409 37.9686 12.8409C34.6408 12.8409 31.8617 10.1402 31.9472 6.81952C32.0327 3.43472 34.762 0.705505 37.9686 0.798142V0.791016Z'
          fill='#962900'
        />
        <path
          d='M37.9764 0.798067C41.3042 0.890703 44.3185 3.0356 44.3185 6.36339C44.3185 9.69118 41.3042 12.8408 37.9764 12.8408C34.6486 12.8408 31.8695 10.1401 31.955 6.81944C32.0406 3.43465 34.7698 0.70543 37.9764 0.798067Z'
          fill='#972900'
        />
        <path
          d='M37.9764 0.805269C41.2971 0.897906 44.3185 3.0428 44.3185 6.36346C44.3185 9.68413 41.2971 12.848 37.9764 12.848C34.6558 12.848 31.8695 10.1544 31.955 6.82665C32.0406 3.44185 34.7698 0.712632 37.9764 0.805269Z'
          fill='#992A00'
        />
        <path
          d='M37.9833 0.812391C41.3039 0.905027 44.3324 3.04279 44.3324 6.37059C44.3324 9.69838 41.3111 12.8551 37.9833 12.8551C34.6555 12.8551 31.8764 10.1616 31.9619 6.83377C32.0474 3.44184 34.7766 0.72688 37.9833 0.812391Z'
          fill='#9A2A00'
        />
        <path
          d='M37.9901 0.819517C41.3108 0.912153 44.3393 3.04279 44.3393 6.37059C44.3393 9.69838 41.3179 12.8551 37.9901 12.8551C34.6623 12.8551 31.8832 10.1616 31.9687 6.83377C32.0542 3.44184 34.7834 0.72688 37.9901 0.812391V0.819517Z'
          fill='#9B2A00'
        />
        <path
          d='M37.9969 0.826719C41.3176 0.919355 44.3461 3.05 44.3461 6.37066C44.3461 9.69133 41.3176 12.8623 37.9969 12.8623C34.6763 12.8623 31.89 10.1688 31.9756 6.84097C32.0611 3.44905 34.7903 0.734082 37.9969 0.819593V0.826719Z'
          fill='#9C2B00'
        />
        <path
          d='M37.9966 0.840803C41.3173 0.940566 44.3458 3.05696 44.3458 6.38475C44.3458 9.71254 41.3173 12.8764 37.9966 12.8764C34.676 12.8764 31.8969 10.1828 31.9753 6.85505C32.0608 3.46313 34.7971 0.748166 37.9966 0.840803Z'
          fill='#9E2B00'
        />
        <path
          d='M38.0054 0.8479C41.3261 0.947663 44.3617 3.06405 44.3617 6.38472C44.3617 9.70538 41.3332 12.8835 38.0054 12.8835C34.6776 12.8835 31.9057 10.1899 31.9912 6.86928C32.0767 3.47735 34.813 0.76239 38.0054 0.855026V0.8479Z'
          fill='#9F2B00'
        />
        <path
          d='M38.0113 0.855207C41.332 0.95497 44.3676 3.06423 44.3676 6.3849C44.3676 9.70556 41.332 12.8837 38.0113 12.8837C34.6906 12.8837 31.9115 10.1901 31.997 6.86946C32.0826 3.47754 34.8189 0.762571 38.0113 0.855207Z'
          fill='#A02C00'
        />
        <path
          d='M38.0113 0.862305C41.332 0.962067 44.3676 3.0642 44.3676 6.392C44.3676 9.71979 41.332 12.8979 38.0113 12.8979C34.6906 12.8979 31.9115 10.2043 31.997 6.88368C32.0897 3.49176 34.8189 0.776794 38.0113 0.869431V0.862305Z'
          fill='#A22C00'
        />
        <path
          d='M38.0181 0.869367C41.3388 0.96913 44.3816 3.07127 44.3816 6.39193C44.3816 9.7126 41.3459 12.8979 38.0181 12.8979C34.6903 12.8979 31.9184 10.2043 32.0039 6.88362C32.0965 3.4917 34.8257 0.776731 38.0181 0.869367Z'
          fill='#A32C00'
        />
        <path
          d='M38.025 0.876448C41.3456 0.97621 44.3884 3.07122 44.3884 6.39189C44.3884 9.71255 41.3456 12.905 38.025 12.905C34.7043 12.905 31.9252 10.2114 32.0107 6.8907C32.1033 3.49878 34.8326 0.783811 38.025 0.876448Z'
          fill='#A42D00'
        />
        <path
          d='M38.0328 0.883772C41.3534 0.983534 44.3962 3.07855 44.3962 6.39921C44.3962 9.71988 41.3534 12.9123 38.0328 12.9123C34.7121 12.9123 31.933 10.2187 32.0185 6.89802C32.1112 3.5061 34.8404 0.791135 38.0328 0.883772Z'
          fill='#A62D00'
        />
        <path
          d='M38.0328 0.890898C41.3534 0.99066 44.3962 3.07855 44.3962 6.39921C44.3962 9.71988 41.3534 12.9123 38.0328 12.9123C34.7121 12.9123 31.933 10.2187 32.0185 6.89802C32.1112 3.5061 34.8404 0.791135 38.0328 0.883772V0.890898Z'
          fill='#A72E00'
        />
        <path
          d='M38.0406 0.897856C41.3613 0.997618 44.4111 3.07838 44.4111 6.39904C44.4111 9.71971 41.3613 12.9192 38.0406 12.9192C34.7199 12.9192 31.9408 10.2256 32.0263 6.90498C32.119 3.51306 34.8482 0.798093 38.0406 0.89073V0.897856Z'
          fill='#A82E00'
        />
        <path
          d='M38.0465 0.912231C41.3672 1.01912 44.417 3.09275 44.417 6.41342C44.417 9.73409 41.3672 12.9336 38.0465 12.9336C34.7258 12.9336 31.9467 10.24 32.0394 6.92648C32.132 3.53456 34.8612 0.819595 38.0465 0.919357V0.912231Z'
          fill='#AA2E00'
        />
        <path
          d='M38.0465 0.919189C41.3672 1.02608 44.417 3.09259 44.417 6.41325C44.417 9.73392 41.3672 12.9406 38.0465 12.9406C34.7258 12.9406 31.9467 10.247 32.0394 6.93344C32.132 3.54152 34.8683 0.826553 38.0465 0.926315V0.919189Z'
          fill='#AB2F00'
        />
        <path
          d='M38.0543 0.92637C41.3678 1.03326 44.432 3.09264 44.432 6.41331C44.432 9.73397 41.375 12.9406 38.0543 12.9406C34.7336 12.9406 31.9545 10.247 32.0472 6.9335C32.1398 3.54157 34.8762 0.826608 38.0543 0.92637Z'
          fill='#AC2F00'
        />
        <path
          d='M38.0611 0.933472C41.3747 1.04036 44.4388 3.09974 44.4388 6.42041C44.4388 9.74107 41.3818 12.9548 38.0611 12.9548C34.7405 12.9548 31.9614 10.2613 32.054 6.94772C32.1466 3.5558 34.883 0.840835 38.0611 0.940598V0.933472Z'
          fill='#AE2F00'
        />
        <path
          d='M38.0689 0.94053C41.3825 1.04742 44.4466 3.09968 44.4466 6.42034C44.4466 9.74101 41.3896 12.9548 38.0689 12.9548C34.7483 12.9548 31.9692 10.2612 32.0618 6.94766C32.1616 3.55573 34.8908 0.840768 38.0689 0.94053Z'
          fill='#AF3000'
        />
        <path
          d='M38.0689 0.947854C41.3825 1.05474 44.4466 3.107 44.4466 6.42054C44.4466 9.73408 41.3825 12.9621 38.0689 12.9621C34.7554 12.9621 31.9692 10.2685 32.0618 6.95498C32.1616 3.56306 34.8908 0.848092 38.0689 0.947854Z'
          fill='#B03000'
        />
        <path
          d='M38.0758 0.95498C41.3893 1.06187 44.4606 3.107 44.4606 6.42054C44.4606 9.73408 41.3965 12.9621 38.0758 12.9621C34.7551 12.9621 31.976 10.2756 32.0687 6.95498C32.1684 3.56306 34.8976 0.848092 38.0758 0.947854V0.95498Z'
          fill='#B23000'
        />
        <path
          d='M38.0826 0.96206C41.3962 1.06895 44.4674 3.10695 44.4674 6.42762C44.4674 9.74828 41.3962 12.9692 38.0826 12.9692C34.7691 12.9692 31.9829 10.2827 32.0755 6.96206C32.1753 3.57014 34.9045 0.855172 38.0826 0.954934V0.96206Z'
          fill='#B33100'
        />
        <path
          d='M38.0827 0.969238C41.3962 1.08325 44.4675 3.11413 44.4675 6.42767C44.4675 9.74121 41.3962 12.9764 38.0827 12.9764C34.7691 12.9764 31.9829 10.2899 32.0827 6.97636C32.1824 3.58444 34.9117 0.869476 38.0827 0.976364V0.969238Z'
          fill='#B43100'
        />
        <path
          d='M38.0905 0.976074C41.404 1.09009 44.4753 3.11384 44.4753 6.42738C44.4753 9.74092 41.404 12.9761 38.0905 12.9761C34.777 12.9761 31.9907 10.2896 32.0905 6.97607C32.1903 3.58415 34.9195 0.869185 38.0905 0.976074Z'
          fill='#B63200'
        />
        <path
          d='M38.0973 0.990601C41.4109 1.10461 44.4893 3.12837 44.4893 6.44191C44.4893 9.75545 41.4109 12.9977 38.0973 12.9977C34.7838 12.9977 31.9976 10.3113 32.0973 6.99773C32.1971 3.6058 34.9263 0.890838 38.0973 0.997726V0.990601Z'
          fill='#B73200'
        />
        <path
          d='M38.1042 0.99768C41.4177 1.11169 44.4961 3.12832 44.4961 6.44186C44.4961 9.7554 41.4177 12.9977 38.1042 12.9977C34.7906 12.9977 32.0044 10.3112 32.1042 6.99768C32.2039 3.60576 34.9331 0.890792 38.1042 0.99768Z'
          fill='#B83200'
        />
        <path
          d='M38.1042 1.00464C41.4177 1.11865 44.4961 3.12815 44.4961 6.44169C44.4961 9.75523 41.4177 13.0046 38.1042 13.0046C34.7906 13.0046 32.0044 10.3182 32.1042 7.00464C32.2039 3.61271 34.9403 0.89775 38.1042 1.00464Z'
          fill='#BA3300'
        />
        <path
          d='M44.51 6.44902C44.51 9.76256 41.4245 13.012 38.111 13.012C34.7975 13.012 32.0112 10.3255 32.111 7.01196C32.2179 3.62004 34.9471 0.905074 38.111 1.01196C41.4245 1.12598 44.51 3.13548 44.51 6.44902Z'
          fill='#BB3300'
        />
        <path
          opacity='0.99'
          d='M38.1188 1.01909C41.4324 1.1331 44.5107 3.1426 44.5107 6.45614C44.5107 9.76968 41.4324 13.012 38.1188 13.012C34.8053 13.012 32.019 10.3255 32.1188 7.01196C32.2186 3.62004 34.9478 0.905074 38.1188 1.01196V1.01909Z'
          fill='#BB3402'
        />
        <path
          opacity='0.99'
          d='M38.1188 1.026C41.4324 1.14001 44.5107 3.15664 44.5107 6.47018C44.5107 9.78372 41.4324 13.026 38.1188 13.026C34.8053 13.026 32.019 10.3395 32.1188 7.026C32.2186 3.63408 34.9478 0.919112 38.1188 1.026Z'
          fill='#BC3604'
        />
        <path
          opacity='0.98'
          d='M38.1256 1.02617C41.4392 1.14018 44.5104 3.15681 44.5104 6.47035C44.5104 9.78389 41.4392 13.019 38.1256 13.019C34.8121 13.019 32.0259 10.3326 32.1256 7.01904C32.2254 3.62712 34.9546 0.912154 38.1256 1.01904V1.02617Z'
          fill='#BC3705'
        />
        <path
          opacity='0.97'
          d='M38.1256 1.0332C41.4392 1.14721 44.5104 3.17096 44.5104 6.4845C44.5104 9.79804 41.4392 13.0332 38.1256 13.0332C34.8121 13.0332 32.0259 10.3467 32.1256 7.0332C32.2254 3.6484 34.9546 0.926307 38.1256 1.0332Z'
          fill='#BD3907'
        />
        <path
          opacity='0.96'
          d='M38.1335 1.04052C41.447 1.15453 44.5183 3.18541 44.5183 6.49895C44.5183 9.81249 41.447 13.0405 38.1335 13.0405C34.8199 13.0405 32.0337 10.3541 32.1335 7.04052C32.2332 3.65572 34.9624 0.933631 38.1335 1.04052Z'
          fill='#BD3A09'
        />
        <path
          opacity='0.96'
          d='M38.1331 1.0476C41.4467 1.15449 44.5108 3.19249 44.5108 6.50603C44.5108 9.81957 41.4467 13.0476 38.1331 13.0476C34.8196 13.0476 32.0405 10.3611 32.1331 7.0476C32.2329 3.6628 34.9621 0.940711 38.1331 1.0476Z'
          fill='#BE3C0B'
        />
        <path
          opacity='0.95'
          d='M38.139 1.05467C41.4525 1.16155 44.5166 3.20669 44.5166 6.52022C44.5166 9.83376 41.4525 13.0547 38.139 13.0547C34.8254 13.0547 32.0463 10.3682 32.139 7.05467C32.2387 3.66987 34.968 0.954903 38.139 1.05467Z'
          fill='#BE3D0D'
        />
        <path
          opacity='0.94'
          d='M38.139 1.06175C41.4525 1.16863 44.5095 3.21376 44.5095 6.5273C44.5095 9.84084 41.4525 13.0617 38.139 13.0617C34.8254 13.0617 32.0463 10.3753 32.139 7.06175C32.2387 3.67695 34.9608 0.961984 38.139 1.06175Z'
          fill='#BF3F0E'
        />
        <path
          opacity='0.94'
          d='M38.1468 1.06175C41.4603 1.16863 44.5173 3.22089 44.5173 6.53443C44.5173 9.84797 41.4603 13.0617 38.1468 13.0617C34.8332 13.0617 32.0541 10.3753 32.1468 7.06175C32.2465 3.67695 34.9686 0.961984 38.1468 1.06175Z'
          fill='#BF4010'
        />
        <path
          opacity='0.93'
          d='M38.1546 1.06887C41.4681 1.17576 44.5251 3.22802 44.5251 6.54156C44.5251 9.85509 41.4681 13.0617 38.1546 13.0617C34.8411 13.0617 32.062 10.3753 32.1546 7.06175C32.2472 3.67695 34.9764 0.961984 38.1546 1.06175V1.06887Z'
          fill='#C04112'
        />
        <path
          opacity='0.92'
          d='M38.1546 1.07603C41.4681 1.18292 44.518 3.2423 44.518 6.55584C44.518 9.86938 41.4681 13.076 38.1546 13.076C34.8411 13.076 32.062 10.3896 32.1546 7.07603C32.2472 3.69123 34.9764 0.976266 38.1546 1.07603Z'
          fill='#C04314'
        />
        <path
          opacity='0.91'
          d='M38.1614 1.08315C41.475 1.19004 44.5249 3.24942 44.5249 6.56296C44.5249 9.8765 41.475 13.076 38.1614 13.076C34.8479 13.076 32.0688 10.3896 32.1614 7.07603C32.2541 3.69123 34.9833 0.976266 38.1614 1.07603V1.08315Z'
          fill='#C14416'
        />
        <path
          opacity='0.91'
          d='M38.1614 1.09019C41.475 1.19708 44.5177 3.26358 44.5177 6.57712C44.5177 9.89066 41.475 13.0902 38.1614 13.0902C34.8479 13.0902 32.0688 10.4037 32.1614 7.09019C32.2541 3.70539 34.9833 0.990426 38.1614 1.09019Z'
          fill='#C14618'
        />
        <path
          opacity='0.9'
          d='M38.1683 1.09727C41.4818 1.19703 44.5246 3.27779 44.5246 6.59133C44.5246 9.90487 41.4818 13.0973 38.1683 13.0973C34.8547 13.0973 32.0756 10.4108 32.1683 7.09727C32.2609 3.71247 34.9901 0.997506 38.1683 1.09727Z'
          fill='#C24719'
        />
        <path
          opacity='0.89'
          d='M38.1683 1.09727C41.4818 1.19703 44.5246 3.27779 44.5246 6.59133C44.5246 9.90487 41.4818 13.0973 38.1683 13.0973C34.8547 13.0973 32.0756 10.4108 32.1683 7.09727C32.2609 3.71247 34.983 0.997506 38.1683 1.09727Z'
          fill='#C2491B'
        />
        <path
          opacity='0.89'
          d='M38.1748 1.10448C41.4883 1.20424 44.5239 3.29213 44.5239 6.60567C44.5239 9.91921 41.4883 13.1045 38.1748 13.1045C34.8612 13.1045 32.0893 10.418 32.1748 7.10448C32.2674 3.71968 34.9895 1.01184 38.1748 1.10448Z'
          fill='#C34A1D'
        />
        <path
          opacity='0.88'
          d='M38.1826 1.11156C41.4961 1.21132 44.5318 3.2992 44.5318 6.61274C44.5318 9.92628 41.4961 13.1116 38.1826 13.1116C34.8691 13.1116 32.0971 10.4251 32.1826 7.11155C32.2752 3.73388 34.9973 1.01892 38.1826 1.11156Z'
          fill='#C34C1F'
        />
        <path
          opacity='0.87'
          d='M38.1826 1.11888C41.4961 1.21864 44.5246 3.31365 44.5246 6.62719C44.5246 9.94073 41.4961 13.1189 38.1826 13.1189C34.8691 13.1189 32.0971 10.4324 32.1826 7.11888C32.2752 3.74121 34.9973 1.02624 38.1826 1.11888Z'
          fill='#C44D21'
        />
        <path
          opacity='0.87'
          d='M38.1904 1.12601C41.5039 1.22577 44.5324 3.32078 44.5324 6.63432C44.5324 9.94786 41.5039 13.1189 38.1904 13.1189C34.8769 13.1189 32.1049 10.4324 32.1904 7.11888C32.283 3.74121 35.0051 1.02624 38.1904 1.11888V1.12601Z'
          fill='#C44E22'
        />
        <path
          opacity='0.86'
          d='M38.1904 1.13292C41.5039 1.23268 44.5324 3.33482 44.5324 6.64836C44.5324 9.9619 41.5039 13.1329 38.1904 13.1329C34.8769 13.1329 32.1049 10.4465 32.1904 7.13292C32.2759 3.75524 35.0051 1.04028 38.1904 1.13292Z'
          fill='#C55024'
        />
        <path
          opacity='0.85'
          d='M38.1972 1.14004C41.5108 1.23981 44.5322 3.34907 44.5322 6.65548C44.5322 9.9619 41.5108 13.1329 38.1972 13.1329C34.8837 13.1329 32.1117 10.4465 32.1972 7.13292C32.2828 3.75524 35.012 1.04028 38.1972 1.13292V1.14004Z'
          fill='#C55126'
        />
        <path
          opacity='0.84'
          d='M38.1972 1.14012C41.5108 1.23988 44.5322 3.34915 44.5322 6.66268C44.5322 9.97622 41.5108 13.1401 38.1972 13.1401C34.8837 13.1401 32.1117 10.4537 32.1972 7.14012C32.2828 3.76245 35.0048 1.04748 38.1972 1.14012Z'
          fill='#C65328'
        />
        <path
          opacity='0.84'
          d='M38.2031 1.14732C41.5166 1.23996 44.5309 3.36347 44.5309 6.67701C44.5309 9.99055 41.5166 13.1473 38.2031 13.1473C34.8896 13.1473 32.1176 10.4609 32.2031 7.14732C32.2886 3.76965 35.0107 1.05469 38.2031 1.14732Z'
          fill='#C6542A'
        />
        <path
          opacity='0.83'
          d='M38.2031 1.1544C41.5166 1.24704 44.5309 3.37055 44.5309 6.68409C44.5309 9.99763 41.5166 13.1544 38.2031 13.1544C34.8896 13.1544 32.1176 10.4679 32.2031 7.1544C32.2886 3.77673 35.0107 1.06177 38.2031 1.1544Z'
          fill='#C7562B'
        />
        <path
          opacity='0.82'
          d='M38.2109 1.16152C41.5245 1.25415 44.5387 3.38479 44.5387 6.69833C44.5387 10.0119 41.5245 13.1615 38.2109 13.1615C34.8974 13.1615 32.1254 10.4751 32.2109 7.16152C32.2964 3.78384 35.0185 1.07601 38.2109 1.16152Z'
          fill='#C7572D'
        />
        <path
          opacity='0.82'
          d='M38.2184 1.16864C41.532 1.26128 44.5391 3.39192 44.5391 6.70546C44.5391 10.019 41.532 13.1615 38.2184 13.1615C34.9049 13.1615 32.14 10.4751 32.2184 7.16152C32.3039 3.78384 35.026 1.07601 38.2184 1.16152V1.16864Z'
          fill='#C8592F'
        />
        <path
          opacity='0.81'
          d='M38.2184 1.17568C41.532 1.26831 44.5391 3.40608 44.5391 6.71962C44.5391 10.0332 41.532 13.1757 38.2184 13.1757C34.9049 13.1757 32.14 10.4892 32.2184 7.17568C32.3039 3.798 35.026 1.09017 38.2184 1.17568Z'
          fill='#C85A31'
        />
        <path
          opacity='0.8'
          d='M38.2253 1.17572C41.5388 1.26836 44.5388 3.40613 44.5388 6.71967C44.5388 10.0332 41.5388 13.1686 38.2253 13.1686C34.9117 13.1686 32.1469 10.4821 32.2253 7.1686C32.3108 3.79092 35.0329 1.08309 38.2253 1.1686V1.17572Z'
          fill='#C95C33'
        />
        <path
          opacity='0.79'
          d='M38.2253 1.183C41.5388 1.27564 44.5388 3.42053 44.5388 6.73407C44.5388 10.0476 41.5388 13.183 38.2253 13.183C34.9117 13.183 32.1469 10.4965 32.2253 7.183C32.3108 3.80533 35.0257 1.09749 38.2253 1.183Z'
          fill='#C95D34'
        />
        <path
          opacity='0.79'
          d='M38.2321 1.19008C41.5456 1.28271 44.5456 3.43473 44.5456 6.74827C44.5456 10.0618 41.5456 13.1901 38.2321 13.1901C34.9186 13.1901 32.1537 10.5036 32.2321 7.19008C32.3105 3.81953 35.0326 1.10457 38.2321 1.19008Z'
          fill='#C95E36'
        />
        <path
          opacity='0.78'
          d='M38.2321 1.19703C41.5456 1.28967 44.5385 3.44169 44.5385 6.75523C44.5385 10.0688 41.5456 13.197 38.2321 13.197C34.9186 13.197 32.1537 10.5106 32.2321 7.19703C32.3105 3.82649 35.0326 1.11152 38.2321 1.19703Z'
          fill='#CA6038'
        />
        <path
          opacity='0.77'
          d='M38.2389 1.20424C41.5525 1.28975 44.5453 3.45602 44.5453 6.76956C44.5453 10.0831 41.5525 13.2042 38.2389 13.2042C34.9254 13.2042 32.1605 10.5178 32.2389 7.20424C32.3173 3.83369 35.0394 1.11873 38.2389 1.20424Z'
          fill='#CA613A'
        />
        <path
          opacity='0.77'
          d='M38.2389 1.21144C41.5525 1.29695 44.5453 3.46322 44.5453 6.77676C44.5453 10.0903 41.5525 13.2114 38.2389 13.2114C34.9254 13.2114 32.1605 10.525 32.2389 7.21144C32.3173 3.84089 35.0394 1.12593 38.2389 1.21144Z'
          fill='#CB633C'
        />
        <path
          opacity='0.76'
          d='M38.2467 1.21144C41.5603 1.29695 44.546 3.47035 44.546 6.78389C44.546 10.0974 41.5603 13.2114 38.2467 13.2114C34.9332 13.2114 32.1684 10.525 32.2467 7.21144C32.3251 3.84089 35.0472 1.12593 38.2467 1.21144Z'
          fill='#CB643D'
        />
        <path
          opacity='0.75'
          d='M38.2546 1.2185C41.5681 1.30401 44.5538 3.47741 44.5538 6.79095C44.5538 10.1045 41.5681 13.2114 38.2546 13.2114C34.941 13.2114 32.1762 10.5249 32.2546 7.21138C32.3329 3.84083 35.0479 1.13299 38.2546 1.21138V1.2185Z'
          fill='#CC663F'
        />
        <path
          opacity='0.74'
          d='M38.2543 1.22566C41.5678 1.31117 44.5464 3.49169 44.5464 6.80523C44.5464 10.1188 41.5678 13.2257 38.2543 13.2257C34.9407 13.2257 32.183 10.5392 32.2543 7.22566C32.3327 3.85511 35.0476 1.14727 38.2543 1.22566Z'
          fill='#CC6741'
        />
        <path
          opacity='0.74'
          d='M38.2611 1.23278C41.5747 1.3183 44.5533 3.50594 44.5533 6.81236C44.5533 10.1188 41.5747 13.2257 38.2611 13.2257C34.9476 13.2257 32.1899 10.5392 32.2611 7.22566C32.3395 3.85511 35.0545 1.14727 38.2611 1.22566V1.23278Z'
          fill='#CD6943'
        />
        <path
          opacity='0.73'
          d='M38.2611 1.23982C41.5747 1.32533 44.5533 3.51298 44.5533 6.82652C44.5533 10.1401 41.5747 13.2398 38.2611 13.2398C34.9476 13.2398 32.1899 10.5534 32.2611 7.23982C32.3395 3.86927 35.0545 1.16143 38.2611 1.23982Z'
          fill='#CD6A45'
        />
        <path
          opacity='0.72'
          d='M38.267 1.24714C41.5805 1.33265 44.552 3.52743 44.552 6.84097C44.552 10.1545 41.5805 13.2471 38.267 13.2471C34.9534 13.2471 32.1957 10.5607 32.267 7.24714C32.3382 3.8766 35.0603 1.16876 38.267 1.24714Z'
          fill='#CE6B47'
        />
        <path
          opacity='0.72'
          d='M38.267 1.25422C41.5805 1.33973 44.552 3.53451 44.552 6.84805C44.552 10.1616 41.5805 13.2542 38.267 13.2542C34.9534 13.2542 32.1957 10.5678 32.267 7.25422C32.3382 3.88368 35.0603 1.17584 38.267 1.25422Z'
          fill='#CE6D48'
        />
        <path
          opacity='0.71'
          d='M38.2748 1.25422C41.5883 1.33261 44.5527 3.54163 44.5527 6.85517C44.5527 10.1687 41.5883 13.2542 38.2748 13.2542C34.9612 13.2542 32.2035 10.5678 32.2748 7.25422C32.346 3.88368 35.0681 1.17584 38.2748 1.25422Z'
          fill='#CF6E4A'
        />
        <path
          opacity='0.7'
          d='M38.2826 1.26135C41.5961 1.33973 44.5605 3.54876 44.5605 6.8623C44.5605 10.1758 41.5961 13.2542 38.2826 13.2542C34.9691 13.2542 32.2113 10.5678 32.2826 7.25422C32.3539 3.88368 35.0688 1.17584 38.2826 1.25422V1.26135Z'
          fill='#CF704C'
        />
        <path
          opacity='0.7'
          d='M38.2826 1.26838C41.5961 1.34676 44.5605 3.56292 44.5605 6.87646C44.5605 10.19 41.5961 13.2684 38.2826 13.2684C34.9691 13.2684 32.2113 10.5819 32.2826 7.26838C32.3539 3.90496 35.0688 1.18999 38.2826 1.26838Z'
          fill='#D0714E'
        />
        <path
          opacity='0.69'
          d='M38.2894 1.27547C41.603 1.35385 44.5602 3.57001 44.5602 6.88354C44.5602 10.1971 41.603 13.2683 38.2894 13.2683C34.9759 13.2683 32.2182 10.5819 32.2894 7.26834C32.3607 3.90492 35.0757 1.19708 38.2894 1.26834V1.27547Z'
          fill='#D07350'
        />
        <path
          opacity='0.68'
          d='M38.2894 1.28275C41.603 1.36113 44.5602 3.58441 44.5602 6.89795C44.5602 10.2115 41.603 13.2827 38.2894 13.2827C34.9759 13.2827 32.2182 10.5963 32.2894 7.28275C32.3607 3.91933 35.0757 1.21149 38.2894 1.28275Z'
          fill='#D17451'
        />
        <path
          opacity='0.67'
          d='M38.296 1.28983C41.6096 1.36821 44.5597 3.59862 44.5597 6.91216C44.5597 10.2257 41.6096 13.2898 38.296 13.2898C34.9825 13.2898 32.2319 10.6034 32.296 7.28983C32.3673 3.92641 35.0822 1.21857 38.296 1.28983Z'
          fill='#D17653'
        />
        <path
          opacity='0.67'
          d='M38.296 1.28983C41.6096 1.36821 44.5597 3.59862 44.5597 6.91216C44.5597 10.2257 41.6096 13.2898 38.296 13.2898C34.9825 13.2898 32.2319 10.6034 32.296 7.28983C32.3673 3.92641 35.0822 1.21857 38.296 1.28983Z'
          fill='#D27755'
        />
        <path
          opacity='0.66'
          d='M38.3029 1.29691C41.6164 1.37529 44.5665 3.61282 44.5665 6.92636C44.5665 10.2399 41.6164 13.2969 38.3029 13.2969C34.9893 13.2969 32.2387 10.6104 32.3029 7.29691C32.3741 3.93349 35.0891 1.22565 38.3029 1.29691Z'
          fill='#D27857'
        />
        <path
          opacity='0.65'
          d='M38.3029 1.30399C41.6164 1.37525 44.5594 3.6199 44.5594 6.93344C44.5594 10.247 41.6164 13.304 38.3029 13.304C34.9893 13.304 32.2387 10.6175 32.3029 7.30399C32.367 3.94057 35.082 1.23273 38.3029 1.30399Z'
          fill='#D37A59'
        />
        <path
          opacity='0.65'
          d='M38.3107 1.31119C41.6242 1.38245 44.5672 3.63423 44.5672 6.94777C44.5672 10.2613 41.6242 13.3112 38.3107 13.3112C34.9971 13.3112 32.2465 10.6247 32.3107 7.31119C32.3748 3.94777 35.0898 1.23993 38.3107 1.31119Z'
          fill='#D37B5A'
        />
        <path
          opacity='0.64'
          d='M38.3185 1.31832C41.632 1.38957 44.5679 3.64136 44.5679 6.9549C44.5679 10.2684 41.632 13.3112 38.3185 13.3112C35.0049 13.3112 32.2543 10.6247 32.3185 7.31119C32.3826 3.94777 35.0976 1.23993 38.3185 1.31119V1.31832Z'
          fill='#D47D5C'
        />
        <path
          opacity='0.63'
          d='M38.3185 1.32535C41.632 1.39661 44.5679 3.65552 44.5679 6.96906C44.5679 10.2826 41.632 13.3254 38.3185 13.3254C35.0049 13.3254 32.2543 10.6389 32.3185 7.32535C32.3826 3.96193 35.0976 1.25409 38.3185 1.32535Z'
          fill='#D47E5E'
        />
        <path
          opacity='0.62'
          d='M38.3253 1.32551C41.6389 1.39676 44.5747 3.65567 44.5747 6.96921C44.5747 10.2828 41.6389 13.3184 38.3253 13.3184C35.0118 13.3184 32.2612 10.6319 32.3253 7.31838C32.3895 3.95496 35.1044 1.25425 38.3253 1.31838V1.32551Z'
          fill='#D58060'
        />
        <path
          opacity='0.62'
          d='M38.3253 1.33242C41.6389 1.40368 44.5676 3.66971 44.5676 6.98325C44.5676 10.2968 41.6389 13.3324 38.3253 13.3324C35.0118 13.3324 32.2612 10.646 32.3253 7.33242C32.3895 3.969 35.1044 1.26828 38.3253 1.33242Z'
          fill='#D58162'
        />
        <path
          opacity='0.61'
          d='M38.3312 1.33962C41.6447 1.41088 44.5735 3.68404 44.5735 6.99758C44.5735 10.3111 41.6447 13.3396 38.3312 13.3396C35.0176 13.3396 32.267 10.6532 32.3312 7.33962C32.3953 3.9762 35.1103 1.27549 38.3312 1.33962Z'
          fill='#D68363'
        />
        <path
          opacity='0.6'
          d='M38.331 1.34694C41.6445 1.4182 44.5661 3.69136 44.5661 7.0049C44.5661 10.3184 41.6445 13.3469 38.331 13.3469C35.0174 13.3469 32.2739 10.6605 32.331 7.34694C32.3951 3.99065 35.1029 1.28281 38.331 1.34694Z'
          fill='#D68465'
        />
        <path
          opacity='0.6'
          d='M38.3397 1.3539C41.6533 1.42516 44.5749 3.70544 44.5749 7.01898C44.5749 10.3325 41.6533 13.3539 38.3397 13.3539C35.0262 13.3539 32.2827 10.6674 32.3397 7.3539C32.4039 3.9976 35.1117 1.28977 38.3397 1.3539Z'
          fill='#D68567'
        />
        <path
          opacity='0.59'
          d='M38.3397 1.36103C41.6533 1.42516 44.5749 3.71257 44.5749 7.02611C44.5749 10.3396 41.6533 13.3539 38.3397 13.3539C35.0262 13.3539 32.2827 10.6674 32.3397 7.3539C32.4039 3.9976 35.1117 1.28977 38.3397 1.3539V1.36103Z'
          fill='#D78769'
        />
        <path
          opacity='0.58'
          d='M38.3466 1.36098C41.6601 1.42511 44.5746 3.71965 44.5746 7.03319C44.5746 10.3467 41.6601 13.361 38.3466 13.361C35.033 13.361 32.2896 10.6745 32.3466 7.36098C32.4036 4.00469 35.1185 1.29685 38.3466 1.36098Z'
          fill='#D7886B'
        />
        <path
          opacity='0.57'
          d='M38.3544 1.36811C41.6679 1.43224 44.5824 3.72678 44.5824 7.04031C44.5824 10.3539 41.6679 13.361 38.3544 13.361C35.0408 13.361 32.2974 10.6745 32.3544 7.36098C32.4114 4.00469 35.1264 1.29685 38.3544 1.36098V1.36811Z'
          fill='#D88A6D'
        />
        <path
          opacity='0.57'
          d='M38.3544 1.37538C41.6679 1.43952 44.5753 3.74118 44.5753 7.05472C44.5753 10.3683 41.6679 13.3754 38.3544 13.3754C35.0408 13.3754 32.2974 10.6889 32.3544 7.37538C32.4114 4.01909 35.1264 1.31125 38.3544 1.37538Z'
          fill='#D88B6E'
        />
        <path
          opacity='0.56'
          d='M38.3602 1.38248C41.6738 1.44661 44.5812 3.7554 44.5812 7.06894C44.5812 10.3825 41.6738 13.3825 38.3602 13.3825C35.0467 13.3825 32.3032 10.696 32.3602 7.38248C32.4173 4.02618 35.1322 1.32547 38.3602 1.38248Z'
          fill='#D98D70'
        />
        <path
          opacity='0.55'
          d='M38.3602 1.38956C41.6738 1.45369 44.5812 3.76248 44.5812 7.07602C44.5812 10.3896 41.6738 13.3896 38.3602 13.3896C35.0467 13.3896 32.3032 10.7031 32.3602 7.38956C32.4173 4.03326 35.1251 1.33255 38.3602 1.38956Z'
          fill='#D98E72'
        />
        <path
          opacity='0.55'
          d='M38.3671 1.39652C41.6806 1.46065 44.5809 3.77656 44.5809 7.0901C44.5809 10.4036 41.6806 13.3965 38.3671 13.3965C35.0535 13.3965 32.3101 10.7101 32.3671 7.39652C32.4241 4.04022 35.1319 1.33951 38.3671 1.39652Z'
          fill='#DA9074'
        />
        <path
          opacity='0.54'
          d='M38.3671 1.40384C41.6806 1.46797 44.5809 3.78389 44.5809 7.09743C44.5809 10.411 41.6806 13.4038 38.3671 13.4038C35.0535 13.4038 32.3101 10.7174 32.3671 7.40384C32.4241 4.04755 35.1319 1.34683 38.3671 1.40384Z'
          fill='#DA9176'
        />
        <path
          opacity='0.53'
          d='M38.3757 1.40384C41.6892 1.46797 44.5823 3.79101 44.5823 7.10455C44.5823 10.4181 41.6892 13.4038 38.3757 13.4038C35.0621 13.4038 32.3258 10.7174 32.3757 7.40384C32.4327 4.04755 35.1405 1.34683 38.3757 1.40384Z'
          fill='#DB9277'
        />
        <path
          opacity='0.52'
          d='M38.3825 1.41097C41.696 1.46797 44.5892 3.79814 44.5892 7.11168C44.5892 10.4252 41.696 13.4038 38.3825 13.4038C35.069 13.4038 32.3326 10.7174 32.3825 7.40384C32.4395 4.04755 35.1474 1.34683 38.3825 1.40384V1.41097Z'
          fill='#DB9479'
        />
        <path
          opacity='0.52'
          d='M38.3825 1.418C41.696 1.47501 44.5892 3.8123 44.5892 7.12584C44.5892 10.4394 41.696 13.418 38.3825 13.418C35.069 13.418 32.3326 10.7315 32.3825 7.418C32.4395 4.06171 35.1474 1.36099 38.3825 1.418Z'
          fill='#DC957B'
        />
        <path
          opacity='0.51'
          d='M38.3893 1.42512C41.7029 1.48213 44.5889 3.81942 44.5889 7.13296C44.5889 10.4465 41.7029 13.418 38.3893 13.418C35.0758 13.418 32.3395 10.7315 32.3893 7.418C32.4392 4.06883 35.1471 1.36099 38.3893 1.418V1.42512Z'
          fill='#DC977D'
        />
        <path
          opacity='0.5'
          d='M38.3893 1.4324C41.7029 1.48941 44.5889 3.83383 44.5889 7.14737C44.5889 10.4609 41.7029 13.4324 38.3893 13.4324C35.0758 13.4324 32.3395 10.7459 32.3893 7.4324C32.4392 4.08323 35.1471 1.3754 38.3893 1.4324Z'
          fill='#DD987F'
        />
        <path
          opacity='0.5'
          d='M38.3952 1.43952C41.7087 1.49653 44.5876 3.84807 44.5876 7.16161C44.5876 10.4752 41.7016 13.4395 38.3952 13.4395C35.0888 13.4395 32.3453 10.7531 32.3952 7.43952C32.4451 4.09035 35.1529 1.38964 38.3952 1.43952Z'
          fill='#DD9A80'
        />
        <path
          opacity='0.49'
          d='M38.3952 1.43952C41.7087 1.49653 44.5876 3.84807 44.5876 7.16161C44.5876 10.4752 41.7087 13.4395 38.3952 13.4395C35.0817 13.4395 32.3453 10.7531 32.3952 7.43952C32.4451 4.09035 35.1529 1.38964 38.3952 1.43952Z'
          fill='#DE9B82'
        />
        <path
          opacity='0.48'
          d='M38.403 1.4466C41.7166 1.50361 44.5954 3.86228 44.5954 7.17582C44.5954 10.4894 41.7166 13.4466 38.403 13.4466C35.0895 13.4466 32.3531 10.7601 32.403 7.4466C32.4529 4.09743 35.1607 1.39672 38.403 1.4466Z'
          fill='#DE9D84'
        />
        <path
          opacity='0.48'
          d='M38.403 1.45356C41.7166 1.51057 44.5883 3.86924 44.5883 7.18278C44.5883 10.4963 41.7166 13.4536 38.403 13.4536C35.0895 13.4536 32.3531 10.7671 32.403 7.45356C32.4529 4.10439 35.1607 1.40368 38.403 1.45356Z'
          fill='#DF9E86'
        />
        <path
          opacity='0.47'
          d='M38.4118 1.46076C41.7253 1.51777 44.5971 3.88357 44.5971 7.1971C44.5971 10.5106 41.7253 13.4608 38.4118 13.4608C35.0983 13.4608 32.3619 10.7743 32.4118 7.46076C32.4617 4.11159 35.1695 1.41088 38.4118 1.46076Z'
          fill='#DFA088'
        />
        <path
          opacity='0.46'
          d='M38.4175 1.46789C41.731 1.51777 44.6028 3.89069 44.6028 7.20423C44.6028 10.5178 41.731 13.4608 38.4175 13.4608C35.1039 13.4608 32.3747 10.7743 32.4175 7.46076C32.4674 4.11159 35.1681 1.41088 38.4175 1.46076V1.46789Z'
          fill='#E0A189'
        />
        <path
          opacity='0.45'
          d='M38.4175 1.47505C41.731 1.52493 44.5956 3.90497 44.5956 7.21851C44.5956 10.5321 41.731 13.475 38.4175 13.475C35.1039 13.475 32.3747 10.7886 32.4175 7.47505C32.4674 4.12588 35.1681 1.42516 38.4175 1.47505Z'
          fill='#E0A28B'
        />
        <path
          opacity='0.45'
          d='M38.4243 1.47509C41.7379 1.52497 44.6025 3.91215 44.6025 7.21856C44.6025 10.525 41.7379 13.468 38.4243 13.468C35.1108 13.468 32.3816 10.7815 32.4243 7.46796C32.4671 4.1188 35.1749 1.41808 38.4243 1.46797V1.47509Z'
          fill='#E1A48D'
        />
        <path
          opacity='0.44'
          d='M38.4243 1.48213C41.7379 1.53201 44.5953 3.91918 44.5953 7.23272C44.5953 10.5463 41.7379 13.4821 38.4243 13.4821C35.1108 13.4821 32.3816 10.7957 32.4243 7.48213C32.4671 4.13296 35.1749 1.43224 38.4243 1.48213Z'
          fill='#E1A58F'
        />
        <path
          opacity='0.43'
          d='M38.4312 1.48915C41.7447 1.53903 44.6022 3.93333 44.6022 7.24687C44.6022 10.5604 41.7447 13.4891 38.4312 13.4891C35.1176 13.4891 32.3884 10.8027 32.4312 7.48915C32.4739 4.13998 35.1818 1.44639 38.4312 1.48915Z'
          fill='#E2A791'
        />
        <path
          opacity='0.43'
          d='M38.4312 1.49647C41.7447 1.54635 44.6022 3.94065 44.6022 7.25419C44.6022 10.5677 41.7447 13.4965 38.4312 13.4965C35.1176 13.4965 32.3884 10.81 32.4312 7.49647C32.4739 4.1473 35.1818 1.45372 38.4312 1.49647Z'
          fill='#E2A892'
        />
        <path
          opacity='0.42'
          d='M38.439 1.50367C41.7525 1.55356 44.6029 3.95498 44.6029 7.26852C44.6029 10.5821 41.7525 13.5037 38.439 13.5037C35.1254 13.5037 32.3962 10.8172 32.439 7.50367C32.4817 4.16163 35.1896 1.46092 38.439 1.50367Z'
          fill='#E3AA94'
        />
        <path
          opacity='0.41'
          d='M38.4468 1.5108C41.7603 1.56068 44.6107 3.96211 44.6107 7.27565C44.6107 10.5892 41.7603 13.5037 38.4468 13.5037C35.1332 13.5037 32.404 10.8172 32.4468 7.50367C32.4895 4.16163 35.1903 1.46092 38.4468 1.50367V1.5108Z'
          fill='#E3AB96'
        />
        <path
          opacity='0.4'
          d='M38.4468 1.51063C41.7603 1.56051 44.6036 3.96906 44.6036 7.2826C44.6036 10.5961 41.7603 13.5106 38.4468 13.5106C35.1332 13.5106 32.404 10.8242 32.4468 7.51063C32.4895 4.16859 35.1903 1.46788 38.4468 1.51063Z'
          fill='#E4AD98'
        />
        <path
          opacity='0.4'
          d='M38.4535 1.51776C41.767 1.56051 44.6102 3.97619 44.6102 7.28973C44.6102 10.6033 41.767 13.5106 38.4535 13.5106C35.1399 13.5106 32.4178 10.8242 32.4535 7.51063C32.4962 4.16859 35.1969 1.46788 38.4535 1.51063V1.51776Z'
          fill='#E4AE9A'
        />
        <path
          opacity='0.39'
          d='M38.4535 1.52504C41.767 1.56779 44.6102 3.9906 44.6102 7.30413C44.6102 10.6177 41.767 13.525 38.4535 13.525C35.1399 13.525 32.4178 10.8386 32.4535 7.52504C32.4962 4.18299 35.1969 1.48228 38.4535 1.52504Z'
          fill='#E4AF9C'
        />
        <path
          opacity='0.38'
          d='M38.4593 1.53199C41.7729 1.57475 44.609 4.00468 44.609 7.31822C44.609 10.6318 41.7729 13.532 38.4593 13.532C35.1458 13.532 32.4237 10.8455 32.4593 7.532C32.5021 4.18995 35.2028 1.48924 38.4593 1.53199Z'
          fill='#E5B19D'
        />
        <path
          opacity='0.38'
          d='M38.4593 1.53907C41.7729 1.58183 44.609 4.01176 44.609 7.3253C44.609 10.6388 41.7729 13.5391 38.4593 13.5391C35.1458 13.5391 32.4237 10.8526 32.4593 7.53908C32.495 4.19703 35.2028 1.49632 38.4593 1.53907Z'
          fill='#E5B29F'
        />
        <path
          opacity='0.37'
          d='M38.4681 1.54628C41.7817 1.58903 44.6106 4.02609 44.6106 7.33963C44.6106 10.6532 41.7817 13.5463 38.4681 13.5463C35.1546 13.5463 32.4325 10.8598 32.4681 7.54628C32.5037 4.20423 35.2116 1.50352 38.4681 1.54628Z'
          fill='#E6B4A1'
        />
        <path
          opacity='0.36'
          d='M38.4681 1.55345C41.7817 1.59621 44.6106 4.03326 44.6106 7.3468C44.6106 10.6603 41.7817 13.5535 38.4681 13.5535C35.1546 13.5535 32.4325 10.867 32.4681 7.55345C32.5037 4.21141 35.2045 1.51782 38.4681 1.55345Z'
          fill='#E6B5A3'
        />
        <path
          opacity='0.35'
          d='M38.475 1.55345C41.7885 1.59621 44.6175 4.04039 44.6175 7.35393C44.6175 10.6675 41.7885 13.5535 38.475 13.5535C35.1614 13.5535 32.4393 10.867 32.475 7.55345C32.5106 4.21141 35.2113 1.51782 38.475 1.55345Z'
          fill='#E7B7A5'
        />
        <path
          opacity='0.35'
          d='M38.4828 1.56058C41.7963 1.60333 44.6182 4.04751 44.6182 7.36105C44.6182 10.6746 41.7963 13.5535 38.4828 13.5535C35.1692 13.5535 32.4471 10.867 32.4828 7.55345C32.5184 4.21141 35.2191 1.51782 38.4828 1.55345V1.56058Z'
          fill='#E7B8A6'
        />
        <path
          opacity='0.34'
          d='M38.4828 1.56773C41.7963 1.60336 44.6182 4.06179 44.6182 7.37533C44.6182 10.6889 41.7963 13.5677 38.4828 13.5677C35.1692 13.5677 32.4471 10.8813 32.4828 7.56773C32.5184 4.22569 35.2191 1.5321 38.4828 1.56773Z'
          fill='#E8BAA8'
        />
        <path
          opacity='0.33'
          d='M38.4886 1.57486C41.8022 1.61049 44.6169 4.07605 44.6169 7.38246C44.6169 10.6889 41.8022 13.5677 38.4886 13.5677C35.1751 13.5677 32.453 10.8813 32.4886 7.56773C32.5243 4.22569 35.225 1.5321 38.4886 1.56773V1.57486Z'
          fill='#E8BBAA'
        />
        <path
          opacity='0.33'
          d='M38.4885 1.58201C41.802 1.61764 44.6168 4.0832 44.6168 7.39674C44.6168 10.7103 41.802 13.582 38.4885 13.582C35.175 13.582 32.46 10.8956 32.4885 7.58201C32.5241 4.2471 35.2248 1.54638 38.4885 1.58201Z'
          fill='#E9BCAC'
        />
        <path
          opacity='0.32'
          d='M38.4953 1.58909C41.8089 1.62472 44.6236 4.09741 44.6236 7.41095C44.6236 10.7245 41.8089 13.5891 38.4953 13.5891C35.1818 13.5891 32.4668 10.9026 32.4953 7.58909C32.531 4.25418 35.2317 1.55346 38.4953 1.58909Z'
          fill='#E9BEAE'
        />
        <path
          opacity='0.31'
          d='M38.4953 1.58909C41.8089 1.62472 44.6165 4.09741 44.6165 7.41095C44.6165 10.7245 41.8089 13.5891 38.4953 13.5891C35.1818 13.5891 32.4668 10.9026 32.4953 7.58909C32.531 4.25418 35.2246 1.55346 38.4953 1.58909Z'
          fill='#EABFAF'
        />
        <path
          opacity='0.3'
          d='M38.5032 1.59605C41.8167 1.63168 44.6243 4.11149 44.6243 7.42503C44.6243 10.7386 41.8167 13.5961 38.5032 13.5961C35.1896 13.5961 32.4746 10.9096 32.5032 7.59605C32.5317 4.26114 35.2324 1.56042 38.5032 1.59605Z'
          fill='#EAC1B1'
        />
        <path
          opacity='0.3'
          d='M38.5032 1.6033C41.8167 1.63893 44.6172 4.11874 44.6172 7.43228C44.6172 10.7458 41.8167 13.5962 38.5032 13.5962C35.1896 13.5962 32.4746 10.9097 32.5032 7.59617C32.5317 4.26125 35.2324 1.56767 38.5032 1.59617V1.6033Z'
          fill='#EBC2B3'
        />
        <path
          opacity='0.29'
          d='M38.511 1.61045C41.8245 1.64608 44.625 4.13302 44.625 7.44656C44.625 10.7601 41.8245 13.6105 38.511 13.6105C35.1974 13.6105 32.4825 10.924 32.511 7.61045C32.5395 4.27554 35.2402 1.58195 38.511 1.61045Z'
          fill='#EBC4B5'
        />
        <path
          opacity='0.28'
          d='M38.5178 1.61758C41.8313 1.65321 44.6318 4.14015 44.6318 7.45368C44.6318 10.7672 41.8313 13.6105 38.5178 13.6105C35.2043 13.6105 32.4893 10.924 32.5178 7.61045C32.5463 4.27554 35.247 1.58195 38.5178 1.61045V1.61758Z'
          fill='#ECC5B7'
        />
        <path
          opacity='0.28'
          d='M38.5178 1.62474C41.8313 1.65324 44.6247 4.15443 44.6247 7.46797C44.6247 10.7815 41.8313 13.6247 38.5178 13.6247C35.2043 13.6247 32.4893 10.9383 32.5178 7.62474C32.5463 4.28982 35.247 1.59623 38.5178 1.62474Z'
          fill='#ECC7B8'
        />
        <path
          opacity='0.27'
          d='M38.5237 1.62474C41.8372 1.65324 44.6306 4.16155 44.6306 7.47509C44.6306 10.7886 41.8372 13.6247 38.5237 13.6247C35.2101 13.6247 32.4952 10.9383 32.5237 7.62474C32.5522 4.28982 35.2529 1.59623 38.5237 1.62474Z'
          fill='#EDC8BA'
        />
        <path
          opacity='0.26'
          d='M38.5237 1.63169C41.8372 1.6602 44.6234 4.16851 44.6234 7.48205C44.6234 10.7956 41.8372 13.6317 38.5237 13.6317C35.2101 13.6317 32.4952 10.9452 32.5237 7.63169C32.5522 4.29678 35.2458 1.60319 38.5237 1.63169Z'
          fill='#EDC9BC'
        />
        <path
          opacity='0.26'
          d='M38.5314 1.63902C41.8449 1.66752 44.6311 4.18296 44.6311 7.4965C44.6311 10.81 41.8449 13.639 38.5314 13.639C35.2178 13.639 32.51 10.9526 32.5314 7.63902C32.5599 4.3041 35.2535 1.61051 38.5314 1.63902Z'
          fill='#EECBBE'
        />
        <path
          opacity='0.25'
          d='M38.5314 1.6461C41.8449 1.6746 44.6311 4.19004 44.6311 7.50358C44.6311 10.8171 41.8449 13.6461 38.5314 13.6461C35.2178 13.6461 32.51 10.9596 32.5314 7.6461C32.5599 4.31118 35.2535 1.61759 38.5314 1.6461Z'
          fill='#EECCC0'
        />
        <path
          opacity='0.24'
          d='M38.5392 1.65306C41.8527 1.68156 44.6318 4.20412 44.6318 7.51766C44.6318 10.8312 41.8527 13.6531 38.5392 13.6531C35.2257 13.6531 32.5178 10.9666 32.5392 7.65306C32.5606 4.31814 35.2613 1.62455 38.5392 1.65306Z'
          fill='#EFCEC2'
        />
        <path
          opacity='0.23'
          d='M38.546 1.66033C41.8596 1.68883 44.6387 4.2114 44.6387 7.52494C44.6387 10.8385 41.8596 13.6532 38.546 13.6532C35.2325 13.6532 32.5246 10.9667 32.546 7.6532C32.5674 4.32541 35.2681 1.63182 38.546 1.6532V1.66033Z'
          fill='#EFCFC3'
        />
        <path
          opacity='0.23'
          d='M38.546 1.66748C41.8596 1.69599 44.6315 4.22568 44.6315 7.53922C44.6315 10.8528 41.8524 13.6675 38.546 13.6675C35.2396 13.6675 32.5246 10.981 32.546 7.66748C32.5674 4.33969 35.2681 1.64611 38.546 1.66748Z'
          fill='#F0D1C5'
        />
        <path
          opacity='0.22'
          d='M38.5529 1.66729C41.8664 1.69579 44.6384 4.23261 44.6384 7.53902C44.6384 10.8454 41.8664 13.6602 38.5529 13.6602C35.2393 13.6602 32.5315 10.9737 32.5529 7.66016C32.5742 4.33237 35.2678 1.63878 38.5529 1.66016V1.66729Z'
          fill='#F0D2C7'
        />
        <path
          opacity='0.21'
          d='M38.5529 1.67469C41.8664 1.69606 44.6384 4.24001 44.6384 7.55355C44.6384 10.8671 41.8664 13.6747 38.5529 13.6747C35.2393 13.6747 32.5315 10.9882 32.5529 7.67469C32.5742 4.3469 35.2678 1.65331 38.5529 1.67469Z'
          fill='#F1D4C9'
        />
        <path
          opacity='0.21'
          d='M38.5597 1.68164C41.8732 1.70302 44.6381 4.25409 44.6381 7.56763C44.6381 10.8812 41.8732 13.6816 38.5597 13.6816C35.2462 13.6816 32.5383 10.9952 32.5597 7.68165C32.5811 4.35385 35.2747 1.66027 38.5597 1.68164Z'
          fill='#F1D5CB'
        />
        <path
          opacity='0.2'
          d='M38.5597 1.68873C41.8732 1.7101 44.6381 4.26117 44.6381 7.57471C44.6381 10.8883 41.8732 13.6887 38.5597 13.6887C35.2462 13.6887 32.5383 11.0023 32.5597 7.68873C32.5811 4.36093 35.2747 1.66735 38.5597 1.68873Z'
          fill='#F1D6CC'
        />
        <path
          opacity='0.19'
          d='M38.5675 1.69605C41.881 1.71743 44.6459 4.27562 44.6459 7.58916C44.6459 10.9027 41.881 13.6961 38.5675 13.6961C35.254 13.6961 32.5461 11.0096 32.5675 7.69605C32.5889 4.36826 35.2825 1.67467 38.5675 1.69605Z'
          fill='#F2D8CE'
        />
        <path
          opacity='0.18'
          d='M38.5674 1.70318C41.881 1.72455 44.6387 4.28275 44.6387 7.59629C44.6387 10.9098 41.881 13.6961 38.5674 13.6961C35.2539 13.6961 32.5532 11.0096 32.5674 7.69605C32.5888 4.36826 35.2824 1.67467 38.5674 1.69605V1.70318Z'
          fill='#F2D9D0'
        />
        <path
          opacity='0.18'
          d='M38.5753 1.70313C41.8888 1.72451 44.6465 4.28983 44.6465 7.60337C44.6465 10.9169 41.8888 13.7031 38.5753 13.7031C35.2617 13.7031 32.561 11.0167 32.5753 7.70313C32.5966 4.37534 35.2902 1.68175 38.5753 1.70313Z'
          fill='#F3DBD2'
        />
        <path
          opacity='0.17'
          d='M38.5821 1.71031C41.8956 1.73168 44.6462 4.29701 44.6462 7.61054C44.6462 10.9241 41.8956 13.7032 38.5821 13.7032C35.2685 13.7032 32.5678 11.0167 32.5821 7.70318C32.5963 4.37539 35.2899 1.68893 38.5821 1.70318V1.71031Z'
          fill='#F3DCD4'
        />
        <path
          opacity='0.16'
          d='M38.5821 1.71722C41.8956 1.7386 44.6462 4.31104 44.6462 7.62458C44.6462 10.9381 41.8956 13.7172 38.5821 13.7172C35.2685 13.7172 32.5678 11.0308 32.5821 7.71722C32.5963 4.38943 35.2899 1.70297 38.5821 1.71722Z'
          fill='#F4DED5'
        />
        <path
          opacity='0.16'
          d='M38.5879 1.72435C41.9015 1.74572 44.6521 4.3253 44.6521 7.63171C44.6521 10.9381 41.9015 13.7172 38.5879 13.7172C35.2744 13.7172 32.5737 11.0308 32.5879 7.71722C32.6022 4.38943 35.2958 1.70297 38.5879 1.71722V1.72435Z'
          fill='#F4DFD7'
        />
        <path
          opacity='0.15'
          d='M38.5879 1.73162C41.9015 1.74588 44.645 4.33257 44.645 7.64611C44.645 10.9597 41.9015 13.7316 38.5879 13.7316C35.2744 13.7316 32.5737 11.0452 32.5879 7.73162C32.6022 4.40383 35.2958 1.71737 38.5879 1.73162Z'
          fill='#F5E1D9'
        />
        <path
          opacity='0.14'
          d='M38.5958 1.7387C41.9093 1.75296 44.6528 4.34678 44.6528 7.66032C44.6528 10.9739 41.9093 13.7387 38.5958 13.7387C35.2822 13.7387 32.5815 11.0522 32.5958 7.7387C32.61 4.41804 35.3036 1.72445 38.5958 1.7387Z'
          fill='#F5E2DB'
        />
        <path
          opacity='0.13'
          d='M38.5958 1.7387C41.9093 1.75296 44.6456 4.34678 44.6456 7.66032C44.6456 10.9739 41.9093 13.7387 38.5958 13.7387C35.2822 13.7387 32.5815 11.0522 32.5958 7.7387C32.61 4.41804 35.3036 1.72445 38.5958 1.7387Z'
          fill='#F6E4DD'
        />
        <path
          opacity='0.13'
          d='M38.6026 1.74578C41.9161 1.76004 44.6525 4.36099 44.6525 7.67452C44.6525 10.9881 41.9161 13.7458 38.6026 13.7458C35.2891 13.7458 32.5883 11.0593 32.6026 7.74578C32.6168 4.42512 35.3104 1.73153 38.6026 1.74578Z'
          fill='#F6E5DE'
        />
        <path
          opacity='0.12'
          d='M38.6026 1.75291C41.9161 1.76716 44.6525 4.36811 44.6525 7.68165C44.6525 10.9952 41.9161 13.7458 38.6026 13.7458C35.2891 13.7458 32.5883 11.0593 32.6026 7.74578C32.6168 4.42512 35.3033 1.73153 38.6026 1.74578V1.75291Z'
          fill='#F7E6E0'
        />
        <path
          opacity='0.11'
          d='M38.6104 1.76007C41.9239 1.77432 44.6531 4.38239 44.6531 7.69593C44.6531 11.0095 41.9239 13.7601 38.6104 13.7601C35.2968 13.7601 32.6032 11.0736 32.6104 7.76007C32.6246 4.4394 35.3111 1.74581 38.6104 1.76007Z'
          fill='#F7E8E2'
        />
        <path
          opacity='0.11'
          d='M38.6172 1.76727C41.9307 1.78152 44.66 4.3896 44.66 7.70314C44.66 11.0167 41.9307 13.7601 38.6172 13.7601C35.3037 13.7601 32.6101 11.0737 32.6172 7.76015C32.6315 4.43948 35.3179 1.75302 38.6172 1.76015V1.76727Z'
          fill='#F8E9E4'
        />
        <path
          opacity='0.1'
          d='M38.6172 1.77431C41.9307 1.78856 44.6528 4.40376 44.6528 7.7173C44.6528 11.0308 41.9307 13.7743 38.6172 13.7743C35.3037 13.7743 32.6101 11.0878 32.6172 7.77431C32.6243 4.45364 35.3179 1.76718 38.6172 1.77431Z'
          fill='#F8EBE6'
        />
        <path
          opacity='0.09'
          d='M38.6231 1.77431C41.9366 1.78143 44.6587 4.41089 44.6587 7.72442C44.6587 11.038 41.9366 13.7743 38.6231 13.7743C35.3095 13.7743 32.6159 11.0878 32.6231 7.77431C32.6302 4.45364 35.3238 1.76718 38.6231 1.77431Z'
          fill='#F9ECE7'
        />
        <path
          opacity='0.09'
          d='M38.6231 1.78163C41.9366 1.78876 44.6587 4.41821 44.6587 7.73175C44.6587 11.0453 41.9366 13.7816 38.6231 13.7816C35.3095 13.7816 32.6159 11.0952 32.6231 7.78163C32.6302 4.46097 35.3238 1.7745 38.6231 1.78163Z'
          fill='#F9EEE9'
        />
        <path
          opacity='0.08'
          d='M38.6318 1.78859C41.9454 1.79571 44.6604 4.43229 44.6604 7.74583C44.6604 11.0594 41.9454 13.7886 38.6318 13.7886C35.3183 13.7886 32.6247 11.1021 32.6319 7.78859C32.639 4.46792 35.3326 1.78146 38.6318 1.78859Z'
          fill='#FAEFEB'
        />
        <path
          opacity='0.07'
          d='M38.6318 1.79567C41.9454 1.80279 44.6604 4.43937 44.6604 7.75291C44.6604 11.0665 41.9454 13.7957 38.6318 13.7957C35.3183 13.7957 32.6247 11.1092 32.6319 7.79567C32.639 4.475 35.3254 1.78854 38.6318 1.79567Z'
          fill='#FAF1ED'
        />
        <path
          opacity='0.06'
          d='M38.6387 1.80287C41.9522 1.81 44.6601 4.4537 44.6601 7.76724C44.6601 11.0808 41.9522 13.8029 38.6387 13.8029C35.3251 13.8029 32.6316 11.1164 32.6387 7.80287C32.6387 4.48221 35.3323 1.79574 38.6387 1.80287Z'
          fill='#FBF2EF'
        />
        <path
          opacity='0.06'
          d='M38.6465 1.81C41.96 1.81 44.6679 4.46083 44.6679 7.77437C44.6679 11.0879 41.96 13.8029 38.6465 13.8029C35.333 13.8029 32.6394 11.1164 32.6465 7.80287C32.6465 4.48221 35.3401 1.79574 38.6465 1.80287V1.81Z'
          fill='#FBF3F1'
        />
        <path
          opacity='0.05'
          d='M38.6465 1.81703C41.96 1.81703 44.6679 4.47499 44.6679 7.78853C44.6679 11.1021 41.96 13.817 38.6465 13.817C35.333 13.817 32.6394 11.1306 32.6465 7.81703C32.6465 4.50349 35.3401 1.8099 38.6465 1.81703Z'
          fill='#FCF5F2'
        />
        <path
          opacity='0.04'
          d='M38.6523 1.81708C41.9659 1.81708 44.6666 4.48216 44.6666 7.78857C44.6666 11.095 41.9659 13.81 38.6523 13.81C35.3388 13.81 32.6523 11.1235 32.6523 7.80995C32.6523 4.49641 35.3459 1.80282 38.6523 1.80995V1.81708Z'
          fill='#FCF6F4'
        />
        <path
          opacity='0.04'
          d='M38.6523 1.8241C41.9659 1.8241 44.6666 4.48918 44.6666 7.80272C44.6666 11.1163 41.9659 13.8241 38.6523 13.8241C35.3388 13.8241 32.6523 11.1376 32.6523 7.8241C32.6523 4.51056 35.3459 1.8241 38.6523 1.8241Z'
          fill='#FDF8F6'
        />
        <path
          opacity='0.03'
          d='M38.6592 1.83142C41.9727 1.83142 44.6663 4.50363 44.6663 7.81717C44.6663 11.1307 41.9727 13.8314 38.6592 13.8314C35.3456 13.8314 32.6592 11.145 32.6592 7.83142C32.6592 4.51788 35.3528 1.83142 38.6592 1.83142Z'
          fill='#FDF9F8'
        />
        <path
          opacity='0.02'
          d='M38.6592 1.8385C41.9727 1.8385 44.6663 4.51071 44.6663 7.82425C44.6663 11.1378 41.9727 13.8385 38.6592 13.8385C35.3456 13.8385 32.6592 11.152 32.6592 7.8385C32.6592 4.52496 35.3456 1.8385 38.6592 1.8385Z'
          fill='#FEFBFA'
        />
        <path
          opacity='0.01'
          d='M38.668 1.84558C41.9815 1.84558 44.6751 4.52492 44.6751 7.83846C44.6751 11.152 41.9815 13.8456 38.668 13.8456C35.3544 13.8456 32.668 11.1591 32.668 7.84558C32.668 4.53204 35.3544 1.84558 38.668 1.84558Z'
          fill='#FEFCFB'
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_3458_3724'
        x1='40.4394'
        y1='10.81'
        x2='36.2922'
        y2='4.30404'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF9200' />
        <stop offset='0.21' stopColor='#FF8D00' />
        <stop offset='0.45' stopColor='#FF7F00' />
        <stop offset='0.62' stopColor='#FF7100' />
        <stop offset='0.82' stopColor='#FF5E00' />
        <stop offset='1' stopColor='#FF5200' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_3458_3724'
        x1='39.7838'
        y1='10.7173'
        x2='33.7197'
        y2='-1.14727'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF7100' stopOpacity='0' />
        <stop offset='0.03' stopColor='#FF6D00' stopOpacity='0.03' />
        <stop offset='0.21' stopColor='#FF5900' stopOpacity='0.25' />
        <stop offset='0.39' stopColor='#FF4800' stopOpacity='0.42' />
        <stop offset='0.56' stopColor='#FF3B00' stopOpacity='0.56' />
        <stop offset='0.72' stopColor='#FF3200' stopOpacity='0.66' />
        <stop offset='0.86' stopColor='#FF2C00' stopOpacity='0.72' />
        <stop offset='0.98' stopColor='#FF2B00' stopOpacity='0.74' />
      </linearGradient>
    </defs>
  </svg>
)

const propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
}

const defaultProps = {
  width: 124,
  height: 45
}

DiscoverCardIcon.propTypes = propTypes
DiscoverCardIcon.defaultProps = defaultProps
export default DiscoverCardIcon
