import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import getUnitTypeOccupancy from '../utils/getUnitTypeOccupancy'
import RatePlans from 'Rentlio/screen/accomodation/component/RatePlans'
import { addItemToBasket } from 'Rentlio/state/actions/basket/ui'
import { removeItemFromBasket, onOccupancyChange, openPackageDealModal } from 'Rentlio/state/actions/ui'
import { getSelectedRatePlans } from 'Rentlio/state/selectors/basket'
import { SERVICES_PAGE, PAYMENT_PAGE } from 'Rentlio/utils/routes'
import Animation from 'Rentlio/components/UI/Animation'

class RatePlansContainer extends React.Component {
  selectUnitTypeAndRedirect = (unitTypeId, ratePlanId, quantity, adults, childrenAges) => {
    const { addItemToBasket, history, selectedRatePlans, services } = this.props

    // If user wants to select only one room go to services page immediately
    if (selectedRatePlans.length === 0 && quantity === 1) {
      history.push(!services.length ? PAYMENT_PAGE : SERVICES_PAGE)
    }

    addItemToBasket(unitTypeId, ratePlanId, quantity, adults, childrenAges)
  }

  getUnitTypeOccupancy = () => {
    const { rates, unitTypes } = this.props
    return getUnitTypeOccupancy(
      rates.length > 0 ? unitTypes.find(unitType => unitType.unitTypeId === rates[0].unitTypeId) : {}
    )
  }

  render() {
    const {
      showRatePlans,
      rates,
      removeItemFromBasket,
      wrapperId,
      unitTypeCurrency,
      arrivalDate,
      adults,
      childrenAges,
      onOccupancyChange,
      basket,
      unitTypes,
      openPackageDealModal
    } = this.props

    return (
      <Animation show={showRatePlans} animationType={'fadeInOut'}>
        <RatePlans
          wrapperId={wrapperId}
          rates={rates}
          addItemToBasket={this.selectUnitTypeAndRedirect}
          removeItemFromBasket={removeItemFromBasket}
          unitTypeCurrency={unitTypeCurrency}
          arrivalDate={arrivalDate}
          adults={adults}
          childrenAges={childrenAges}
          onOccupancyChange={onOccupancyChange}
          unitTypeOccupancy={this.getUnitTypeOccupancy()}
          basket={basket}
          unitTypes={unitTypes}
          openPackageDealModal={openPackageDealModal}
        />
      </Animation>
    )
  }
}

const mapStateToProps = state => ({
  selectedRatePlans: getSelectedRatePlans(state),
  services: state.services,
  arrivalDate: state.request.from,
  adults: state.request.adults,
  childrenAges: state.request.children,
  unitTypes: state.unitTypes,
  basket: state.basket
})

const mapDispatchToProps = dispatch => ({
  addItemToBasket: (unitTypeId, ratePlanId, quantity, adults, childrenAges) =>
    dispatch(addItemToBasket(unitTypeId, ratePlanId, quantity, adults, childrenAges)),
  removeItemFromBasket: unitTypeId => dispatch(removeItemFromBasket(unitTypeId)),
  onOccupancyChange: (adults, children) => dispatch(onOccupancyChange(adults, children)),
  openPackageDealModal: (rateName, rateId) => dispatch(openPackageDealModal(rateName, rateId))
})

RatePlansContainer.propTypes = {
  selectedRatePlans: PropTypes.array,
  unitTypeCurrency: PropTypes.string,
  rates: PropTypes.array,
  showRatePlans: PropTypes.bool,
  addItemToBasket: PropTypes.func,
  removeItemFromBasket: PropTypes.func,
  history: PropTypes.object,
  services: PropTypes.array,
  wrapperId: PropTypes.string,
  arrivalDate: PropTypes.number,
  adults: PropTypes.number,
  childrenAges: PropTypes.array,
  onOccupancyChange: PropTypes.func,
  unitTypes: PropTypes.array,
  basket: PropTypes.array,
  openPackageDealModal: PropTypes.func
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RatePlansContainer))
