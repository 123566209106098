import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const FooterWrapper = styled.div`
  height: 150px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  margin: auto;

  @media ${device.tablet} {
    height: 200px;
    width: 80%;
  }

  @media ${device.mobile} {
    position: relative;
    z-index: 2;
    height: 650px;
    justify-content: space-evenly;
    flex-direction: column;
    padding-bottom: 10px;
    padding-top: 200px;
  }
`

export const FooterAgreement = styled.div`
  margin-right: 30px;
  font-size: ${props => props.theme.fonts._12};
  font-weight: ${props => props.theme.fonts.mediumWeight};
  line-height: ${props => props.theme.fonts._14};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media ${device.tablet} {
    margin-right: 0;
    margin-bottom: 20px;
  }
`

export const Agreements = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
  }

  @media ${device.mobile} {
    order: 3;
  }
`
