/* eslint-disable no-undef */
import { DEFAULT_LOCALE } from 'Rentlio/helper/translation'
import fetch from 'Rentlio/state/utils/fetch'
import getBaseUri from 'Rentlio/utils/url'
import 'url-polyfill'

const basePath = getBaseUri() + '/api'

const api = ({ getState }) => next => action => {
  const result = next(action)
  if (!action.meta || !action.meta.async) {
    return result
  }

  if (action.meta.async && action.meta.UI) {
    return next({
      type: `${action.type}_COMPLETED`
    })
  }

  // Fetch locale from state
  const { configuration } = getState()

  let { path, method = 'GET', body } = action.meta
  body.language = configuration.locale || DEFAULT_LOCALE
  body.currency = configuration.currency

  const url = new URL(`${basePath}${path}`)

  if (method === 'GET' && body) {
    Object.keys(body).forEach(key => body[key] !== null && url.searchParams.append(key, body[key]))
    body = null
  }

  if (method !== 'GET' && body) {
    url.searchParams.append('language', body.language)
    url.searchParams.append('currency', body.currency)
  }

  if (!path) {
    throw new Error(`'path' not specified for async action ${action.type}`)
  }

  return fetch(url, method, body).then(
    res => handleResponse(res, action, next),
    err => handleErrors(err, action, next)
  )
}

function handleErrors(err, action, next) {
  next({
    type: `${action.type}_FAILED`,
    payload: err,
    meta: action.meta
  })

  return err
}

function handleResponse(res, action, next) {
  next({
    type: `${action.type}_COMPLETED`,
    payload: res.response,
    meta: action.meta
  })

  return res
}

export default api
