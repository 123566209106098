import React from 'react'
import PropTypes from 'prop-types'

const ArrowRight = ({ stroke, height, width }) => (
  <span>
    <svg width={width} height={height} viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1 7L17 7' stroke={stroke || 'white'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M11.4 1L17 7L11.4 13'
        stroke={stroke || 'white'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </span>
)

ArrowRight.defaultProps = {
  width: 18,
  height: 14
}

ArrowRight.propTypes = {
  stroke: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
}

export default ArrowRight
