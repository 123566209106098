import styled from 'styled-components'
import { CancelButton } from 'Rentlio/components/UI/button'
import { device } from 'Rentlio/style/responsive'

export const Section = styled.div`
  position: relative;
  width: 100%;

  ::before {
    content: '';
    position: absolute;
    width: 30%;
    top: -10px;
    bottom: 0;
    right: 0;
    border-top: 1px solid ${props => props.theme.colors.border};
    background: ${props => props.theme.colors.reservationSummaryBackground};
  }

  @media ${device.tablet} {
    ::before {
      display: none;
    }
  }
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1180px;
  margin: 0 auto;
  position: relative;

  @media ${device.tablet} {
    flex-direction: column;
  }

  @media ${device.laptop} {
    max-width: 900px;
  }
`

export const BookerInfo = styled.div`
  max-width: 650px;
  padding: 40px 12px 210px 0;

  @media ${device.tablet} {
    order: 2;
    padding: 80px 30px 100px 30px;
    margin-bottom: 0;
  }

  @media ${device.laptopAndWider} {
    padding: 40px 0 210px;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 56px;
  align-items: center;

  ${CancelButton} {
    ${({ enabled }) => (!enabled ? 'pointer-events: none;' : '')};
    cursor: ${({ enabled }) => (!enabled ? 'none' : 'pointer')};
    opacity: ${({ enabled }) => (!enabled ? '.7' : '1')};
    margin-right: 12px;
  }

  @media ${device.mobile} {
    flex-direction: column;
    height: 150px;
    justify-content: space-between;
    margin-right: 0;

    ${CancelButton} {
      margin-right: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }

  @media ${device.laptopAndWider} {
    ${CancelButton} {
      margin-right: 30px;
    }
  }
`

export const Link = styled.span`
  color: ${props => props.theme.colors.primary};
  text-decoration: underline;
  cursor: pointer;
`

export const FormError = styled.div`
  color: ${({ theme }) => theme.colors.alertColor};
  font-size: ${({ theme }) => theme.fonts._14};
  line-height: ${({ theme }) => theme.fonts._18};
  padding-top: 10px;
  padding-bottom: 10px;
`
