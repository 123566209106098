import moment from 'moment'
import groupReservations from 'Rentlio/screen/offer/components/Offer/util/groupReservations'
import chargesTypesEnum from 'Rentlio/utils/chargesTypesEnum'

function getChargeBasePrice(primaryPrice, taxes) {
  let divider = 1

  taxes.map(tax => {
    divider += tax.amount / 100
  })

  return primaryPrice / divider
}

export const getOfferReceipt = (booking, { isVatRegistered, vatRate, id }) => {
  const receipt = {
    items: [],
    totalBookingCost: 0,
    taxes: [],
    currency: null,
    isVatRegistered,
    vatRate,
    bookingEngineId: id
  }

  const taxesMap = {}
  groupReservations(booking.reservations).map(res => {
    const item = {
      name: res.unitType.name,
      arrival: moment.unix(res.arrivalDate),
      departure: moment.unix(res.departureDate),
      cancellationPolicy: res.cancellationPolicy,
      ratePlanName: res.ratePlanName,
      totalCost: 0,
      totalRateCost: 0,
      totalServicesCost: 0,
      currency: res.currency,
      charges: [],
      unitsNumber: res.unitsNumber
    }

    const chargesMap = {}
    res.charges.map(({ name, primaryPrice, quantity, taxes, type }) => {
      const key = `${name}_${primaryPrice.toFixed(2)}`

      // init key
      if (typeof chargesMap[key] === 'undefined') {
        chargesMap[key] = {
          primaryPrice,
          name,
          quantity: 0,
          key,
          includedExtras: []
        }
      }

      if (type === chargesTypesEnum.ACCOMMODATION) {
        chargesMap[key].includedExtras = res.includedExtras
          .map(item => item.name)
          .filter((name, index, parent) => parent.indexOf(name) === index)
      }

      chargesMap[key].quantity += quantity
      item.totalCost += primaryPrice * quantity
      if (type === chargesTypesEnum.ACCOMMODATION) {
        item.totalRateCost += primaryPrice * quantity
      }
      if (type === chargesTypesEnum.SERVICE || type === chargesTypesEnum.FOOD_AND_BEVERAGE) {
        item.totalServicesCost += primaryPrice * quantity
      }

      taxes.map(tax => {
        const taxKey = `${tax.label}_${tax.amount}`

        if (typeof taxesMap[taxKey] === 'undefined') {
          taxesMap[taxKey] = {
            label: tax.label,
            rate: tax.amount,
            taxAmount: 0
          }
        }

        taxesMap[taxKey].taxAmount += getChargeBasePrice(primaryPrice, taxes) * (tax.amount / 100)
      })
    })

    item.charges = Object.values(chargesMap)
    receipt.currency = res.currency
    receipt.items.push(item)
  })

  receipt.taxes = Object.values(taxesMap)

  // handle grouping - calculate total for grouped item
  receipt.items = receipt.items.map(item => ({ ...item, totalCost: item.totalCost * item.unitsNumber }))

  receipt.totalBookingCost = receipt.items.reduce((sum, item) => sum + item.totalCost, 0)

  return receipt
}
