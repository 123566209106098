export function formatPrice(price, currency) {
  if (price === null) {
    return null
  }

  if (currency && currency === 'VND') {
    return Math.round(price).toLocaleString('en-US')
  }

  if (typeof price === 'string') {
    price = parseFloat(price)
  }

  return price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
