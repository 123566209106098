import styled from 'styled-components'

export const Title = styled.h2`
  margin-bottom: 30px;
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._24};
  font-size: ${props => props.theme.fonts._28};
  color: ${props => props.theme.colors.textColor};
`

export const Paragraph = styled.p`
  color: ${props => props.theme.colors.labelTextColor};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._24};
`
