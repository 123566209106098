import colors from 'Rentlio/style/colors'

const getCustomM2PayFormStyle = () => {
  const style = {
    base: {
      iconColor: colors.textColor,
      color: colors.textColor,
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '14px',
      opacity: 1,
      marginBottom: '60px',

      ':WebkitAutofill': {
        color: colors.lightText
      },
      '::placeholder': {
        color: colors.lightText
      }
    },
    invalid: {
      iconColor: colors.alertColor,
      color: colors.alertColor
    },
    cardNumberInput: {
      borderRadius: '10px',
      padding: '21px 15px',
      fontSize: '14px',
      lineHeight: '16px',
      border: `1px solid ${colors.border}`,
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      width: '100%',
      height: '60px'
    },
    cardExpiryInput: {
      borderRadius: '10px',
      padding: '21px 15px',
      fontSize: '14px',
      lineHeight: '16px',
      border: `1px solid ${colors.border}`,
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      width: '100%',
      height: '60px'
    }
  }

  return style
}

export default getCustomM2PayFormStyle
