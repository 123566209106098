import styled, { css } from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const MaxOccupancyWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: ${props => props.theme.colors.textColor};
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};

  ${({ shouldBeHiddenOnMobile }) =>
    shouldBeHiddenOnMobile
      ? css`
          @media ${device.tablet} {
            display: none;
          }
        `
      : null}
      
   @media ${device.onlyMobile} {
    flex-direction: column;
    justify-content: center;
   }

   @media ${device.mobile} {
    flex-direction: column;
    justify-content: center;
   }
`

export const MaxOccupancyInfo = styled.div`
  display: flex;
  margin: 10px 10px 10px 0;
`

export const MaxOccupancyLabel = styled.span`
  color: rgba(0, 0, 0, 0.6);
  margin-right: 10px;

  @media ${device.tablet} {
    display: none;
  }
`
export const MaxOccupancyValue = styled.span`
  margin-left: 10px;
`

export const PropertyInfoWrapper = styled.div`
  display: flex;

  @media ${device.laptop} {
    margin-top: 10px;
  }

  @media ${device.tablet} {
    margin-top: 0;
    margin-left: 15px;
  }

  @media ${device.onlyMobile} {
    margin: 10px 0 0 0;
  }
`

export const PropertyLink = styled.a`
  display: inline-flex;
  align-items: center;
  padding: 7px 10px;
  border-radius: 4px;
  background: ${props => props.theme.colors.textColor};
  color: ${props => props.theme.colors.white};

  @media ${device.laptop} {
    margin: 0;
  }

  svg {
    min-width: 8px;
  }
`

export const PropertyName = styled.p`
  margin: 0 0 0 10px;
`

export const LocationWrapper = styled.a`
  display: inline-flex;
  align-items: center;
  margin-left: 15px;
  padding: 0;
  border-radius: 4px;
  color: ${props => props.theme.colors.textColor};
`

export const LocationName = styled.p`
  margin: 0 0 0 5px;
`
