import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const AmenityFilterWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 90%;
  display: flex;
  flex-flow: row wrap;
  padding: ${({ selected }) => (selected ? '30px 50px 0 45px' : '23px 60px 23px 45px')};
  background: white;
  border-radius: 20px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.05);
  transition: all ${({ theme }) => theme.animations.defaultTransition};
  z-index: ${({ theme }) => theme.zIndex.amenityFilter};

  @media ${device.tablet} {
    width: 325px;
    padding: 20px 30px;
    margin: 0 auto;
  }
`

export const SelectedAmenities = styled.div`
  display: flex;
  flex-flow: row wrap;
  max-width: 80%;

  @media ${device.tablet} {
    display: none;
  }
`

export const MobileAmenityItems = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 20px;

  @media ${device.mobile} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.onlyMobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const MobileModalControls = styled.div`
  display: flex;
  margin-top: 40px;
`

export const AmenityItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 15px;
  margin-right: ${({ small }) => (small ? '10px' : '25px')};
  margin-top: ${({ small }) => (small ? '5px' : '0')};
  margin-bottom: ${({ small }) => (small ? '5px' : '30px')};
  border: 1px solid ${({ theme }) => theme.colors.searchInputDivider};
  border-radius: 8px;
  cursor: pointer;
  color: ${({ selected }) => (selected ? 'white' : 'inherit')};
  background: ${({ selected, theme }) => (selected ? theme.colors.veryDarkGray : 'white')};
  transition: all ${({ theme }) => theme.animations.defaultTransition};
  font-size: ${({ theme, small }) => (small ? theme.fonts._12 : theme.fonts._14)};
  line-height: ${({ theme, small }) => (small ? theme.fonts._14 : theme.fonts._24)};
  user-select: none;

  @media ${device.tablet} {
    margin-bottom: 0px;
    margin-right: 0;
  }
`

export const AmenityImage = styled.img`
  display: block;
  max-width: 16px;
  max-height: 12px;
  object-fit: cover;
  user-select: none;
  filter: ${({ selected }) => (selected ? 'invert(100%)' : 'none')};
`

export const AmenityName = styled.div`
  margin: 0 10px;
  user-select: none;
`
export const FilterPreviewButton = styled.div`
  display: flex;
  align-items: center;
  margin-right: 50px;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fonts._14};
  line-height: ${({ theme }) => theme.fonts._16};
  font-weight: ${({ theme }) => theme.fonts.mediumWeight};
`

export const FilterPreviewButtonText = styled.div`
  margin-left: 10px;
  line-height: 36px;
`

export const ActiveFilters = styled.div`
  display: none;
  font-size: ${({ theme }) => theme.fonts._14};
  line-height: ${({ theme }) => theme.fonts._16};
  font-weight: 500;
  margin-left: auto;

  @media ${device.tablet} {
    display: flex;
    align-items: center;
  }
`

export const ActiveFiltersNumber = styled.div`
  height: 36px;
  width: 36px;
  margin-left: 20px;
  border-radius: 50%;
  text-align: center;
  font-size: ${({ theme }) => theme.fonts._14};
  line-height: 36px;
  font-weight: bold;
  background: ${({ theme }) => theme.colors.unitTypeOddBorder};
`

export const ClearAllFilter = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
  font-size: ${({ theme }) => theme.fonts._12};
  line-height: ${({ theme }) => theme.fonts._14};

  @media ${device.tablet} {
    display: ${({ showOnMobile }) => (showOnMobile ? 'flex' : 'none')};
  }
`

export const ModalSaveButton = styled.div`
  cursor: pointer;
  border-radius: 10px;
  padding: 20px 35px;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  text-transform: uppercase;
  text-align: center;
  user-select: none;
  transition: all ${({ theme }) => theme.animations.defaultTransition};

  &:hover {
    background: ${({ theme }) => theme.colors.darkBlue};
  }
`
