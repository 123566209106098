export const getTouristTax = (touristTaxes, adults, childrenAges) => {
  if (!touristTaxes || !touristTaxes.length) {
    return null
  }
  let price = 0
  if (adults) {
    touristTaxes.map(tax => {
      price += tax.adults * adults
    })
  }

  if (childrenAges && childrenAges.length) {
    touristTaxes.map(tax => {
      childrenAges.map(age => {
        if (age < 12) {
          price += tax.childrenUnder12
        } else {
          price += tax.childrenAbove12
        }
      })
    })
  }

  return price
}
