const FREE_OF_CHARGE = 1
const AS_ADULT = 2
const CUSTOM = 3

const ChildrenCategoriesTypeEnum = {
  FREE_OF_CHARGE,
  AS_ADULT,
  CUSTOM
}

export default ChildrenCategoriesTypeEnum
