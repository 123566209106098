import React from 'react'
import PropTypes from 'prop-types'

import getCountryCodeById from '../../../utils/getCountryCodeById'
import { StripeErrorMessage, StripeRowWrapper } from './style'
import getCustomStripeFormStyle from './customStripeFormStyle'
import { CreditCardRow, FormError, FormGroup, FormLabel, FormRow } from 'Rentlio/components/UI/form'
import { Input } from 'Rentlio/components/UI/input'
import Checkbox from 'Rentlio/components/UI/Checkbox'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

class StripePayment extends React.PureComponent {
  static contextType = LanguageContext
  stripe = null
  card = null

  state = {
    paymentMethodSettings: {},
    stripeErrorMessage: ''
  }

  componentDidMount = () => {
    this.setStateFromProps()
  }

  componentDidUpdate = prevProps => {
    const { stripe, paymentMethodSettings } = this.props
    if (stripe && stripe.clientSecret && prevProps.stripe?.clientSecret !== stripe.clientSecret) {
      this.submitPayment()
    }
    if (prevProps.paymentMethodSettings !== paymentMethodSettings) {
      this.setState({ stripeErrorMessage: '' })
    }
  }

  setStateFromProps = () => {
    const { paymentMethodSettings } = this.props
    this.setState({ paymentMethodSettings: paymentMethodSettings }, () => this.initializeStripeComponents())
  }

  initializeStripeComponents = () => {
    const { paymentMethodSettings, locale } = this.props
    this.stripe = window.Stripe(paymentMethodSettings.publishableApiKey)
    const elements = this.stripe.elements({ locale: locale })

    const cardNumber = elements.create('cardNumber', {
      showIcon: true,
      style: getCustomStripeFormStyle()
    })
    cardNumber.mount('#stripe-card-number')
    // we only need one element to call confirm card setup
    this.card = cardNumber

    const cardExpiry = elements.create('cardExpiry', {
      style: getCustomStripeFormStyle()
    })
    cardExpiry.mount('#stripe-card-expiry')

    const cardCvc = elements.create('cardCvc', {
      style: getCustomStripeFormStyle()
    })
    cardCvc.mount('#stripe-card-cvc')
  }

  onBookPressed = () => {
    const { getStripeClientSecret, paymentMethodSettings, receipt } = this.props
    const { bookingEngineId, currency } = receipt
    getStripeClientSecret(
      paymentMethodSettings.publishableApiKey,
      bookingEngineId,
      receipt.price?.total || receipt.totalBookingCost,
      currency,
      this.getGuestDetails()
    ).then(({ response }) => {
      if (!response || !response.clientSecret) {
        // we are removing spinner from screen if request fails
        const { onStripePaymentFailed } = this.props
        onStripePaymentFailed()
      }
    })
  }

  submitPayment = () => {
    const { cardholderSameAsGuest, guestDetails, cardholderName, stripe, onStripePaymentFailed } = this.props
    const { firstName, lastName } = guestDetails
    this.stripe
      .confirmCardSetup(stripe.clientSecret, {
        payment_method: {
          card: this.card,
          billing_details: {
            name: cardholderSameAsGuest ? firstName + ' ' + lastName : cardholderName
          }
        }
      })
      .then(result => {
        if (result.error) {
          this.setState({ stripeErrorMessage: result.error.message })
          onStripePaymentFailed()
        } else {
          // handle stripe success - book reservation with customerId and payment_method added
          const { callBookReservationApi, paymentDetails, paymentMethodSettings } = this.props
          callBookReservationApi(guestDetails, {
            ...paymentDetails,
            customerId: stripe.customerId,
            paymentMethodId: result.setupIntent.payment_method,
            publishableApiKey: paymentMethodSettings.publishableApiKey
          })
        }
      })
  }

  getGuestDetails = () => {
    const { guestDetails } = this.props
    const guest = { ...guestDetails }
    if (getCountryCodeById(guestDetails.country)) {
      guest.countryCode = getCountryCodeById(guestDetails.country)
    }
    if (guestDetails.isCompany) {
      guest.firstName = ' '
      guest.lastName = ' '
    }
    return guest
  }

  render() {
    const { translate } = this.context
    const {
      cardholderSameAsGuest,
      handleSameAsGuest,
      cardholderName,
      handleCardHolderName,
      getValidationError,
      handleAddress,
      handleCity,
      handlePostalCode,
      guestDetails
    } = this.props
    const { city, address, postalCode } = guestDetails
    const { stripeErrorMessage } = this.state
    return (
      <>
        <FormRow>
          <Checkbox
            isChecked={cardholderSameAsGuest}
            click={handleSameAsGuest}
            text={translate('Card holder same as booker')}
          />
        </FormRow>
        {!cardholderSameAsGuest && (
          <FormRow>
            <FormGroup fullWidth={true}>
              <FormLabel>
                <label htmlFor='stripeCardHolder'>{translate('Card holder')}</label>
              </FormLabel>
              <Input id='stripeCardHolder' value={cardholderName} onChange={handleCardHolderName} />
              <FormError>{getValidationError('name')}</FormError>
            </FormGroup>
          </FormRow>
        )}
        <FormRow>
          <FormGroup fullWidth={true}>
            <FormLabel>
              <label htmlFor='stripeAddress'>{translate('Address')}</label>
            </FormLabel>
            <Input id='stripeAddress' value={address} onChange={handleAddress} />
            <FormError>{getValidationError('address')}</FormError>
          </FormGroup>
        </FormRow>
        <FormRow>
          <FormGroup>
            <FormLabel>
              <label htmlFor='stripeCity'>{translate('City')}</label>
            </FormLabel>
            <Input id='stripeCity' value={city} onChange={handleCity} />
            <FormError>{getValidationError('city')}</FormError>
          </FormGroup>
          <FormGroup>
            <FormLabel>
              <label htmlFor='stripeZipCode'>{translate('Zip code')}</label>
            </FormLabel>
            <Input id='stripeZipCode' value={postalCode} onChange={handlePostalCode} />
            <FormError>{getValidationError('postalCode')}</FormError>
          </FormGroup>
        </FormRow>
        {
          // under are stripe separate elements for each field
          // if confirm stripe payment fails, error will be displayed on stripe error message under
        }
        <CreditCardRow>
          <FormGroup>
            <FormLabel>
              <label htmlFor='cardNumber'>{translate('Card number')}</label>
            </FormLabel>
            <StripeRowWrapper>
              <div id='stripe-card-number' className='input empty' placeholder='' />
            </StripeRowWrapper>
          </FormGroup>
          <FormGroup>
            <FormLabel>
              <label htmlFor='expirationDate'>{translate('Expiration date')}</label>
            </FormLabel>
            <StripeRowWrapper>
              <div id='stripe-card-expiry' className='input empty' />
            </StripeRowWrapper>
          </FormGroup>
          <FormGroup>
            <FormLabel>
              <label htmlFor='cvv'>CVV</label>
            </FormLabel>
            <StripeRowWrapper>
              <div id='stripe-card-cvc' className='input empty' />
            </StripeRowWrapper>
          </FormGroup>
        </CreditCardRow>
        <StripeErrorMessage>{stripeErrorMessage}</StripeErrorMessage>
      </>
    )
  }
}

StripePayment.propTypes = {
  currency: PropTypes.string,
  stripe: PropTypes.object,
  paymentMethodSettings: PropTypes.object,
  locale: PropTypes.string,
  cardholderName: PropTypes.string,
  handleCardHolderName: PropTypes.func,
  cardholderSameAsGuest: PropTypes.bool,
  handleSameAsGuest: PropTypes.func,
  getValidationError: PropTypes.func,
  receipt: PropTypes.object,
  handleAddress: PropTypes.func,
  handleCity: PropTypes.func,
  handlePostalCode: PropTypes.func,
  guestDetails: PropTypes.object,
  callBookReservationApi: PropTypes.func,
  onStripePaymentFailed: PropTypes.func,
  getStripeClientSecret: PropTypes.func,
  paymentDetails: PropTypes.object
}

export default StripePayment
