import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import {
  CardNumberWrapper,
  CardLabel,
  CardWrapper,
  CardHolderWrapper,
  HolderLabel,
  CardHolder,
  CardNumber,
  Number
} from './style'
import Maestro from 'Rentlio/components/Icons/maestro'
import Visa from 'Rentlio/components/Icons/visa'
import Mastercard from 'Rentlio/components/Icons/mastercard'
import { AMEX, getCardNumberIssuer, MAESTRO, MASTERCARD, VISA } from 'Rentlio/utils/cardNumber'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import Amex from 'Rentlio/components/Icons/amex'

const Card = ({ cardHolderName, maskedPan }) => {
  const { translate } = useContext(LanguageContext)

  const displayPan = maskedPan
    ? maskedPan.slice(0, 4) +
      ' ' +
      maskedPan.slice(4, 8) +
      ' ' +
      maskedPan.slice(8, 12) +
      ' ' +
      maskedPan.slice(12, 16) +
      ' ' +
      maskedPan.slice(16)
    : '**** **** **** ****'

  const cardType = getCardNumberIssuer(maskedPan.replace(/\*/g, 1))

  return (
    <CardWrapper>
      {/*   TODO implement and translate actual date */}
      {/* <StatusWrapper> */}
      {/* <Date>{translate('Paid on', { '%date%': 'August 23rd 2020' })}</Date> */}
      {/* <CheckmarkPaid /> */}
      {/* </StatusWrapper> */}
      <CardHolderWrapper>
        <HolderLabel>{translate('Card holder')}: </HolderLabel>
        <CardHolder>{cardHolderName}</CardHolder>
      </CardHolderWrapper>
      <CardNumberWrapper>
        <CardLabel>{translate('Card number')}</CardLabel>
        <CardNumber>
          <Number>{displayPan.replace(/\*/g, '•')}</Number>
          {cardType === AMEX && <Amex />}
          {cardType === MASTERCARD && <Mastercard />}
          {cardType === VISA && <Visa />}
          {cardType === MAESTRO && <Maestro />}
        </CardNumber>
      </CardNumberWrapper>
    </CardWrapper>
  )
}

Card.propTypes = {
  cardHolderName: PropTypes.string,
  maskedPan: PropTypes.string
}

export default Card
