import React from 'react'
import PropTypes from 'prop-types'
import {
  Selected,
  SelectWrapper,
  DropdownWrapper,
  Option,
  SelectedIcon,
  SelectedInput,
  PlaceholderWrapper
} from './style'
import ArrowDown from 'Rentlio/components/Icons/arrowDown'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

class Select extends React.PureComponent {
  static contextType = LanguageContext
  wrapperRef = React.createRef()
  state = {
    showDropdown: false,
    dropdownList: this.props.options,
    selectedInputValue: ''
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick)
    const { selected, options } = this.props
    const { translate } = this.context

    let selectedItem
    if (selected !== null && options && Array.isArray(options) && options.length > 0) {
      selectedItem = options.find(item => translate(item.value) === selected)
    }
    this.setState({
      selectedInputValue: selectedItem ? translate(selectedItem.label) : ''
    })
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick)
  }

  componentDidUpdate(prevProps) {
    const { selected, options } = this.props
    const { translate } = this.context

    if (selected !== prevProps.selected || options !== prevProps.options) {
      const selectedItem = options.find(option => option.value === selected)
      this.setState({
        selectedInputValue: selectedItem ? translate(selectedItem.label) : '',
        dropdownList: options
      })
    }
  }

  handleOutsideClick = event => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.closeDropdown()
    }
  }

  optionsLabelSelector = item => {
    const { optionsLabelSelector } = this.props
    const { translate } = this.context
    if (typeof optionsLabelSelector === 'function') {
      return optionsLabelSelector(item)
    }

    return translate(item.label)
  }

  getSelectedLabel = () => {
    const { options, selected } = this.props
    const selectedItem = options.find(item => this.optionsValueSelector(item) === selected)

    if (typeof selectedItem === 'undefined') {
      return null
    }

    return this.optionsLabelSelector(selectedItem)
  }

  optionsValueSelector = item => {
    const { optionsValueSelector } = this.props

    if (typeof optionsValueSelector === 'function') {
      return optionsValueSelector(item)
    }

    return item.value
  }

  showDropdown = () => {
    if (!this.isDisabled()) {
      this.setState({ showDropdown: true }, () => this.props.enableSearch && this.selectedInput.focus())
    }
  }

  closeDropdown = () => {
    this.setState({ showDropdown: false }, () => {
      this.props.enableSearch && this.handleSelectedOnCloseDropdown()
    })
  }

  handleSelectedOnCloseDropdown = () => {
    const { options, selected } = this.props
    const { translate } = this.context
    let selectedItem = null
    if (selected !== null && options && Array.isArray(options) && options.length > 0) {
      selectedItem = options.find(item => translate(item.value) === selected)
    }

    return this.setState({
      selectedInputValue: selectedItem ? translate(selectedItem.label) : '',
      dropdownList: options
    })
  }

  isDisabled = () => {
    const { options } = this.props
    return !(options && Array.isArray(options) && options.length > 0)
  }

  selectOption = item => {
    const { selectOption } = this.props
    if (typeof selectOption === 'function') {
      selectOption(item)
    }

    this.closeDropdown()
  }

  isItemSelected = item => this.optionsValueSelector(item) === this.props.selected

  isEmpty = () => this.props.selected === null || this.props.selected === ''

  onChangeInputData = event => {
    if (event && event.target) {
      this.setState({ dropdownList: this.filterOptions(event.target.value), selectedInputValue: event.target.value })
    }
  }

  filterOptions = text => {
    const { translate } = this.context
    const { options } = this.props
    if (!text || text === '') return options
    const filteredOptions = options.filter(item => {
      if (item.label.toLowerCase().includes(String(text).toLowerCase())) {
        return true
      }
      return false
    })
    if (filteredOptions.length === 0) {
      filteredOptions.push({ label: translate('No results'), value: 'No results' })
    }

    return filteredOptions
  }

  render = () => {
    const { placeholder, enableSearch, disabled } = this.props
    const { showDropdown, selectedInputValue, dropdownList } = this.state
    const { translate } = this.context

    return (
      <SelectWrapper ref={this.wrapperRef} disabled={disabled}>
        <Selected onClick={this.showDropdown} isDropdownShown={showDropdown} disabled={disabled}>
          {enableSearch ? (
            <SelectedInput
              onChange={this.onChangeInputData}
              value={enableSearch ? selectedInputValue : this.getSelectedLabel()}
              ref={node => (this.selectedInput = node)}
              placeholder={translate(placeholder)}
            />
          ) : this.isEmpty() ? (
            <PlaceholderWrapper>{translate(placeholder)}</PlaceholderWrapper>
          ) : (
            this.getSelectedLabel()
          )}
          <SelectedIcon rotate={showDropdown ? 1 : 0}>
            <ArrowDown strokeWidth={2} />
          </SelectedIcon>
        </Selected>
        <DropdownWrapper active={showDropdown}>
          {dropdownList.map((item, i) => (
            <Option key={i} onClick={() => this.selectOption(item)} selected={this.isItemSelected(item)}>
              {this.optionsLabelSelector(item)}
            </Option>
          ))}
        </DropdownWrapper>
      </SelectWrapper>
    )
  }
}

Select.propTypes = {
  options: PropTypes.array,
  placeholder: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  optionsLabelSelector: PropTypes.func,
  optionsValueSelector: PropTypes.func,
  selectOption: PropTypes.func,
  enableSearch: PropTypes.bool,
  disabled: PropTypes.bool
}

Select.defaultProps = {
  selected: null,
  options: [],
  placeholder: 'Select item'
}

export default Select
