import { css, keyframes } from 'styled-components'

const fadeInOut = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const carouselTransition = css`
0.8s ease-in-out
`

const defaultTransition = css`
0.2s ease-in-out
`

const cubicBezierTransition = css`
0.25s cubic-bezier(0.39, 0.575, 0.565, 1)
`

const animations = {
  fadeInOut,
  carouselTransition,
  defaultTransition,
  cubicBezierTransition
}

export default animations
