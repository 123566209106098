import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { OfferNoticeWrapper } from './style'
import { getOfferNoticeText } from 'Rentlio/utils/bookingTypeEnum'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import Wrapper from 'Rentlio/components/UI/wrapper'
class OfferNotice extends Component {
  static contextType = LanguageContext

  render() {
    const { offer, booking } = this.props
    const notice = getOfferNoticeText(offer, booking)

    const { translate } = this.context
    if (!notice) {
      return null
    }

    return (
      <OfferNoticeWrapper>
        <Wrapper>{translate(notice)}</Wrapper>
      </OfferNoticeWrapper>
    )
  }
}

OfferNotice.propTypes = {
  offer: PropTypes.object,
  booking: PropTypes.object
}

export default OfferNotice
