import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import { translateInternal } from 'Rentlio/helper/translation'

const LanguageContext = createContext()

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(window.initialBookingEngineData.configuration.locale)

  const changeLanguage = locale => {
    setLanguage(locale)
  }

  const translate = (translationString, parameters) => {
    return translateInternal(translationString, language, parameters)
  }

  return <LanguageContext.Provider value={{ changeLanguage, translate }}>{children}</LanguageContext.Provider>
}

LanguageProvider.propTypes = {
  children: PropTypes.node
}

export { LanguageContext }
export default LanguageProvider
