import styled, { css } from 'styled-components'
import { device } from 'Rentlio/style/responsive'
import { visibilityHidden } from 'Rentlio/style/mixins/utils'

export const SingleRatePlanWrapper = styled.div`
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0px 0.30914px 0.549583px rgba(0, 0, 0, 0.02), 0px 0.781839px 1.38994px rgba(0, 0, 0, 0.02),
    0px 1.59488px 2.83534px rgba(0, 0, 0, 0.03), 0px 3.28515px 5.84026px rgba(0, 0, 0, 0.03),
    0px 9px 16px rgba(0, 0, 0, 0.05);
  background: ${props => props.theme.colors.ratePlanBackground};
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  overflow: visible;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    border-radius: 0;
    box-shadow: 0px 0.30914px 0.549583px rgba(0, 0, 0, 0.02), 0px 0.781839px 1.38994px rgba(0, 0, 0, 0.02),
      0px 1.59488px 2.83534px rgba(0, 0, 0, 0.03), 0px 3.28515px 5.84026px rgba(0, 0, 0, 0.03),
      0px 9px 16px rgba(0, 0, 0, 0.05);
  }
`

export const InformationWrapper = styled.div`
  margin-bottom: 40px;

  @media ${device.tablet} {
    margin-bottom: 5px;
  }
`

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 100px;

  @media ${device.tablet} {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 40px;

    input {
      font-size: 32px;
    }
  }
`

export const SearchLabel = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.labelTextColor};
  margin-bottom: 10px;
`

export const Title = styled.div`
  color: ${props => props.theme.colors.primary};
  font-weight: bold;
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  margin-bottom: 20px;
  text-transform: uppercase;

  @media ${device.tablet} {
    text-align: center;
  }
`

export const LinkWrapper = styled.div`
  display: flex;
  gap: 10px;

  @media ${device.tablet} {
    text-align: center;
    justify-content: center;
    margin-bottom: 18px;
  }
`

export const LinkText = styled.div`
  color: ${props => props.theme.colors.textColor};
  font-weight: 500;
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  text-decoration: none;
  cursor: pointer;

  @media ${device.tablet} {
    text-align: center;
  }
`

export const PriceRow = styled.div`
  display: flex;
  align-items: baseline;
  flex: 0 0 100%;
  padding: 26px 20px 20px;
  width: 100%;
  margin-bottom: 30px;
  background: ${props => props.theme.colors.locationGray};
  border-radius: 10px;
  color: ${props => props.theme.colors.textColor};

  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`

export const PriceItem = styled.div`
  display: flex;
  flex-direction: column;
`

export const PriceLabel = styled.span`
  display: inline-block;
  margin-bottom: 7.5px;
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  text-transform: uppercase;
  color: ${props => props.theme.colors.lightText};

  @media ${device.tabletAndWider} {
    margin-bottom: 15px;
  }
`

export const Price = styled.span`
  font-size: ${props => props.theme.fonts._18};
  line-height: ${props => props.theme.fonts._21};
  font-weight: ${props => props.theme.fonts.boldWeight};
  color: ${props => props.theme.colors.textColor};
  text-transform: uppercase;

  @media ${device.tabletAndWider} {
    font-size: ${props => props.theme.fonts._24};
    line-height: ${props => props.theme.fonts._28};
  }
`

export const PriceCurrency = styled.span`
  margin-left: 5px;
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};

  @media ${device.tabletAndWider} {
    font-size: ${props => props.theme.fonts._18};
    line-height: ${props => props.theme.fonts._21};
  }
`

export const PriceIconWrapper = styled.div`
  margin: 15px auto;

  @media ${device.tabletAndWider} {
    margin: auto 30px 0;
    padding-bottom: 5px;
  }
`

export const PriceDivider = styled.div`
  width: 1px;
  height: 17px;
  background: ${props => props.theme.colors.lighterText};
  margin-left: 10px;
  margin-right: 10px;
`

export const PerNight = styled.div`
  margin-left: 5px;
  color: ${props => props.theme.colors.lightText};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
`

export const CancellationPolicyWrapper = styled.div`
  color: ${props => props.theme.colors.lightText};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  margin-right: 15px;

  @media ${device.tablet} {
    text-align: center;
    max-width: 400px;
  }
`

export const OccupancyWrapper = styled.div`
  display: flex;

  @media ${device.tablet} {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media ${device.tabletAndWider} {
    margin: 0 0 0 auto;
  }
`

export const OccupancyItem = styled.div`
  position: relative;
  padding: 0 15px;

  @media ${device.tablet} {
    margin-bottom: 25px;

    &:first-child {
      flex: 0 0 100%;
    }
  }

  @media ${device.laptopAndWider} {
    padding: 0 30px;
  }
`

export const OccupancyLabel = styled.div`
  color: ${props => props.theme.colors.lightText};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  margin-bottom: 15px;
  text-transform: uppercase;
  text-align: ${({ alignLeft }) => (alignLeft ? 'left' : 'center')};

  @media ${device.tablet} {
    text-align: center;
    margin-bottom: 7.5px;
  }
`

export const OccupancyValue = styled.div`
  font-weight: normal;
  font-size: ${props => props.theme.fonts._18};
  line-height: ${props => props.theme.fonts._21};
  color: ${props => props.theme.colors.textColor};
  text-align: ${({ alignLeft }) => (alignLeft ? 'left' : 'center')};

  @media ${device.tablet} {
    text-align: center;
  }

  @media ${device.mobile} {
    font-size: ${props => props.theme.fonts._14};
  }
`

export const OccupancyDivider = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 70px;
  background: linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, rgba(13, 13, 13, 0.1) 51.04%, rgba(51, 51, 51, 0) 100%);

  @media ${device.tablet} {
    ${visibilityHidden}
  }
`

export const IncludedExtrasWrapper = styled.div`
  margin-bottom: 21px;
  text-align: center;

  ${({ color }) =>
    color
      ? css`
          color: ${color};
        `
      : null}

  @media ${device.tabletAndWider} {
    display: flex;
    text-align: left;
  }
`

export const Label = styled.span`
  text-transform: uppercase;
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};

  ${({ color }) =>
    color
      ? css`
          color: ${color};
        `
      : null}

  @media ${device.tablet} {
    display: block;
    margin-bottom: 7.5px;
  }
`

export const Value = styled.span`
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  color: ${props => props.theme.colors.textColor};
  align-self: center;

  ${({ color }) =>
    color
      ? css`
          color: ${color};
        `
      : null}

  @media ${device.tabletAndWider} {
    margin-left: 10px;
    max-width: 768px;
  }
`
