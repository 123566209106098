import React from 'react'
import PropTypes from 'prop-types'
import {
  CookieNoticeWrapper,
  Notice,
  Action,
  AcceptButton,
  DeclineButton,
  UserChoices,
  UserChoice,
  PrivacyAndTerms,
  Checkbox
} from './style'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
class CookieNotice extends React.PureComponent {
  state = {
    cookies: {}
  }

  componentDidMount = () => {
    const { cookies } = this.props
    this.setState({
      cookies: cookies
    })
  }

  toogleCookie = e => {
    const { name, checked } = e.target
    this.setState({
      cookies: { ...this.state.cookies, [name]: { ...this.state.cookies[name], enabled: checked } }
    })
  }

  saveCookies = () => {
    const { acceptCookieNotice, saveCookies } = this.props
    const { cookies } = this.state

    saveCookies(cookies)
    acceptCookieNotice()
  }

  closeNotice = () => {
    const { activateCookieNotice } = this.props
    activateCookieNotice(false)
  }

  render = () => {
    const { text, hideUserSelectOptions, openPrivacyPolicyModal, openTermsOfServiceModal } = this.props
    const { cookies } = this.state
    const { translate } = this.context

    return (
      <CookieNoticeWrapper id='cookie-notice'>
        <Notice>
          {translate(text)}
          <UserChoices hide={hideUserSelectOptions}>
            {cookies &&
              Object.keys(cookies).map(cookieName => (
                <UserChoice key={cookieName}>
                  <Checkbox
                    name={cookieName}
                    type={'checkbox'}
                    checked={cookies[cookieName].enabled}
                    onChange={this.toogleCookie}
                  />
                  {cookies[cookieName].label}
                </UserChoice>
              ))}
          </UserChoices>
          <PrivacyAndTerms>
            <a onClick={openPrivacyPolicyModal}>{translate('Privacy Policy')}</a> {`& `}
            <a onClick={openTermsOfServiceModal}>{translate('Terms and Conditions')}</a>
          </PrivacyAndTerms>
        </Notice>
        <Action>
          <AcceptButton onClick={this.saveCookies} data-cy='cookie-accept'>
            {translate('Accept')} {hideUserSelectOptions ? translate('All') : ''}
          </AcceptButton>
          <DeclineButton onClick={this.closeNotice} data-cy='cookie-decline'>
            {translate('Decline')}
          </DeclineButton>
        </Action>
      </CookieNoticeWrapper>
    )
  }
}

CookieNotice.defaultProps = {
  hideUserSelectOptions: false
}

CookieNotice.propTypes = {
  cookies: PropTypes.object,
  text: PropTypes.string,
  activateCookieNotice: PropTypes.func,
  hideUserSelectOptions: PropTypes.bool,
  openPrivacyPolicyModal: PropTypes.func,
  openTermsOfServiceModal: PropTypes.func,
  saveCookies: PropTypes.func,
  acceptCookieNotice: PropTypes.func
}

CookieNotice.contextType = LanguageContext

export default CookieNotice
