import moment from 'moment'

const getPreviousMonth = (year, month) => ({
  month: month === 1 ? 12 : month - 1,
  year: month === 1 ? year - 1 : year
})

const getNextMonth = (year, month) => ({
  month: month === 12 ? 1 : month + 1,
  year: month === 12 ? year + 1 : year
})

const getDayAvailability = (selectedDate, availability) => {
  const date = selectedDate.date()
  const month = selectedDate.month() + 1
  const year = selectedDate.year()

  const availabilityForDayMonth = availability[year] && availability[year][month] ? availability[year][month] : {}

  return availabilityForDayMonth ? availabilityForDayMonth[date] : 0
}

const getPreviousDayAvailability = (selectedDate, availability) => {
  const year = selectedDate.year()
  const month = selectedDate.month() + 1
  const day = selectedDate.date()

  const { month: previousMonth, year: previousYear } = getPreviousMonth(year, month)
  const previousMonthAvailability = (availability[previousYear] && availability[previousYear][previousMonth]) || {}

  if (day === 1) {
    return previousMonthAvailability[
      // This complicated formula only checks what is the last day of previous month
      Object.keys(previousMonthAvailability).length > 0 ? Object.keys(previousMonthAvailability).length - 1 : 0
    ]
  }

  return (availability[year] && availability[year][month] && availability[year][month][day - 1]) || 0
}
/*
These are functions for handling unit type calendar.
We are not allowing selecting range if it is not available.
After we select arrival date, all dates except the ones available for departure are becoming blocked.
Check in only and check out only dates are different colors and when we click on one of them - message underneath
is displayed which states that selected date is checkin/out only.
*/

const isDateRangeAvailable = (startDate, endDate, unitTypeAvailability) => {
  if (!startDate || !endDate || !unitTypeAvailability || Object.keys(unitTypeAvailability).length === 0) {
    return false
  }
  const startDateYear = startDate.year()
  const endDateYear = endDate.year()
  const startDateMonth = startDate.month() + 1
  const endDateMonth = endDate.month() + 1
  const startDateDay = startDate.date()
  const endDateDay = endDate.date()

  // case when range is in same month
  if (startDateYear === endDateYear && startDateMonth === endDateMonth) {
    return isMonthAvailable(unitTypeAvailability, startDate, startDateYear, startDateMonth, startDateDay, endDateDay)
  }

  // case when range is in same year
  if (startDateYear === endDateYear && startDateMonth !== endDateMonth) {
    return isYearAvailable(
      unitTypeAvailability,
      startDateYear,
      startDateMonth,
      endDateMonth,
      startDateDay,
      endDateDay,
      startDate
    )
  }

  if (startDateYear !== endDateYear) {
    for (let i = startDateYear; i < endDateYear + 1; i++) {
      // calculating availability for first year in range
      if (
        i === startDateYear &&
        !isYearAvailable(unitTypeAvailability, startDateYear, startDateMonth, 12, startDateDay, 31, startDate)
      ) {
        return false
      }
      // calculating availability for years in the middle of start and end year
      if (
        i !== startDateYear &&
        i !== endDateYear &&
        !isYearAvailable(unitTypeAvailability, i, 1, 12, 1, 31, startDate)
      ) {
        return false
      }
      // calculating availability for last year in range
      if (
        i === endDateYear &&
        !isYearAvailable(unitTypeAvailability, endDateYear, 1, endDateMonth, 1, endDateDay, startDate)
      ) {
        return false
      }
    }
    return true
  }
}

const isYearAvailable = (
  unitTypeAvailability,
  year,
  startDateMonth,
  endDateMonth,
  startDateDay,
  endDateDay,
  startOfRangeDate
) => {
  const yearAvailability = unitTypeAvailability && unitTypeAvailability[year]
  if (!yearAvailability || Object.keys(yearAvailability).length === 0) {
    return false
  }

  if (startDateMonth === endDateMonth) {
    return isMonthAvailable(unitTypeAvailability, startOfRangeDate, year, startDateMonth, startDateDay, endDateDay)
  }

  for (let i = startDateMonth; i < endDateMonth + 1; i++) {
    // availability of start month
    if (
      i === startDateMonth &&
      !isMonthAvailable(unitTypeAvailability, startOfRangeDate, year, startDateMonth, startDateDay)
    ) {
      return false
    }
    // availability of end month
    if (
      i === endDateMonth &&
      !isMonthAvailable(unitTypeAvailability, startOfRangeDate, year, endDateMonth, 1, endDateDay)
    ) {
      return false
    }
    // availability of months between start and end month
    if (
      i !== startDateMonth &&
      i !== endDateMonth &&
      !isMonthAvailable(unitTypeAvailability, startOfRangeDate, year, i)
    ) {
      return false
    }
  }
  return true
}

const isMonthAvailable = (unitTypeAvailability, startOfRangeDate, year, month, startDateDay, endDateDay) => {
  const monthAvailability = unitTypeAvailability && unitTypeAvailability[year] && unitTypeAvailability[year][month]
  const monthDays = monthAvailability ? Object.keys(monthAvailability).length : 0

  if (!monthAvailability || monthDays === 0) {
    return false
  }

  const startDate = startDateDay || 1
  const endDate = endDateDay || monthDays

  for (let i = startDate; i < endDate + 1; i++) {
    const currentDate = moment().set({ year: year, month: month - 1, date: i })

    const dayBeforeAvailability = getPreviousDayAvailability(currentDate, unitTypeAvailability)

    if ((!monthAvailability[i] || monthAvailability[i] < 1) && (!dayBeforeAvailability || dayBeforeAvailability < 1)) {
      return false
    }
    if (
      isCheckInOnlyDay(currentDate, unitTypeAvailability) &&
      currentDate.format('DD.MM.YYYY') !== startOfRangeDate.format('DD.MM.YYYY')
    ) {
      return false
    }
  }
  return true
}

// we are checking if availability day before is 0, if yes then we are marking day as blocked, othewise no
const shouldBlockDay = (unitTypeAvailability, selectedDate) => {
  const selectedDateAvailability = getDayAvailability(selectedDate, unitTypeAvailability)
  const previousDayAvailability = getPreviousDayAvailability(selectedDate, unitTypeAvailability)

  // dont block this date if:
  if (
    // 1. its available
    (selectedDateAvailability && selectedDateAvailability > 0) ||
    // 2. Its not available, but previous day IS available - and this day can be used for checkOut
    ((!selectedDateAvailability || selectedDateAvailability < 1) &&
      previousDayAvailability &&
      previousDayAvailability > 0)
  ) {
    return false
  }

  return true
}

const isCheckOutOnlyDay = (selectedDate, unitTypeAvailability) => {
  if (
    !unitTypeAvailability ||
    selectedDate.isBefore(
      moment()
        .hours(0)
        .minutes(0)
        .seconds(0)
    )
  ) {
    return false
  }
  const availabilityDay = getDayAvailability(selectedDate, unitTypeAvailability)
  const availabilityDayBefore = getPreviousDayAvailability(selectedDate, unitTypeAvailability)

  // checkout date is only if availability is 0 and availability day before is > 0
  if ((!availabilityDay || availabilityDay < 1) && availabilityDayBefore && availabilityDayBefore > 0) {
    return true
  }
}

const isCheckInOnlyDay = (selectedDate, unitTypeAvailability) => {
  if (
    !unitTypeAvailability ||
    selectedDate.isBefore(
      moment()
        .hours(0)
        .minutes(0)
        .seconds(0)
    )
  ) {
    return false
  }

  const availabilityDay = getDayAvailability(selectedDate, unitTypeAvailability)
  const availabilityDayBefore = getPreviousDayAvailability(selectedDate, unitTypeAvailability)

  // checkin date is only if availability is > 0 and day before is 0 or less
  if (availabilityDay && availabilityDay > 0 && (!availabilityDayBefore || availabilityDayBefore < 1)) {
    return true
  }
}

export { shouldBlockDay, isDateRangeAvailable, isCheckInOnlyDay, isCheckOutOnlyDay, getPreviousMonth, getNextMonth }
