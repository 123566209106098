const AMEX_REGEX = [/^3[47][0-9]{13}$/]
const MAESTRO_REGEX = [
  /^(6759[0-9]{2})[0-9]{6,13}$/,
  /^(50[0-9]{4})[0-9]{6,13}$/,
  /^5[6-9][0-9]{10,17}$/,
  /^6[0-9]{11,18}$/
]
const MASTERCARD_REGEX = [
  /^5[1-5][0-9]{14}$/,
  /^2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12})$/
]
const VISA_REGEX = [/^4([0-9]{12}|[0-9]{15}|[0-9]{18})$/]

function isCard(input, regex, card) {
  for (let i = 0; i < regex.length; i++) {
    const result = input.match(regex[i])
    if (result !== null) {
      return card
    }
  }

  return null
}

export function getCardNumberIssuer(cardNumber) {
  if (isCard(cardNumber, AMEX_REGEX, AMEX) !== null) {
    return AMEX
  }

  if (isCard(cardNumber, MAESTRO_REGEX, MAESTRO) !== null) {
    return MAESTRO
  }

  if (isCard(cardNumber, MASTERCARD_REGEX, MASTERCARD) !== null) {
    return MASTERCARD
  }

  if (isCard(cardNumber, VISA_REGEX, VISA) !== null) {
    return VISA
  }

  return null
}
export const AMEX = 'amex'
export const MAESTRO = 'maestro'
export const MASTERCARD = 'mastercard'
export const VISA = 'visa'
