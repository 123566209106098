import { FETCH_AVAILABILITY_COMPLETED } from 'Rentlio/state/types'
import createReducer from 'Rentlio/state/utils/createReducer'

/**
 * todo this still isn't defined what can be null, what can't
const initialState = {
  id: int,
  name: string,
  propertyId: int,
  propertyTitle: string,
  currency: string,
  activeLanguages: string[],
  logoName: string,
  logoUrl: string,
  coverImageName: string,
  coverImageUrl: string,
  primaryColor: string,
  secondaryColor: string,
  address: string,
  latitude: float,
  longitude: float,
  phone: string,
  email: string,
  facebook: string,
  instagram: string,
  twitter: string
}
**/

const initialState = {
  id: null,
  name: null,
  propertyId: null,
  propertyTitle: null,
  currency: null,
  activeLanguages: null,
  logoName: null,
  logoUrl: null,
  coverImageName: null,
  coverImageUrl: null,
  primaryColor: null,
  secondaryColor: null,
  address: null,
  latitude: null,
  longitude: null,
  phone: null,
  countryDialCode: null,
  email: null,
  facebook: null,
  instagram: null,
  twitter: null
}

const bookingEngine = createReducer(initialState)({
  [FETCH_AVAILABILITY_COMPLETED]: (state, action) => ({ ...action.payload.bookingEngine })
})

export default bookingEngine

// selectors
export function getBookingEngine(state) {
  return state.bookingEngine
}
