import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const OfferWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 100px 0;
`

export const OfferExpiryDate = styled.div`
  width: 100%;
  margin-top: 30px;
  text-align: right;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @media ${device.tablet} {
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
`

export const OfferExpiryLabel = styled.div`
  margin-bottom: 7.5px;

  @media ${device.tabletAndWider} {
    margin-bottom: 0;

    &:not(:first-child) {
      margin-left: 30px;
    }
  }
`
export const OfferExpiryValue = styled.div`
  font-weight: 600;
  margin: 0 0 15px;

  @media ${device.tabletAndWider} {
    margin: 0 0 0 10px;
  }
`
