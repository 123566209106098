import PropTypes from 'prop-types'
import React from 'react'
import { Label, LocationWrapper, Location, IconWrapper, Locations, LocationName } from './style'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import ArrowDown from 'Rentlio/components/Icons/arrowDown'

class LocationSelect extends React.Component {
  static contextType = LanguageContext

  wrapperRef = React.createRef()
  state = {
    editing: false
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick)
  }

  toggleEditing = () => {
    const { editing } = this.state
    if (editing) {
      return
    }
    this.setState({ editing: true })
  }

  handleOutsideClick = event => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ editing: false })
    }
  }

  handleLocationSelect = location => {
    const { onLocationChange } = this.props

    this.setState({ editing: false })

    if (location === 'All locations') location = null
    onLocationChange(location)
  }

  getLocations = () => {
    const { unitTypes } = this.props
    const locations = unitTypes
      .map(({ city }) => city)
      .filter((city, index, array) => array.indexOf(city) === index && city !== null)

    locations.unshift('All locations')

    return locations
  }

  render() {
    const { editing } = this.state
    const { location } = this.props
    const { translate } = this.context
    return (
      <LocationWrapper onClick={this.toggleEditing} ref={this.wrapperRef} editing={editing}>
        <Label>{translate('Location')}</Label>
        <Location>
          <LocationName>{location || translate('All locations')}</LocationName>
          <IconWrapper>
            <ArrowDown strokeWidth={2} />
          </IconWrapper>
        </Location>
        {editing && (
          <Locations>
            {this.getLocations().map((city, index) => (
              <li key={index} onClick={() => this.handleLocationSelect(city)}>
                {translate(city)}
              </li>
            ))}
          </Locations>
        )}
      </LocationWrapper>
    )
  }
}

LocationSelect.propTypes = {
  unitTypes: PropTypes.array,
  location: PropTypes.string,
  onLocationChange: PropTypes.func
}

export default LocationSelect
