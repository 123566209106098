import PropTypes from 'prop-types'
import React from 'react'
import { Map, ContactInfoContainer, ContactWrapper, FullscreenButton } from './style'
import InfoRow from './InfoRow'
import { Maximize } from 'Rentlio/components/Icons/maximize'

import Wrapper from 'Rentlio/components/UI/wrapper'

const Contact = ({ values, longitude, latitude, markerColor }) => {
  const mapUrl =
    `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=13&size=640x640&scale=2` +
    `&markers=color:0x${markerColor}|${latitude},${longitude}` +
    '&key=' +
    window.extraInfo.GOOGLE_MAPS_KEY

  const googleMapsUrl = `https://maps.google.com?q=${latitude},${longitude}`
  return (
    <ContactWrapper>
      <Wrapper isRelative>
        <ContactInfoContainer>
          {values.map(({ label, value }) => {
            if (!value) return
            return <InfoRow key={label} label={label} value={value} />
          })}
        </ContactInfoContainer>

        <a href={googleMapsUrl} target='_blank' rel='noopener noreferrer'>
          <Map map={mapUrl}>
            <FullscreenButton>
              <Maximize />
            </FullscreenButton>
          </Map>
        </a>
      </Wrapper>
    </ContactWrapper>
  )
}

Contact.propTypes = {
  values: PropTypes.array,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  markerColor: PropTypes.string
}

export default Contact
