import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const ServiceWrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.border};
  }

  @media ${device.tablet} {
    padding: 40px 0;
    flex-wrap: wrap;
  }
`

export const Info = styled.div`
  display: flex;

  @media ${device.tablet} {
    width: 100%;
  }
`

export const Title = styled.div`
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};

  @media ${device.tablet} {
    font-size: ${props => props.theme.fonts._18};
    line-height: ${props => props.theme.fonts._21};
  }
`

export const Description = styled.div`
  color: ${props => props.theme.colors.lightText};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
`

export const PriceInfo = styled.div`
  margin-left: auto;
  margin-right: 100px;
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    margin: 20px 0;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    span {
      margin: 0;
    }
  }
`
export const Price = styled.div`
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  margin-right: 10px;

  @media ${device.tablet} {
    margin-right: 0;
    font-size: ${props => props.theme.fonts._24};
    line-height: ${props => props.theme.fonts._28};
  }
`
export const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media ${device.tablet} {
    input {
      font-size: 32px;
    }
  }
`

export const DisableMandatory = styled.div`
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};

  input {
    color: ${({ isDisabled, theme }) => isDisabled && theme.colors.disabledGray};
  }

  svg {
    path {
      fill: ${({ isDisabled, theme }) => isDisabled && theme.colors.disabledGray};
    }

    rect {
      fill: ${({ isDisabled, theme }) => isDisabled && theme.colors.disabledGray};
    }
  }
`
