import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'
import { Radio, RadioGroupWrapper } from 'Rentlio/components/UI/RadioGroup/style'

export const PaymentInfoWrapper = styled.div`
  max-width: 650px;
  width: 100%;
  margin-bottom: 150px;
  padding-top: 40px;
  padding-right: 50px;

  @media ${device.tablet} {
    order: 2;
    padding: 80px 30px 100px 30px;
    margin-bottom: 0;

    ${RadioGroupWrapper} {
      margin-bottom: 40px;
    }

    ${Radio} {
      min-width: unset;

      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
`
