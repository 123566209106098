import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'
import { visibilityHidden } from 'Rentlio/style/mixins/utils'

export const PriceTableWrapper = styled.div`
  margin: 15px 0;
`

export const TableHead = styled.div`
  display: flex;
  font-family: inherit;
  font-family: inherit;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 15px;
  }

  @media ${device.tabletAndWider} {
    padding: 15px 5px;
  }
`

export const Text = styled.span`
  display: inline-block;
  font-family: inherit;
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  font-weight: ${props => props.theme.fonts.mediumWeight};
`

export const HeadItem = styled.div`
  font-family: inherit;
  color: ${props => props.theme.colors.textColor};

  @media ${device.tabletAndWider} {
    flex: ${props => (props.width ? '0 1 ' + props.width + '%' : '1 1 auto')};
    text-align: ${props => (props.alignRight ? 'right' : 'left')};
    margin-right: ${props => (props.offsetRight ? 'auto' : '0px')};
    white-space: nowrap;

    &:first-child {
      padding-left: 15px;
    }

    ${Text} {
      padding-right: 15px;
    }
  }

  @media ${device.tablet} {
    width: 25%;

    white-space: nowrap;
    text-align: center;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: end;
    }
  }

  ${Text} {
    font-weight: ${props => props.theme.fonts.regularWeight};
  }
`

export const TableBody = styled.div`
  font-family: inherit;

  @media ${device.tabletAndWider} {
    padding: 0 5px;
  }
`

export const Row = styled.div`
  font-family: inherit;
  display: flex;
  border-top: 1px solid ${props => props.theme.colors.border};
  padding: 5px 15px;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }

  @media ${device.tabletAndWider} {
    padding: 15px 0;
  }
`

export const Column = styled.div`
  font-family: inherit;

  @media ${device.tablet} {
    display: flex;
    justify-content: space-between;
    padding: 5px 0px;
    width: 25%;
    white-space: nowrap;
    justify-content: center;
    white-space: break-spaces;

    &:first-child {
      justify-content: left;
      text-align: left;
    }

    &:last-child {
      justify-content: end;
      text-align: right;
    }
  }

  @media ${device.tabletAndWider} {
    flex: ${props => (props.width ? '0 1 ' + props.width + '%' : '1 1 auto')};
    text-align: ${props => (props.alignRight ? 'right' : 'left')};
    margin-right: ${props => (props.offsetRight ? 'auto' : '0px')};

    &:first-child {
      padding-left: 15px;
    }

    ${Text} {
      padding-right: 15px;
    }
  }
`

export const TableFooter = styled.div`
  font-family: inherit;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 0px 0px 8px 8px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.veryDarkGray};

  ${Text} {
    padding-right: 0;
    font-size: ${props => props.theme.fonts._14};
    line-height: ${props => props.theme.fonts._16};
  }

  @media ${device.mobile} {
    padding: 15px;
  }
`

export const TotalWrapper = styled.div`
  font-family: inherit;

  ${Text}:first-child {
    display: inline-block;
    margin-right: 70px;
    text-transform: uppercase;

    @media ${device.mobile} {
      ${visibilityHidden}
    }
  }
`

export const SelectedCurrency = styled.span`
  @media (max-width: 500px) {
    display: none;
  }
`
