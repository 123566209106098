import React from 'react'
import PropTypes from 'prop-types'
import {
  LANG_CROATIAN,
  LANG_ENGLISH,
  LANG_FRENCH,
  LANG_GERMAN,
  LANG_ITALIAN,
  LANG_CZECH,
  LANG_SPANISH,
  LANG_VIETNAMESE,
  LANG_GREEK,
  LANG_POLISH,
  LANG_SLOVENIAN
} from 'Rentlio/helper/translation'
import {
  Select,
  Selected,
  LanguageWrapper,
  SelectedLanguage,
  Label,
  Dropdown,
  DropdownItem
} from 'Rentlio/screen/layout/components/Header/Languages/style'
import {
  CroatianFlag,
  EnglishFlag,
  FrenchFlag,
  GermanFlag,
  ItalianFlag,
  CzechFlag,
  SpanishFlag,
  VietnameseFlag,
  GreekFlag,
  PolishFlag,
  SlovenianFlag
} from 'Rentlio/components/Icons/flags'
import Animation from 'Rentlio/components/UI/Animation'
import ArrowDown from 'Rentlio/components/Icons/arrowDown'

import { LanguageContext } from 'Rentlio/context/LanguageContext'

class Languages extends React.PureComponent {
  static contextType = LanguageContext

  wrapperRef = React.createRef()

  state = {
    showDropdown: false
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick)
  }

  closeDropdown = () => this.setState({ showDropdown: false })

  handleOutsideClick = event => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.closeDropdown()
    }
  }

  getLocaleIcon(locale) {
    if (locale === LANG_ENGLISH) {
      return <EnglishFlag />
    }

    if (locale === LANG_CROATIAN) {
      return <CroatianFlag />
    }

    if (locale === LANG_GERMAN) {
      return <GermanFlag />
    }

    if (locale === LANG_ITALIAN) {
      return <ItalianFlag />
    }

    if (locale === LANG_FRENCH) {
      return <FrenchFlag />
    }

    if (locale === LANG_CZECH) {
      return <CzechFlag />
    }

    if (locale === LANG_SPANISH) {
      return <SpanishFlag />
    }

    if (locale === LANG_VIETNAMESE) {
      return <VietnameseFlag />
    }

    if (locale === LANG_GREEK) {
      return <GreekFlag />
    }

    if (locale === LANG_POLISH) {
      return <PolishFlag />
    }

    if (locale === LANG_SLOVENIAN) {
      return <SlovenianFlag />
    }
  }

  onClick = locale => {
    const { changeLanguage } = this.context
    const { isCheckIn } = this.props

    changeLanguage(locale)
    this.props.selectLocale(locale, isCheckIn)
    this.closeDropdown()
  }

  render() {
    const { showDropdown } = this.state
    const { activeLanguages, locale } = this.props
    const { translate } = this.context

    return (
      <LanguageWrapper>
        <Label>{translate('Language')}</Label>
        <Select ref={this.wrapperRef}>
          <Selected onClick={() => activeLanguages.length > 1 && this.setState({ showDropdown: true })}>
            {this.getLocaleIcon(locale)}
            <SelectedLanguage>{locale}</SelectedLanguage>
            {activeLanguages.length > 1 && <ArrowDown />}
          </Selected>
          <Animation show={this.state.showDropdown} animationType={'fadeInOutBottom'}>
            <Dropdown showDropdown={showDropdown}>
              {activeLanguages.map(lang => (
                <DropdownItem key={lang} onClick={() => this.onClick(lang)}>
                  {this.getLocaleIcon(lang)}
                  <SelectedLanguage>{lang}</SelectedLanguage>
                </DropdownItem>
              ))}
            </Dropdown>
          </Animation>
        </Select>
      </LanguageWrapper>
    )
  }
}

Languages.propTypes = {
  activeLanguages: PropTypes.array,
  locale: PropTypes.string,
  selectLocale: PropTypes.func,
  isCheckIn: PropTypes.bool
}

export default Languages
