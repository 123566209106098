import { FETCH_AVAILABILITY_COMPLETED } from 'Rentlio/state/types'
import createReducer from 'Rentlio/state/utils/createReducer'

/**
 const initialState = [
 ...
 {
    unitTypeId: int,
    unitTypeName: string,
    numberOfUnits: int,
    available: bool,
    availableUnits: int,
    rates: object[],
    images: object[],
    amenities: object[]
   }
 ...
 ]
 */

const initialState = []

const unitTypes = createReducer(initialState)({
  [FETCH_AVAILABILITY_COMPLETED]: (state, action) => [...action.payload.unitTypes]
})

export default unitTypes
