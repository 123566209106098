import PropTypes from 'prop-types'
import React from 'react'
import Cart from '../Cart'
import SingleUnitType from './SingleUnitType'
import { NoUnitTypes, UnitTypesWrapper, Container } from './style'
import ImportantNote from './ImportantNote'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import PromoContainer from 'Rentlio/screen/layout/containers/promo'
import Animation from 'Rentlio/components/UI/Animation'
import { breakpoint } from 'Rentlio/style/responsive'

class UnitTypes extends React.PureComponent {
  static contextType = LanguageContext

  componentDidMount() {
    if (window.innerWidth <= breakpoint.small) {
      this.handleFadeInAnimation()
    }
  }

  handleFadeInAnimation = () => {
    const unitTypes = document.querySelectorAll('.unitType-name')
    const entryOptions = {
      rootMargin: '99% 0px -99% 0px'
    }

    // eslint-disable-next-line
    const unitTypeObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('sticky')
        } else {
          entry.target.classList.remove('sticky')
        }
      })
    }, entryOptions)

    unitTypes.forEach(unit => {
      unitTypeObserver.observe(unit)
    })
  }

  hasAmenities = unitType => {
    const {
      configuration: { selectedAmenities }
    } = this.props

    if (selectedAmenities.length === 0) {
      return true
    }

    const unitTypeAmenitiesThatAreMatched = unitType.amenities.filter(({ id }) => selectedAmenities.includes(id))

    return unitTypeAmenitiesThatAreMatched.length === selectedAmenities.length
  }

  isInSelectedBookingEngineId = unitType => {
    const { selectedBookingEngineId } = this.props

    if (selectedBookingEngineId === null || selectedBookingEngineId === unitType.bookingEngine.id) {
      return true
    }

    return false
  }

  isInLocation = unitType => {
    const {
      configuration: { location }
    } = this.props

    if (!location) {
      return true
    }

    return location === unitType.city
  }

  getAvailableUnits = (basket, unitType) => {
    const alreadyAddedRoomsForUnitType = basket.filter(item => item.unitTypeId === unitType.unitTypeId)
    const availableUnits = unitType.availableUnits - alreadyAddedRoomsForUnitType.length
    return availableUnits > 0 ? availableUnits : 0
  }

  updateDates = (startDate, endDate) => {
    const { updateDates } = this.props
    updateDates(startDate, endDate)
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  render() {
    const {
      unitTypes,
      request,
      fetchAvailabilityForMonth,
      unitTypeAvailability,
      totalCost,
      clearBasket,
      unitTypesMaxAvailability,
      services,
      animationLoading,
      primaryColor,
      selectedUnitTypeCurrency,
      isGroupBookingEngine,
      groupBESlug,
      openUnitTypeModal,
      basket,
      alwaysShowRates,
      importantNote
    } = this.props
    const { translate } = this.context

    const filteredUnitTypes = unitTypes.filter(
      unitType =>
        this.hasAmenities(unitType) && this.isInSelectedBookingEngineId(unitType) && this.isInLocation(unitType)
    )

    return (
      <Container>
        {totalCost.accomodationCount !== 0 && (
          <Animation show={true} animationType={'fadeInOutCart'} zIndex={60}>
            <Cart
              totalCost={totalCost}
              clearBasket={clearBasket}
              services={services}
              selectedUnitTypeCurrency={selectedUnitTypeCurrency}
            />
          </Animation>
        )}
        <Animation show={!animationLoading} animationType={'fadeInOutBottomSlow'}>
          <PromoContainer isMobile />
          <UnitTypesWrapper>
            {importantNote && <ImportantNote note={importantNote} />}
            {filteredUnitTypes.map((unitType, index) => (
              <SingleUnitType
                key={unitType.unitTypeId}
                animationLoading={!animationLoading}
                even={index % 2 === 0}
                unitType={unitType}
                request={request}
                updateDates={this.updateDates}
                fetchAvailabilityForMonth={fetchAvailabilityForMonth}
                unitTypeAvailability={unitTypeAvailability}
                maxAvailability={unitTypesMaxAvailability[unitType.unitTypeId]}
                primaryColor={primaryColor}
                isGroupBookingEngine={isGroupBookingEngine}
                groupBESlug={groupBESlug}
                openUnitTypeModal={openUnitTypeModal}
                availableUnits={this.getAvailableUnits(basket, unitType)}
                alwaysShowRates={alwaysShowRates}
              />
            ))}
            {filteredUnitTypes.length === 0 && <NoUnitTypes>{translate('No units available')}</NoUnitTypes>}
          </UnitTypesWrapper>
        </Animation>
      </Container>
    )
  }
}

UnitTypes.propTypes = {
  unitTypes: PropTypes.array,
  request: PropTypes.object,
  unitTypesMaxAvailability: PropTypes.object,
  unitTypeAvailability: PropTypes.object,
  updateDates: PropTypes.func,
  fetchAvailabilityForMonth: PropTypes.func,
  totalCost: PropTypes.object,
  clearBasket: PropTypes.func,
  services: PropTypes.array,
  animationLoading: PropTypes.bool,
  primaryColor: PropTypes.string,
  selectedUnitTypeCurrency: PropTypes.string,
  isGroupBookingEngine: PropTypes.bool,
  groupBESlug: PropTypes.string,
  configuration: PropTypes.object,
  selectedBookingEngineId: PropTypes.number,
  openUnitTypeModal: PropTypes.func,
  basket: PropTypes.array,
  alwaysShowRates: PropTypes.bool,
  importantNote: PropTypes.string
}
export default UnitTypes
