import styled from 'styled-components'

const SmallText = styled.span`
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  color: ${props => props.theme.colors.textColor};
  margin-left: 5px;
  margin-right: 5px;
  opacity: 0.6;
`

export default SmallText
