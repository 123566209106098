import moment from 'moment'
import { trans } from 'Rentlio/helper/translation'

const FORMAT_VALIDATION_ERROR = 'Expiration date is not in XX/YY format'
const DATE_IN_THE_PAST_VALIDATION_ERROR = 'Expiration date is in the past'

export function expirationDateConstraint(value) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return null
  }

  if (typeof value !== 'string') {
    return trans(FORMAT_VALIDATION_ERROR)
  }

  const parts = value.split('/')

  if (parts.length !== 2) {
    return trans(FORMAT_VALIDATION_ERROR)
  }

  const month = parseInt(parts[0])
  let year = parseInt(parts[1])

  if (isNaN(month) || isNaN(year)) {
    return trans(FORMAT_VALIDATION_ERROR)
  }

  if (month < 1 || month > 12) {
    return FORMAT_VALIDATION_ERROR
  }

  if (year < 100) {
    year = 2000 + year
  }

  const now = moment()

  if (now.year() > year || (now.year() === year && now.month() + 1 > month)) {
    return trans(DATE_IN_THE_PAST_VALIDATION_ERROR)
  }

  return null
}
