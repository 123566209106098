import React from 'react'
import PropTypes from 'prop-types'
import { MobileInformationWrapper, Name } from './style'
import MaxOccupancy from 'Rentlio/components/MaxOccupancy'

const MobileInformation = ({
  name,
  maxOccupancy,
  standardOccupancy,
  isGroupBookingEngine,
  unitTypeName,
  unitTypeCity,
  unitTypeSlug,
  groupBESlug
}) => (
  <MobileInformationWrapper className='unitType-name'>
    <MaxOccupancy
      maxOccupancy={maxOccupancy}
      standardOccupancy={standardOccupancy}
      unitTypeName={unitTypeName}
      unitTypeCity={unitTypeCity}
      isGroupBookingEngine={isGroupBookingEngine}
      unitTypeSlug={unitTypeSlug}
      groupBESlug={groupBESlug}
    />
    <Name>{name}</Name>
  </MobileInformationWrapper>
)

MobileInformation.propTypes = {
  name: PropTypes.string,
  maxOccupancy: PropTypes.number,
  standardOccupancy: PropTypes.number,
  unitTypeName: PropTypes.string,
  unitTypeCity: PropTypes.string,
  isGroupBookingEngine: PropTypes.bool,
  unitTypeSlug: PropTypes.string,
  groupBESlug: PropTypes.string
}

export default MobileInformation
