import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const CurrencyWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 46px;
  margin-right: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  display: flex;

  @media ${device.mobile} {
    margin-top: 28px;
    margin-bottom: 0;
    margin-right: 0;
  }
`

export const SelectedCurrency = styled.div`
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  font-weight: ${props => props.theme.fonts.mediumWeight};
  margin-left: 5px;
  margin-right: 5px;
`

export const Label = styled.div`
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._17};
  color: ${props => props.theme.colors.textColor};
  margin-left: 5px;
  margin-right: 5px;
  opacity: 0.6;
`

export const Select = styled.div`
  position: relative;
  z-index: ${props => props.theme.zIndex.dropdown};
`

export const Selected = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
`

export const Dropdown = styled.div`
  position: absolute;
  top: -20px;
  left: -20px;
  padding: 10px 0;
  z-index: ${props => props.theme.zIndex.dropdown};
  border-radius: 10px;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 0.927421px 0.686979px rgba(0, 0, 0, 0.0279017), 0px 2.34552px 1.73742px rgba(0, 0, 0, 0.0399338),
    0px 4.78464px 3.54417px rgba(0, 0, 0, 0.0500662), 0px 9.85544px 7.30033px rgba(0, 0, 0, 0.0620983),
    0px 27px 20px rgba(0, 0, 0, 0.09);
  cursor: pointer;
`

export const DropdownItem = styled.div`
  width: 100%;
  padding: 10px 20px 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: all ${({ theme }) => theme.animations.defaultTransition};

  &:hover {
    background: ${({ theme }) => theme.colors.lightGrayishBlue};
  }
`
