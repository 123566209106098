import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const UnitTypesWrapper = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;

  @media ${device.tablet} {
    margin-top: 0;
  }
`
export const NoUnitTypes = styled.div`
  height: 100px;
  margin: 0 auto;
  font-size: ${({ theme }) => theme.fonts._24};
  line-height: ${({ theme }) => theme.fonts._28};
  text-align: center;
`

export const Container = styled.div``
