import moment from 'moment'

export function convertCurrency(value, from, to) {
  if (typeof value === 'undefined' || typeof from === 'undefined' || typeof to === 'undefined') {
    return {
      value: value || 0,
      currency: from || ''
    }
  }

  if (window.currencies === null) {
    return {
      value,
      currency: from
    }
  }

  let baseValue = value

  if (window.currencies.baseCurrency !== from) {
    const toBaseConversion = window.currencies.currencies[from]

    if (typeof toBaseConversion === 'undefined') {
      return {
        value,
        currency: from
      }
    }

    baseValue = value / toBaseConversion
  }

  const conversion = window.currencies.currencies[to]

  if (typeof conversion === 'undefined') {
    return {
      value,
      currency: from
    }
  }

  return {
    value: baseValue * conversion,
    currency: to
  }
}

export function shouldShowExchangeRateForHRK(selectedCurrency) {
  /* we are showing fixed exchange rate for all properties that have HRK as primary or secondary currency and 
  user has EUR or HRK selected on BE layout. So even if rentlio user has HRK as one of invoice currencies and does not setup HRK as one of BE currencies
  exchange rate will be shown 
  */
  const propertyPrimaryCurrency = window.initialBookingEngineData?.bookingEngine?.currency
  const propertySecondaryCurrency = window.initialBookingEngineData?.bookingEngine?.secondaryCurrency

  if (
    (propertyPrimaryCurrency === 'HRK' || propertySecondaryCurrency === 'HRK') &&
    (selectedCurrency === 'HRK' || selectedCurrency === 'EUR')
  ) {
    return true
  }

  return false
}

export function shouldShowHrkTotal(selectedCurrency) {
  const propertySecondaryCurrency = window.initialBookingEngineData?.bookingEngine?.secondaryCurrency
  if (
    propertySecondaryCurrency === 'HRK' &&
    selectedCurrency === 'EUR' &&
    moment().isSameOrAfter(moment.unix(1672531200))
  ) {
    return true
  }

  return false
}
