import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const PackageDealWrapper = styled.div`
  margin-top: 60px;
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0px 0.30914px 0.549583px rgba(0, 0, 0, 0.02), 0px 0.781839px 1.38994px rgba(0, 0, 0, 0.02),
    0px 1.59488px 2.83534px rgba(0, 0, 0, 0.03), 0px 3.28515px 5.84026px rgba(0, 0, 0, 0.03),
    0px 9px 16px rgba(0, 0, 0, 0.05);
  background: ${props => props.theme.colors.textColor};
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  height: ${props => (props.height ? `${props.height}px` : 'auto')};
  overflow: visible;

  @media ${device.tablet} {
    align-items: center;
    margin-bottom: 0;
    border-radius: ${props => (props.promote ? '10px' : '0px')};
    box-shadow: 0px 0.30914px 0.549583px rgba(0, 0, 0, 0.02), 0px 0.781839px 1.38994px rgba(0, 0, 0, 0.02),
      0px 1.59488px 2.83534px rgba(0, 0, 0, 0.03), 0px 3.28515px 5.84026px rgba(0, 0, 0, 0.03),
      0px 9px 16px rgba(0, 0, 0, 0.05);
  }
`

export const UpperPartWrapper = styled.div`
  display: flex;
  overflow: visible;
  flex: 1;
  height: ${props => (props.height ? `${props.height}px` : 'auto')};

  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
  }
`

export const InformationWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;

  @media ${device.tablet} {
    margin-bottom: 5px;
  }
`

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 100px;

  @media ${device.tablet} {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 40px;

    input {
      font-size: 32px;
    }
  }
`

export const SearchLabel = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.labelTextColor};
  margin-bottom: 10px;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;

  @media ${device.tablet} {
    flex-direction: column;
  }
`

export const Title = styled.div`
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  font-size: ${props => props.theme.fonts._24};
  line-height: ${props => props.theme.fonts._28};
  margin-bottom: 20px;

  @media ${device.tablet} {
    text-align: center;
    margin-right: 0px;
    margin-bottom: 15px;
  }
`

export const PriceRow = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;

  @media ${device.tablet} {
    justify-content: center;
  }
`

export const TotalPrice = styled.div`
  font-weight: bold;
  font-size: ${props => props.theme.fonts._24};
  line-height: ${props => props.theme.fonts._28};
  color: ${props => props.theme.colors.white};

  @media ${device.onlyMobile} {
    font-size: ${props => props.theme.fonts._20};
    line-height: ${props => props.theme.fonts._24};
  }
`

export const TotalPriceCurrency = styled.div`
  margin-left: 5px;
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._18};
  line-height: ${props => props.theme.fonts._21};
  color: ${props => props.theme.colors.white};
`

export const AveragePrice = styled.div`
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._18};
  line-height: ${props => props.theme.fonts._21};
  color: ${props => props.theme.colors.white};

  @media ${device.tablet} {
    font-size: ${props => props.theme.fonts._16};
    line-height: ${props => props.theme.fonts._18};
  }
`

export const PriceDivider = styled.div`
  width: 1px;
  height: 17px;
  background: ${props => props.theme.colors.locationGray};
  margin-left: 10px;
  margin-right: 10px;
`

export const PerNight = styled.div`
  margin-left: 5px;
  color: ${props => props.theme.colors.locationGray};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
`

export const CancellationPolicyWrapper = styled.div`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  margin-right: 15px;

  @media ${device.tablet} {
    text-align: center;
    max-width: 400px;
  }
`

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 60px;

  @media ${device.tablet} {
    margin-bottom: 1rem;
    padding-top: 10px;
  }
`

export const RateInformation = styled.div`
  color: ${props => props.theme.colors.white};
  font-weight: medium;
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  text-decoration: underline;

  @media ${device.tablet} {
    margin-bottom: 60px;
  }
`

export const MoreDetails = styled.div`
  color: ${props => props.theme.colors.secondary};
  font-weight: medium;
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  text-decoration: underline;
  margin-left: 30px;
  cursor: pointer;

  @media ${device.tablet} {
    text-align: center;
    margin-left: 0px;
    margin-top: 15px;
  }
`

export const ErrorMessages = styled.div`
  color: ${props => props.theme.colors.alertColor};
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  margin-bottom: 20px;

  @media ${device.tablet} {
    text-align: center;
    margin-bottom: 20px;
  }
`

export const IncludedExtrasWrapper = styled.div`
  vertical-align: center;
  color: ${props => props.theme.colors.white};

  @media ${device.tablet} {
    text-align: center;
  }
`

export const Label = styled.span`
  text-transform: uppercase;
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  color: ${props => props.theme.colors.white};

  @media ${device.tablet} {
    display: block;
    margin-bottom: 7.5px;
  }
`
