export function getRawValueFromHourFormat(value) {
  if (typeof value !== 'string') {
    return value
  }

  return +value.split(':')[0]
}

export function getCheckInHoursOptions(start = 0, end = 23) {
  let startTime = start
  let endTime = end

  if (typeof start === 'string') {
    startTime = +start.split(':')[0]
  }

  if (typeof end === 'string') {
    endTime = +end.split(':')[0]
  }

  const result = []
  for (let i = startTime; i <= endTime; i++) {
    result.push({
      value: i,
      label: getHourFormatFromRawValue(i)
    })
  }

  return result
}

export function getHourFormatFromRawValue(value) {
  if (value === null) {
    return null
  }

  return value + ':00'
}
