import styled from 'styled-components'

export const Link = styled.a`
  display: flex;
  align-items: center;
`

export const Logo = styled.img`
  width: 106px;
  max-height: 60px;
  margin: 0 10px;
  object-fit: contain;
`

export const Label = styled.div`
  color: ${props => props.theme.colors.poweredByRentlioColor};
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  font-weight: 500;
  text-decoration: none;
`
