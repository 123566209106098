import moment from 'moment'
import { OFFER_STATUS_ENUM } from 'Rentlio/utils/offerStatusEnum'

export const BOOKING_TYPE = {
  BOOKING: 1,
  OFFER: 2
}

export const getOfferNoticeText = (offer, booking) => {
  if (
    moment
      .unix(offer.validUntil)
      .startOf('day')
      .isBefore(moment().startOf('day'))
  ) {
    return 'This offer has expired.'
  }

  if (+offer.status !== OFFER_STATUS_ENUM.IN_PROGRESS) {
    return 'Offer is no longer available.'
  }

  if (+booking.type !== BOOKING_TYPE.OFFER || booking.deleted === 'Y') {
    return 'Booking is not available.'
  }

  if (booking.reservations.some(reservation => reservation.arrivalDate + 86340 < moment().unix())) {
    return 'This offer has bookings in past.'
  }

  if (booking.hasAvail === false) {
    return 'Not enough available units.'
  }

  return null
}

export const isOfferStillActive = (offer, booking) => {
  return getOfferNoticeText(offer, booking) === null
}
