import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const HeaderWrapper = styled.div`
  height: 100px;

  @media ${device.mobile} {
    height: 160px;
  }
`

export const HeaderInnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media ${device.tablet} {
    justify-content: space-around;
  }
`

export const BrandingWrapper = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  text-align: center;

  @media ${device.mobile} {
    width: 100%;
    padding-top: 20px;

    > a {
      margin: 0 auto;
    }
  }
`

export const OptionsWrapper = styled.div`
  margin-left: auto;
  display: flex;
  margin-right: 30px;

  @media ${device.tablet} {
    margin-right: 0;
    margin-left: 0;
  }

  @media ${device.mobile} {
    width: 100%;
    justify-content: space-evenly;
    margin: 0 30px;
  }
`
export const Title = styled.div`
  float: left;
  font-family: 'Suranna', serif;
  font-size: ${props => props.theme.fonts._28};
  line-height: ${props => props.theme.fonts._60};
  color: ${props => props.theme.colors.black};
  white-space: nowrap;

  @media ${device.tablet} {
    margin: 0 auto;
  }

  @media ${device.mobile} {
    margin: 20px auto 0 auto;
  }
`

export const Logo = styled.img`
  display: block;
  height: 60px;
  max-width: 200px;
  margin: 0 auto;
  object-fit: contain;
`
