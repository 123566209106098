export function incrementalDateValidation(newValue, currentValue = '') {
  // Handle null current value (empty field initially)
  if (!currentValue) currentValue = ''

  // Prevent entering non-digits
  if (newValue.length > currentValue.length && isNaN(newValue.charAt(newValue.length - 1))) {
    return currentValue
  }

  // Prevent entering 1st digit larger than 3
  if (currentValue.length === 0 && Number(newValue) > 3) {
    return ''
  }

  // Prevent entering 2nd digit larger than one if 1st is < 0
  if (currentValue.length === 1 && Number(currentValue) === 3 && Number(newValue.charAt(1)) > 1) {
    return currentValue
  }

  // Prevent entering 3rd digit larger than 1
  if (currentValue.length === 2 && newValue.length === 3 && Number(newValue.substring(2, 3)) > 1) {
    return currentValue
  }

  // Prevent entering 5th digit larger than 2 if 4th is < 0
  if (
    currentValue.length === 4 &&
    newValue.length === 5 &&
    Number(newValue.charAt(3)) === 1 &&
    Number(newValue.charAt(4)) > 2
  ) {
    return currentValue
  }

  // Prevent entering 7th digit larger than 2 and smaller than 1 - 1st case
  if (
    currentValue.length === 6 &&
    newValue.length === 7 &&
    Number(newValue.charAt(6)) !== 1 &&
    Number(newValue.charAt(6)) !== 2
  ) {
    return currentValue
  }

  // Prevent entering 7th digit larger than 2 and smaller than 1 - 1st case
  if (
    currentValue.length === 5 &&
    newValue.length === 6 &&
    Number(newValue.charAt(5)) !== 1 &&
    Number(newValue.charAt(5)) !== 2
  ) {
    return currentValue
  }

  // Prevent entering 3rd digit larger than 1 second case
  if (currentValue.length === 3 && newValue.length === 4 && Number(newValue.substring(3, 4)) > 1) {
    return currentValue
  }

  // Add dot after 2nd and 5th digits
  if ((newValue.length === 2 && currentValue.length === 1) || (newValue.length === 5 && currentValue.length === 4)) {
    return `${newValue}.`
  }

  // Remove dot when deleting 3rd or 6th digit
  if ((newValue.length === 3 && currentValue.length === 4) || (newValue.length === 6 && currentValue.length === 7)) {
    return newValue.slice(0, -1)
  }

  // Add dot after deleting 3rd or 6th digit
  if ((newValue.length === 3 && currentValue.length === 2) || (newValue.length === 6 && currentValue.length === 5)) {
    const lastChar = newValue.charAt(newValue.length - 1)
    return `${newValue.slice(0, -1)}.${lastChar}`
  }

  // Limit to 10 characters (DD/MM/YYYY)
  if (newValue && newValue.length > 10) {
    return currentValue
  }

  return newValue
}
