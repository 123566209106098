import { servicePricingTypeEnum } from 'Rentlio/utils/servicePricingTypeEnum'
import { PROMO_CODE_DISCOUNT_TYPE_ENUM } from 'Rentlio/utils/promoCodeDiscountTypeEnum'

const childrenCategoriesType = {
  FREE_OF_CHARGE: 1,
  AS_ADULT: 2,
  CUSTOM: 3,
  MANUAL: 4
}

const childrenCategoryDerivationType = {
  PERCENTAGE: 1,
  FIXED: 2
}

/**
 * I do realize this is utterly confusing. Idea is as follows
 * 1. Take adults + all children as min occupancy row
 * 2. Take all custom percentage - and add them to total persons
 * 3. Take average of totalPersons pax row minus minOccupancy row on calendar and use that as base for all % calculations
 * 4. On top of everything add all custom nominal & manually entered values
 */
const getTotalOBPRate = (ratePlan, adults, childrenAges) => {
  const { childrenCategories } = ratePlan
  let adultsNumber = adults || 0
  const childrenSelectedCategories = getChildrenSelectedCategories(childrenAges, childrenCategories)
  // because value type nominal for children we are not counting inside pax, we are adding it separately
  const numberOfChildrenTreatedAsAdults = getNumberOfChildrenTreatedAsAdults(childrenSelectedCategories)
  adultsNumber += numberOfChildrenTreatedAsAdults

  const persons = adultsNumber + getNumberOfChildrenWithPercentageValues(childrenSelectedCategories)

  const paxPriceForAllPersons = getPaxForPersons(ratePlan, persons) ? getPaxForPersons(ratePlan, persons).totalRate : 0
  const paxPriceForAdults = getPaxForPersons(ratePlan, adultsNumber)
    ? getPaxForPersons(ratePlan, adultsNumber).totalRate
    : 0

  const childrenPrice = getOBPChildrenPrice(
    childrenSelectedCategories,
    (paxPriceForAllPersons - paxPriceForAdults) / (persons - adultsNumber),
    ratePlan.nights
  )

  return paxPriceForAdults + childrenPrice
}

const getTotalPPPDRate = ({ nights, totalRate, pricePerPerson, childrenCategories }, adults, childrenAges) => {
  const childrenSelectedCategories = getChildrenSelectedCategories(childrenAges, childrenCategories)
  const childrenPrice = getPPPDChildrenPrice(childrenSelectedCategories, pricePerPerson, nights)

  const adultsNumber = adults || 0
  const adultPrice = adultsNumber * pricePerPerson * nights
  return totalRate + adultPrice + childrenPrice
}

const getTotalRate = (unitTypeOccupancy, ratePlan, adults, childrenAges) => {
  if (!ratePlan) {
    return 0
  }

  if (ratePlan.isPaxRate) {
    return getTotalOBPRate(ratePlan, adults, childrenAges) + getExcludedServicesTotal(ratePlan, adults, childrenAges)
  }

  if (ratePlan.isPPPDRate) {
    return getTotalPPPDRate(ratePlan, adults, childrenAges) + getExcludedServicesTotal(ratePlan, adults, childrenAges)
  }

  return (
    ratePlan.totalRate +
    getExcludedServicesTotal(ratePlan, adults, childrenAges) +
    getExtraPersonCharge(unitTypeOccupancy, ratePlan, adults) +
    getChildrenExtraCharge(unitTypeOccupancy, ratePlan, childrenAges)
  )
}
// we are calculating total rate before discount if promo code is applied
const getTotalRateBeforeDiscount = (unitTypeOccupancy, rate, adults, childrenAges) => {
  if (!rate) {
    return 0
  }
  // we are setting totalRate as totalRateBeforeDiscount so we can reuse existing funcations to calculate total rate before discount
  let ratePlan = { ...rate, totalRate: rate.totalRateBeforeDiscount }
  if (ratePlan.isPaxRate) {
    ratePlan = {
      ...ratePlan,
      paxValues: ratePlan.paxValues?.length
        ? ratePlan.paxValues.map(paxValue => ({ ...paxValue, totalRate: paxValue.totalRateBeforeDiscount }))
        : []
    }
  }

  let excludedServicesTotal = getExcludedServicesTotal(ratePlan, adults, childrenAges)
  if (ratePlan.activePromoCode && ratePlan.activePromoCode.discountType === PROMO_CODE_DISCOUNT_TYPE_ENUM.PERCENTAGE) {
    // we are getting services price already discounted from api response so we need to add discounted amount to price to get price before discount
    excludedServicesTotal = excludedServicesTotal / (1 - Number(ratePlan.activePromoCode.discountAmount) / 100)
  }

  if (ratePlan.isPaxRate) {
    return getTotalOBPRate(ratePlan, adults, childrenAges) + excludedServicesTotal
  }

  if (ratePlan.isPPPDRate) {
    return getTotalPPPDRate(ratePlan, adults, childrenAges) + excludedServicesTotal
  }

  return (
    ratePlan.totalRate +
    excludedServicesTotal +
    getExtraPersonCharge(unitTypeOccupancy, ratePlan, adults) +
    getChildrenExtraCharge(unitTypeOccupancy, ratePlan, childrenAges)
  )
}

// when we have excluded services/meals on rate we need to add this excluded services to accommodation price separately for adults/children so we can update live price on layout
const getExcludedServicesTotal = (ratePlan, adults, childrenAges) => {
  if (!ratePlan?.services?.length || !ratePlan?.services?.find(item => !item.isIncluded && !item.isMandatory)) {
    return 0
  }

  const excludedServices = ratePlan.services.filter(item => !item.isIncluded && !item.isMandatory)
  let totalPrice = 0

  excludedServices.map(service => {
    if (!service.dailyPrices?.length) {
      return
    }

    service.dailyPrices.map(dailyPrice => {
      const priceForDay = dailyPrice.price || 0
      if (service.pricingType !== servicePricingTypeEnum.PER_PERSON) {
        totalPrice += priceForDay
        return
      }

      totalPrice += priceForDay * adults

      if (childrenAges && Array.isArray(childrenAges) && childrenAges.length) {
        const selectedCategories = getChildrenSelectedCategories(childrenAges, service?.childrenCategories) || []
        selectedCategories &&
          selectedCategories.map(category => {
            if (category.type === childrenCategoriesType.FREE_OF_CHARGE) {
              return
            }
            if (category.type === childrenCategoriesType.AS_ADULT) {
              totalPrice += priceForDay
            }
            if (category.type === childrenCategoriesType.CUSTOM) {
              totalPrice += priceForDay * ((category.percentageValue || 0) / 100)
            }
            if (category.type === childrenCategoriesType.MANUAL) {
              totalPrice += category.nominalValue || 0
            }
          })
      }
    })
  })

  return totalPrice
}

/** DEPRECATED - do not use in new code **/
const getExtraPersonCharge = (unitTypeOccupancy, ratePlan, adults) => {
  if (!unitTypeOccupancy.hasExtraPersonCharge) {
    return 0
  }

  if (adults <= unitTypeOccupancy.standardOccupancy || adults > unitTypeOccupancy.maxOccupancy) {
    return 0
  }

  const extraPersonCount = adults - unitTypeOccupancy.standardOccupancy

  return extraPersonCount * unitTypeOccupancy.extraPersonCharge * ratePlan.nights
}

/** DEPRECATED - do not use in new code **/
const getChildrenExtraCharge = (unitTypeOccupancy, ratePlan, childrenAges) => {
  if (!childrenAges || !Array.isArray(childrenAges) || childrenAges.length === 0) {
    return 0
  }

  if (!unitTypeOccupancy.childrenAllowed || unitTypeOccupancy.childrenFreeOfCharge) {
    return 0
  }

  let sum = 0
  childrenAges.map(age => {
    if (age < unitTypeOccupancy.childrenAge) {
      return
    }
    sum += unitTypeOccupancy.childrenExtraCharge
  })

  return sum * ratePlan.nights
}

const getPaxForPersons = (ratePlan, persons) => {
  const { paxValues } = ratePlan
  if (!persons || !paxValues) return null
  const pax = paxValues.find(paxValue => {
    return paxValue.pax === persons
  })
  // if persons === base pax persons then we are returning rate plans total rate - base rate
  return pax || { totalRate: ratePlan.totalRate }
}

const getAverageRate = (unitTypeOccupancy, ratePlan, adults, childrenAges) => {
  if (!ratePlan) {
    return 0
  }

  const { nights } = ratePlan
  const totalRate = getTotalRate(unitTypeOccupancy, ratePlan, adults, childrenAges)

  return totalRate / nights
}

const getChildrenSelectedCategories = (childrenAges, childrenCategories) => {
  const selectedCategories = []
  if (!childrenAges || childrenAges.length === 0) {
    return selectedCategories
  }

  childrenAges.map(childAge => {
    let categoryIsFilled = false
    childrenCategories.map(category => {
      if (+childAge >= category.ageFrom && +childAge < category.ageTo) {
        categoryIsFilled = true
        return selectedCategories.push(category)
      }
    })
    // we are checking if category for child exists and if not we are setting price same as adult
    if (!categoryIsFilled) {
      selectedCategories.push({
        type: childrenCategoriesType.AS_ADULT,
        percentageValue: 100,
        nominalValue: null,
        nominalValueBeforeTax: null,
        valueType: childrenCategoryDerivationType.PERCENTAGE
      })
    }
  })

  return selectedCategories
}

const getNumberOfChildrenTreatedAsAdults = childrenCategories => {
  if (!childrenCategories || childrenCategories.length === 0) {
    return 0
  }

  return childrenCategories.filter(category => +category.type === childrenCategoriesType.AS_ADULT).length
}

const getNumberOfChildrenWithPercentageValues = childrenCategories => {
  if (!childrenCategories || childrenCategories.length === 0) {
    return 0
  }

  return childrenCategories.filter(
    category =>
      +category.type === childrenCategoriesType.CUSTOM &&
      +category.valueType === childrenCategoryDerivationType.PERCENTAGE
  ).length
}

/** Total value for all nights **/
const getOBPChildrenPrice = (categories, priceForAdult, nights) => {
  if (!categories || categories.length === 0) {
    return 0
  }
  let priceForChildren = 0

  categories.map(category => {
    switch (category.type) {
      case childrenCategoriesType.AS_ADULT:
        break
      case childrenCategoriesType.CUSTOM: {
        if (!category.percentageValue && !category.nominalValue) {
          break
        }
        const priceForChild =
          category.valueType === childrenCategoryDerivationType.PERCENTAGE
            ? (+category.percentageValue / 100) * priceForAdult
            : +category.nominalValue * nights
        priceForChildren += priceForChild
        break
      }

      case childrenCategoriesType.MANUAL:
        priceForChildren += category.nominalValue * nights
        break
    }
  })

  return priceForChildren
}

/** Total value for all nights **/
const getPPPDChildrenPrice = (categories, priceForSingleAdultNight, nights) => {
  if (!categories) {
    return 0
  }

  let priceForChildren = 0

  categories.map(category => {
    switch (category.type) {
      case childrenCategoriesType.AS_ADULT:
        priceForChildren = priceForChildren + priceForSingleAdultNight * nights
        break
      case childrenCategoriesType.CUSTOM: {
        if (!category.percentageValue && !category.nominalValue) {
          break
        }
        const priceForChild =
          category.valueType === childrenCategoryDerivationType.PERCENTAGE
            ? (+category.percentageValue / 100) * priceForSingleAdultNight
            : +category.nominalValue
        priceForChildren = priceForChildren + priceForChild * nights
        break
      }
      case childrenCategoriesType.MANUAL:
        priceForChildren += category.nominalValue * nights
        break
    }
  })

  return priceForChildren
}

export { getTotalRate, getAverageRate, getTotalRateBeforeDiscount }
