import React from 'react'

const Phone = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.9908 18.9198C13.8122 19.119 12.6336 18.9199 11.5532 18.6212C10.3745 18.223 9.39234 17.7252 8.41015 17.1278C6.24933 15.734 4.38317 14.0416 2.71345 12.0504C1.82947 11.0549 1.14194 9.95973 0.650845 8.76504C0.15975 7.4708 -0.134908 6.17656 0.0615305 4.78277C0.257969 3.48853 0.847283 2.29384 1.73125 1.39783C1.92769 1.09916 2.02591 0.900046 2.22235 0.700932C2.32057 0.601375 2.51701 0.402262 2.61523 0.302705C3.10632 0.00403388 3.4992 -0.0955231 3.99029 0.103591C4.28495 0.302705 4.57961 0.501819 4.87426 0.800489C5.46358 1.39783 6.05289 2.09473 6.64221 2.89119C6.83865 3.18986 7.03508 3.58808 7.1333 3.98631C7.32974 4.58365 7.23152 5.18099 6.83865 5.67878C6.54399 6.07701 6.15111 6.47523 5.75824 6.87346C5.26714 7.37125 5.0707 7.96859 5.26714 8.66549C5.36536 8.96416 5.46358 9.26283 5.66002 9.5615C6.15111 10.2584 6.74043 10.8557 7.32974 11.4531C8.11549 12.2495 8.99946 12.9464 9.98166 13.5438C10.3745 13.7429 10.7674 13.942 11.1603 14.0416C11.946 14.2407 12.5354 14.0416 12.9282 13.3447C13.2229 12.9464 13.5175 12.5482 13.9104 12.15C14.4015 11.5526 15.089 11.3535 15.8748 11.5526C16.1695 11.6522 16.4641 11.7518 16.7588 11.8513C17.6427 12.3491 18.4285 12.8469 19.2142 13.4442C19.4107 13.6433 19.6071 13.7429 19.7053 13.942C20.0982 14.5393 20.0982 15.1367 19.7053 15.6345C19.5089 15.9331 19.3125 16.1323 19.116 16.3314C18.8214 16.7296 18.4285 17.1278 18.0356 17.5261C17.1516 18.3225 16.1695 18.8203 14.9908 18.9198Z'
      fill='#333333'
    />
  </svg>
)

export default Phone
