import React from 'react'
const Checkmark = () => (
  <svg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.77938 3.33273C1.43335 2.90229 0.803894 2.83386 0.373452 3.17989C-0.0569891 3.52593 -0.125416 4.15538 0.220617 4.58582L1.77938 3.33273ZM3.44444 7L2.66506 7.62655C2.84722 7.85314 3.11897 7.98924 3.40953 7.99939C3.70009 8.00954 3.98068 7.89274 4.17821 7.67941L3.44444 7ZM9.73376 1.67941C10.109 1.27416 10.0847 0.641466 9.67941 0.266239C9.27416 -0.108987 8.64147 -0.0846529 8.26624 0.320592L9.73376 1.67941ZM0.220617 4.58582L2.66506 7.62655L4.22383 6.37345L1.77938 3.33273L0.220617 4.58582ZM4.17821 7.67941L9.73376 1.67941L8.26624 0.320592L2.71068 6.32059L4.17821 7.67941Z'
      fill='white'
    />
  </svg>
)

export default Checkmark
