import ChildrenCategoriesTypeEnum from 'Rentlio/utils/childrenCategoriesEnum'
import { feePricingTypeEnum } from 'Rentlio/utils/feePricingTypeEnum'

function getDailyPricesSum(dailyPrices, field = 'price') {
  if (!dailyPrices) {
    return 0
  }
  return dailyPrices.reduce((sum, item) => (sum += item[field]), 0)
}

// all adults and children treatedd as adults go here
function getTotalAdultsPrice(basePrice, { adults, children }, { childrenDifferentThanAdults, childrenCategories }) {
  if (childrenDifferentThanAdults) {
    let totalAdults = adults

    children.map(age =>
      childrenCategories.map(category => {
        if (age >= category.ageFrom && age < category.ageTo) {
          if (category.type === ChildrenCategoriesTypeEnum.AS_ADULT) {
            totalAdults++
          }
        }
      })
    )
    return basePrice * totalAdults
  }

  return basePrice * (adults + children.length)
}

// all custom children prices go here
function getTotalChildrenPrice(basePrice, { children }, { childrenDifferentThanAdults, childrenCategories }) {
  if (!childrenDifferentThanAdults) {
    return 0
  }

  let childrenPrice = 0

  children.map(age =>
    childrenCategories.map(category => {
      if (age >= category.ageFrom && age < category.ageTo) {
        if (category.type === ChildrenCategoriesTypeEnum.CUSTOM && category.percentageValue > 0) {
          childrenPrice += (basePrice * category.percentageValue) / 100
        }
      }
    })
  )

  return childrenPrice
}

export function getFeeCosts(fee, request) {
  const { dailyPrices, pricingType } = fee
  const basePrice = getDailyPricesSum(dailyPrices)

  if (pricingType === feePricingTypeEnum.PER_PERSON) {
    const priceForAdults = getTotalAdultsPrice(basePrice, request, fee)
    const childrenPrice = getTotalChildrenPrice(basePrice, request, fee)

    return priceForAdults + childrenPrice
  }

  return basePrice
}

export function getFeeCostsBeforeTax(fee, request) {
  const { dailyPrices, pricingType } = fee
  const basePrice = getDailyPricesSum(dailyPrices, 'priceBeforeTax')

  if (pricingType === feePricingTypeEnum.PER_PERSON) {
    const priceForAdults = getTotalAdultsPrice(basePrice, request, fee)
    const childrenPrice = getTotalChildrenPrice(basePrice, request, fee)

    return priceForAdults + childrenPrice
  }

  return basePrice
}
