import colors from 'Rentlio/style/colors'

const getCustomStripeFormStyle = () => {
  const style = {
    base: {
      iconColor: colors.textColor,
      color: colors.textColor,
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '14px',
      opacity: 1,

      ':-webkit-autofill': {
        color: colors.lightText
      },
      '::placeholder': {
        color: colors.lightText
      }
    },
    invalid: {
      iconColor: colors.alertColor,
      color: colors.alertColor
    }
  }

  return style
}

export default getCustomStripeFormStyle
