import styled from 'styled-components'

export const Box = styled.div`
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ isChecked, theme, disabled }) =>
    disabled ? theme.colors.disabledGray : isChecked ? theme.colors.buttonBackground : theme.colors.white};
  border: 1px solid ${props => props.theme.colors.border};
  cursor: pointer;
  margin-right: 14px;
`

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  font-weight: 600;
`

export const Label = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.fonts.regularWeight};
  font-size: ${({ theme }) => theme.fonts._14};
  line-height: ${({ theme }) => theme.fonts._16};
`
