import moment from 'moment'

export function getNights(request) {
  const { to, from } = request
  return moment.unix(to).diff(moment.unix(from), 'days')
}

export const isPromoCodeAppliedToAnyRate = unitTypes => {
  if (!unitTypes || !unitTypes.length) {
    return false
  }
  let isApplied = false
  unitTypes.map(unitType => {
    unitType.rates &&
      unitType.rates.map(rate => {
        if (rate.activePromoCode) {
          isApplied = true
        }
      })
  })
  return isApplied
}
