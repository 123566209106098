import styled from 'styled-components'

export const Title = styled.div`
  margin: 20px 0 30px 0px;
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._24};
  line-height: ${props => props.theme.fonts._28};
`

export const Description = styled.p`
  margin-top: 30px;
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._24};
  color: ${props => props.theme.colors.textColor};
  font-weight: ${props => props.theme.fonts.regularWeight};
  text-align: left;
  opacity: 0.8;
  white-space: pre-wrap;
  margin-bottom: 0px;
`

export const Included = styled.div`
  margin-top: 0;
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._24};
  color: ${props => props.theme.colors.textColor};
  font-weight: ${props => props.theme.fonts.mediumWeight};
  text-align: left;
  white-space: pre-wrap;
`

export const PromoBadge = styled.div`
  margin-left: ${({ marginLeft }) => marginLeft || '10px'};
  padding: 6px 10px;
  text-transform: uppercase;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  font-weight: ${({ theme }) => theme.fonts.boldWeight};
  width: fit-content;
`
