import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  background: ${props => (props.color ? props.color : props.theme.colors.notePromotoBg)};
  padding: 18px 20px;
  box-sizing: border-box;
  margin: 0px 0px 27px 0px;
  max-width: 1140px;
  flex: 1;

  @media ${device.mobile} {
    padding: 20px;
  }

  @media ${device.tablet} {
    padding: 20px;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 40px;

  @media ${device.mobile} {
    width: 30px;
  }

  @media ${device.tablet} {
    width: 30px;
  }
`

export const NoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  white-space: break-spaces;
  padding-left: 20px;
  box-sizing: border-box;
  font-weight: ${props => props.theme.fonts.regularWeight};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  color: ${props => (props.color ? props.color : props.theme.colors.textColor)};
  opacity: 0.8;

  @media ${device.mobile} {
    padding-left: 10px;
    font-size: ${props => props.theme.fonts._12};
    line-height: ${props => props.theme.fonts._14};
  }

  @media ${device.tablet} {
    padding-left: 10px;
    font-size: ${props => props.theme.fonts._12};
    line-height: ${props => props.theme.fonts._14};
  }
`
