import moment from 'moment'

function getSuggestedDates(unitTypeId, availability, startDate, endDate) {
  const suggestedDates = []
  if (!startDate || !endDate) {
    return suggestedDates
  }

  const now = moment() // Current date to avoid past dates
  const difference = endDate.diff(startDate, 'days') // Number of nights the user is staying
  const maxDays = 15 // Maximum range to suggest is 15 days before/after

  const unitTypeAvailability = availability[unitTypeId]
  if (!unitTypeAvailability) {
    return suggestedDates
  }

  let foundDates = 0

  // Helper function to add date ranges to the suggestions
  function addDateRange(start, end, type) {
    if (start.isSame(startDate, 'day') && end.isSame(endDate.clone().subtract(1, 'day'), 'day')) {
      // Skip adding the exact same range as startDate and endDate
      return
    }
    suggestedDates.push({
      start,
      end,
      type
    })
    foundDates++
  }

  // Helper function to check if a range is unique
  function isDateRangeUnique(start, end) {
    return !suggestedDates.some(range => range.start.isSame(start, 'day') && range.end.isSame(end, 'day'))
  }

  // Helper function to check availability for a range of dates
  function isRangeAvailable(unitTypeAvailability, start, end) {
    const current = start.clone()
    while (current.isBefore(end, 'day') || current.isSame(end, 'day')) {
      if (!checkAvailability(unitTypeAvailability, current)) {
        return false
      }
      current.add(1, 'day')
    }
    return true
  }

  // Helper function to check if a partial date range overlaps with any full ranges
  function isOverlapping(partialStart, partialEnd) {
    return suggestedDates.some(
      ({ start, end, type }) =>
        type === 'full' &&
        (partialStart.isBetween(start, end, 'day', '[]') || partialEnd.isBetween(start, end, 'day', '[]'))
    )
  }

  // Loop to find full ranges in the future and past within the 15-day window
  for (let i = 0; i <= maxDays && foundDates < 2; i++) {
    const futureDay = startDate.clone().add(i, 'days')
    const futureDayEnd = futureDay.clone().add(difference - 1, 'days') // Adjust for nights

    // Check full range in the future
    if (isRangeAvailable(unitTypeAvailability, futureDay, futureDayEnd)) {
      addDateRange(futureDay, futureDayEnd, 'full')
      if (foundDates >= 2) break
    }

    const pastDay = startDate.clone().subtract(i, 'days')
    const pastDayEnd = pastDay.clone().add(difference - 1, 'days') // Adjust for nights

    // Check full range in the past
    if (pastDay.isSameOrAfter(now, 'day') && isRangeAvailable(unitTypeAvailability, pastDay, pastDayEnd)) {
      addDateRange(pastDay, pastDayEnd, 'full')
      if (foundDates >= 2) break
    }
  }

  // Only check partial dates if the difference is 2 days or more
  if (foundDates < 2 && difference > 1) {
    for (let i = 0; i <= maxDays && foundDates < 2; i++) {
      const futureDay = startDate.clone().add(i, 'days')
      const futureDayEnd = futureDay.clone().add(difference - 2, 'days') // One day shorter for partial range

      // Check partial future date range, ensure it doesn't overlap with full dates and is unique
      if (
        isRangeAvailable(unitTypeAvailability, futureDay, futureDayEnd) &&
        !isOverlapping(futureDay, futureDayEnd) &&
        isDateRangeUnique(futureDay, futureDayEnd)
      ) {
        addDateRange(futureDay, futureDayEnd, 'partial')
        if (foundDates >= 2) break
      }

      const pastDay = startDate.clone().subtract(i, 'days')
      const pastDayEnd = pastDay.clone().add(difference - 2, 'days') // One day shorter for partial range

      // Check partial past date range, ensure it doesn't overlap with full dates and is unique
      if (
        pastDay.isSameOrAfter(now, 'day') &&
        isRangeAvailable(unitTypeAvailability, pastDay, pastDayEnd) &&
        !isOverlapping(pastDay, pastDayEnd) &&
        isDateRangeUnique(pastDay, pastDayEnd)
      ) {
        addDateRange(pastDay, pastDayEnd, 'partial')
        if (foundDates >= 2) break
      }
    }
  }

  // Return full and partial dates with adjusted end date (+1 day)
  return suggestedDates.map(range => ({
    ...range,
    end: range.end.clone().add(1, 'day') // Increment end date by 1 day
  }))
}

// Helper function to check if a specific day is available
function checkAvailability(unitTypeAvailability, day) {
  const year = day.year()
  const month = day.month() + 1 // moment months are 0-indexed
  const date = day.date()

  return unitTypeAvailability[year] && unitTypeAvailability[year][month] && unitTypeAvailability[year][month][date] > 0
}

export { getSuggestedDates }
