import React from 'react'
import PropTypes from 'prop-types'
import Links from './Links'
import Information from './Information'
import { BookerInfo, Section, Wrapper, ButtonWrapper, Link, FormError } from './style'
import BookingErrors from 'Rentlio/screen/payment/containers/bookingErrors'
import { SpinnerOverlay } from 'Rentlio/screen/payment/components/Payment/PaymentInfo/style'
import Checkbox from 'Rentlio/components/UI/Checkbox'
import ReservationSummary from 'Rentlio/components/ReservationSummary'
import { CancelButton } from 'Rentlio/components/UI/button'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import { termsOfServiceAcceptedConstraint } from 'Rentlio/helper/validator/termsOfServiceAccepted'
import { getValidationErrors, validateData } from 'Rentlio/helper/validator'
import CancellationPolicyContainer from 'Rentlio/screen/layout/containers/cancellationPolicy'
import { BE_BANK_TRANSFER } from 'Rentlio/utils/paymentTypesEnum'
import Animation from 'Rentlio/components/UI/Animation'
import Spinner from 'Rentlio/components/UI/Spinner'

class Reservations extends React.Component {
  static contextType = LanguageContext

  state = {
    clickedOnce: false,
    loading: false,
    termsAccepted: false,
    errors: {}
  }

  cancelReservation = () => {
    const { termsAccepted, clickedOnce } = this.state
    const { reservation, cancelReservation } = this.props

    const errors = validateData(
      { termsAccepted },
      {
        termsAccepted: [termsOfServiceAcceptedConstraint]
      }
    )

    if (errors !== true) {
      this.setState({ errors })
      return
    } else {
      this.setState({ errors: {} })
    }

    if (!clickedOnce && termsAccepted) {
      this.setState({ clickedOnce: true, loading: true }, () => {
        cancelReservation(reservation.id).finally(() => this.setState({ loading: false, clickedOnce: false }))
      })
    }
  }

  onTermsAcceptClicked = () => {
    this.setState(prevState => ({ termsAccepted: !prevState.termsAccepted }))
  }

  getValidationError = key => getValidationErrors(this.state.errors, key)

  getTosCheckboxContent = () => {
    const { translate } = this.context
    const { openTermsOfServiceModal, openCancellationPolicyModal } = this.props
    return (
      <>
        {translate('CancelTOS')} <Link onClick={() => openTermsOfServiceModal()}>{translate('Terms of service')}</Link>{' '}
        {translate('and')} <Link onClick={() => openCancellationPolicyModal()}>{translate('Cancellation policy')}</Link>
      </>
    )
  }

  render() {
    const { termsAccepted, loading } = this.state
    const { reservation, bookingEngine, openTermsOfServiceModal, openCancellationPolicyModal } = this.props
    const { translate } = this.context

    const prePaymentDetails = {
      prepaymentAmount: reservation.prepaymentAmount,
      prepaymentCurrency: reservation.prepaymentCurrency,
      prepaymentPercentage: reservation.prepaymentPercentage
    }

    return (
      <Section>
        <Animation show={loading} animationType={'fadeInOut'} isModal>
          <SpinnerOverlay>
            <Spinner width={70} disableScroll color={'#07587A'} />
          </SpinnerOverlay>
        </Animation>

        <CancellationPolicyContainer />
        <Wrapper>
          <BookerInfo>
            <Links
              isCanceled={reservation.isCanceled}
              openTermsOfServiceModal={openTermsOfServiceModal}
              openCancellationPolicyModal={openCancellationPolicyModal}
              isBankTransferMethod={BE_BANK_TRANSFER === reservation.paymentMethodId}
              prePaymentDetails={prePaymentDetails}
              bankTransferSettings={reservation.bankTransferSettings}
              referenceNumber={reservation.referenceNumber}
              currency={reservation.receipt.currency}
            />
            <Information guest={reservation.guest} />
            {!reservation.isCanceled && (
              <>
                <ButtonWrapper enabled={termsAccepted}>
                  <CancelButton onClick={this.cancelReservation}>{translate('Cancel reservation')}</CancelButton>
                  <Checkbox
                    isChecked={termsAccepted}
                    click={this.onTermsAcceptClicked}
                    text={this.getTosCheckboxContent()}
                  />
                </ButtonWrapper>
                <BookingErrors />
                <FormError>{this.getValidationError('termsAccepted')}</FormError>
              </>
            )}
          </BookerInfo>
          <ReservationSummary
            receipt={reservation.receipt}
            createdAt={reservation.createdAt}
            request={reservation.request}
            cardHolderName={reservation.cardHolderName}
            maskedPan={reservation.maskedPan}
            showCard={BE_BANK_TRANSFER !== reservation.paymentMethodId}
            bookingEngine={bookingEngine}
            selectedPaymentMethod={reservation.paymentMethodId}
            touristTaxes={reservation.touristTaxes}
            hasTitle
          />
        </Wrapper>
      </Section>
    )
  }
}

Reservations.propTypes = {
  reservation: PropTypes.object,
  bookingEngine: PropTypes.object,
  cancelReservation: PropTypes.func,
  openTermsOfServiceModal: PropTypes.func,
  openCancellationPolicyModal: PropTypes.func,
  prePaymentDetails: PropTypes.object
}

export default Reservations
