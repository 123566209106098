import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'
import { SelectWrapper } from 'Rentlio/components/UI/Select/style'

export const ChildrenAgeWrapper = styled.div`
  margin: 25px 0px 0px 0px;
  padding-top: 15px;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme, isDarkStyle }) => (isDarkStyle ? theme.colors.black : theme.colors.white)};
`

export const ChildrenAgeTitle = styled.div`
  font-size: ${({ theme }) => theme.fonts._12};
  line-height: ${({ theme }) => theme.fonts._14};
  color: ${({ theme, isDarkStyle }) => (isDarkStyle ? theme.colors.white : theme.colors.semiTransparentBlackText)};
  margin-bottom: 20px;
`

export const SelectsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 210px;

  > div:nth-child(2n - 1) {
    margin-right: 25px;
  }

  ${SelectWrapper} {
    flex-basis: 92px;
    margin-bottom: 10px;
  }

  @media ${device.tablet} {
    max-width: 258px;

    ${SelectWrapper} {
      flex-basis: 116px;
    }
  }

  @media ${device.mobile} {
    max-width: 218px;

    ${SelectWrapper} {
      flex-basis: 96px;
    }
  }
`
