import styled from 'styled-components'
import { device, breakpoint } from 'Rentlio/style/responsive'

export const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin 15px 20px;
  color: #fff;
`

export const Container = styled.div`
  align-items: center;
  padding: 50px 0px 10px 0px;
  box-shadow: 0px 0.30914032459259033px 0.549582839012146px 0px rgba(0, 0, 0, 0.02);
  box-shadow: 0px 0.78183913230896px 1.3899362087249756px 0px rgba(0, 0, 0, 0.02);
  box-shadow: 0px 1.5948784351348877px 2.8353395462036133px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 3.2851481437683105px 5.840263366699219px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 9px 16px 0px rgba(0, 0, 0, 0.05);

  @media ${device.laptop} {
    padding: 50px 0px 0px 0px;
  }
  @media ${device.mobile} {
    padding: 40px 0px 20px 0px;
  }
`

export const HeaderRow = styled.div`
  border: 1px solid #1e1e1e;
  border-radius: 12px;
  background: #1e1e1e;
`

export const Wrapper = styled.div`
  max-width: 1180px;
  margin: 0 auto;
  @media ${device.laptop} {
    max-width: 900px;
  }

  @media ${device.tablet} {
    padding: 0 20px;
  }
`

export const Form = styled.div`
  margin-top: 30px;
`

export const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -15px; // A negative margin can help with the gutter between inputs
  margin-top: 15px;
  margin-bottom: 5px;

  @media (max-width: 881px) {
    margin-top: 15px;
    margin-bottom: 25px;
  }

  @media ${device.mobile} {
    margin-bottom: 0px;
  }
`

export const FormGroup = styled.div`
  padding: 15px;
  box-sizing: border-box;
  flex: 0 0 100%;
  max-width: 100%;

  @media (min-width: ${breakpoint.small}px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: ${({ isEnglish }) => (isEnglish ? '780px' : '881px')}) {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
`
