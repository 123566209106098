import {
  FETCH_AVAILABILITY_COMPLETED,
  SELECT_LOCALE,
  SELECT_CURRENCY,
  FILTER_BY_AMENITY,
  REMOVE_FILTER_BY_AMENITY,
  CLEAR_AMENITY_FILTERS,
  FILTER_BY_LOCATION
} from 'Rentlio/state/types'
import createReducer from 'Rentlio/state/utils/createReducer'

/** todo this will be filled with more values later
 const initialState = {
  locale: string,
  currency: string,
  selectedAmenities: int[],
  location: null|string
}
 **/

const initialState = {
  locale: null,
  currency: null,
  selectedAmenities: [],
  location: null
}

const configuration = createReducer(initialState)({
  [FETCH_AVAILABILITY_COMPLETED]: (state, action) => ({
    ...action.payload.configuration,
    selectedAmenities: state.selectedAmenities ? [...state.selectedAmenities] : []
  }),
  [SELECT_LOCALE]: (state, { locale }) => ({ ...state, locale }),
  [SELECT_CURRENCY]: (state, { currency }) => ({ ...state, currency }),
  [FILTER_BY_AMENITY]: (state, { amenityId }) => {
    const newSelectedAmenities = state.selectedAmenities.filter(id => id !== amenityId)
    newSelectedAmenities.push(amenityId)

    return { ...state, selectedAmenities: newSelectedAmenities }
  },
  [REMOVE_FILTER_BY_AMENITY]: (state, { amenityId }) => ({
    ...state,
    selectedAmenities: state.selectedAmenities.filter(id => id !== amenityId)
  }),
  [CLEAR_AMENITY_FILTERS]: (state, _) => ({
    ...state,
    selectedAmenities: []
  }),
  [FILTER_BY_LOCATION]: (state, { city }) => ({
    ...state,
    location: city
  })
})

export default configuration
