import PropTypes from 'prop-types'
import React from 'react'
import Service from './Service'

import {
  Divider,
  Header,
  Image,
  InformationWrapper,
  RatePlanName,
  RemoveUnitTypeButton,
  ReservationInformation,
  SingleBasketItemWrapper,
  Title,
  TitleMobile,
  TotalPrice
} from './style'
import { getServiceQuantity } from 'Rentlio/utils/services'
import Close from 'Rentlio/components/Icons/close'
import { formatPrice } from 'Rentlio/components/utils/Price/price'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import { CurrenciesContext } from 'Rentlio/context/CurrenciesContext'

class SingleBasketItem extends React.Component {
  static contextType = LanguageContext

  updateQuantity = (serviceId, newQuantity, isMandatoryQuantityUpdate) => {
    const { item, updateServiceQuantity } = this.props

    updateServiceQuantity(item.basketId, serviceId, newQuantity, isMandatoryQuantityUpdate)
  }

  getFeaturedImage = () => {
    const { item } = this.props

    let selectedImage = item.images.find(image => image.isFeatured)

    if (selectedImage === undefined) {
      selectedImage = item.images.find(image => image.orderNumber === 1)
    }

    if (selectedImage === undefined && item.images.length > 0) {
      selectedImage = item.images[0]
    }

    return selectedImage === undefined ? null : selectedImage.url
  }

  getRatePlanName() {
    const { item, ratePlanNames } = this.props

    const rateName = ratePlanNames.find(({ id }) => item.ratePlanId === id)

    return !rateName ? null : rateName.name
  }

  getNameForService = serviceId => {
    const { serviceNames } = this.props

    const serviceName = serviceNames.find(({ id }) => serviceId === id)

    return !serviceName ? null : serviceName.name
  }

  /**
   * Returns current quantity of non mandatory items, for mandatory calculates real quantity
   * @param {*} service
   */
  getQuantity = service => {
    // do not calculate for non mandatory services or mandatory that was already calculated (on refresh)
    if (!service.isMandatory || service.isMandatoryQuantityUpdate === true) {
      return service.quantity
    }

    const {
      request: { adults, children, nights }
    } = this.props
    const { quantity, paymentType } = service
    const mandatoryQuantity = getServiceQuantity(paymentType, nights, adults, children, quantity)
    if (mandatoryQuantity !== quantity) {
      this.updateQuantity(service.id, mandatoryQuantity, true)
    }
    return mandatoryQuantity
  }

  render() {
    const { item, removeItemFromBasket } = this.props
    const itemNumber = item.itemNumber === null ? null : item.itemNumber + '. '
    const { translate } = this.context
    return (
      <CurrenciesContext.Consumer>
        {({ currencyConversion }) => {
          const { value, currency } = currencyConversion(item.totalRate, item.currency)
          return (
            <SingleBasketItemWrapper>
              <Header>
                <Image src={this.getFeaturedImage()} />
                <TitleMobile>
                  {itemNumber}
                  {item.unitTypeName}
                </TitleMobile>
                <InformationWrapper>
                  <Title>
                    {itemNumber}
                    {item.unitTypeName}
                  </Title>
                  <ReservationInformation>
                    <TotalPrice>
                      {formatPrice(value, currency)} {currency}
                    </TotalPrice>
                    <Divider />
                    <RatePlanName>{this.getRatePlanName()}</RatePlanName>
                    {item.includedServices.length > 0 && (
                      <>
                        <Divider />
                        <RatePlanName>
                          {translate('Included')}: {item.includedServices.join(', ')}
                        </RatePlanName>
                      </>
                    )}
                    {/* <Divider />
                    <Occupancy>todo {translate('adults')}</Occupancy> */}
                  </ReservationInformation>
                </InformationWrapper>
                <RemoveUnitTypeButton onClick={() => removeItemFromBasket(item.basketId)}>
                  {translate('Remove this unit')} <Close />
                </RemoveUnitTypeButton>
              </Header>
              {item.services.map(service => (
                <Service
                  key={service.id}
                  service={service}
                  quantity={this.getQuantity(service)}
                  name={this.getNameForService(service.id)}
                  updateQuantity={newQuantity => this.updateQuantity(service.id, newQuantity)}
                  unitTypeCurrency={item.currency}
                />
              ))}
            </SingleBasketItemWrapper>
          )
        }}
      </CurrenciesContext.Consumer>
    )
  }
}

SingleBasketItem.propTypes = {
  item: PropTypes.object,
  request: PropTypes.shape({
    nights: PropTypes.number,
    adults: PropTypes.number,
    children: PropTypes.array
  }),
  updateMandatoryQuantity: PropTypes.func,
  updateServiceQuantity: PropTypes.func,
  removeItemFromBasket: PropTypes.func,
  ratePlanNames: PropTypes.array,
  serviceNames: PropTypes.array
}

export default SingleBasketItem
