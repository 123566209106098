import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const SingleUnitTypeWrapper = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  background: ${props => (props.even ? 'transparent' : props.theme.colors.unitTypeOddBackground)};
  border-bottom: 1px solid ${props => (props.even ? 'transparent' : props.theme.colors.unitTypeOddBorder)};
  border-top: 1px solid ${props => (props.even ? 'transparent' : props.theme.colors.unitTypeOddBorder)};

  @media ${device.mobile} {
    padding-top: 0px;
  }
`

export const SingleUnitTypeGrid = styled.div`
  display: flex;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
  }
`

export const PaddingContainer = styled.div`
  max-width: 100%;
  align-self: center;

  @media ${device.tablet} {
    display: flex;
    justify-content: center;
  }

  @media ${device.mobile} {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
`
