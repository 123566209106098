import styled, { css } from 'styled-components'
import { device } from 'Rentlio/style/responsive'

const searchBoxShadow = css`
  box-shadow: 0px 0.927421px 0.686979px rgba(0, 0, 0, 0.0279017), 0px 2.34552px 1.73742px rgba(0, 0, 0, 0.0399338),
    0px 4.78464px 3.54417px rgba(0, 0, 0, 0.0500662), 0px 9.85544px 7.30033px rgba(0, 0, 0, 0.0620983),
    0px 27px 20px rgba(0, 0, 0, 0.09);
`

export const Wrapper = styled.div`
  width: ${({ isGroup }) => (isGroup ? '1059px;' : '816px;')}
  height: 100%;
  margin: 0 auto;
  position: relative;
`

export const SearchWrapper = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 200px;
  margin-bottom: 80px;
  height: 120px;
  border-radius: 20px;
  ${searchBoxShadow}
  z-index: ${({ theme }) => theme.zIndex.search};

  @media ${device.tablet} {
    width: 356px;
    flex-direction: column;
    margin: 60px auto 0 auto;
    height: auto;
    box-shadow: none;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  @media ${device.onlyMobile} {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }
`

export const SearchInnerWrapper = styled.div`
  background-color: ${props => props.theme.colors.white};
  display: flex;
  width: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: ${({ isGroup }) => (isGroup ? '0 25px 0 50px;' : '0 60px 0 40px;')}
  box-sizing: border-box;
  position: relative;

  ${({ isGroup }) =>
    isGroup
      ? css`
          justify-content: flex-start;
        `
      : css`
          justify-content: center;
        `};

  @media ${device.laptop} {
    padding-right: 20px;  
    padding-left: 20px;
  }

  @media ${device.tablet} {
    width: 100%;
    flex-direction: column;
    align-items: center;
    height: '145px';
    padding: ${({ isGroup }) => (isGroup ? '30px 30px 30px' : '30px 40px 10px 40px')};
    border-radius: 20px;
    ${searchBoxShadow}
    z-index: ${({ theme }) => theme.zIndex.occupancySelect};
  }
`

export const SearchLabel = styled.span`
  display: block;
  transition: all ${({ theme }) => theme.animations.defaultTransition};
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translateY(-50%) translateX(-50%);

  ${({ loading }) =>
    loading
      ? css`
          opacity: 0.3;
        `
      : null};
`

export const Divider = styled.div`
  display: none;

  @media ${device.tablet} {
    display: block;
    height: 26px;
    position: absolute;
    width: 100%;
    box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.03), 0px 9.85544px 7.30033px rgba(0, 0, 0, 0.03),
      0px 4.78464px 3.54417px rgba(0, 0, 0, 0.03), 0px 2.34552px 1.73742px rgba(0, 0, 0, 0.02),
      0px 0.927421px 0.686979px rgba(0, 0, 0, 0.02);
    border-radius: 0 0 20px 20px;
    top: 41%;
  }
`

export const SearchSubmit = styled.div`
  position: relative;
  cursor: pointer;
  height: 100%;
  width: 240px;
  flex-shrink: 0;
  padding: 50px 55px;
  border-radius: 0 20px 20px 0;
  background: ${props => props.theme.colors.buttonBackground};
  color: ${props => props.theme.colors.buttonText};
  text-transform: uppercase;
  font-weight: ${props => props.theme.fonts.boldWeight};
  font-size: ${props => props.theme.fonts._18};
  line-height: ${props => props.theme.fonts._21};
  transition: all ${({ theme }) => theme.animations.defaultTransition};
  text-align: center;
  user-select: none;

  &:hover {
    background: ${props => props.theme.colors.darkBlue};

    > ${SearchLabel} {
      transform: translateY(-15px) translateX(-50%);
    }
  }

  @media ${device.tablet} {
    margin: 40px auto 0 auto;
    border-radius: 20px;
    padding: 25px;
    max-width: 355px;
    width: 100%;
    height: 71px;
    ${searchBoxShadow}
  }

  @media ${device.onlyMobile} {
    width: 100%;
  }
`
