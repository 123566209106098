import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const PaymentWrapper = styled.div`
  margin-bottom: 150px;
`

export const PaymentInnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const Section = styled.div`
  position: relative;
  width: 100%;

  ::before {
    content: '';
    position: absolute;
    width: 30%;
    top: -10px;
    bottom: 0;
    right: 0;
    border-top: 1px solid ${props => props.theme.colors.border};
    background: ${props => props.theme.colors.reservationSummaryBackground};
  }

  @media ${device.tablet} {
    ::before {
      display: none;
    }
  }
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1180px;
  margin: 0 auto;
  position: relative;

  @media ${device.tablet} {
    flex-direction: column;
  }

  @media ${device.laptop} {
    max-width: 900px;
  }
`
