import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const PromoRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @media ${device.tablet} {
    justify-content: center;
  }
`

export const PromoOriginalPrice = styled.div`
  font-weight: ${({ theme }) => theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._18};
  line-height: ${props => props.theme.fonts._21};
  color: ${({ theme }) => theme.colors.secondary};
  position: relative;

  &:after {
    content: ' ';
    background: ${({ theme }) => theme.colors.secondary};
    position: absolute;
    height: 2px;
    left: 0;
    right: 0;
    top: 48%;
  }

  @media ${device.onlyMobile} {
    font-size: ${props => props.theme.fonts._20};
    line-height: ${props => props.theme.fonts._24};
  }
`

export const PromoBadge = styled.div`
  margin-left: ${({ marginLeft }) => marginLeft || '10px'};
  padding: 6px 10px;
  text-transform: uppercase;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  font-weight: ${({ theme }) => theme.fonts.boldWeight};
`
