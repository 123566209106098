import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const SingleBasketItemWrapper = styled.div`
  background: ${props => props.theme.colors.backgroundColor};
  border-radius: 10px;
  box-shadow: 0px 0.30914px 0.549583px rgba(0, 0, 0, 0.02), 0px 0.781839px 1.38994px rgba(0, 0, 0, 0.02),
    0px 1.59488px 2.83534px rgba(0, 0, 0, 0.03), 0px 3.28515px 5.84026px rgba(0, 0, 0, 0.03),
    0px 9px 16px rgba(0, 0, 0, 0.05);
  padding: 30px;
  margin-top: 65px;

  @media ${device.tablet} {
    border-radius: 0;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.secondary};
  padding-bottom: 20px;

  @media ${device.tablet} {
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 65px;
  }

  @media ${device.mobile} {
    padding-bottom: 25px;
  }
`

export const Image = styled.img`
  width: 120px;
  height: 80px;
  box-shadow: 0px 0.498106px 0.442761px rgba(0, 0, 0, 0.0196802), 0px 1.19702px 1.06402px rgba(0, 0, 0, 0.0282725),
    0px 2.25388px 2.00345px rgba(0, 0, 0, 0.035), 0px 4.02054px 3.57381px rgba(0, 0, 0, 0.0417275),
    0px 7.51997px 6.68442px rgba(0, 0, 0, 0.0503198), 0px 18px 16px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  border: none;
  object-fit: cover;

  @media ${device.tablet} {
    order: 3;
    width: 160px;
    height: 113px;
  }

  @media ${device.mobile} {
    margin-bottom: 24px;
  }
`

export const InformationWrapper = styled.div`
  margin-left: 35px;

  @media ${device.tablet} {
    order: 4;
    margin-left: 0;
    padding: 0 24px;
  }

  @media ${device.mobile} {
    flex: 0 0 100%;
  }
`

export const Title = styled.div`
  font-weight: bold;
  font-size: ${props => props.theme.fonts._24};
  line-height: ${props => props.theme.fonts._28};
  margin-bottom: 5px;

  @media ${device.tablet} {
    display: none;
  }
`

export const TitleMobile = styled.div`
  display: none;
  font-weight: bold;
  font-size: ${props => props.theme.fonts._24};
  line-height: ${props => props.theme.fonts._28};
  margin-bottom: 5px;

  @media ${device.tablet} {
    display: block;
    order: 2;
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
`

export const ReservationInformation = styled.div`
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};

  @media ${device.tablet} {
    flex-direction: column;
  }
`

export const Divider = styled.div`
  height: 10px;
  width: 1px;
  background: ${props => props.theme.colors.border};
  margin-left: 15px;
  margin-right: 15px;

  @media ${device.tablet} {
    display: none;
  }
`

export const TotalPrice = styled.div`
  font-weight: ${props => props.theme.fonts.mediumWeight};

  @media ${device.tablet} {
    margin-bottom: 15px;
  }
`

export const RatePlanName = styled.div`
  color: ${props => props.theme.colors.lightText};

  @media ${device.tablet} {
    margin-bottom: 15px;
  }
`

export const Occupancy = styled.div`
  color: ${props => props.theme.colors.lightText};
`

export const RemoveUnitTypeButton = styled.div`
  cursor: pointer;
  margin-left: auto;
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};

  svg {
    margin-left: 15px;
  }

  @media ${device.tablet} {
    order: 1;
    flex-basis: 100%;
    text-align: center;
    padding: 20px;
  }
`
