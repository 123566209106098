import React, { useEffect, useRef, useContext } from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Terms, TermsLabel, Label, Text, Container } from './style'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import { FormError } from 'Rentlio/components/UI/form'

const TermsAndConditions = ({ termsOfService, termsScrolledThrough, termsRead, setHasScroll }) => {
  const termsAndConditionsRef = useRef(null)
  const { translate } = useContext(LanguageContext)

  useEffect(() => {
    const checkScrollEnd = () => {
      if (termsAndConditionsRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = termsAndConditionsRef.current
        if (scrollHeight - scrollTop <= clientHeight) {
          termsScrolledThrough()
        }
      }
    }

    const checkIfScrollingIsRequired = () => {
      const { scrollHeight, clientHeight } = termsAndConditionsRef.current
      if (scrollHeight > clientHeight) {
        setHasScroll(true) // Call setHasScroll if scrolling is needed
      } else {
        termsScrolledThrough()
      }
    }

    const termsElement = termsAndConditionsRef.current
    if (termsElement) {
      termsElement.addEventListener('scroll', checkScrollEnd)
      checkIfScrollingIsRequired()
    }

    return () => {
      if (termsElement) {
        termsElement.removeEventListener('scroll', checkScrollEnd)
      }
    }
  }, [termsOfService])

  return (
    <Container>
      <TermsLabel>
        <Label>{translate('Terms and conditions')}</Label>
      </TermsLabel>
      <Wrapper termsRead={termsRead}>
        <Terms ref={termsAndConditionsRef}>
          <Text>{termsOfService}</Text>
        </Terms>
      </Wrapper>
      {!termsRead && (
        <FormError>
          {translate(
            'Please read fully these Terms and Conditions in order to be able to accept them and proceed to enter credit card information'
          )}
        </FormError>
      )}
    </Container>
  )
}

TermsAndConditions.propTypes = {
  termsOfService: PropTypes.string,
  termsScrolledThrough: PropTypes.func,
  termsRead: PropTypes.bool,
  setHasScroll: PropTypes.func
}

export default TermsAndConditions
