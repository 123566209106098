import React from 'react'
import PropTypes from 'prop-types'

import { Icon, InputWithIconWrapper } from './style'
import { Input } from 'Rentlio/components/UI/input'

class InputWithIcon extends React.PureComponent {
  render() {
    const { icon, value, onChange, idForInput, placeholder, disabled } = this.props
    return (
      <InputWithIconWrapper>
        <Input value={value} onChange={onChange} id={idForInput} placeholder={placeholder} disabled={disabled} />
        <Icon>{icon}</Icon>
      </InputWithIconWrapper>
    )
  }
}

InputWithIcon.propTypes = {
  icon: PropTypes.node,
  value: PropTypes.string,
  onChange: PropTypes.func,
  idForInput: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool
}

export default InputWithIcon
