import React from 'react'
import PropTypes from 'prop-types'
import Currency from './Currency'
import Languages from './Languages'
import { HeaderWrapper, Title, Logo, BrandingWrapper, HeaderInnerWrapper, OptionsWrapper } from './style'
import PromoContainer from 'Rentlio/screen/layout/containers/promo'
import { HOME_PAGE } from 'Rentlio/utils/routes'

import Wrapper from 'Rentlio/components/UI/wrapper'

const onlineCheckInUrlPathname = '/reservation/check-in'

class Header extends React.PureComponent {
  getHomepageUrl = () => {
    // The problem is on Online Check-in form it leads to the Booking Engine home, and the Booking Engine doesn't necessarily have to be set up correctly with prices, etc.
    // Then you can't go back to the Check-in form in any way except by using the back button. You have a room saved in your
    // cart that you've already booked, and if you click Continue, it prompts you to fill in the booker details and credit card information again, which is misleading.
    if (window.location.pathname.includes(onlineCheckInUrlPathname)) {
      return null
    }

    const { logoRedirectsTo, unitTypeId, locale, bookingEngineId } = this.props

    if (logoRedirectsTo && logoRedirectsTo !== '') {
      return logoRedirectsTo
    }

    let homePageUrl = `${HOME_PAGE}?language=${locale}`

    if (unitTypeId !== null) {
      homePageUrl += `&unitTypeId=${unitTypeId}`
    }

    if (bookingEngineId !== null) {
      homePageUrl += `&bookingEngineId=${bookingEngineId}`
    }

    return homePageUrl
  }

  render() {
    const {
      name,
      logoUrl,
      locale,
      activeCurrencies,
      activeLanguages,
      selectLocale,
      selectCurrency,
      currency,
      isCheckIn
    } = this.props
    return (
      <HeaderWrapper>
        <Wrapper>
          <HeaderInnerWrapper>
            <BrandingWrapper>
              <a href={this.getHomepageUrl()}>{logoUrl ? <Logo src={logoUrl} /> : <Title>{name}</Title>}</a>
            </BrandingWrapper>
            <OptionsWrapper>
              <PromoContainer />
              {!isCheckIn && (
                <Currency activeCurrencies={activeCurrencies} selectCurrency={selectCurrency} currency={currency} />
              )}
              <Languages
                locale={locale}
                activeLanguages={activeLanguages}
                selectLocale={selectLocale}
                isCheckIn={isCheckIn}
              />
            </OptionsWrapper>
          </HeaderInnerWrapper>
        </Wrapper>
      </HeaderWrapper>
    )
  }
}

Header.propTypes = {
  name: PropTypes.string,
  logoUrl: PropTypes.string,
  locale: PropTypes.string,
  activeCurrencies: PropTypes.array,
  activeLanguages: PropTypes.array,
  selectLocale: PropTypes.func,
  selectCurrency: PropTypes.func,
  currency: PropTypes.string,
  unitTypeId: PropTypes.string,
  bookingEngineId: PropTypes.number,
  logoRedirectsTo: PropTypes.string,
  isCheckIn: PropTypes.bool
}

export default Header
