import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const CarouselWrapper = styled.div`
  position: relative;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  scroll-behaviour: smooth;
  -webkit-overflow-scrolling: touch;
`
export const CarouselContainer = styled.div`
  position: relative;
  overflow: hidden;
  scroll-behaviour: smooth;
  -webkit-overflow-scrolling: touch;
  height: ${props => (props.isModalImage ? '400px' : '300px')};
  width: ${props => (props.isModalImage ? '100%' : '400px')};
  box-shadow: 0px 0.498106px 0.442761px rgba(0, 0, 0, 0.0196802), 0px 1.19702px 1.06402px rgba(0, 0, 0, 0.0282725),
    0px 2.25388px 2.00345px rgba(0, 0, 0, 0.035), 0px 4.02054px 3.57381px rgba(0, 0, 0, 0.0417275),
    0px 7.51997px 6.68442px rgba(0, 0, 0, 0.0503198), 0px 18px 16px rgba(0, 0, 0, 0.07);
  @media ${device.laptop} {
    height: ${props => (props.isModalImage ? '400px' : '200px')};
    width: ${props => (props.isModalImage ? '100%' : '267px')};
  }

  @media ${device.tablet} {
    display: flex;
    height: ${props => (props.isModalImage ? '300px' : '265px')};
    width: ${props => (props.isModalImage ? '100%' : '355px')};
    scroll-snap-type: x mandatory;
    -webkit-scroll-snap-type: x mandatory;
    scroll-snap-type-x: mandatory;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      -webkit-appearance: none;
      display: none;
    }

    div:first-of-type img {
      border-radius: 10px 0 0 10px;
    }

    div:nth-last-of-type(3) img {
      border-radius: 0 10px 10px 0;
    }
  }

  @media ${device.onlyMobile} {
    width: 100%;
    height: 200px;
  }
`

export const ImageWrapper = styled.div`
  position: absolute;
  opacity: ${props => (props.active ? '1' : '0')};
  scroll-snap-align: start;
  scroll-snap-stop: always;
  min-width: 100%;
  height: inherit;
  width: inherit;

  @media ${device.tablet} {
    position: relative;
    opacity: 1;
  }
`

export const CarouselImage = styled.img`
  height: inherit;
  width: inherit;
  background-size: cover;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: opacity ${({ theme }) => theme.animations.carouselTransition};

  @media ${device.tablet} {
    opacity: 1;
  }
`

export const CarouselLeftArrow = styled.div`
  user-select: none;
  cursor: pointer;
  position: absolute;
  padding: 50px 20px;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform ${({ theme }) => theme.animations.defaultTransition};

  :hover {
    transform: translate(-14%, -50%);
  }

  @media ${device.tablet} {
    display: none;
  }
`

export const Details = styled.div`
  margin: 0 auto;
  width: 200px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;

  @media ${device.laptop} {
    width: 90px;
  }

  @media ${device.tablet} {
    display: none;
  }
`

export const CarouselRightArrow = styled.div`
  user-select: none;
  cursor: pointer;
  position: absolute;
  padding: 50px 20px;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform ${({ theme }) => theme.animations.defaultTransition};

  :hover {
    transform: translate(14%, -50%);
  }

  @media ${device.tablet} {
    display: none;
  }
`

export const DotsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 40px;
  right: 20px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto auto 15px;
`

export const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.white};
  opacity: ${({ activeSlide }) => (activeSlide ? 1 : '0.6')};
`
