const getCardExpirationDateValue = (value, currentExpirationDate) => {
  // backslash is added automatically so we do not allow adding it manually
  if (value.charAt(value.length - 1) === '/' && value.length > currentExpirationDate.length) {
    return currentExpirationDate
  }
  // to add slash
  if (value.length === 2 && currentExpirationDate.length === 1) {
    return `${value}/`
  }
  // when we want to delete slash also
  if (value.length === 3 && currentExpirationDate.length === 4) {
    return value.slice(0, -1)
  }
  // when we want to add slash after deleting 3rd char
  if (value.length === 3 && currentExpirationDate.length === 2) {
    const lastChar = value.charAt(value.length - 1)
    return `${value.slice(0, -1)}/${lastChar}`
  }
  // we are allowing only 5 chars max
  if (value.length === 6) {
    return currentExpirationDate
  }
  return value
}

export default getCardExpirationDateValue
