import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: ${props => props.theme.zIndex.unitTypeModal};
  padding: 100px 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${props => props.theme.colors.modalBackground};
`
export const ModalView = styled.div`
  position: relative;
  width: ${props => (props.isLarge ? 900 : props.width ? props.width : 780)}px;
  max-width: ${props => (props.isLarge ? 900 : props.width ? props.width : 780)}px;
  padding: ${props => (props.padding ? props.padding : '50px 50px ' + (props.paddingBottom || '60px'))};
  margin: 0 auto;
  border-radius: 10px;
  background: ${props => props.theme.colors.backgroundColor};

  @media ${device.tablet} {
    width: 95%;
  }

  @media ${device.mobile} {
    padding: 20px;
  }
`

export const CloseWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: -40px;
  color: ${props => props.theme.colors.buttonText};
  font-size: ${props => props.theme.fonts._18};
  line-height: ${props => props.theme.fonts._21};
  cursor: pointer;
`

export const CloseText = styled.p`
  margin: 0 10px 0 0;
  font-size: ${props => props.theme.fonts._18};
  line-height: ${props => props.theme.fonts._21};
  font-weight: ${props => props.theme.fonts.mediumWeight};
  cursor: pointer;
`
