import React from 'react'

const House = () => (
  <svg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.43802 10H5.65289C5.35537 10 5.09091 9.72246 5.09091 9.41023V6.87771C5.09091 5.35126 2.90909 5.35126 2.90909 6.87771V9.41023C2.90909 9.72246 2.64463 10 2.34711 10H0.561983C0.264463 10 0 9.72246 0 9.41023V3.92888C0 3.79011 0.0661157 3.61665 0.165289 3.51258L3.60331 0.182134C3.83471 -0.0607112 4.16529 -0.0607112 4.36364 0.182134L7.80165 3.51258C7.93388 3.61665 8 3.79011 8 3.92888V9.41023C8 9.72246 7.73554 10 7.43802 10Z'
      fill='white'
    />
  </svg>
)

export default House
