import React from 'react'

const LongArrowRight = () => (
  <svg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M1 7L17 7' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M11.3999 1L16.9999 7L11.3999 13'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default LongArrowRight
