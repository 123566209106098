import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { FormLabel, FormRow, FormGroup, BankDetails, ReferenceNumber, IbanInputWrapper } from './style'

import { Input } from 'Rentlio/components/UI/input'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

const BankTransferDetails = ({ bankTransferSettings, referenceNumber }) => {
  const { translate } = useContext(LanguageContext)

  // todo fix this
  if (!bankTransferSettings) {
    return null
  }
  const { accountHolderName, bankAddress, bankName, iban, swiftCode } = bankTransferSettings

  return (
    <div>
      <ReferenceNumber>
        <label>{translate('Reference number')}: </label>
        <span> &nbsp;{referenceNumber}</span>
      </ReferenceNumber>
      <FormRow>
        <FormGroup width={200}>
          <FormLabel>SWIFT/BIC</FormLabel>
          <Input placeholder='123' value={swiftCode} inActive readOnly />
        </FormGroup>
        <FormGroup width={300}>
          <FormLabel>{translate('IBAN / Account Number')}</FormLabel>
          <IbanInputWrapper>
            <Input placeholder='01/20' value={iban} inActive readOnly />
          </IbanInputWrapper>
        </FormGroup>
      </FormRow>
      <BankDetails>
        <div>
          <label>{translate('Account holder name')}</label>
          <span>{accountHolderName}</span>
        </div>
        <div>
          <label>{translate('Bank name')}</label>
          <span>{bankName}</span>
        </div>
        <div>
          <label>{translate('Bank address')}</label>
          <span>{bankAddress}</span>
        </div>
      </BankDetails>
    </div>
  )
}

BankTransferDetails.propTypes = {
  bankTransferSettings: PropTypes.object,
  referenceNumber: PropTypes.string,
  currency: PropTypes.string
}

export default BankTransferDetails
