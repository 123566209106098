import { CLOSE_MODAL, OPEN_MODAL } from 'Rentlio/state/types'
import createReducer from 'Rentlio/state/utils/createReducer'

const initialState = {
  openModal: null,
  modalData: null
}
const modals = createReducer(initialState)({
  [OPEN_MODAL]: (state, { modalName, data }) => ({ ...state, openModal: modalName, modalData: data || null }),
  [CLOSE_MODAL]: state => ({ ...state, openModal: null, modalData: null })
})

export default modals
