import React from 'react'

const Phone = () => (
  <svg width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.4359 2C20.0741 2 20.5337 2.09185 20.9744 2.5C21.415 2.90815 21.4872 3.40889 21.4872 4V18C21.4872 18.563 21.415 19.0778 20.9744 19.5C20.5337 19.9222 20.0741 20 19.4359 20H3.02565C2.41786 20 1.94303 19.9222 1.48719 19.5C1.03135 19.0778 0.974365 18.563 0.974365 18V4C0.974365 3.40889 1.03135 2.90815 1.48719 2.5C1.94303 2.09185 2.41786 2 3.02565 2H4.05129V5H8.15385V2H14.3077V5H18.4103V2H19.4359ZM19.4359 18V7H3.02565V18H19.4359ZM7.12821 0V4H5.08832V0H7.12821ZM17.3846 0V4H15.3333V0H17.3846Z'
      fill='#333333'
    />
  </svg>
)

export default Phone
