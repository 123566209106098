import React from 'react'
import PropTypes from 'prop-types'
import { Policies, RatesToggle, RoomNotAvailableLabel, UnavailableForRequestWrapper } from './style'
import colors from 'Rentlio/style/colors'
import ArrowDown from 'Rentlio/components/Icons/arrowDown'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

class UnavailableForRequest extends React.PureComponent {
  static contextType = LanguageContext
  getErrorMessage = translate => {
    const { request, unitType } = this.props

    if (request.adults + request.children.length > unitType.maxOccupancy) {
      return ' ' + translate('Max occupancy exceeded')
    }
  }

  render() {
    const { showModalHandler } = this.props
    const { translate } = this.context
    return (
      <UnavailableForRequestWrapper>
        <RoomNotAvailableLabel>
          {translate('Unit not available')} {this.getErrorMessage(translate)}
        </RoomNotAvailableLabel>
        <RatesToggle>
          {translate('Show rates')} <ArrowDown strokeWidth={2} stroke={colors.lightGray} />
        </RatesToggle>
        <Policies onClick={showModalHandler}>{translate('Policies')}</Policies>
      </UnavailableForRequestWrapper>
    )
  }
}

UnavailableForRequest.propTypes = {
  unitType: PropTypes.object,
  request: PropTypes.object,
  showModalHandler: PropTypes.func
}

export default UnavailableForRequest
