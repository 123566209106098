import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const UnavailableWrapper = styled.div`
  margin-top: 47px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 90px;

  @media ${device.tablet} {
    margin-left: 0;
    margin-top: 60px;
  }
`

export const RoomNotAvailableLabel = styled.div`
  color: ${props => props.theme.colors.alertColor};
  font-size: ${props => props.theme.fonts._18};
  line-height: ${props => props.theme.fonts._21};
  font-weight: ${props => props.theme.fonts.mediumWeight};
  margin-bottom: 15px;
`

export const SuggestedDates = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-top: 15px;
`

export const SuggestedDatesItem = styled.div`
  cursor: pointer;
  background: white;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.colors.border};
  padding: 9px;
  color: ${props => props.theme.colors.labelTextColor};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  transition: all ${({ theme }) => theme.animations.defaultTransition};

  :hover {
    background: ${({ theme }) => theme.colors.primary};
    color: white;
  }
`

export const ChooseAnotherDate = styled.div`
  cursor: pointer;
  min-width: 240px;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 20px 15px;
  background: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.buttonText};
  border-radius: 10px;
  text-transform: uppercase;
  font-weight: ${props => props.theme.fonts.boldWeight};
  font-size: ${props => props.theme.fonts._18};
  line-height: ${props => props.theme.fonts._21};
  transition: all ${({ theme }) => theme.animations.defaultTransition};

  &:hover {
    background: ${({ theme }) => theme.colors.vividOrange};
  }

  @media ${device.laptop} {
    font-size: ${props => props.theme.fonts._16};
    margin: 20px 0px;
  }
`

export const Policies = styled.span`
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  text-decoration: underline;
  cursor: pointer;
`

export const CalendarOuterWrapper = styled.div`
  position: relative;
  width: 100%;
`
export const CalendarInnerWrapper = styled.div`
  position: absolute;
  top: -20px;
  left: 50%;
  margin-left: -162px;
  z-index: ${props => props.theme.zIndex.calendar};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
`

export const CalendarButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const CalendarFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 30px 10px 30px;
`

export const OnlyCheckInOutText = styled.div`
  margin-bottom: 15px;
  font-weight: ${props => props.theme.fonts.regularWeight};
  font-size: ${props => props.theme.fonts._10};
  line-height: ${props => props.theme.fonts._12};
  color: ${props => props.theme.colors.secondary};
`

export const CalendarButtonSave = styled.div`
  cursor: pointer;
  background: ${props => props.theme.colors.buttonBackground};
  color: ${props => props.theme.colors.buttonText};
  border-radius: 4px;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._18};
`

export const CalendarButtonClear = styled.div`
  cursor: pointer;
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._18};
  text-decoration: underline;
`
