export const breakpoint = {
  extraSmall: 400,
  small: 600,
  tablet: 900,
  laptop: 1200
}

export const device = {
  onlyMobile: `(max-width: ${breakpoint.extraSmall}px)`,
  mobile: `(max-width: ${breakpoint.small}px)`,
  tablet: `(max-width: ${breakpoint.tablet}px)`,
  tabletAndWider: `(min-width: ${breakpoint.tablet}px)`,
  laptop: `(max-width: ${breakpoint.laptop}px)`,
  laptopAndWider: `(min-width: ${breakpoint.laptop}px)`
}
