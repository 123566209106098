import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { Paragraph, Title } from './style'
import Modal from 'Rentlio/components/Modal'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import Animation from 'Rentlio/components/UI/Animation'
import { modals } from 'Rentlio/screen/layout/utils/modalsEnum'

const TermsOfService = ({ termsOfService, closeModal, activeModal }) => {
  const { translate } = useContext(LanguageContext)

  return (
    <Animation show={activeModal === modals.TERMS_AND_CONDITIONS} animationType={'fadeInOut'} isModal>
      <Modal hideModalHandler={closeModal} show={activeModal === modals.TERMS_AND_CONDITIONS}>
        <Title>{translate('Terms of service')}</Title>
        {termsOfService && termsOfService.split('\n').map((line, i) => <Paragraph key={i}>{line}</Paragraph>)}
      </Modal>
    </Animation>
  )
}

TermsOfService.propTypes = {
  termsOfService: PropTypes.string,
  closeModal: PropTypes.func,
  activeModal: PropTypes.string
}

export default TermsOfService
