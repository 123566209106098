import colors from 'Rentlio/style/colors'
import { breakpoint } from 'Rentlio/style/responsive'

const getCustomMonriFormStyle = () => {
  var width = window.innerWidth > 0 ? window.innerWidth : window.screen.width
  const style = {
    base: {
      color: colors.lightText
    },
    label: {
      base: {
        fontSize: '12px',
        color: colors.lightText,
        margin: '0px 0px 10px 0px',
        letterSpacing: '0px'
      }
    },
    input: {
      base: {
        fontSize: '14px',
        color: colors.textColor,
        margin: '0px 20px 50px 0px',
        padding: '0px 15px 0px 15px',
        borderRadius: '10px',
        border: '1px solid rgba(51, 51, 51, 0.3)',
        height: '60px',
        width: '272px'
      },
      invalid: {
        color: colors.alertColor
      }
    }
  }
  if (width < breakpoint.laptop || width === breakpoint.laptop) {
    style.input.base.width = '100%'
  }

  return style
}

export default getCustomMonriFormStyle
