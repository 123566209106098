import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { Paragraph, Title } from './style'
import Modal from 'Rentlio/components/Modal'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import Animation from 'Rentlio/components/UI/Animation'
import { modals } from 'Rentlio/screen/layout/utils/modalsEnum'

const PrivacyPolicy = ({ privacyPolicy, closeModal, activeModal }) => {
  const { translate } = useContext(LanguageContext)
  return (
    <Animation show={activeModal === modals.PRIVACY_POLICY} animationType={'fadeInOut'} isModal>
      <Modal hideModalHandler={closeModal} show={activeModal === modals.PRIVACY_POLICY}>
        <Title>{translate('Privacy policy')}</Title>
        {privacyPolicy && privacyPolicy.split('\n').map((line, i) => <Paragraph key={i}>{line}</Paragraph>)}
      </Modal>
    </Animation>
  )
}

PrivacyPolicy.propTypes = {
  privacyPolicy: PropTypes.string,
  closeModal: PropTypes.func,
  activeModal: PropTypes.string
}

export default PrivacyPolicy
