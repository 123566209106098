import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { Switch, withRouter } from 'react-router-dom'
import Search from './Search'
import { HeroWrapper, OfferId, ReservationId, Wrapper } from './style'
import AmenityFilterContainer from 'Rentlio/screen/layout/containers/amenityFilter'
import BreadCrumbs from 'Rentlio/screen/layout/components/Breadcrumbs'
import { HOME_PAGE, PAYMENT_PAGE, SERVICES_PAGE } from 'Rentlio/utils/routes'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

// todo update as the development goes on
const breadCrumbRoutes = [SERVICES_PAGE, PAYMENT_PAGE]

function getReservationIdsLabel(reservation) {
  if (Object.keys(reservation).length === 0) {
    return null
  }

  const { reservationIds } = reservation

  let label = ''

  reservationIds.map((id, i) => {
    label += id.toString()

    if (i !== reservationIds.length - 1) {
      label += ', '
    }
  })
  return label
}

const Hero = ({
  unitTypes,
  bookingEngine,
  request,
  fetchAvailability,
  location,
  booking,
  animationLoading,
  configuration,
  filterByLocation,
  reservation,
  isCheckIn
}) => {
  const shouldShowSearch = location.pathname === HOME_PAGE
  const shouldShowBreadCrumbs = breadCrumbRoutes.find(route => route === location.pathname) !== undefined
  const isReservationPage = location.pathname.startsWith('/reservations/')
  const isOfferPage = location.pathname.startsWith('/offer/')
  const { translate } = useContext(LanguageContext)

  return (
    <HeroWrapper backgroundImage={bookingEngine.coverImageUrl} isSearchShown={shouldShowSearch} isCheckIn={isCheckIn}>
      <Switch>
        {shouldShowSearch && (
          <Wrapper>
            <Search
              request={request}
              fetchAvailability={fetchAvailability}
              animationLoading={animationLoading}
              isGroup={bookingEngine.isGroup}
              configuration={configuration}
              unitTypes={unitTypes}
              filterByLocation={filterByLocation}
            />
            <AmenityFilterContainer />
          </Wrapper>
        )}
        {shouldShowBreadCrumbs && (
          <Wrapper>
            <BreadCrumbs currentPath={location.pathname} />
          </Wrapper>
        )}
        {isReservationPage && (
          <Wrapper>
            <ReservationId>
              {reservation.code
                ? `${translate('Reservation')}: ${reservation?.code}`
                : // Fallback for legacy users if there is no code property
                  `${translate('Reservation ID')}: ${getReservationIdsLabel(reservation)}`}
            </ReservationId>
          </Wrapper>
        )}
        {isOfferPage && (
          <Wrapper>
            <OfferId>
              {translate('Offer')} #{booking?.code}
            </OfferId>
          </Wrapper>
        )}
      </Switch>
    </HeroWrapper>
  )
}

Hero.propTypes = {
  bookingEngine: PropTypes.object,
  request: PropTypes.object,
  location: PropTypes.object,
  fetchAvailability: PropTypes.func,
  reservation: PropTypes.object,
  animationLoading: PropTypes.bool,
  configuration: PropTypes.object,
  booking: PropTypes.object,
  unitTypes: PropTypes.array,
  filterByLocation: PropTypes.func,
  isCheckIn: PropTypes.bool
}

export default withRouter(Hero)
