import PropTypes from 'prop-types'
import React from 'react'
import { ThemeProvider } from 'styled-components'
import colors from './colors'
import fonts from './fonts'
import { zIndex } from './zIndex'
import animations from 'Rentlio/style/animations'

const theme = {
  colors: colors,
  fonts: fonts,
  zIndex: zIndex,
  animations: animations
}

const Theme = ({ children }) => <ThemeProvider theme={theme}>{{ ...children }}</ThemeProvider>

const propTypes = {
  children: PropTypes.node
}

Theme.propTypes = propTypes

export default Theme
