import React from 'react'
import PropTypes from 'prop-types'
import {
  CarouselImage,
  CarouselLeftArrow,
  CarouselRightArrow,
  CarouselWrapper,
  ImageWrapper,
  DotsWrapper,
  Dot,
  CarouselContainer,
  Details
} from './style'
import ArrowLeft from 'Rentlio/components/Icons/arrowLeft'
import ArrowRight from 'Rentlio/components/Icons/arrowRight'
import { breakpoint } from 'Rentlio/style/responsive'

class Carousel extends React.PureComponent {
  ref = React.createRef()
  io = null

  state = {
    isLoaded: false,
    slideNumber: 0
  }

  componentDidMount() {
    this.carouselWidth = this.ref.current.offsetWidth

    if (this.ref && document.body.clientWidth <= breakpoint.tablet) {
      this.ref.current.addEventListener('scroll', this.handleCarouselScroll)
    }
    // eslint-disable-next-line no-undef
    this.io = new IntersectionObserver(
      entries => {
        entries.map(entry => {
          if (entry.isIntersecting && entry.target === this.ref.current) {
            this.setState({ isLoaded: true })
          }
        })
      },
      {
        /* Using default options. Details below */
      }
    )
    this.io && this.io.observe(this.ref.current)
  }

  componentWillUnmount() {
    this.ref.current.removeEventListener('scroll', this.handleCarouselScroll)
    this.io && this.io.unobserve(this.ref.current)
    this.io && this.io.disconnect()
  }

  handleCarouselScroll = e => {
    if (!e) {
      return
    }

    this.setState({ slideNumber: e.target.scrollLeft / this.carouselWidth })
  }

  render() {
    const { isLoaded, slideNumber } = this.state

    const { images, nextImage, previousImage, currentImage, isModalImage, showModalHandler } = this.props

    return (
      <CarouselWrapper>
        <CarouselContainer
          isModalImage={isModalImage}
          ref={this.ref}
          onScroll={this.handleCarouselScroll()}
          onClick={() =>
            typeof this.props.showModalHandler === 'function' &&
            document.body.clientWidth <= breakpoint.tablet &&
            showModalHandler()
          }
        >
          {isLoaded &&
            images.map((image, index) => (
              <ImageWrapper key={image.name} active={currentImage === index}>
                <CarouselImage src={image.url} alt={image.name} />
              </ImageWrapper>
            ))}
          <CarouselLeftArrow onClick={previousImage}>
            <ArrowLeft />
          </CarouselLeftArrow>
          {!isModalImage && (
            <Details
              isModalImage={isModalImage}
              onClick={() => typeof this.props.showModalHandler === 'function' && showModalHandler()}
            />
          )}
          <CarouselRightArrow onClick={nextImage}>
            <ArrowRight />
          </CarouselRightArrow>
        </CarouselContainer>
        {document.body.clientWidth <= breakpoint.tablet && (
          <DotsWrapper>
            {isLoaded &&
              images.map((image, index) => (
                <Dot key={image.name} activeSlide={slideNumber >= index - 0.4 && slideNumber <= index + 0.4}></Dot>
              ))}
          </DotsWrapper>
        )}
      </CarouselWrapper>
    )
  }
}

Carousel.propTypes = {
  images: PropTypes.array,
  currentImage: PropTypes.number,
  nextImage: PropTypes.func,
  previousImage: PropTypes.func,
  isModalImage: PropTypes.bool,
  showModalHandler: PropTypes.func
}

export default Carousel
