import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const UnavailableForRequestWrapper = styled.div`
  margin-top: 47px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 90px;

  @media ${device.tablet} {
    margin-left: 0;
    margin-top: 60px;
  }
`

export const RoomNotAvailableLabel = styled.div`
  color: ${props => props.theme.colors.alertColor};
  font-size: ${props => props.theme.fonts._18};
  line-height: ${props => props.theme.fonts._21};
  font-weight: ${props => props.theme.fonts.mediumWeight};
  margin-bottom: 15px;
  max-width: 240px;
  text-align: center;
`

export const RatesToggle = styled.div`
  width: 240px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
  background: ${props => props.theme.colors.border};
  color: ${props => props.theme.colors.lightGray};
  border-radius: 10px;
  text-transform: uppercase;
  font-weight: ${props => props.theme.fonts.boldWeight};
  font-size: ${props => props.theme.fonts._18};
  line-height: ${props => props.theme.fonts._21};
  user-select: none;
  cursor: not-allowed;
`

export const Policies = styled.span`
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  text-decoration: underline;
  cursor: pointer;
`
