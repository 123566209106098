import moment from 'moment'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { OfferExpiryDate, OfferExpiryLabel, OfferExpiryValue, OfferWrapper } from './style'
import groupReservations from './util/groupReservations'
import OfferNoticeContainer from 'Rentlio/screen/offer/containers/offerNotice'
import Reservation from 'Rentlio/screen/offer/components/Offer/Reservation'
import Wrapper from 'Rentlio/components/UI/wrapper'
import OfferFooterContainer from 'Rentlio/screen/offer/containers/offerFooter'
import { DATE_FORMAT } from 'Rentlio/utils/dateFormatEnum'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

class Offer extends Component {
  static contextType = LanguageContext
  render() {
    const { translate } = this.context
    const { booking, offer, bookingEngine } = this.props

    return (
      <OfferWrapper>
        <OfferNoticeContainer />
        <Wrapper>
          <OfferExpiryDate>
            <OfferExpiryLabel>{translate('Offer valid until')}:</OfferExpiryLabel>
            <OfferExpiryValue>{moment.unix(offer.validUntil).format(DATE_FORMAT)}</OfferExpiryValue>
            <OfferExpiryLabel>{translate('Created at')}:</OfferExpiryLabel>
            <OfferExpiryValue>
              {moment
                .unix(offer.createdAt)
                .utc()
                .format(DATE_FORMAT)}
            </OfferExpiryValue>
          </OfferExpiryDate>
          {/* todo group this later */}
          {groupReservations(booking.reservations).map(item => (
            <Reservation key={item.reservationsId} reservation={item} bookingEngine={bookingEngine} />
          ))}
        </Wrapper>
        <OfferFooterContainer />
      </OfferWrapper>
    )
  }
}

Offer.propTypes = {
  offer: PropTypes.object,
  booking: PropTypes.object,
  bookingEngine: PropTypes.object
}

export default Offer
