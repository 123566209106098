import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { PromoBadge, PromoOriginalPrice, PromoRow } from './style'
import { PROMO_CODE_DISCOUNT_TYPE_ENUM } from 'Rentlio/utils/promoCodeDiscountTypeEnum'
import { CurrenciesContext } from 'Rentlio/context/CurrenciesContext'
import { formatPrice } from 'Rentlio/components/utils/Price/price'

const Promo = ({ ratePlan, unitTypeCurrency, rateBeforeDiscount }) => {
  const { currencyConversion } = useContext(CurrenciesContext)
  const { discountAmount, discountType, name } = ratePlan.activePromoCode

  const totalRateBeforeDiscount = currencyConversion(rateBeforeDiscount, unitTypeCurrency)
  const discountAmountConverted = currencyConversion(discountAmount, unitTypeCurrency)

  return (
    <PromoRow>
      <PromoOriginalPrice>
        {formatPrice(totalRateBeforeDiscount.value)} {totalRateBeforeDiscount.currency}
      </PromoOriginalPrice>
      <PromoBadge>
        {name}
        {' -'}
        {discountType === PROMO_CODE_DISCOUNT_TYPE_ENUM.NOMINAL
          ? formatPrice(discountAmountConverted.value, unitTypeCurrency) + unitTypeCurrency
          : discountAmount + '%'}
      </PromoBadge>
    </PromoRow>
  )
}

Promo.propTypes = {
  ratePlan: PropTypes.object,
  unitTypeCurrency: PropTypes.string,
  rateBeforeDiscount: PropTypes.number
}

export default Promo
