import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { SpinnerWrapper } from './style'

const Spinner = ({ width, disableScroll, strokeWidth, color }) => {
  useEffect(() => {
    if (!disableScroll) return
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [disableScroll])

  return (
    <SpinnerWrapper width={width} strokeWidth={strokeWidth} color={color}>
      <svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='50' cy='50' r='45' />
      </svg>
    </SpinnerWrapper>
  )
}

Spinner.defaultProps = {
  width: 40,
  strokeWidth: 5,
  color: '#fff'
}

Spinner.propTypes = {
  width: PropTypes.number,
  disableScroll: PropTypes.bool,
  strokeWidth: PropTypes.number,
  color: PropTypes.string
}

export default Spinner
