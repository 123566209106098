import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const ReceiptItemRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  margin-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;

  @media ${device.tablet} {
    padding: 0;
  }
`

export const Label = styled.div`
  display: flex;
  align-items: center;
  max-width: 80%;
  opacity: 0.8;
  svg {
    margin-left: 10px;
    margin-right: 10px;
  }

  @media ${device.onlyMobile} {
    max-width: 70%;
  }
`

export const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${props => props.theme.fonts.mediumWeight};
`

export const Value = styled.div`
  font-weight: ${props => props.theme.fonts.boldWeight};
`

export const CancellationPolicyWrapper = styled.div`
  margin-top: 10px;
  flex-basis: 100%;
  opacity: 0.8;
`

export const CheckInOutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
`

export const CheckInOutContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`
