import { trans } from 'Rentlio/helper/translation'

export function maxLengthConstraint(value, maxLengthValue) {
  if (typeof maxLengthValue === 'undefined' || maxLengthValue === null || maxLengthValue < 1) {
    throw new Error('Badly defined maxLengthValue')
  }

  if (typeof value !== 'string' || value === null) {
    return null
  }

  return value.length > maxLengthValue ? trans('MaxLengthValidationError', { '%maxLength%': maxLengthValue }) : null
}
