import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Divider, Label, Value, StyledLink, Wrapper } from './style'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

const InfoRow = ({ label, value }) => {
  const { translate } = useContext(LanguageContext)

  const getItem = () => {
    switch (label) {
      case 'Email':
        return <StyledLink href={`mailto:${value}`}>{value}</StyledLink>
      case 'Phone':
      case 'Second phone':
        return <StyledLink href={`tel:${value}`}>{value}</StyledLink>
      case 'Web':
        return (
          <StyledLink href={value !== null && !value.includes('http') ? `https://${value}` : value} target='__blank'>
            {value}
          </StyledLink>
        )
      default:
        return <Value>{value}</Value>
    }
  }

  return (
    <Wrapper hide={value}>
      <Label>{translate(label)}</Label>
      <Divider />
      {getItem()}
    </Wrapper>
  )
}
InfoRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  isEmail: PropTypes.bool,
  isLink: PropTypes.bool
}

export default InfoRow
