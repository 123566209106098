import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import UnitTypes from 'Rentlio/screen/accomodation/component/UnitTypes'
import { fetchAvailability, fetchAvailabilityForMonth } from 'Rentlio/state/actions/api'
import { clearBasket, openUnitTypeModal } from 'Rentlio/state/actions/ui'
import { getMaxAvailabilityForUnitTypes } from 'Rentlio/state/selectors/unitTypes'
import { DATE_URL_FORMAT } from 'Rentlio/utils/dateFormatEnum'
import { setQueryParameters } from 'Rentlio/utils/url'
import { getBookingEngineIdInBasket, getTotalCost } from 'Rentlio/state/selectors/basket'
import { breakpoint } from 'Rentlio/style/responsive'

class UnitTypesContainer extends React.Component {
  state = {
    firstLoad: false
  }

  componentDidMount = () => this.repopulateQueryString()

  componentDidUpdate(prevProps) {
    const { firstLoad } = this.state
    if (!firstLoad) this.setState({ firstLoad: true })
    if (window.innerWidth <= breakpoint.tablet && firstLoad && prevProps.unitTypes !== this.props.unitTypes) {
      window.scroll({
        top: 800,
        behavior: 'smooth'
      })
    }

    const {
      request,
      configuration: { selectedAmenities, location }
    } = this.props
    const {
      request: prevRequest,
      configuration: { selectedAmenities: prevSelectedAmenities, location: prevLocation }
    } = prevProps

    if (
      prevRequest.from !== request.from ||
      prevRequest.to !== request.to ||
      prevRequest.adults !== request.adults ||
      prevRequest.children !== request.children ||
      prevRequest.rooms !== request.rooms ||
      prevSelectedAmenities.length !== selectedAmenities.length ||
      prevLocation !== location
    ) {
      this.repopulateQueryString()
    }
  }

  repopulateQueryString = () => {
    const {
      request: { from, to, adults, children, rooms, unitTypeId, promoCode, bookingEngineId },
      configuration: { selectedAmenities, location }
    } = this.props

    const params = {
      from:
        from !== null
          ? moment
              .unix(from)
              .utc()
              .format(DATE_URL_FORMAT)
          : null,
      to:
        to !== null
          ? moment
              .unix(to)
              .utc()
              .format(DATE_URL_FORMAT)
          : null,
      adults: adults !== null ? adults : null,
      children: children !== null ? children : null,
      rooms: rooms !== null ? rooms : null,
      amenities: selectedAmenities.length > 0 ? selectedAmenities.join(',') : null,
      location: location !== null ? location : null
    }

    if (unitTypeId !== null) {
      params.unitTypeId = unitTypeId
    }

    if (bookingEngineId !== null) {
      params.bookingEngineId = bookingEngineId
    }

    if (promoCode !== null && promoCode !== '') {
      params.promoCode = promoCode
    }

    setQueryParameters(params)
  }

  updateDates = (from, to) => {
    const { fetchAvailability } = this.props
    const params = {
      from: from !== null ? from.utc().format(DATE_URL_FORMAT) : null,
      to: to !== null ? to.utc().format(DATE_URL_FORMAT) : null
    }

    const newParams = setQueryParameters(params)
    fetchAvailability(newParams)
  }

  render() {
    const {
      unitTypes,
      request,
      fetchAvailability,
      fetchAvailabilityForMonth,
      unitTypeAvailability,
      totalCost,
      clearBasket,
      unitTypesMaxAvailability,
      services,
      animationLoading,
      primaryColor,
      selectedUnitTypeCurrency,
      isGroupBookingEngine,
      groupBESlug,
      configuration,
      selectedBookingEngineId,
      openUnitTypeModal,
      basket,
      alwaysShowRates,
      importantNote
    } = this.props

    return (
      <UnitTypes
        openUnitTypeModal={openUnitTypeModal}
        unitTypes={unitTypes}
        request={request}
        unitTypeAvailability={unitTypeAvailability}
        fetchAvailability={fetchAvailability}
        fetchAvailabilityForMonth={fetchAvailabilityForMonth}
        updateDates={this.updateDates}
        totalCost={totalCost}
        unitTypesMaxAvailability={unitTypesMaxAvailability}
        clearBasket={clearBasket}
        services={services}
        animationLoading={animationLoading}
        primaryColor={primaryColor}
        selectedUnitTypeCurrency={selectedUnitTypeCurrency}
        isGroupBookingEngine={isGroupBookingEngine}
        groupBESlug={groupBESlug}
        configuration={configuration}
        selectedBookingEngineId={selectedBookingEngineId}
        basket={basket}
        alwaysShowRates={alwaysShowRates}
        importantNote={importantNote}
      />
    )
  }
}

const mapStateToProps = state => ({
  primaryColor: state.bookingEngine.primaryColor,
  unitTypes: state.unitTypes,
  request: state.request,
  unitTypeAvailability: state.unitTypeAvailability,
  totalCost: getTotalCost(state),
  selectedBookingEngineId: getBookingEngineIdInBasket(state),
  unitTypesMaxAvailability: getMaxAvailabilityForUnitTypes(state),
  services: state.services,
  animationLoading: state.animationLoader.animationLoading,
  selectedUnitTypeCurrency: state.basket[0]?.currency,
  isGroupBookingEngine: state.bookingEngine.isGroup,
  groupBESlug: state.bookingEngine.slug,
  alwaysShowRates: state.bookingEngine.alwaysShowRates,
  configuration: state.configuration,
  basket: state.basket,
  importantNote: state.bookingEngine.importantNote
})

const mapDispatchToProps = dispatch => ({
  fetchAvailability: data => dispatch(fetchAvailability(data)),
  fetchAvailabilityForMonth: (unitTypeUuid, year, month) =>
    dispatch(fetchAvailabilityForMonth(unitTypeUuid, year, month)),
  clearBasket: () => dispatch(clearBasket()),
  openUnitTypeModal: data => dispatch(openUnitTypeModal(data))
})

UnitTypesContainer.propTypes = {
  unitTypes: PropTypes.array,
  request: PropTypes.object,
  unitTypesMaxAvailability: PropTypes.object,
  unitTypeAvailability: PropTypes.object,
  selectedUnitTypeCurrency: PropTypes.string,
  fetchAvailability: PropTypes.func,
  fetchAvailabilityForMonth: PropTypes.func,
  totalCost: PropTypes.object,
  clearBasket: PropTypes.func,
  services: PropTypes.array,
  animationLoading: PropTypes.bool,
  bookingEnigine: PropTypes.array,
  primaryColor: PropTypes.string,
  isGroupBookingEngine: PropTypes.bool,
  groupBESlug: PropTypes.string,
  configuration: PropTypes.object,
  selectedBookingEngineId: PropTypes.number,
  openUnitTypeModal: PropTypes.func,
  basket: PropTypes.array,
  alwaysShowRates: PropTypes.bool,
  importantNote: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(UnitTypesContainer)
