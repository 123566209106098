import React from 'react'
import { connect } from 'react-redux'
import GuestRegistration from 'Rentlio/screen/guestRegistration/components'
import { updateGuestRegistration } from 'Rentlio/state/actions/api'

const GuestRegistrationContainer = props => <GuestRegistration {...props} />

const mapStateToProps = ({ guests, reservation, configuration }) => ({
  guests,
  reservation,
  locale: configuration.locale
})

const mapDispatchToProps = dispatch => ({
  updateGuestRegistration: (guests, uuid) => dispatch(updateGuestRegistration(guests, uuid))
})

export default connect(mapStateToProps, mapDispatchToProps)(GuestRegistrationContainer)
