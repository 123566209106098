import styled from 'styled-components'

export const Title = styled.p`
  margin: 0 0 10px;
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._32};
  line-height: ${props => props.theme.fonts._37};
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% + 40px);
  height: 128px;
  transform: translateX(-20px);
  padding: 0 20px;
  border-radius: 10px;

  &:nth-of-type(odd) {
    background: ${props => props.theme.colors.amenitiesBckground};
  }
`
export const PolicyWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Policy = styled.p`
  display: inline;
  margin-left: 10px;
  font-weight: ${props => props.theme.fonts.boldWeight};
  font-size: ${props => props.theme.fonts._18};
  line-height: ${props => props.theme.fonts._21};
`

export const Description = styled.p`
  margin: 5px 0 0;
  font-weight: ${props => props.theme.fonts.regularWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._24};
  color: ${props => props.theme.colors.policyText};
`
