import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import Reservations from 'Rentlio/screen/reservations/components/Reservations'
import { cancelReservation, fetchReservation } from 'Rentlio/state/actions/api'
import { openCancellationPolicyModal, openTermsOfServiceModal } from 'Rentlio/state/actions/ui'

const ReservationsContainer = ({
  bookingEngine,
  reservation,
  cancelReservation,
  openTermsOfServiceModal,
  openCancellationPolicyModal
}) => (
  <Reservations
    reservation={reservation}
    bookingEngine={bookingEngine}
    cancelReservation={cancelReservation}
    openTermsOfServiceModal={openTermsOfServiceModal}
    openCancellationPolicyModal={openCancellationPolicyModal}
  />
)

ReservationsContainer.propTypes = {
  bookingEngine: PropTypes.object,
  reservation: PropTypes.object,
  cancelReservation: PropTypes.func,
  openTermsOfServiceModal: PropTypes.func,
  openCancellationPolicyModal: PropTypes.func,
  locale: PropTypes.string,
  prePaymentDetails: PropTypes.object
}

const mapStateToProps = state => ({
  reservation: state.reservation,
  bookingEngine: state.bookingEngine,
  locale: state.configuration.locale
})

const mapDispatchToProps = dispatch => ({
  cancelReservation: id => dispatch(cancelReservation(id)).then(() => dispatch(fetchReservation(id))),
  openTermsOfServiceModal: () => dispatch(openTermsOfServiceModal()),
  openCancellationPolicyModal: () => dispatch(openCancellationPolicyModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(ReservationsContainer)
