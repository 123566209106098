import React from 'react'
import PropTypes from 'prop-types'
import { SpinnerOverlay } from './style'
import Animation from 'Rentlio/components/UI/Animation'
import Spinner from 'Rentlio/components/UI/Spinner'

const Loading = ({ isLoading }) => {
  return (
    <Animation show={isLoading} animationType={'fadeInOut'} isModal>
      <SpinnerOverlay>
        <Spinner width={70} disableScroll color={'#07587A'} />
      </SpinnerOverlay>
    </Animation>
  )
}

Loading.propTypes = {
  isLoading: PropTypes.bool
}

export default Loading
