import React from 'react'
import PropTypes from 'prop-types'

const VisaSecurityIcon = ({ width, height }) => (
  <svg width={width} height={height} viewBox='0 0 35 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_3459_4529)'>
      <path d='M34.5 12.6448H0V30H34.5V12.6448Z' fill='#1434CB' />
      <path
        d='M13.744 0.141953L10.4961 7.93603H8.37731L6.77951 1.7162C6.68193 1.33403 6.59829 1.19379 6.30382 1.03075C5.82117 0.767793 5.02663 0.520613 4.32617 0.368097L4.37322 0.141953H7.78314C8.21701 0.141953 8.60905 0.43296 8.70837 0.936086L9.5517 5.44669L11.6374 0.141953H13.7422H13.744ZM22.0449 5.39235C22.0536 3.33602 19.2187 3.22207 19.2378 2.30171C19.2448 2.02298 19.5079 1.72496 20.0881 1.64958C20.3756 1.61276 21.1667 1.58296 22.0641 1.99844L22.416 0.345307C21.9334 0.170002 21.3131 0.00170898 20.5412 0.00170898C18.56 0.00170898 17.1661 1.06055 17.1539 2.5787C17.1417 3.70065 18.1488 4.32649 18.9085 4.69989C19.6909 5.08206 19.9522 5.32749 19.9487 5.66758C19.9435 6.18999 19.325 6.42139 18.75 6.43016C17.7411 6.44593 17.1574 6.15668 16.6922 5.93755L16.328 7.64677C16.7967 7.8624 17.6609 8.04998 18.5566 8.0605C20.6631 8.0605 22.0397 7.01392 22.0466 5.3941M27.2791 7.93954H29.1331L27.5109 0.141953H25.8016C25.4165 0.141953 25.0924 0.366344 24.9478 0.713449L21.9403 7.93603H24.0452L24.4634 6.772H27.0352L27.2774 7.93603L27.2791 7.93954ZM25.0419 5.17848L26.0978 2.25088L26.7041 5.17848H25.0419ZM16.6068 0.141953L14.9497 7.93603H12.9459L14.603 0.141953H16.6068Z'
        fill='#1434CB'
      />
      <path
        d='M7.20674 20.309C7.20674 20.0601 7.26599 19.8497 7.38621 19.6779C7.50644 19.5061 7.67023 19.3746 7.87758 19.287C8.08493 19.1993 8.31841 19.1555 8.57977 19.1555C8.87076 19.1555 9.1025 19.1836 9.27674 19.2414C9.44924 19.2993 9.58341 19.3554 9.67576 19.415L9.7228 20.0671H9.70712C9.62174 19.9952 9.51546 19.9304 9.39174 19.876C9.26629 19.8217 9.13386 19.7761 8.99273 19.7445C8.85159 19.7112 8.71394 19.6955 8.57629 19.6955C8.36023 19.6955 8.18599 19.7305 8.05182 19.8006C7.91765 19.8708 7.85144 19.9952 7.85144 20.1705C7.85144 20.3055 7.8828 20.4107 7.94727 20.4861C8.01 20.5615 8.10583 20.6246 8.23303 20.6719C8.36023 20.721 8.51705 20.7701 8.70523 20.8227C8.88296 20.8718 9.05023 20.9208 9.20705 20.9699C9.36212 21.0208 9.49977 21.0856 9.61652 21.1663C9.73326 21.2469 9.82386 21.3538 9.89182 21.4871C9.95803 21.6203 9.99288 21.7904 9.99288 21.9972C9.99288 22.3969 9.86394 22.7037 9.60432 22.9158C9.3447 23.1279 8.98227 23.2331 8.51705 23.2331C8.28356 23.2331 8.05008 23.2016 7.81311 23.1367C7.57614 23.0718 7.38447 22.9877 7.23811 22.8808L7.19629 22.2234H7.21197C7.38273 22.3689 7.58833 22.4828 7.82879 22.5652C8.06924 22.6476 8.3097 22.6897 8.55364 22.6897C9.07114 22.6897 9.32902 22.5109 9.32902 22.1515C9.32902 22.0235 9.2994 21.9201 9.2419 21.8412C9.1844 21.7623 9.08856 21.6957 8.95962 21.6413C8.82894 21.5888 8.65993 21.5344 8.45083 21.4783C8.28705 21.4345 8.13023 21.3854 7.98386 21.3328C7.83576 21.2802 7.70508 21.2136 7.58833 21.1312C7.47159 21.0488 7.38099 20.9419 7.31303 20.8104C7.24682 20.6789 7.21197 20.5124 7.21197 20.309H7.20674Z'
        fill='white'
      />
      <path
        d='M10.502 23.1596V19.2292H13.0198V19.799H11.1902V20.8491H12.6347V21.3978H11.1902V22.5916H13.0198V23.1613H10.502V23.1596Z'
        fill='white'
      />
      <path
        d='M14.0632 21.1943C14.0632 21.4906 14.1173 21.75 14.2253 21.9692C14.3333 22.1901 14.4884 22.3601 14.6905 22.4811C14.8926 22.602 15.1366 22.6616 15.4206 22.6616C15.7046 22.6616 15.9538 22.6178 16.1454 22.5301C16.3371 22.4425 16.5044 22.3268 16.649 22.1865H16.6647L16.5932 22.8737C16.4416 22.9772 16.2639 23.0631 16.0583 23.1297C15.8527 23.1963 15.6123 23.2314 15.3387 23.2314C15.0303 23.2314 14.7532 23.1788 14.5093 23.0736C14.2636 22.9684 14.0545 22.8229 13.882 22.6388C13.7095 22.4548 13.5771 22.2374 13.4865 21.9902C13.3959 21.7413 13.3506 21.4766 13.3506 21.1943C13.3506 20.9121 13.3959 20.6421 13.4865 20.3949C13.5771 20.1477 13.7095 19.9321 13.882 19.7481C14.0545 19.564 14.2636 19.4185 14.5058 19.3133C14.7498 19.2081 15.0233 19.1555 15.3282 19.1555C15.6018 19.1555 15.8388 19.1836 16.0374 19.2414C16.236 19.2993 16.4051 19.3799 16.5462 19.4868L16.6176 20.1898H16.6019C16.4573 20.0478 16.2883 19.9356 16.0932 19.8515C15.898 19.7673 15.6698 19.7253 15.4101 19.7253C15.1261 19.7253 14.8839 19.7849 14.6835 19.9058C14.4832 20.0268 14.3298 20.1968 14.2235 20.4177C14.1173 20.6386 14.065 20.8963 14.065 21.1926L14.0632 21.1943Z'
        fill='white'
      />
      <path
        d='M18.8391 22.6424C19.1929 22.6424 19.449 22.5653 19.6093 22.4128C19.7696 22.2585 19.8515 21.9991 19.8515 21.6292V19.2292H20.4265V21.6642C20.4265 22.2165 20.2888 22.6144 20.0118 22.8616C19.7365 23.1088 19.334 23.2315 18.806 23.2315C18.2781 23.2315 17.8738 23.1088 17.5829 22.8651C17.2919 22.6197 17.1455 22.22 17.1455 21.666V19.231H17.8338V21.6152C17.8338 21.9833 17.9122 22.248 18.0673 22.4058C18.2223 22.5636 18.4785 22.6442 18.8357 22.6442L18.8391 22.6424Z'
        fill='white'
      />
      <path
        d='M21.1006 23.1596V19.2292H22.7507C23.0068 19.2292 23.2298 19.2801 23.4163 19.3835C23.6027 19.4869 23.7456 19.6289 23.8449 19.8095C23.9442 19.9901 23.9948 20.1952 23.9948 20.4231C23.9948 20.6913 23.9216 20.9262 23.7735 21.1261C23.6254 21.3259 23.425 21.4591 23.1723 21.524L24.1742 23.1578H23.3832L22.4788 21.6169H21.7906V23.1578H21.1023L21.1006 23.1596ZM22.566 19.778H21.7888V21.1015H22.566C22.8151 21.1015 22.9963 21.0472 23.1079 20.9385C23.2194 20.8298 23.2751 20.6632 23.2751 20.4389C23.2751 20.2145 23.2194 20.0479 23.1079 19.9392C22.9963 19.8305 22.8151 19.7762 22.566 19.7762V19.778Z'
        fill='white'
      />
      <path
        d='M24.6885 23.1596V19.2292H27.208V19.799H25.3785V20.8491H26.8229V21.3978H25.3785V22.5916H27.208V23.1613H24.6885V23.1596Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_3459_4529'>
        <rect width='34.5' height='30' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

const propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
}

const defaultProps = {
  width: 60,
  height: 40
}

VisaSecurityIcon.propTypes = propTypes
VisaSecurityIcon.defaultProps = defaultProps
export default VisaSecurityIcon
