import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import reservation from './reducers/reservation'
import bookingEngine from './reducers/bookingEngine'
import request from './reducers/request'
import configuration from './reducers/configuration'
import services from './reducers/services'
import unitTypes from './reducers/unitTypes'
import guests from './reducers/guests'
import api from './middleware/api'
import gtm from './middleware/gtm'
import offer from 'Rentlio/state/reducers/offer'
import booking from 'Rentlio/state/reducers/booking'
import basketPersister, { updateBasketFromStorage } from 'Rentlio/state/middleware/basket/index'
import modals from 'Rentlio/state/reducers/modals'
import unitTypeAvailability from 'Rentlio/state/reducers/unitTypeAvailability'
import basket from 'Rentlio/state/reducers/basket'
import paymentDetails from 'Rentlio/state/reducers/paymentDetails'
import paymentMethods from 'Rentlio/state/reducers/paymentMethods'
import animationLoader from 'Rentlio/state/reducers/animationLoader'
import monri from 'Rentlio/state/reducers/monri'
import stripe from 'Rentlio/state/reducers/stripe'
import m2pay from 'Rentlio/state/reducers/m2pay'
import bookingErrors from 'Rentlio/state/reducers/bookingErrors'
import pricePerDay from 'Rentlio/state/reducers/pricePerDay'
import fees from 'Rentlio/state/reducers/fees'
import touristTaxes from 'Rentlio/state/reducers/touristTaxes'
import cookieNotice from 'Rentlio/state/reducers/cookieNotice'
import { isPromoCodeAppliedToAnyRate } from 'Rentlio/state/selectors/request'

export default function configureStore(preloadedState) {
  const rootReducer = combineReducers({
    bookingEngine: bookingEngine,
    request: request,
    configuration: configuration,
    services: services,
    unitTypes: unitTypes,
    basket: basket,
    unitTypeAvailability: unitTypeAvailability,
    reservation: reservation,
    paymentDetails,
    paymentMethods,
    modals,
    animationLoader,
    monri,
    stripe,
    m2pay,
    bookingErrors,
    booking,
    offer,
    pricePerDay,
    fees,
    cookieNotice,
    touristTaxes,
    guests
  })

  let composeEnhancers = compose

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  }

  // add flag is promo code is applied
  const isPromoCodeApplied = isPromoCodeAppliedToAnyRate(preloadedState?.unitTypes)
  const modifiedPreloadedState = {
    ...preloadedState,
    request: { ...preloadedState.request, isPromoCodeAppliedToAnyRate: isPromoCodeApplied }
  }

  const store = createStore(
    rootReducer,
    modifiedPreloadedState,
    composeEnhancers(applyMiddleware(thunkMiddleware, api, basketPersister, gtm))
  )

  updateBasketFromStorage(store)

  return store
}
