import React from 'react'
import { connect } from 'react-redux'
import AmenityFilter from 'Rentlio/screen/layout/components/AmenityFilter'
import { clearAmenityFilters, filterByAmenity, removeFilterByAmenity } from 'Rentlio/state/actions/ui'
import { getAmenities } from 'Rentlio/state/selectors/unitTypes'

const AmenityFilterContainer = props => <AmenityFilter {...props} />

const mapStateToProps = state => ({
  amenities: getAmenities(state),
  selectedAmenities: state.configuration.selectedAmenities
})

const mapDispatchToProps = dispatch => ({
  filterByAmenity: amenityId => dispatch(filterByAmenity(amenityId)),
  removeFilterByAmenity: amenityId => dispatch(removeFilterByAmenity(amenityId)),
  clearAmenityFilters: () => dispatch(clearAmenityFilters())
})

export default connect(mapStateToProps, mapDispatchToProps)(AmenityFilterContainer)
