import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

const Wrapper = styled.div`
  max-width: 1180px;
  height: 100%;
  margin: 0 auto;
  ${props => (props.isRelative ? 'position: relative;' : '')}
  
  @media ${device.laptop} {
    max-width: 900px;
  }
`

export default Wrapper
