import { trans } from 'Rentlio/helper/translation'

export function minLengthConstraint(value, minLengthValue) {
  if (typeof minLengthValue === 'undefined' || minLengthValue === null || minLengthValue < 1) {
    throw new Error('Badly defined minLengthValue')
  }

  if (typeof value !== 'string' || value === null) {
    return null
  }

  return value.length < minLengthValue ? trans('MinLengthValidationError', { '%minLength%': minLengthValue }) : null
}
