import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { DayPickerRangeController } from 'react-dates'
import { START_DATE } from 'react-dates/constants'
import {
  CalendarButtonClear,
  CalendarButtons,
  CalendarButtonSave,
  CalendarInnerWrapper,
  CalendarOuterWrapper,
  ChooseAnotherDate,
  Policies,
  RoomNotAvailableLabel,
  SuggestedDates,
  SuggestedDatesItem,
  UnavailableWrapper,
  CalendarFooter,
  OnlyCheckInOutText
} from './style'
import { getSuggestedDates } from 'Rentlio/screen/accomodation/utils/suggestedDates'
import {
  isDateRangeAvailable,
  shouldBlockDay,
  isCheckOutOnlyDay,
  getPreviousMonth
} from 'Rentlio/screen/accomodation/utils/datesAvailability'
import ArrowLeft from 'Rentlio/components/Icons/arrowLeft'
import ArrowRight from 'Rentlio/components/Icons/arrowRight'
import SmallText from 'Rentlio/components/UI/smallText'
import { SUGGESTED_DATE_FORMAT } from 'Rentlio/utils/dateFormatEnum'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import { breakpoint } from 'Rentlio/style/responsive'

class Unavailable extends React.Component {
  static contextType = LanguageContext
  ref = React.createRef()
  calendarWrapperRef = React.createRef()
  io = null

  state = {
    focusedInput: null,
    startDate: moment.unix(this.props.from),
    endDate: moment.unix(this.props.to),
    showCalendar: false,
    unblockedDays: [],
    checkOutOnlyText: '',
    initialAvailabilityFetched: false
  }

  componentDidMount() {
    // eslint-disable-next-line no-undef
    this.io = new IntersectionObserver(entries => {
      entries.map(entry => {
        // If unavailable is in viewport and its not already loaded
        if (entry.isIntersecting && entry.target === this.ref.current && !this.state.initialAvailabilityFetched) {
          this.fetchAvailabilityForMonth(this.state.startDate)
          this.setState({ initialAvailabilityFetched: true })
        }
      })
    })
    this.io && this.io.observe(this.ref.current)
  }

  componentWillUnmount() {
    this.io && this.io.unobserve(this.ref.current)
    this.io && this.io.disconnect()
  }

  componentDidUpdate(prevProps) {
    // we need to update this component if from & to are updated from Search
    if (prevProps.from !== this.props.from || prevProps.to !== this.props.to) {
      this.setState({
        startDate: moment.unix(this.props.from),
        endDate: moment.unix(this.props.to),
        initialAvailabilityFetched: false
      })
    }
    if (prevProps.unitTypeAvailability !== this.props.unitTypeAvailability) {
      this.getUnblockedDays()
    }
  }

  onOutsideClick = event => {
    const { showCalendar } = this.state
    const calendarWrapperNode = this.calendarWrapperRef.current

    if (calendarWrapperNode && !calendarWrapperNode.contains(event.target) && showCalendar) {
      this.closeCalendar()
    }
  }

  onDatesChange = (startDate, endDate, unitTypeAvailability) => {
    if (!startDate && !endDate) {
      this.setState({ checkOutOnlyText: '' })
      this.getUnblockedDays()
      return
    }
    if (!endDate) {
      this.getUnblockedDays(startDate)
      this.setcheckOutOnlyText(startDate, endDate, unitTypeAvailability)
      return this.setState({ startDate: startDate, endDate: null })
    }

    const isRangeAvailable = isDateRangeAvailable(startDate, endDate, unitTypeAvailability)
    if (isRangeAvailable) {
      this.setcheckOutOnlyText(startDate, endDate, unitTypeAvailability)
      this.setState({ startDate, endDate }, () => this.getUnblockedDays())
    }
  }

  setcheckOutOnlyText = (startDate, endDate, unitTypeAvailability) => {
    const { translate } = this.context

    let checkOutOnlyText = ''
    if (startDate && !endDate && isCheckOutOnlyDay(startDate, unitTypeAvailability)) {
      checkOutOnlyText = `${startDate.format('ll')} ${translate('is check out day only')}`
    }
    if (endDate && startDate && isCheckOutOnlyDay(endDate, unitTypeAvailability)) {
      checkOutOnlyText = `${endDate.format('ll')} ${translate('is check out day only')}`
    }

    this.setState({ checkOutOnlyText: checkOutOnlyText })
  }

  onFocusChange = focusedInput => {
    if (focusedInput === null) {
      focusedInput = START_DATE
    }
    this.setState({ focusedInput: focusedInput })
  }

  closeCalendar = () => this.setState({ showCalendar: false })
  openCalendar = () => {
    const top = this.ref.current.getBoundingClientRect().top
    if (window.innerWidth <= breakpoint.tablet && top > 180) {
      this.ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    this.setState({ showCalendar: true, focusedInput: START_DATE })
  }

  clearCalendar = () => {
    this.setState(
      {
        startDate: moment.unix(this.props.from),
        endDate: moment.unix(this.props.to),
        checkOutOnlyText: ''
      },
      () => this.getUnblockedDays()
    )
  }

  submitCalendar = () => {
    const { updateDates } = this.props
    const { startDate, endDate } = this.state

    updateDates(startDate, endDate)
    this.closeCalendar()
  }

  renderCalendarInfo = () => {
    const { checkOutOnlyText } = this.state
    const { translate } = this.context

    return (
      <CalendarFooter>
        {checkOutOnlyText && checkOutOnlyText !== '' && <OnlyCheckInOutText> {checkOutOnlyText} </OnlyCheckInOutText>}
        <CalendarButtons>
          <CalendarButtonClear onClick={this.clearCalendar}>{translate('Clear')}</CalendarButtonClear>
          <CalendarButtonSave onClick={this.submitCalendar}>{translate('Save')}</CalendarButtonSave>
        </CalendarButtons>
      </CalendarFooter>
    )
  }

  selectSuggestedDate = (startDate, endDate) => {
    const { updateDates } = this.props
    updateDates(startDate, endDate)
  }

  fetchAvailabilityForMonth = currentDate => {
    const { fetchAvailabilityForMonth, unitTypeUuid } = this.props

    // moment.js returns months in zero indexed system
    const month = currentDate.month() + 1
    const year = currentDate.year()
    const todayMonth = moment().month() + 1
    const todayYear = moment().year()
    const { month: monthBeforeNow, year: yearOfMonthBefore } = getPreviousMonth(todayYear, todayMonth)

    // we are allowing fetching month before, but not two
    if ((month < monthBeforeNow && year === yearOfMonthBefore) || year < yearOfMonthBefore) {
      return
    }

    fetchAvailabilityForMonth(unitTypeUuid, year, month)
  }

  getUnblockedDays = startDate => {
    const { unitTypeAvailability, unitTypeId } = this.props
    const minDate =
      startDate ||
      moment()
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
    const date = minDate.clone()
    const date2YearsLater = minDate.clone().add(2, 'years')
    const newUnblockedDays = {}
    while (date.isSameOrBefore(date2YearsLater)) {
      if (startDate && this.isDayBlocked(date)) {
        break
      }
      if (startDate && isCheckOutOnlyDay(date, unitTypeAvailability[unitTypeId])) {
        newUnblockedDays[date.format('DD-MM-YYYY')] = true
        break
      }
      if (!this.isDayBlocked(date)) {
        newUnblockedDays[date.format('DD-MM-YYYY')] = true
      }
      date.add(1, 'days')
    }
    this.setState({ unblockedDays: newUnblockedDays })
  }

  isDayBlocked = date => {
    const dateNow = moment()
    if (date.isBefore(dateNow) && date.format('DD.MM.YYYY') !== dateNow.format('DD.MM.YYYY')) {
      return false
    }

    const { unitTypeAvailability, unitTypeId } = this.props

    try {
      // moment.js returns month starting from 0
      return shouldBlockDay(unitTypeAvailability[unitTypeId], date)
    } catch (e) {
      // We want to catch only type error, not everthing else
      if (!(e instanceof TypeError)) {
        throw e
      }
    }

    return true
  }

  render() {
    const { showCalendar, startDate, endDate, focusedInput, unblockedDays } = this.state
    const { unitTypeAvailability, unitTypeId, showModalHandler } = this.props

    // don't use .utc() here, otherwise date picker will have timezone bugs
    const minDate = moment()
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)

    startDate !== null && startDate.locale(moment.locale())
    endDate !== null && endDate.locale(moment.locale())

    const suggestedDates = getSuggestedDates(unitTypeId, unitTypeAvailability, startDate, endDate)

    const { translate } = this.context
    return (
      <UnavailableWrapper ref={this.ref}>
        <RoomNotAvailableLabel>{translate('Unit not available')}</RoomNotAvailableLabel>
        {suggestedDates.length > 0 && <SmallText>{translate('See similar dates')}</SmallText>}
        <SuggestedDates>
          {suggestedDates.map((range, index) => (
            <SuggestedDatesItem key={index} onClick={() => this.selectSuggestedDate(range.start, range.end)}>
              {range.start.format(SUGGESTED_DATE_FORMAT)} - {range.end.format(SUGGESTED_DATE_FORMAT)}
            </SuggestedDatesItem>
          ))}
        </SuggestedDates>
        <ChooseAnotherDate onClick={() => this.openCalendar()}>{translate('Choose another date')}</ChooseAnotherDate>
        <CalendarOuterWrapper>
          <CalendarInnerWrapper ref={this.calendarWrapperRef} id={unitTypeId}>
            {showCalendar && (
              <DayPickerRangeController
                startDate={startDate}
                endDate={endDate}
                focusedInput={focusedInput}
                onDatesChange={({ startDate, endDate }) =>
                  this.onDatesChange(startDate, endDate, unitTypeAvailability[unitTypeId])
                }
                onFocusChange={this.onFocusChange}
                onOutsideClick={this.onOutsideClick}
                numberOfMonths={1}
                hideKeyboardShortcutsPanel={true}
                isOutsideRange={day => day.isBefore(minDate)}
                daySize={40}
                navPrev={<ArrowLeft stroke='#333333' />}
                navNext={<ArrowRight stroke='#333333' />}
                renderCalendarInfo={this.renderCalendarInfo}
                onPrevMonthClick={this.fetchAvailabilityForMonth}
                onNextMonthClick={this.fetchAvailabilityForMonth}
                isDayBlocked={day => !unblockedDays[day.format('DD-MM-YYYY')]}
                isDayHighlighted={day => isCheckOutOnlyDay(day, unitTypeAvailability[unitTypeId])}
              />
            )}
          </CalendarInnerWrapper>
        </CalendarOuterWrapper>
        <Policies onClick={showModalHandler}>{translate('Policies')}</Policies>
      </UnavailableWrapper>
    )
  }
}

Unavailable.propTypes = {
  from: PropTypes.number,
  to: PropTypes.number,
  unitTypeId: PropTypes.number,
  unitTypeUuid: PropTypes.string,
  unitTypeAvailability: PropTypes.object,
  updateDates: PropTypes.func,
  fetchAvailabilityForMonth: PropTypes.func,
  showModalHandler: PropTypes.func
}

export default Unavailable
