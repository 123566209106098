export default function getBaseUri() {
  return window.location.origin
}

export function setQueryParameters(addedParams, title) {
  const url = new URL(window.location.pathname, getBaseUri())
  const currentQuery = new URLSearchParams(window.location.search)

  const currentParams = {}
  currentQuery.forEach((value, key) => (currentParams[key] = value))

  const newParams = { ...currentParams, ...addedParams }

  Object.keys(newParams).map(key => url.searchParams.append(key, newParams[key]))
  Object.keys(newParams)
    .filter(key => newParams[key] === null)
    .map(key => url.searchParams.delete(key))

  window.history.pushState(newParams, title || '', url.search)

  return newParams
}
