import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const PoweredByRentlioWrapper = styled.a`
  color: ${props => props.theme.colors.poweredByRentlioColor};
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  font-weight: bold;
  text-decoration: none;

  svg {
    margin-right: 5px;
  }

  span {
    margin-right: 10px;
  }

  img {
    width: 106px;
    margin-bottom: 5px;
  }

  @media ${device.mobile} {
    order: 2;
  }
`
