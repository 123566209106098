import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const InformationWrapper = styled.div`
  margin-left: 50px;
  margin-top: 35px;
  width: 400px;

  @media ${device.tablet} {
    width: 100%;
    max-width: 400px;
    margin-left: 0;
    margin-top: 0;
    text-align: center;
  }

  @media ${device.mobile} {
    padding-left: 30px;
    padding-right: 30px;
  }
`

export const Name = styled.h2`
  margin-top: 25px;
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._24};
  line-height: ${props => props.theme.fonts._28};

  @media ${device.tablet} {
    display: none;
  }
`

export const Description = styled.div`
  margin-top: 10px;
  margin-bottom: 40px;
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._24};
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;

  @media ${device.tablet} {
    text-align: left;
  }
`

export const MoreDetails = styled.a`
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  color: ${props => props.theme.colors.secondary};
  position: relative;
  cursor: pointer;
  transition: all ${({ theme }) => theme.animations.defaultTransition};

  :after {
    transition: all ${({ theme }) => theme.animations.defaultTransition};
    content: ' ';
    background: ${props => props.theme.colors.secondary};
    height: 2px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 100%;
    bottom: -5px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.vividOrange};

    &:after {
      background: ${props => props.theme.colors.vividOrange};
    }
  }
`
