import moment from 'moment'
import { modals } from 'Rentlio/screen/layout/utils/modalsEnum'
import {
  REMOVE_ITEM_FROM_BASKET,
  CLEAR_BASKET,
  UPDATE_SERVICE_QUANTITY,
  UPDATE_PAYMENT_INFO,
  SELECT_LOCALE,
  SELECT_CURRENCY,
  OPEN_MODAL,
  CLOSE_MODAL,
  LOADING_DONE,
  SET_PROMO_CODE,
  CLEAR_PROMO_CODE,
  FILTER_BY_AMENITY,
  REMOVE_FILTER_BY_AMENITY,
  CLEAR_AMENITY_FILTERS,
  FILTER_BY_LOCATION,
  OPEN_UNIT_TYPE_MODAL,
  OPEN_PACKAGE_DEAL_MODAL,
  SEND_SUCCESSFUL_RESERVATION_EVENT,
  SEND_PAYMENT_FORM_INPUT_EVENT,
  ON_OCCUPANCY_CHANGE,
  PRICE_PER_DAY_RESERVATION_CHANGE,
  ACTIVATE_COOKIE_NOTICE,
  ACCEPT_COOKIE_NOTICE
} from 'Rentlio/state/types'
import { fetchAvailability, fetchReservation } from 'Rentlio/state/actions/api'
import { DATE_URL_FORMAT } from 'Rentlio/utils/dateFormatEnum'
import { setQueryParameters } from 'Rentlio/utils/url'

const getAvailabilityParams = state => {
  const { from, to, adults, children, rooms, unitTypeId, promoCode } = state.request
  const params = {
    from:
      from !== null
        ? moment
            .unix(from)
            .utc()
            .format(DATE_URL_FORMAT)
        : null,
    to:
      to !== null
        ? moment
            .unix(to)
            .utc()
            .format(DATE_URL_FORMAT)
        : null,
    adults: adults !== null ? adults : null,
    children: children !== null ? children : null,
    rooms: rooms !== null ? rooms : null,
    promoCode
  }

  if (unitTypeId !== null) {
    params.unitTypeId = unitTypeId
  }

  return params
}

const getAvailabilityParamsFromOffer = (state, locale) => {
  const { arrivalDate, departureDate, adults } = state

  const params = {
    from:
      arrivalDate !== null
        ? moment
            .unix(arrivalDate)
            .utc()
            .format(DATE_URL_FORMAT)
        : null,
    to:
      departureDate !== null
        ? moment
            .unix(departureDate)
            .utc()
            .format(DATE_URL_FORMAT)
        : null,
    adults: adults !== null ? adults : null,
    children: [],
    rooms: 1,
    language: locale
  }

  return params
}

export const updateServiceQuantity = (basketId, serviceId, newQuantity, isMandatoryQuantityUpdate) => ({
  type: UPDATE_SERVICE_QUANTITY,
  basketId,
  serviceId,
  newQuantity,
  isMandatoryQuantityUpdate
})

export const removeItemFromBasket = basketId => ({
  type: REMOVE_ITEM_FROM_BASKET,
  basketId
})

export const clearBasket = () => ({
  type: CLEAR_BASKET
})

export const updatePaymentInfo = paymentDetails => ({
  type: UPDATE_PAYMENT_INFO,
  paymentDetails
})

export const clearPromoCode = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_PROMO_CODE })
  const params = getAvailabilityParams(getState())

  setQueryParameters(params)
  dispatch(fetchAvailability(params))
}

export const setPromoCode = promoCode => (dispatch, getState) => {
  dispatch({ type: SET_PROMO_CODE, promoCode })

  const params = getAvailabilityParams(getState())
  setQueryParameters(params)
  dispatch(fetchAvailability(params))
}

export const selectLocale = (locale, isCheckIn) => (dispatch, getState) => {
  moment.locale(locale)
  dispatch({
    type: SELECT_LOCALE,
    locale
  })

  const params = getAvailabilityParams(getState())
  const {
    reservation,
    booking: { reservations }
  } = getState()

  if (
    params.from !== null &&
    params.from !== 'Invalid date' &&
    params.to !== null &&
    params.to !== 'Invalid date' &&
    !isCheckIn
  ) {
    dispatch(fetchAvailability(params))
  } else if (reservations && reservations.length > 0) {
    const offerParams = getAvailabilityParamsFromOffer(reservations[0], locale)
    dispatch(fetchAvailability(offerParams))
  }

  if (Object.keys(reservation).length !== 0 && !isCheckIn) {
    dispatch(fetchReservation(reservation.id))
  }
}

export const selectCurrency = currency => dispatch => {
  dispatch({ type: SELECT_CURRENCY, currency })
}

export const openTermsOfServiceModal = () => ({
  type: OPEN_MODAL,
  modalName: modals.TERMS_AND_CONDITIONS
})

export const openPrivacyPolicyModal = () => ({
  type: OPEN_MODAL,
  modalName: modals.PRIVACY_POLICY
})

export const openCancellationPolicyModal = () => ({
  type: OPEN_MODAL,
  modalName: modals.CANCELLATION_POLICY
})

export const openM2Pay3DSModal = data => ({
  type: OPEN_MODAL,
  modalName: modals.M2PAY_3DS,
  data: data
})

export const closeModal = () => ({
  type: CLOSE_MODAL
})

export const stopLoading = () => ({
  type: LOADING_DONE
})

export const filterByAmenity = amenityId => ({
  type: FILTER_BY_AMENITY,
  amenityId
})

export const filterByLocation = city => ({ type: FILTER_BY_LOCATION, city })

export const removeFilterByAmenity = amenityId => ({
  type: REMOVE_FILTER_BY_AMENITY,
  amenityId
})

export const clearAmenityFilters = () => ({ type: CLEAR_AMENITY_FILTERS })

// This action shouldnt cause any side effect in redux
// we are just dispatching it so we can send equivalent GTM data layer events
export const openUnitTypeModal = data => ({ type: OPEN_UNIT_TYPE_MODAL, payload: data })

// This action shouldnt cause any side effect in redux
// we are just dispatching it so we can send equivalent GTM data layer events
export const openPackageDealModal = data => ({ type: OPEN_PACKAGE_DEAL_MODAL, payload: data })

// Used for GTM and other possible events that need to be sent
export const sendSuccessfulReservationEvent = (internalId, guestDetails) => ({
  type: SEND_SUCCESSFUL_RESERVATION_EVENT,
  guestDetails,
  internalId
})

// Used for GTM and other possible events that need to be sent
export const sendPaymentFormInputEvent = () => ({ type: SEND_PAYMENT_FORM_INPUT_EVENT })

export const onOccupancyChange = (adults, children) => ({
  type: ON_OCCUPANCY_CHANGE,
  adults,
  children
})

export const setPricePerDayReservationIndex = index => ({
  type: PRICE_PER_DAY_RESERVATION_CHANGE,
  index
})

export const activateCookieNotice = isActive => ({
  type: ACTIVATE_COOKIE_NOTICE,
  isActive
})

export const acceptCookieNotice = () => ({
  type: ACCEPT_COOKIE_NOTICE
})
