import React from 'react'
import PropTypes from 'prop-types'

const Visa = ({ width, height }) => (
  <svg width={width} height={height} viewBox='0 0 50 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M18.6922 0.282803L12.2469 15.758H8.04175L4.86997 3.40808C4.67741 2.6474 4.50998 2.36873 3.92434 2.04825C2.96819 1.52626 1.38922 1.03651 0 0.732551L0.0943583 0.282803H6.8633C7.72611 0.282803 8.50175 0.860792 8.69765 1.86066L10.3728 10.8154L14.5127 0.282545H18.6922V0.282803ZM35.1685 10.7055C35.1855 6.62108 29.5563 6.39608 29.595 4.57154C29.607 4.01626 30.1324 3.42588 31.2824 3.27519C31.8524 3.2001 33.4229 3.14282 35.2042 3.96775L35.9029 0.686104C34.9457 0.336472 33.7142 0 32.1819 0C28.2498 0 25.4827 2.10347 25.4594 5.11547C25.434 7.34331 27.4345 8.5865 28.9416 9.3268C30.4921 10.0849 31.0124 10.5721 31.0065 11.2502C30.9955 12.2882 29.7698 12.7465 28.6245 12.7645C26.625 12.7955 25.4647 12.2201 24.5399 11.7874L23.8188 15.1777C24.7483 15.6068 26.464 15.9812 28.2427 16C32.4219 16 35.1557 13.9226 35.1685 10.7055ZM45.5516 15.758H49.2308L46.0192 0.282803H42.6234C41.8598 0.282803 41.2157 0.730229 40.9306 1.41814L34.9611 15.758H39.1383L39.9675 13.4465H45.0713L45.5516 15.758ZM41.1129 10.2748L43.2067 4.46446L44.4118 10.2748H41.1129ZM24.376 0.282803L21.0866 15.758H17.1086L20.3994 0.282803H24.376Z'
      fill='#1434CB'
    />
  </svg>
)

const propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
}

const defaultProps = {
  width: 32,
  height: 21
}

Visa.propTypes = propTypes
Visa.defaultProps = defaultProps
export default Visa
