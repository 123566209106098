import fetch from 'isomorphic-fetch'
import 'es6-promise/auto'

// eslint-disable-next-line import/exports-last
export default (url, method, body) => {
  const options = {
    method,
    headers: requestHeaders(),
    credentials: 'include',
    body: method !== 'GET' ? JSON.stringify(body) : null
  }

  return fetch(url, options).then(res => parseStatus(res.status, res))
}

function parseStatus(status, res) {
  res = res.json()

  return new Promise((resolve, reject) => {
    if (status >= 200 && status < 300) {
      res.then(response => resolve({ success: true, status, response }))
    } else {
      // eslint-disable-next-line prefer-promise-reject-errors
      res.then(response => reject({ success: false, status, response }))
    }
  })
}

function requestHeaders() {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
}
