import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const NightPickerWrapper = styled.div`
  // Safari browser 10.1 alignment fix
  display: flex;
  align-items: center;

  @media ${device.laptop} {
    width: 100%;
    margin-left: 2rem;
  }

  @media ${device.tablet} {
    margin-left: 0;
    margin-bottom: 24px;
    order: 1;
  }
`

export const NightPickerInputWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;

  @media ${device.tablet} {
    width: 100%;
    justify-content: space-around;
  }
`

export const NightPickerCalendarWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 100px;
  z-index: ${props => props.theme.zIndex.calendar};
  box-shadow: 0px 18px 18px rgba(0, 0, 0, 0.15);
  border-radius: 0px 0px 8px 8px;
  overflow: hidden;
  width: 100%;
  background-color: white;

  /* &::after {
    content: '';
    position: absolute;
    top: 20px;
    width: 92%;
    height: 1px;
    margin: 0 40px;
    background-color: rgba(51, 51, 51, 0.15);
  } */
  .DayPicker {
    padding-top: 40px;
  }

  @media ${device.tablet} {
    border-radius: 8px;
    left: 50%;
    transform: translateX(-50%);
    top: ${({ isGroup }) => (isGroup ? '80px' : '140px')};
  }
`

export const DateRow = styled.div`
  white-space: nowrap;
  @media ${device.tablet} {
    display: flex;
    width: ${({ isGroup }) => (isGroup ? '100%' : 'auto')};
    flex-direction: ${({ isGroup }) => (isGroup ? 'row' : 'column')};
    align-items: center;
    justify-content: ${({ isGroup }) => (isGroup ? 'center' : 'flex-start')};
  }
`

export const Label = styled.div`
  text-transform: uppercase;
  color: ${props => props.theme.colors.semiTransparentBlackText};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  margin-bottom: 10px;

  @media ${device.laptop} {
    text-align: ${({ isGroup }) => (isGroup ? 'left' : 'center')};
    margin-bottom: ${({ isGroup }) => (isGroup ? '15px' : '10px')};
  }

  @media ${device.tablet} {
    margin-bottom: ${({ isGroup }) => (isGroup ? '0' : '10px')};
    margin-right: ${({ isGroup }) => (isGroup ? 'auto' : '0')};
  }
`

export const Date = styled.div`
  color: black;
  display: inline-flex;
  align-items: baseline;
  cursor: pointer;

  &.placeholder {
    padding: ${({ isGroup }) => (isGroup ? '0 60px 0 0;' : '34px 60px 0')};

    @media ${device.laptop} {
      padding: ${({ isGroup }) => (isGroup ? '22px 29px 0' : '61px 33px 0')};
    }

    @media ${device.tablet} {
      padding: 58px 20px 0;
      padding: ${({ isGroup }) => (isGroup ? '27px 26px 0' : '58px 20px 0')};
    }
  }

  @media ${device.laptop} {
    flex-direction: ${({ isGroup }) => (isGroup ? 'row' : 'column')};
    align-items: ${({ isGroup }) => (isGroup ? 'left' : 'center')};
  }

  @media ${device.tablet} {
    flex-direction: ${({ isGroup }) => (isGroup ? 'row' : 'column')};
    align-items: center;
  }
`

export const Day = styled.span`
  font-weight: ${({ isGroup }) => (isGroup ? 500 : 'bold')};
  font-size: ${({ isGroup, theme }) => (isGroup ? theme.fonts._24 : theme.fonts._32)};
  line-height: ${({ isGroup, theme }) => (isGroup ? theme.fonts._28 : theme.fonts._37)};

  @media ${device.tablet} {
    font-weight: normal;
  }
`

export const Month = styled.span`
  margin-left: 8px;
  font-size: ${props => props.theme.fonts._24};
  line-height: ${props => props.theme.fonts._28};
  text-transform: capitalize;

  @media ${device.tablet} {
    margin-left: 0;
    font-size: ${({ isGroup, theme }) => (isGroup ? theme.fonts._24 : theme.fonts._18)};
    line-height: ${({ isGroup, theme }) => (isGroup ? theme.fonts._28 : theme.fonts._21)};
  }
`

export const Year = styled.span`
  font-weight: ${({ isGroup }) => (isGroup ? 500 : 'bold')};
  font-size: ${({ isGroup, theme }) => (isGroup ? theme.fonts._24 : theme.fonts._32)};
  line-height: ${({ isGroup, theme }) => (isGroup ? theme.fonts._28 : theme.fonts._37)};

  @media ${device.laptop} {
    display: none;
  }
`
export const GroupBEDateDivider = styled.div`
  display: inline-flex;
  font-weight: 500;
  font-size: ${props => props.theme.fonts._24};
  line-height: ${props => props.theme.fonts._28};
`

export const ArrowWrapper = styled.div`
  margin: 35px 30px 0;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 35px;
`

export const DayPickerDivider = styled.div`
  display: block;
  position: relative;
  margin: 0 40px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.15);
  transform: translateY(29px);
  z-index: 1;
`
