import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import BankTransferDetails from '../BankTransferDetails'
import { Wrapper, Title, LinkWrapper, LinkText, Text, SavePage, SavePageText } from './style'
import ArrowRight from 'Rentlio/components/Icons/arrowLongRight'
import { Link } from 'Rentlio/components/Icons/link'
import colors from 'Rentlio/style/colors'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import { formatPrice } from 'Rentlio/components/utils/Price/price'

const Links = ({
  isCanceled,
  openTermsOfServiceModal,
  openCancellationPolicyModal,
  isBankTransferMethod,
  prePaymentDetails,
  bankTransferSettings,
  referenceNumber,
  currency
}) => {
  const { translate } = useContext(LanguageContext)
  const [isReservationCancelled, setIsReservationCancelled] = useState(isCanceled)
  const [clipboardNotice, setClipboardNotice] = useState(false)

  useEffect(() => {
    if (isReservationCancelled !== isCanceled) {
      setIsReservationCancelled(isCanceled)
      window.scrollTo(0, 0)
    }
  }, [isCanceled])

  const saveUrlToClipboard = url => {
    try {
      navigator.clipboard.writeText(url)
      setClipboardNotice(true)
    } catch (err) {
      console.error(
        'Error catch: Failed to copy: ',
        err,
        'This requires a secure origin — either HTTPS or localhost (or disabled by running Chrome with a flag). '
      )
    }
  }

  return (
    <Wrapper>
      {!isReservationCancelled && (
        <>
          <Title>{translate('ThankYou')}</Title>
          <Text light>
            {!isBankTransferMethod
              ? translate('Your reservation was successfully completed!')
              : translate('PrePaymentReservationMsg', {
                  '%amount%': formatPrice(prePaymentDetails.prepaymentAmount, currency),
                  '%currency%': prePaymentDetails.prepaymentCurrency ?? currency
                })}
          </Text>
        </>
      )}

      {isReservationCancelled && (
        <>
          <Title>{translate('Reservation cancelled')}</Title>
          <Text>{translate('Cancellation policy applies as stated.')}</Text>
        </>
      )}
      {isBankTransferMethod && (
        <BankTransferDetails
          referenceNumber={referenceNumber}
          prePaymentDetails={prePaymentDetails}
          bankTransferSettings={bankTransferSettings}
          currency={currency}
        />
      )}
      <LinkWrapper margin={'40px 0 20px'}>
        <ArrowRight stroke={colors.secondary} width={8} height={6} />
        <LinkText onClick={openTermsOfServiceModal}>{translate('Property policy')}</LinkText>
      </LinkWrapper>
      <LinkWrapper margin={'0 0 45px'}>
        <ArrowRight stroke={colors.secondary} width={8} height={6} />
        <LinkText onClick={openCancellationPolicyModal}>{translate('Cancellation policy')}</LinkText>
      </LinkWrapper>
      <Text small>{translate('Save this page so you can come back another time.')}</Text>
      <SavePage onClick={() => saveUrlToClipboard(window.href)}>
        <SavePageText>{window.location.href}</SavePageText>
        <Link />
      </SavePage>
      {clipboardNotice && <Text small>{translate('Page saved to clipboard')} </Text>}
    </Wrapper>
  )
}

Links.propTypes = {
  isCanceled: PropTypes.bool,
  openTermsOfServiceModal: PropTypes.func,
  openCancellationPolicyModal: PropTypes.func,
  isBankTransferMethod: PropTypes.bool,
  prePaymentDetails: PropTypes.object,
  bankTransferSettings: PropTypes.object,
  referenceNumber: PropTypes.string,
  currency: PropTypes.string
}

export default Links
