import styled, { css } from 'styled-components'

export const Input = styled.input`
  border-radius: 10px;
  padding: 21px 15px;
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  border: 1px solid ${props => props.theme.colors.border};
  width: 100%;
  transition: all ${({ theme }) => theme.animations.defaultTransition};
  ${({ inActive, theme }) =>
    inActive
      ? css`
          color: ${({ theme }) => theme.colors.veryDarkGray};
          font-size: ${({ theme }) => theme.fonts._18};
          font-weight: ${({ theme }) => theme.fonts.mediumWeight};
          background-color: ${theme.colors.white};
          pointer-events: none;
        `
      : null}

  &:focus {
    border: 1px solid ${props => props.theme.colors.secondary};
    outline: none;
    box-shadow: 0px 5px 10px rgba(221, 164, 29, 0.2);
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.textColor};
    opacity: 0.5;
  }
`
