import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const Title = styled.h2`
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._24};
  font-size: ${props => props.theme.fonts._28};
  color: ${props => props.theme.colors.textColor};
`

export const RateName = styled.div`
  margin-bottom: 20px;
  margin-top: 30px;
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._18};
  font-size: ${props => props.theme.fonts._22};
  color: ${props => props.theme.colors.textColor};
`

export const Paragraph = styled.div`
  color: ${props => props.theme.colors.labelTextColor};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._24};
`

export const PreviewWrapper = styled.div`
  width: 100%;
  min-height: 400px;

  @media ${device.tablet} {
    width: 100%;
    min-height: 400px;
    scale: 0.7;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const PreviewIframe = styled.iframe`
  border: none;
  animation: ${({ theme }) => theme.animations.fadeInOut} 0.4s ease-in-out;

  @media ${device.tablet} {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: -40px;
  }
`
