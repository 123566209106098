import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const DefaultButton = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'blocked' : 'pointer')};
  border-radius: 10px;
  padding: ${({ isLarge }) => (isLarge ? '17px 40px' : '12px 15px')};
  margin-bottom: 15px;
  min-width: 180px;
  width: 100%;
  background: ${props => props.theme.colors.normalButtonBackground};
  color: ${props => props.theme.colors.normalButtonText};
  font-weight: bold;
  font-size: ${({ theme, isLarge }) => (isLarge ? theme.fonts._18 : theme.fonts._14)};
  line-height: ${({ theme, isLarge }) => (isLarge ? theme.fonts._21 : theme.fonts._16)};
  text-transform: uppercase;
  text-align: center;
  user-select: none;
  transition: all ${({ theme }) => theme.animations.defaultTransition};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  &:hover {
    background: ${({ theme }) => theme.colors.veryDarkGray2};
  }
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`

export const DefaultWhiteButton = styled(DefaultButton)`
  background: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.textColor};

  &:hover {
    background: ${({ theme }) => theme.colors.vividOrange};
  }
`

export const SecondaryButton = styled(DefaultButton)`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  background: ${props => props.theme.colors.secondary};
  margin: 0;

  &:hover {
    background: ${({ theme }) => theme.colors.vividOrange};
  }
`

export const CancelButton = styled.div`
  width: 70%;
  height: 60px;
  padding: 20px 0;
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._24};
  background: ${props => props.theme.colors.alertColor};
  color: ${props => props.theme.colors.normalButtonText};
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  user-select: none;

  @media ${device.laptopAndWider} {
    width: 250px;
    font-size: ${props => props.theme.fonts._18};
    line-height: ${props => props.theme.fonts._21};
  }
`

export const GoldButton = styled(DefaultButton)`
  width: 300px;
  height: 60px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  background: ${props => props.theme.colors.goldButton};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;

  &:hover {
    background: ${({ theme }) => theme.colors.vividOrange};
    cursor: pointer;
  }
`
