import PropTypes from 'prop-types'
import React from 'react'
import { PromoBadge } from '../SingleRatePlan/Promo/style'
import Promo from '../SingleRatePlan/Promo'
import OccupancySelect from '../SingleRatePlan/OccupancySelect'
import { getTotalRate, getAverageRate, getTotalRateBeforeDiscount } from '../SingleRatePlan/helper/getTotalRate'
import RatePlanNote from '../SingleRatePlan/RatePlanNote'
import {
  ActionsWrapper,
  AveragePrice,
  CancellationPolicyWrapper,
  InformationWrapper,
  PerNight,
  PriceDivider,
  PriceRow,
  PackageDealWrapper,
  Title,
  TotalPrice,
  TotalPriceCurrency,
  ErrorMessages,
  UpperPartWrapper,
  TitleWrapper,
  MoreDetails
} from './style'
import PackageDealModal from './components/PackageDealModal'
import { IncludedExtrasWrapper, Label, Value } from 'Rentlio/screen/offer/components/Offer/Reservation/RatePlan/style'
import CancellationPolicy from 'Rentlio/components/UI/CancellationPolicy'
import NotBookable from 'Rentlio/screen/accomodation/component/RatePlans/SingleRatePlan/NotBookable'
import { DefaultWhiteButton } from 'Rentlio/components/UI/button'

import { formatPrice } from 'Rentlio/components/utils/Price/price'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import { CurrenciesContext } from 'Rentlio/context/CurrenciesContext'
import { device } from 'Rentlio/style/responsive'
import colors from 'Rentlio/style/colors'
import Modal from 'Rentlio/components/Modal'
import Animation from 'Rentlio/components/UI/Animation'

// package deal is separated component from SingleRatePlan even though is mostly the same to avoid raising complexity of SingleRatePlan component with introducing many conditional statements
class PackageDeal extends React.PureComponent {
  static contextType = LanguageContext
  wrapperRef = React.createRef()
  upperPartRef = React.createRef()
  mediaMatchQuery = window.matchMedia(device.tablet)

  state = {
    height: null,
    upperPartHeight: null,
    showPackageDealModal: false
  }

  componentDidMount() {
    this.checkMediaWidth(this.mediaMatchQuery)
    // NOTE: Backward compatibility for older version of safari (13 etc. - chrome and firefox are ok here) - don't remove !
    // Please be aware that the MediaQueryList.addListener() method has been deprecated.
    // https://www.w3.org/TR/cssom-view-1/#mediaquerylist
    if (this.mediaMatchQuery?.addEventListener) {
      this.mediaMatchQuery.addEventListener('change', this.checkMediaWidth)
    } else {
      this.mediaMatchQuery.addListener(this.checkMediaWidth)
    }
  }

  componentDidUpdate(prevProps) {
    // when promo code get applied or removed we need to recalculate component height because promo code adds to height
    // we are first setting height to null so auto in css gets applied and then we are setting height in state with this new  recalculated height
    if (
      prevProps.ratePlan &&
      this.props.ratePlan &&
      prevProps.ratePlan.activePromoCode !== this.props.ratePlan.activePromoCode
    ) {
      this.setState({ height: null, upperPartHeight: null }, () => {
        this.setState({
          height: this.wrapperRef.current.clientHeight,
          upperPartHeight: this.upperPartRef.current.clientHeight
        })
      })
    }
  }

  componentWillUnmount() {
    if (this.mediaMatchQuery?.addEventListener) {
      this.mediaMatchQuery.removeEventListener('change', this.checkMediaWidth)
    } else {
      this.mediaMatchQuery.removeListener(this.checkMediaWidth)
    }
  }

  checkMediaWidth = (matchQuery = this.mediaMatchQuery) => {
    if (matchQuery.matches) {
      return this.setState({
        height: null,
        upperPartHeight: null
      })
    }

    // we are setting component height so it does not resize when occupancy expands,
    // and initially in css we do not know height because it depends on text length
    return this.setState({
      height: this.wrapperRef.current.clientHeight,
      upperPartHeight: this.upperPartRef.current.clientHeight
    })
  }

  showPackageDealModal = () => {
    const {
      ratePlan: { ratePlanName, ratePlanId },
      openPackageDealModal
    } = this.props
    // this is used just so we can send GTM event if needed
    openPackageDealModal({ name: ratePlanName, rateId: ratePlanId })
    this.setState({ showPackageDealModal: true })
  }

  hidePackageDealModal = () => {
    this.setState({ showPackageDealModal: false })
    document.body.classList.remove('modal-open')
  }

  onOccupancyChange = (adults, childrenAges) => {
    const { onOccupancyChange } = this.props
    onOccupancyChange(adults, childrenAges)
  }

  getOccupancyErrorMessages = (adults, childrenAges) => {
    const { translate } = this.context
    const { unitTypeOccupancy } = this.props
    const childrens = childrenAges ? childrenAges.length : 0

    let errorMessages = ''
    if ((!adults || adults === 0) && childrenAges.length === 0) {
      errorMessages = errorMessages + translate('Occupancy can not be empty')
    }
    if (adults + childrenAges.length > unitTypeOccupancy.maxOccupancy) {
      errorMessages =
        errorMessages +
        translate('Max occupancy for this unit is maxOccupancy', {
          '%maxOccupancy%': unitTypeOccupancy.maxOccupancy
        })
    }
    if (unitTypeOccupancy.minOccupancy !== null && adults + childrenAges.length < unitTypeOccupancy.minOccupancy) {
      errorMessages =
        errorMessages +
        translate('Min occupancy for this unit is minOccupancy', {
          '%minOccupancy%': unitTypeOccupancy.minOccupancy
        })
    }
    if (unitTypeOccupancy.maxOccupancyAdults !== null && adults > unitTypeOccupancy.maxOccupancyAdults) {
      errorMessages =
        errorMessages +
        translate('Max occupancy for adults is maxOccupancyAdults', {
          '%maxOccupancyAdults%': unitTypeOccupancy.maxOccupancyAdults
        })
    }
    if (unitTypeOccupancy.minOccupancyAdults !== null && adults < unitTypeOccupancy.minOccupancyAdults) {
      errorMessages =
        errorMessages +
        translate('Min occupancy for adults is minOccupancyAdults', {
          '%minOccupancyAdults%': unitTypeOccupancy.minOccupancyAdults
        })
    }
    if (unitTypeOccupancy.maxOccupancyChildren !== null && childrens > unitTypeOccupancy.maxOccupancyChildren) {
      errorMessages =
        errorMessages +
        translate('Max occupancy for children is maxOccupancyChildren', {
          '%maxOccupancyChildren%': unitTypeOccupancy.maxOccupancyChildren
        })
    }
    if (unitTypeOccupancy.minOccupancyChildren !== null && childrens < unitTypeOccupancy.minOccupancyChildren) {
      errorMessages =
        errorMessages +
        translate('Min occupancy for children is minOccupancyChildren', {
          '%minOccupancyChildren%': unitTypeOccupancy.minOccupancyChildren
        })
    }
    // variable to determine if this error is already inside errorMessages, so we do not write it more than once
    let childrenAgesErrorExist = false
    unitTypeOccupancy.maxOccupancyChildren !== 0 &&
      childrenAges.map(childAge => {
        if (
          unitTypeOccupancy.childrenAge !== null &&
          childAge < unitTypeOccupancy.childrenAge &&
          !childrenAgesErrorExist
        ) {
          childrenAgesErrorExist = true
          errorMessages =
            errorMessages +
            translate('This unit does not accept children under childrenAge', {
              '%childrenAge%': unitTypeOccupancy.childrenAge
            })
        }
      })

    return errorMessages
  }

  addItemToBasket = () => {
    const { ratePlan, addItemToBasket, childrenAges, adults } = this.props
    if (this.getOccupancyErrorMessages(adults, childrenAges)) {
      return
    }
    addItemToBasket(ratePlan.unitTypeId, ratePlan.ratePlanId, 1, adults, childrenAges)
  }

  getIncludedExtras = services => {
    if (!services || services.length === 0) {
      return ''
    }

    return services.join(', ')
  }

  render() {
    const { height, upperPartHeight, showPackageDealModal } = this.state
    const { ratePlan, unitTypeCurrency, arrivalDate, adults, childrenAges, isAvailable, unitTypeOccupancy } = this.props
    const { translate } = this.context

    const occupancyError = this.getOccupancyErrorMessages(adults, childrenAges)
    const hasOccupancyError = occupancyError && occupancyError !== ''

    return (
      <CurrenciesContext.Consumer>
        {({ currencyConversion }) => {
          const { value: totalRate, currency: totalCurrency } = currencyConversion(
            getTotalRate(unitTypeOccupancy, ratePlan, adults, childrenAges),
            unitTypeCurrency
          )
          const { value: averageRatePerNight, currency: averageCurrency } = currencyConversion(
            getAverageRate(unitTypeOccupancy, ratePlan, adults, childrenAges),
            unitTypeCurrency
          )
          return (
            <PackageDealWrapper height={height} ref={this.wrapperRef} promote={ratePlan.promote}>
              <UpperPartWrapper height={upperPartHeight} ref={this.upperPartRef}>
                <InformationWrapper>
                  <TitleWrapper>
                    <PromoBadge marginLeft={'0px'}>{translate('Package deal')}</PromoBadge>
                    <MoreDetails onClick={() => this.showPackageDealModal()}>{translate('More details')}</MoreDetails>
                    <Animation show={showPackageDealModal} animationType={'fadeInOut'} isModal>
                      <Modal hideModalHandler={this.hidePackageDealModal} show={showPackageDealModal}>
                        <PackageDealModal
                          hasThumbnails
                          images={ratePlan.images || []}
                          hideModalHandler={this.hidePackageDealModal}
                          description={ratePlan.packageDescription || ''}
                          name={ratePlan.ratePlanName}
                          includedServices={ratePlan.includedServices || []}
                        />
                      </Modal>
                    </Animation>
                  </TitleWrapper>
                  <Title>{ratePlan.ratePlanName}</Title>
                  {ratePlan.activePromoCode !== null && (
                    <Promo
                      ratePlan={ratePlan}
                      unitTypeCurrency={unitTypeCurrency}
                      rateBeforeDiscount={getTotalRateBeforeDiscount(unitTypeOccupancy, ratePlan, adults, childrenAges)}
                    />
                  )}
                  {hasOccupancyError ? (
                    <ErrorMessages>{occupancyError}</ErrorMessages>
                  ) : (
                    <PriceRow>
                      <TotalPrice>{formatPrice(totalRate, totalCurrency)}</TotalPrice>
                      <TotalPriceCurrency>{totalCurrency}</TotalPriceCurrency>
                      <PriceDivider />
                      <AveragePrice>
                        {formatPrice(averageRatePerNight, averageCurrency)} {averageCurrency}
                      </AveragePrice>
                      <PerNight>{translate('per night')}</PerNight>
                    </PriceRow>
                  )}
                  {ratePlan.includedServices && ratePlan.includedServices.length > 0 && (
                    <IncludedExtrasWrapper color={colors.white}>
                      <Label color={colors.white}>{translate('Included')}:</Label>
                      <Value color={colors.white}>{this.getIncludedExtras(ratePlan.includedServices)}</Value>
                    </IncludedExtrasWrapper>
                  )}
                  <CancellationPolicyWrapper>
                    <CancellationPolicy
                      policy={ratePlan.policy}
                      translate={translate}
                      totalRatePrice={totalRate}
                      unitTypeCurrency={totalCurrency}
                      arrivalDate={arrivalDate}
                    />
                  </CancellationPolicyWrapper>
                </InformationWrapper>
                <OccupancySelect
                  adults={adults}
                  childrenAges={childrenAges}
                  onOccupancyChange={this.onOccupancyChange}
                  isDarkStyle
                />
                <ActionsWrapper>
                  {ratePlan.isBookable && isAvailable && (
                    <DefaultWhiteButton onClick={this.addItemToBasket} disabled={hasOccupancyError} isPackageDeal>
                      {translate('Select this unit')}
                    </DefaultWhiteButton>
                  )}
                  {(!ratePlan.isBookable || !isAvailable) && (
                    <NotBookable ratePlan={ratePlan} isAvailable={isAvailable} isPackageDeal />
                  )}
                  {/* <RateInformation>{translate('Rate information')}</RateInformation> */}
                </ActionsWrapper>
              </UpperPartWrapper>
              {ratePlan?.rateNote && <RatePlanNote note={ratePlan.rateNote} promote={ratePlan.promote} isPackageDeal />}
            </PackageDealWrapper>
          )
        }}
      </CurrenciesContext.Consumer>
    )
  }
}

PackageDeal.propTypes = {
  ratePlan: PropTypes.object,
  addItemToBasket: PropTypes.func,
  removeItemFromBasket: PropTypes.func,
  numberOfRooms: PropTypes.number,
  unitTypeCurrency: PropTypes.string,
  arrivalDate: PropTypes.number,
  adults: PropTypes.number,
  childrenAges: PropTypes.array,
  onOccupancyChange: PropTypes.func,
  unitTypeOccupancy: PropTypes.object,
  isAvailable: PropTypes.bool,
  openPackageDealModal: PropTypes.func
}

export default PackageDeal
