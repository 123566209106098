import styled from 'styled-components'

export const StripeErrorMessage = styled.p`
  width: 100%;
  margin-bottom: 50px;
  color: ${({ theme }) => theme.colors.alertColor};
  font-size: ${({ theme }) => theme.fonts._14};
  line-height: ${({ theme }) => theme.fonts._18};
`

export const StripeRowWrapper = styled.div`
  border-radius: 10px;
  padding: 21px 15px;
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  border: 1px solid ${props => props.theme.colors.border};
  width: 100%;
  height: 60px;
`
