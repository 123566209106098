export function getMaxAvailabilityForUnitTypes(state) {
  const { unitTypes, basket, request } = state

  const roomsLeft = request.rooms - basket.length

  // Assumption here is that if we have something in basket - it should be only for that single bookingEngineId
  const bookingEngineId = basket.length > 0 ? basket[0].bookingEngineId : null

  return unitTypes.reduce((accumulator, unitType) => {
    const ratesAvailability = {}
    unitType.rates.map(rate => {
      // If bookingEngineId is set - and is different from current UnitType BE id, set its availability to 0
      if (bookingEngineId !== null && bookingEngineId !== unitType.bookingEngine.id) {
        ratesAvailability[rate.ratePlanId] = 0
        return
      }

      const alreadyInBasket = basket.filter(
        item => item.unitTypeId === unitType.unitTypeId && item.ratePlanId === rate.ratePlanId
      ).length

      const availabilityAfterBasketItemAreRemoved =
        unitType.availableUnits - basket.filter(item => item.unitTypeId === unitType.unitTypeId).length

      ratesAvailability[rate.ratePlanId] = alreadyInBasket + Math.min(roomsLeft, availabilityAfterBasketItemAreRemoved)
    })

    accumulator[unitType.unitTypeId] = ratesAvailability
    return accumulator
  }, {})
}

export function getAmenities(state) {
  const amenitiesHashMap = state.unitTypes.reduce((accumulator, unitType) => {
    unitType.amenities.map(amenity => {
      if (typeof accumulator[amenity.id] === 'undefined') {
        accumulator[amenity.id] = { amenity }
      }
    })

    return accumulator
  }, {})

  return Object.values(amenitiesHashMap).map(({ amenity }) => amenity)
}
