import React from 'react'
import PropTypes from 'prop-types'

import { M2PayErrorMessage, FormLabel, CreditCardRow, FormGroup, FormRow } from './style'
import getCustomM2PayFormStyle from './customM2PayFormStyle'
import M2Pay3DSContainer from './containers/m2Pay3DS'
import { FormError } from 'Rentlio/components/UI/form'
import { Input } from 'Rentlio/components/UI/input'
import Checkbox from 'Rentlio/components/UI/Checkbox'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

class M2PayPayment extends React.PureComponent {
  static contextType = LanguageContext
  m2pay = null

  state = {
    paymentMethodSettings: {},
    m2payErrorMessage: ''
  }

  componentDidMount = () => {
    this.setStateFromProps()
  }

  componentDidUpdate = prevProps => {
    const { paymentMethodSettings } = this.props

    if (prevProps.paymentMethodSettings !== paymentMethodSettings && paymentMethodSettings) {
      this.setState({ m2payErrorMessage: '' })
      !this.m2pay && this.initializeM2PayComponents()
    }
  }

  setStateFromProps = () => {
    const { paymentMethodSettings } = this.props
    this.setState({ paymentMethodSettings: paymentMethodSettings }, () => {
      if (this.state.paymentMethodSettings?.merchantID && !this.form) {
        this.initializeM2PayComponents()
      }
    })
  }

  initializeM2PayComponents = () => {
    const { paymentMethodSettings, locale } = this.props

    this.m2pay = new window.hostedFields.classes.Form(document.forms[0], {
      autoSetup: true,
      autoSubmit: true,
      // Additional fields to tokenise
      tokenise: '.add-to-token',
      stylesheets: '#hostedfield-stylesheet',
      merchantID: paymentMethodSettings?.merchantID,
      // Optional field configuration (by type)
      fields: {
        any: {
          nativeEvents: true
        }
      },
      // Additional CSS classes
      classes: {
        invalid: 'error'
      },
      locale: locale
    })
  }

  onBookPressed = (guestDetails, paymentDetails) => {
    this.setState({ m2payErrorMessage: null })
    const { onM2PayPaymentFailed, openM2Pay3DSModal } = this.props
    const { paymentMethodSettings } = this.state
    const { bookingEngineId, currency } = this.props.receipt

    const data = this.getData()
    const form = document.getElementById('form')
    // form is invalid
    if (form && !form.reportValidity()) {
      return onM2PayPaymentFailed()
    }
    // form is valid
    const $ = global.jQuery
    const $form = $('#form')
    const hostedform = $form.hostedForm('instance')

    hostedform &&
      hostedform.getPaymentDetails(data, true).then(
        // Success validating the form and requesting a payment token
        res => {
          if (res.success) {
            openM2Pay3DSModal({
              merchantId: paymentMethodSettings.merchantID,
              currency: currency,
              webBookingEngineId: bookingEngineId,
              token: res.paymentToken,
              guestDetails: guestDetails,
              paymentDetails: paymentDetails
            })
          } else if (res.invalid) {
            $form.valid()
            onM2PayPaymentFailed()
            this.setState({ m2payErrorMessage: res.invalid })
          } else {
            onM2PayPaymentFailed()
            this.setState({
              m2payErrorMessage: 'There was a problem fetching the payment token. Please seek assistance.'
            })
          }
        }
      )
  }

  getCardHolderName = () => {
    const { cardholderSameAsGuest, cardholderName } = this.props
    const { firstName, lastName, companyName, isCompany } = this.props.guestDetails
    if (!cardholderSameAsGuest) return cardholderName

    if (isCompany) return companyName

    return firstName && lastName ? `${firstName} ${lastName}` : null
  }

  getData = () => {
    const { address, postalCode } = this.props.guestDetails
    const data = {
      customerName: this.getCardHolderName(),
      customerAddress: address,
      customerPostcode: postalCode
    }

    return data
  }

  updateErrorMessage = errorMessage => this.setState({ m2payErrorMessage: errorMessage || '' })

  render() {
    const { translate } = this.context
    const {
      cardholderSameAsGuest,
      handleSameAsGuest,
      cardholderName,
      handleCardHolderName,
      getValidationError,
      handleAddress,
      handlePostalCode,
      handleCity,
      guestDetails,
      paymentMethodSettings,
      callBookReservationApi,
      onM2PayPaymentFailed
    } = this.props
    const { address, postalCode, city } = guestDetails
    const { m2payErrorMessage } = this.state

    return (
      <>
        <M2Pay3DSContainer
          callBookReservationApi={callBookReservationApi}
          updateErrorMessage={this.updateErrorMessage}
          onM2PayPaymentFailed={onM2PayPaymentFailed}
        />
        <form
          style={getCustomM2PayFormStyle().base}
          id='form'
          noValidate='novalidate'
          lang='en'
          data-hostedform-tokenize='{"#form-card-expiry-date":"card-expiry-date"}{"#form-card-number":"card-number"}{"#form-card-cvv":"card-cvv"}{"#form-customer-address":"customer-address"}'
        >
          <input type='hidden' name='merchantID' value={paymentMethodSettings.merchantID} />
          <input type='hidden' name='paymentToken' value='' />
          <button type='submit' name='submit' value='' id='submitbutton' style={{ visibility: 'hidden' }} />

          <FormRow>
            <Checkbox
              isChecked={cardholderSameAsGuest}
              click={handleSameAsGuest}
              text={translate('Card holder same as booker')}
            />
          </FormRow>
          {!cardholderSameAsGuest && (
            <FormRow>
              <FormGroup fullWidth={true}>
                <FormLabel>
                  <label htmlFor='form-customer-name'>{translate('Card holder')}</label>
                </FormLabel>
                <Input
                  id='form-customer-name'
                  value={cardholderName}
                  onChange={handleCardHolderName}
                  name='paymentToken[customerName]'
                  autocomplete='cc-name'
                />
                <FormError>{getValidationError('name')}</FormError>
              </FormGroup>
            </FormRow>
          )}
          <FormRow>
            <FormGroup fullWidth={true}>
              <FormLabel>
                <label htmlFor='form-customer-address'>{translate('Address')}</label>
              </FormLabel>
              <Input
                id='form-customer-address'
                type='customerAddress'
                value={address}
                onChange={handleAddress}
                name='paymentToken[customerAddress]'
                autocomplete='cc-address'
              />
              <FormError>{getValidationError('address')}</FormError>
            </FormGroup>
          </FormRow>
          <FormRow>
            <FormGroup fullWidth={true}>
              <FormLabel>
                <label htmlFor='form-customer-city'>{translate('City')}</label>
              </FormLabel>
              <Input
                id='form-customer-city'
                type='customerCity'
                value={city}
                onChange={handleCity}
                name='paymentToken[customerCity]'
                autocomplete='cc-city'
              />
              <FormError>{getValidationError('city')}</FormError>
            </FormGroup>
          </FormRow>
          <FormRow>
            <FormGroup fullWidth={true}>
              <FormLabel>
                <label htmlFor='form-customer-postcode'>{translate('Zip code')}</label>
              </FormLabel>
              <Input
                id='form-customer-postcode'
                value={postalCode}
                onChange={handlePostalCode}
                name='paymentToken[customerPostcode]'
                autocomplete='cc-postcode'
              />
              <FormError>{getValidationError('postalCode')}</FormError>
            </FormGroup>
          </FormRow>
          <CreditCardRow>
            <FormGroup>
              <FormLabel>
                <label htmlFor='form-card-number'>{translate('Card number')}</label>
              </FormLabel>
              <input
                id='form-card-number'
                type='hostedfield:cardNumber'
                name='card-number'
                autoComplete='cc-number'
                className='form-control form-control-hosted '
                style={getCustomM2PayFormStyle().cardNumberInput}
                required
                placeholder='1234 1234 1234 1234'
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>
                <label htmlFor='form-expiry-date'>{translate('Expiration date')}</label>
              </FormLabel>
              <input
                id='form-card-expiry-date'
                type='hostedfield:cardExpiryDate'
                name='card-expiry-date'
                autoComplete='cc-exp'
                className='form-control form-control-hosted'
                data-hostedfield='{"dropdown":false}'
                data-hostedfield-format='m | y'
                data-hostedfield-min-date='-40'
                data-hostedfeld-max-date='0'
                required
                style={getCustomM2PayFormStyle().cardExpiryInput}
                placeholder='MM / YYYY'
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>
                <label htmlFor='form-card-cvv'>CVV</label>
              </FormLabel>
              <input
                id='form-card-cvv'
                type='hostedfield:cardCVV'
                name='card-cvv'
                autoComplete='cc-csc'
                className='form-control form-control-hosted'
                required
                style={getCustomM2PayFormStyle().cardExpiryInput}
                placeholder='123'
              />
            </FormGroup>
          </CreditCardRow>
        </form>
        <M2PayErrorMessage>{m2payErrorMessage}</M2PayErrorMessage>
      </>
    )
  }
}

M2PayPayment.propTypes = {
  currency: PropTypes.string,
  m2pay: PropTypes.object,
  paymentMethodSettings: PropTypes.object,
  locale: PropTypes.string,
  cardholderName: PropTypes.string,
  handleCardHolderName: PropTypes.func,
  cardholderSameAsGuest: PropTypes.bool,
  handleSameAsGuest: PropTypes.func,
  getValidationError: PropTypes.func,
  receipt: PropTypes.object,
  handleAddress: PropTypes.func,
  handlePostalCode: PropTypes.func,
  guestDetails: PropTypes.object,
  callBookReservationApi: PropTypes.func,
  onM2PayPaymentFailed: PropTypes.func,
  getM2PayClientSecret: PropTypes.func,
  paymentDetails: PropTypes.object,
  handleCity: PropTypes.func,
  openM2Pay3DSModal: PropTypes.func
}

export default M2PayPayment
