import styled from 'styled-components'

export const Textarea = styled.textarea`
  width: 100%;
  border-radius: 10px;
  padding: 20px 15px;
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  border: 1px solid ${props => props.theme.colors.border};
  resize: none;
  transition: all ${({ theme }) => theme.animations.defaultTransition};

  &:focus {
    border: 1px solid ${props => props.theme.colors.secondary};
    outline: none;
    box-shadow: 0px 5px 10px rgba(221, 164, 29, 0.2);
  }
`
