import styled from 'styled-components'

export const Wrapper = styled.div``

export const Title = styled.div`
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._32};
  line-height: ${props => props.theme.fonts._37};
  color: ${props => props.theme.colors.textColor};
  margin-bottom: 20px;
`

export const LinkWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  margin: ${({ margin }) => margin};
`

export const LinkText = styled.a`
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  text-decoration: underline;
  color: ${props => props.theme.colors.primary};
  margin-left: 13px;
  user-select: none;
  cursor: pointer;
`

export const Text = styled.p`
  font-weight: normal;
  font-size: ${({ small }) => (small ? '14px' : '18px')};
  line-height: 21px;
  color: ${props => (props.light ? props.theme.colors.labelTextColor : props.theme.colors.TextColor)};
  margin-bottom: 40px;
`

export const SavePage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  border-radius: 10px;
  padding: 20px 15px;
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  border: 1px solid ${props => props.theme.colors.border};
  cursor: pointer;
`

export const SavePageText = styled.p`
  font-weight: ${props => props.theme.fonts.regularWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  color: ${props => props.theme.colors.textColor};
  user-select: all;
  word-break: break-word;
`
