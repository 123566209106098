export const paymentTypesLabels = {
  '1': 'per night',
  '2': 'per person',
  '3': 'per adult',
  '4': 'per child under 12',
  '5': 'per child above 12',
  '6': 'per person per night',
  '7': 'one time fee'
}

export const paymentTypes = {
  PER_NIGHT: 1,
  PER_PERSON: 2,
  PER_ADULT: 3,
  PER_CHILDREN_UNDER_12: 4,
  PER_CHILDREN_ABOVE_12: 5,
  PER_PERSON_PER_NIGHT: 6,
  ONE_TIME_FEE: 7
}

export const getServiceQuantity = (paymentType, nights, adults, children, quantity) => {
  let serviceQuantity = quantity
  const teenagers = getTeenagersNumber(children)
  const youngsters = getChildrenNumber(children)

  switch (paymentType) {
    case paymentTypes.PER_PERSON:
      serviceQuantity = adults + teenagers + youngsters
      break
    case paymentTypes.PER_ADULT:
      serviceQuantity = adults
      break
    case paymentTypes.ONE_TIME_FEE:
      serviceQuantity = quantity
      break
    case paymentTypes.PER_NIGHT:
      serviceQuantity = nights
      break
    case paymentTypes.PER_CHILDREN_ABOVE_12:
      serviceQuantity = teenagers
      break
    case paymentTypes.PER_CHILDREN_UNDER_12:
      serviceQuantity = youngsters
      break
    case paymentTypes.PER_PERSON_PER_NIGHT:
      serviceQuantity = (adults + teenagers + youngsters) * nights
      break
    default:
      serviceQuantity = quantity
  }

  return serviceQuantity
}

export const getTeenagersNumber = children => {
  return children.filter(c => parseInt(c) > 11).length
}

export const getChildrenNumber = children => {
  return children.filter(c => parseInt(c) <= 11).length
}
