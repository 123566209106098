import { FETCH_AVAILABILITY_FOR_MONTH_COMPLETED } from 'Rentlio/state/types'
import createReducer from 'Rentlio/state/utils/createReducer'

/**
 * {
 *   ...
 *   [unitTypeId: int]: {
 *     ...
 *     [year: int]: {
 *        ...
 *        {
 *          [month: int]: {
 *            ...
 *            [day: int]: [availability: int]
 *            ...
 *          }
 *        }
 *        ...
 *     }
 *     ...
 *   }
 *   ...
 * }
 */

const unitTypeAvailability = createReducer({})({
  [FETCH_AVAILABILITY_FOR_MONTH_COMPLETED]: (state, action) => {
    // I know this will not be a fully mutated state after action, but we dont want to mutate what we dont use
    // for now at least, if anything starts tripping with this, we'll switch this to deep clone
    const newState = { ...state }

    action.payload.forEach(item => {
      typeof newState[item.unitTypeId] === 'undefined' && (newState[item.unitTypeId] = {})
      typeof newState[item.unitTypeId][item.year] === 'undefined' && (newState[item.unitTypeId][item.year] = {})
      typeof newState[item.unitTypeId][item.year][item.month] === 'undefined' &&
        (newState[item.unitTypeId][item.year][item.month] = {})

      newState[item.unitTypeId][item.year][item.month][item.day] = item.availability
    })

    return newState
  }
})

export default unitTypeAvailability
