import { v4 } from 'uuid'
import { ADD_ITEM_TO_BASKET, CLEAR_BASKET, REMOVE_ITEM_FROM_BASKET, UPDATE_SERVICE_QUANTITY } from 'Rentlio/state/types'
import createReducer from 'Rentlio/state/utils/createReducer'

/**
 * const initialState = [
 *    ...
 *    {
 *      ...unitType,
 *      ...selectedRatePlan,
 *      services: [],
 *    }
 *    ...
 * ]
 */

const initialState = []
const basket = createReducer(initialState)({
  [ADD_ITEM_TO_BASKET]: (state, action) => {
    const { quantity, payload } = action
    const newItems = []

    for (let i = 0; i < quantity; i++) {
      newItems.push({ ...payload, basketId: v4() })
    }

    return updateItemNumbers([...state, ...newItems])
  },
  [REMOVE_ITEM_FROM_BASKET]: (state, action) =>
    updateItemNumbers(state.filter(item => item.basketId !== action.basketId)),
  [CLEAR_BASKET]: () => [],
  [UPDATE_SERVICE_QUANTITY]: (state, action) =>
    state.map(basketItem => {
      const { basketId, serviceId, newQuantity, isMandatoryQuantityUpdate } = action
      if (basketId !== basketItem.basketId) {
        return basketItem
      }

      const updatedServices = basketItem.services.map(service => {
        if (service.id === serviceId) {
          return {
            ...service,
            quantity: newQuantity,
            isMandatoryQuantityUpdate: isMandatoryQuantityUpdate === true ? true : undefined
          }
        }

        return { ...service }
      })

      return {
        ...basketItem,
        services: updatedServices
      }
    })
})

function updateItemNumbers(basket) {
  return basket.map(basketItem => {
    const filteredSameUnitTypes = basket.filter(item => item.unitTypeId === basketItem.unitTypeId)

    return {
      ...basketItem,
      itemNumber:
        filteredSameUnitTypes.length === 1
          ? null
          : filteredSameUnitTypes.findIndex(item => item.basketId === basketItem.basketId) + 1
    }
  })
}

export default basket
