import React from 'react'
import PropTypes from 'prop-types'
import colors from '../../style/colors'

const Close = ({ color, height, width }) => (
  <svg width={width} height={height} viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M9 1L1 9' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M1 1L9 9' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)

Close.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
}

Close.defaultProps = {
  color: colors.alertColor,
  width: 10,
  height: 10
}

export default Close
