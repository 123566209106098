import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { BreadCrumbsWrapper, ItemWrapper } from './style'
import Checkmark from 'Rentlio/components/Icons/checkmark'
import { HOME_PAGE, PAYMENT_PAGE, SERVICES_PAGE } from 'Rentlio/utils/routes'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

const breadCrumbsDefinition = [
  {
    label: 'Accommodation',
    path: HOME_PAGE
  },
  {
    label: 'Extras',
    path: SERVICES_PAGE
  },
  {
    label: 'Confirmation',
    path: PAYMENT_PAGE
  }
]

class BreadCrumbs extends React.PureComponent {
  static contextType = LanguageContext
  render() {
    const { currentPath } = this.props
    const currentIndex = breadCrumbsDefinition.findIndex(breadcrumb => currentPath === breadcrumb.path)
    const { translate } = this.context

    return (
      <BreadCrumbsWrapper>
        {breadCrumbsDefinition.map((item, i) => (
          <ItemWrapper key={i} current={i === currentIndex} previous={i < currentIndex} next={i > currentIndex}>
            <Link to={item.path}>
              {i < currentIndex && <Checkmark />}
              {translate(item.label)}
            </Link>
          </ItemWrapper>
        ))}
      </BreadCrumbsWrapper>
    )
  }
}

BreadCrumbs.propTypes = {
  currentPath: PropTypes.string
}

export default BreadCrumbs
