import React from 'react'
import { connect } from 'react-redux'
import OfferNotice from 'Rentlio/screen/offer/components/OfferNotice'

const OfferNoticeContainer = props => <OfferNotice {...props} />

const mapStateToProps = ({ offer, booking }) => ({
  offer,
  booking
})

export default connect(mapStateToProps)(OfferNoticeContainer)
