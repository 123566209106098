import { formatPrice } from 'Rentlio/components/utils/Price/price'
import { getFeeCosts, getFeeCostsBeforeTax } from 'Rentlio/state/selectors/basketFee'
import { getNights } from 'Rentlio/state/selectors/request'
import ChildrenCategoriesTypeEnum from 'Rentlio/utils/childrenCategoriesEnum'
import { servicePricingTypeEnum } from 'Rentlio/utils/servicePricingTypeEnum'
import { paymentTypes } from 'Rentlio/utils/services'

function getServiceCostWithPricingDynamic(service, request) {
  const { dailyPrices, quantity, pricingType } = service

  const basePrice = getDailyPricesSum(dailyPrices)

  if (pricingType === servicePricingTypeEnum.PER_PERSON) {
    const priceForAdults = getTotalAdultsPrice(basePrice, request, service)
    const childrenPrice = getTotalChildrenPrice(basePrice, request, service)

    return quantity * (priceForAdults + childrenPrice)
  }

  return quantity * basePrice
}

function getServiceCostBeforeTaxWithPricingDynamic(service, request) {
  const { dailyPrices, quantity, pricingType } = service

  const basePrice = getDailyPricesSum(dailyPrices, 'priceBeforeTax')

  if (pricingType === servicePricingTypeEnum.PER_PERSON) {
    const priceForAdults = getTotalAdultsPrice(basePrice, request, service)
    const childrenPrice = getTotalChildrenPrice(basePrice, request, service)

    return quantity * (priceForAdults + childrenPrice)
  }

  return quantity * basePrice
}

// all custom children prices go here
function getTotalChildrenPrice(basePrice, { children }, { childrenDifferentThanAdults, childrenCategories }) {
  if (!childrenDifferentThanAdults) {
    return 0
  }

  let childrenPrice = 0

  children.map(age =>
    childrenCategories.map(category => {
      if (age >= category.ageFrom && age < category.ageTo) {
        if (category.type === ChildrenCategoriesTypeEnum.CUSTOM && category.percentageValue > 0) {
          childrenPrice += (basePrice * category.percentageValue) / 100
        }
      }
    })
  )

  return childrenPrice
}

// all adults and children treatedd as adults go here
function getTotalAdultsPrice(basePrice, { adults, children }, { childrenDifferentThanAdults, childrenCategories }) {
  if (childrenDifferentThanAdults) {
    let totalAdults = adults

    children.map(age =>
      childrenCategories.map(category => {
        if (age >= category.ageFrom && age < category.ageTo) {
          if (category.type === ChildrenCategoriesTypeEnum.AS_ADULT) {
            totalAdults++
          }
        }
      })
    )
    return basePrice * totalAdults
  }

  return basePrice * (adults + children.length)
}

function getDailyPricesSum(dailyPrices, field = 'price') {
  if (!dailyPrices) {
    return 0
  }
  return dailyPrices.reduce((sum, item) => (sum += item[field]), 0)
}

function getServiceCostLegacy(service, request) {
  const { price, quantity, paymentType, isMandatory } = service
  if (isMandatory) {
    // mandatory services use real price model that
    // was already applied for getting correct quantity
    return quantity * price
  }

  const nights = getNights(request)
  switch (paymentType) {
    case paymentTypes.PER_PERSON:
    case paymentTypes.PER_ADULT:
    case paymentTypes.PER_CHILDREN_UNDER_12:
    case paymentTypes.PER_CHILDREN_ABOVE_12:
    case paymentTypes.ONE_TIME_FEE:
      return quantity * price
    case paymentTypes.PER_NIGHT:
    case paymentTypes.PER_PERSON_PER_NIGHT:
      return nights * price * quantity
  }

  // if paymentType = 0
  return quantity * price
}

function getServiceCostBeforeTaxLegacy(service, request) {
  const { priceBeforeTax, quantity, paymentType, isMandatory } = service
  const nights = getNights(request)

  if (isMandatory) {
    // mandatory services use real price model that
    // was already applied for getting correct quantity
    return quantity * priceBeforeTax
  }

  switch (paymentType) {
    case paymentTypes.PER_PERSON:
    case paymentTypes.PER_ADULT:
    case paymentTypes.PER_CHILDREN_UNDER_12:
    case paymentTypes.PER_CHILDREN_ABOVE_12:
    case paymentTypes.ONE_TIME_FEE:
      return quantity * priceBeforeTax
    case paymentTypes.PER_NIGHT:
    case paymentTypes.PER_PERSON_PER_NIGHT:
      return nights * priceBeforeTax * quantity
  }

  // if paymentType = 0
  return quantity * priceBeforeTax
}

export function getTotalCost(state) {
  const result = {
    total: 0,
    accomodation: 0,
    extras: 0
  }

  const { basket, request } = state

  basket.map(basketItem => {
    result.accomodation += basketItem.totalRate

    basketItem.services.map(service => {
      result.extras += getServiceCosts(service, request)
    })

    basketItem.fees.map(fee => {
      result.extras += getFeeCosts(fee, request)
    })
  })

  result.total = result.accomodation + result.extras
  result.accomodationCount = basket.length

  return result
}

export function getServiceCostsBeforeTax(service, request) {
  const { paymentType } = service

  if (typeof paymentType !== 'undefined' && paymentType !== null) {
    return getServiceCostBeforeTaxLegacy(service, request)
  }

  return getServiceCostBeforeTaxWithPricingDynamic(service, request)
}

export function getSelectedRatePlans(state) {
  return state.basket.map(item => ({ unitTypeId: item.unitTypeId, ratePlanId: item.ratePlanId }))
}

export function getReceipt(state) {
  const receipt = {
    accomodation: {
      items: [],
      totalBeforeTax: 0,
      taxAmount: 0,
      totalCost: 0
    },
    extras: {
      items: [],
      totalBeforeTax: 0,
      taxAmount: 0,
      totalCost: 0
    },
    price: {
      total: 0,
      beforeTax: 0,
      taxAmount: 0
    },
    touristTax: {
      notIncluded: 0,
      included: 0
    }
  }

  const accomodationItems = {}
  const extrasItems = {}
  let bookingEngineId = null

  state.basket.map(basketItem => {
    const accomodationKey = `${basketItem.unitTypeId}_${basketItem.ratePlanId}`
    if (typeof accomodationItems[accomodationKey] === 'undefined') {
      accomodationItems[accomodationKey] = {
        name: basketItem.unitTypeName,
        ratePlanName: basketItem.ratePlanName,
        quantity: 0,
        priceBeforeTax: 0,
        price: 0,
        cancellationPolicy: null,
        isVatRegistered: basketItem.isVatRegistered,
        vatRate: basketItem.vatRate,
        currency: basketItem.currency,
        checkInBegin: basketItem.checkInBegin,
        checkInEnd: basketItem.checkInEnd,
        checkOutBegin: basketItem.checkOutBegin,
        checkOutEnd: basketItem.checkOutEnd,
        servicesCost: 0
      }
    }

    bookingEngineId = basketItem.bookingEngineId
    accomodationItems[accomodationKey].quantity += 1
    accomodationItems[accomodationKey].price += basketItem.totalRate
    accomodationItems[accomodationKey].priceBeforeTax += basketItem.totalRateBeforeTax

    if (basketItem.cancellationPolicy !== null) {
      accomodationItems[accomodationKey].cancellationPolicy = basketItem.cancellationPolicy
    }
    basketItem.includedServices.map(name => {
      extrasItems[name] = {
        name,
        isIncluded: true,
        quantity: 1,
        priceBeforeTax: 0,
        price: 0,
        currency: basketItem.currency
      }
    })

    basketItem.services.map(service => {
      if (service.quantity === 0) {
        return
      }

      const serviceKey = `SERVICE_${service.id}`
      if (typeof extrasItems[serviceKey] === 'undefined') {
        extrasItems[serviceKey] = {
          name: service.name,
          quantity: 0,
          priceBeforeTax: 0,
          price: 0,
          isMandatory: service.isMandatory,
          paymentType: service.paymentType,
          currency: basketItem.currency
        }
      }

      extrasItems[serviceKey].quantity += service.quantity
      extrasItems[serviceKey].priceBeforeTax += getServiceCostsBeforeTax(service, state.request)
      extrasItems[serviceKey].price += getServiceCosts(service, state.request)
      accomodationItems[accomodationKey].servicesCost += getServiceCosts(service, state.request)
    })

    basketItem.fees.map(fee => {
      const feeKey = `FEE_${fee.id}`
      if (typeof extrasItems[feeKey] === 'undefined') {
        extrasItems[feeKey] = {
          name: fee.name,
          quantity: 1,
          priceBeforeTax: 0,
          price: 0,
          currency: basketItem.currency
        }
      }

      extrasItems[feeKey].priceBeforeTax += getFeeCostsBeforeTax(fee, state.request)
      extrasItems[feeKey].price += getFeeCosts(fee, state.request)
      accomodationItems[accomodationKey].servicesCost += getFeeCosts(fee, state.request)
    })

    receipt.touristTax.notIncluded += basketItem.isTouristTaxIncluded ? 0 : basketItem.touristTax || 0
    receipt.touristTax.included += basketItem.isTouristTaxIncluded ? basketItem.touristTax || 0 : 0
  })
  receipt.accomodation.items = Object.values(accomodationItems)
  receipt.extras.items = Object.values(extrasItems)
  receipt.isVatRegistered = Object.values(accomodationItems)[0]?.isVatRegistered
  receipt.vatRate = Object.values(accomodationItems)[0]?.vatRate
  receipt.currency = Object.values(accomodationItems)[0]?.currency
  receipt.bookingEngineId = bookingEngineId

  receipt.accomodation.totalCost = receipt.accomodation.items.reduce((sum, item) => sum + item.price, 0)
  receipt.accomodation.totalBeforeTax = receipt.accomodation.items.reduce((sum, item) => sum + item.priceBeforeTax, 0)
  receipt.accomodation.taxAmount =
    receipt.accomodation.totalCost - receipt.touristTax.included - receipt.accomodation.totalBeforeTax

  receipt.extras.totalCost = receipt.extras.items.reduce((sum, item) => sum + item.price, 0)
  receipt.extras.totalBeforeTax = receipt.extras.items.reduce((sum, item) => sum + item.priceBeforeTax, 0)
  receipt.extras.taxAmount = receipt.extras.totalCost - receipt.extras.totalBeforeTax

  receipt.price.total = receipt.accomodation.totalCost + receipt.extras.totalCost
  receipt.price.beforeTax = receipt.accomodation.totalBeforeTax + receipt.extras.totalBeforeTax
  receipt.price.taxAmount = receipt.accomodation.taxAmount + receipt.extras.taxAmount

  return receipt
}

export function getPrePaymentDetails(state) {
  const { basket } = state

  if (!basket?.length) {
    return {
      prepaymentAmount: 0,
      prepaymentPercentage: 0
    }
  }
  const filterHasPrepayment = basket.filter(
    item => item.cancellationPolicy?.prepaymentAmount && Number(item.cancellationPolicy.prepaymentAmount) > 0
  )

  function toAbsoluteValue(value, percent) {
    return Number(value * (percent / 100))
  }

  const getPrePaymentAmount = filterHasPrepayment
    .map(item => {
      let servicesCost = 0
      item.services.map(service => {
        if (service.quantity === 0) {
          return
        }
        servicesCost += getServiceCosts(service, state.request)
      })
      return toAbsoluteValue(item.totalRate + servicesCost, item.cancellationPolicy.prepaymentAmount)
    })
    .reduce((sum, value) => sum + value, 0)

  const getPrePaymentPercentage = formatPrice((getPrePaymentAmount / getTotalCost(state).accomodation) * 100)

  return {
    prepaymentAmount: getPrePaymentAmount,
    prepaymentPercentage: getPrePaymentPercentage
  }
}

export function getBookingEngineIdInBasket(state) {
  if (state.basket.length === 0) {
    return null
  }

  return state.basket[0].bookingEngineId
}

export function getServiceCosts(service, request) {
  const { paymentType } = service

  if (typeof paymentType !== 'undefined' && paymentType !== null) {
    return getServiceCostLegacy(service, request)
  }

  return getServiceCostWithPricingDynamic(service, request)
}
