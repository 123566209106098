export const zIndex = {
  contactInfo: 5,
  calendar: 10,
  unitTypeModal: 20,
  occupancySelect: 15,
  dropdown: 100,
  cart: 50,
  cookieNotice: 60,
  search: 10,
  searchLocations: 11,
  // has to be lower than search
  amenityFilter: 9
}
