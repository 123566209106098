import { UPDATE_PAYMENT_INFO } from 'Rentlio/state/types'
import createReducer from 'Rentlio/state/utils/createReducer'

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  country: '',
  address: '',
  city: '',
  postalCode: '',
  estimatedTimeOfArrival: '',
  note: '',
  cardholderName: '',
  cardNumber: '',
  cvv: '',
  expirationDate: '',
  selectedPaymentMethod: 1
}
const paymentDetails = createReducer(initialState)({
  [UPDATE_PAYMENT_INFO]: (state, action) => {
    return action.paymentDetails
  }
})

export default paymentDetails
