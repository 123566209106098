import moment from 'moment'
import { trans } from 'Rentlio/helper/translation'

export function dateValidator(value) {
  if (typeof value !== 'string') {
    return trans('The date format is incorrect')
  }

  const datePattern = /^\d{2}\.\d{2}\.\d{4}$/
  if (!datePattern.test(value)) {
    return trans('The date format is incorrect')
  }

  const isValidDate = moment(value, 'DD.MM.YYYY', true).isValid()

  if (!isValidDate) {
    return trans('The date is invalid')
  }

  return null
}
