import React from 'react'
import PropTypes from 'prop-types'
import UnitTypeModal from '../UnitTypeModal'
import { Description, InformationWrapper, MoreDetails, Name } from './style'
import MaxOccupancy from 'Rentlio/components/MaxOccupancy'
import Modal from 'Rentlio/components/Modal'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import Animation from 'Rentlio/components/UI/Animation'

class Information extends React.PureComponent {
  static contextType = LanguageContext

  getDescription() {
    const { description } = this.props

    if (!description) {
      return ''
    }

    return description.length > 240 ? description.slice(0, 240) + '...' : description
  }

  hideModalHandler = () => this.props.hideUnitTypeDetailsModalHandler()

  render() {
    const {
      name,
      maxOccupancy,
      standardOccupancy,
      images,
      amenities,
      description,
      unitTypeName,
      unitTypeCity,
      latitude,
      longitude,
      primaryColor,
      groupBESlug,
      isGroupBookingEngine,
      unitTypeSlug,
      showUnitTypeDetailsModalHandler,
      hideUnitTypeDetailsModalHandler,
      showUnitTypeDetailsModal
    } = this.props
    const { translate } = this.context
    return (
      <InformationWrapper>
        <Animation show={showUnitTypeDetailsModal} animationType={'fadeInOut'} isModal>
          <Modal hideModalHandler={this.hideModalHandler} show={showUnitTypeDetailsModal}>
            <UnitTypeModal
              hasThumbnails
              images={images}
              amenities={amenities}
              hideModalHandler={hideUnitTypeDetailsModalHandler}
              maxOccupancy={maxOccupancy}
              standardOccupancy={standardOccupancy}
              description={description}
              name={name}
              latitude={latitude}
              longitude={longitude}
              primaryColor={primaryColor}
              unitTypeName={unitTypeName}
              unitTypeCity={unitTypeCity}
              isGroupBookingEngine={isGroupBookingEngine}
              unitTypeSlug={unitTypeSlug}
              groupBESlug={groupBESlug}
            />
          </Modal>
        </Animation>
        <MaxOccupancy
          maxOccupancy={maxOccupancy}
          standardOccupancy={standardOccupancy}
          shouldBeHiddenOnMobile
          unitTypeName={unitTypeName}
          unitTypeCity={unitTypeCity}
          isGroupBookingEngine={isGroupBookingEngine}
          unitTypeSlug={unitTypeSlug}
          groupBESlug={groupBESlug}
        />
        <Name>{name}</Name>
        <Description>{this.getDescription()}</Description>
        <MoreDetails onClick={() => showUnitTypeDetailsModalHandler()}>{translate('More details')}</MoreDetails>
      </InformationWrapper>
    )
  }
}

Information.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  standardOccupancy: PropTypes.number,
  maxOccupancy: PropTypes.number,
  images: PropTypes.array,
  amenities: PropTypes.array,
  unitTypeName: PropTypes.string,
  unitTypeCity: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  primaryColor: PropTypes.string,
  isGroupBookingEngine: PropTypes.bool,
  unitTypeSlug: PropTypes.string,
  groupBESlug: PropTypes.string,
  openUnitTypeModal: PropTypes.func,
  showUnitTypeDetailsModalHandler: PropTypes.func,
  hideUnitTypeDetailsModalHandler: PropTypes.func,
  showUnitTypeDetailsModal: PropTypes.bool
}

export default Information
