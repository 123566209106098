import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import M2Pay3DS from '../components/M2Pay3DS'
import { closeModal } from 'Rentlio/state/actions/ui'
import { modals } from 'Rentlio/screen/layout/utils/modalsEnum'

const M2Pay3DSContainer = props => {
  return props.activeModal === modals.M2PAY_3DS ? <M2Pay3DS {...props} /> : null
}

M2Pay3DSContainer.propTypes = {
  closeModal: PropTypes.func,
  activeModal: PropTypes.string
}

const mapStateToProps = state => ({
  activeModal: state.modals.openModal,
  modalData: state.modals.modalData
})

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(M2Pay3DSContainer)
