import React from 'react'
import PropTypes from 'prop-types'
import { Label, Link, Logo } from './style'

const FooterBranding = ({ footerLogoUrl, footerLabel, footerWebsite }) => {
  if (!footerLogoUrl && !footerLabel) {
    return null
  }

  return (
    <Link href={!footerWebsite ? null : footerWebsite} target='_blank'>
      {footerLogoUrl && <Logo src={footerLogoUrl} />}
      {footerLabel && <Label>{footerLabel}</Label>}
    </Link>
  )
}

FooterBranding.propTypes = {
  footerLogoUrl: PropTypes.string,
  footerLabel: PropTypes.string,
  footerWebsite: PropTypes.string
}

export default FooterBranding
