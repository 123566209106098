import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { Agreements, FooterAgreement, FooterWrapper } from './style'
import PoweredByRentlio from './PoweredByRentlio'
import SocialNetworks from './SocialNetworks'
import FooterBranding from 'Rentlio/screen/layout/components/Footer/FooterBranding'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

const Footer = ({
  facebook,
  instagram,
  twitter,
  openTermsOfServiceModal,
  openPrivacyPolicyModal,
  footerLogoUrl,
  footerLabel,
  footerWebsite
}) => {
  const { translate } = useContext(LanguageContext)
  return (
    <FooterWrapper>
      <SocialNetworks facebook={facebook} twitter={twitter} instagram={instagram} />
      <Agreements>
        <FooterAgreement onClick={() => openPrivacyPolicyModal()}>{translate('Privacy policy')}</FooterAgreement>
        <FooterAgreement onClick={() => openTermsOfServiceModal()}>{translate('Terms of service')}</FooterAgreement>
      </Agreements>
      <FooterBranding footerLogoUrl={footerLogoUrl} footerLabel={footerLabel} footerWebsite={footerWebsite} />
      <PoweredByRentlio />
    </FooterWrapper>
  )
}

Footer.propTypes = {
  facebook: PropTypes.string,
  instagram: PropTypes.string,
  twitter: PropTypes.string,
  footerLogoUrl: PropTypes.string,
  footerLabel: PropTypes.string,
  footerWebsite: PropTypes.string,
  openTermsOfServiceModal: PropTypes.func,
  openPrivacyPolicyModal: PropTypes.func
}

export default Footer
