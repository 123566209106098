import moment from 'moment'
import { FULL_AMOUNT_WILL_BE_PAID_ENUM } from 'Rentlio/utils/cancellationPolicyEnums'
import { formatPrice } from 'Rentlio/components/utils/Price/price'
import { BE_BANK_TRANSFER } from 'Rentlio/utils/paymentTypesEnum'

export const getDaysBeforeArrivalTime = (arrival, daysBefore, translate, dateOnly) => {
  const dateBefore = arrival
    .clone()
    .hours(0)
    .subtract(daysBefore, 'days')
    .subtract(dateOnly ? 0 : 1, 'seconds')

  return dateOnly
    ? dateBefore.format('DD.MM.YYYY')
    : `${dateBefore.format('DD.MM.YYYY.')} ${translate('at')} ${dateBefore.format('HH:mm')}`
}

export const isDateInPast = (arrival, useDiffInMinutes, createdAt) => {
  if (!arrival) return false
  const dateToday = createdAt ? moment.utc(createdAt * 1000) : moment().utc()
  const dateToCompare = arrival.clone()

  // because on billing dynamics we are using only dates, and on terms of cancellation we are using exact hours:minutes
  // so if we book at the treshold day before treshold we will still write exact terms of cancellation
  if (!useDiffInMinutes) {
    dateToday
      .hours(12)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
    dateToCompare
      .hours(12)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
  }

  const diff = dateToCompare.diff(dateToday, useDiffInMinutes ? 'minutes' : 'days')

  return !(diff > 0)
}

export const getAccommodationCancelationPolicyText = (
  translate,
  currencyConversion,
  totalRatePrice,
  unitTypeCurrency,
  policy,
  arrivalDate,
  createdAt
) => {
  if (!policy) {
    return
  }

  const {
    daysBeforeArrival,
    canGuestCancelFreeOfCharge,
    cancellationFeePercentageBefore,
    cancellationFeePercentageAfter
  } = policy

  const arrival = moment.utc(arrivalDate * 1000)
  const { value, currency } = currencyConversion(totalRatePrice, unitTypeCurrency)

  const isTresholdDateInThePast = isDateInPast(
    arrival.clone().subtract(daysBeforeArrival ? +daysBeforeArrival : 0, 'days'),
    true,
    createdAt
  )

  let cancellationPolicyText = ''

  if (canGuestCancelFreeOfCharge) {
    if (daysBeforeArrival === 0) {
      cancellationPolicyText = translate('You can cancel your reservation free of charge at any time')
    }

    if (daysBeforeArrival && daysBeforeArrival > 0) {
      // handle case when arrivalDate - daysBeforeArrival is in the past
      cancellationPolicyText = isTresholdDateInThePast
        ? `${translate('If you cancel reservation you will be charged amount', {
            '%amount%': formatPrice(value * (cancellationFeePercentageAfter / 100), currency),
            '%currency%': currency || ''
          })}`
        : // handle case when arrivalDate - daysBeforeArrival is in the future
          `${translate('You can cancel your reservation free of charge before date', {
            '%days%': daysBeforeArrival,
            '%currency%': currency || '',
            '%date%': getDaysBeforeArrivalTime(arrival, daysBeforeArrival, translate)
          })} ${translate('If you cancel reservation after that, you will be charged amount', {
            '%amount%': formatPrice(value * (cancellationFeePercentageAfter / 100), currency),
            '%currency%': currency || ''
          })}`
    }
  }

  if (!canGuestCancelFreeOfCharge) {
    if (daysBeforeArrival === 0) {
      cancellationPolicyText = `${translate('You cannot cancel your reservation free of charge')} ${translate(
        'If you cancel at any time you will be charged',
        {
          '%amount%': formatPrice(value * (cancellationFeePercentageBefore / 100), currency),
          '%currency%': currency || ''
        }
      )}`
    }

    if (daysBeforeArrival && daysBeforeArrival > 0) {
      // handle case when arrivalDate - daysBeforeArrival is in the past
      cancellationPolicyText = isTresholdDateInThePast
        ? `${translate('You cannot cancel your reservation free of charge')} ${translate(
            'If you cancel reservation you will be charged amount',
            {
              '%amount%': formatPrice(value * (cancellationFeePercentageAfter / 100), currency),
              '%currency%': currency || ''
            }
          )}`
        : // handle case when arrivalDate - daysBeforeArrival is in the future
          `${translate('You cannot cancel your reservation free of charge')} ${translate(
            'You will be charged amount if you cancel before date',
            {
              '%amount%': formatPrice(value * (cancellationFeePercentageBefore / 100), currency),
              '%currency%': currency || '',
              '%date%': getDaysBeforeArrivalTime(arrival, daysBeforeArrival, translate)
            }
          )} ${translate('If you cancel reservation after that, you will be charged amount', {
            '%amount%': formatPrice(value * (cancellationFeePercentageAfter / 100), currency),
            '%currency%': currency || ''
          })}`
    }
  }
  return cancellationPolicyText
}

export const getNoShowText = (translate, currencyConversion, totalRatePrice, reservationCurrency, policy) => {
  if (!policy) {
    return
  }

  const { noShowPercentage } = policy
  const { value, currency } = currencyConversion(totalRatePrice, reservationCurrency)

  if (noShowPercentage === 0) {
    return translate('You will not be charged in case of no-show')
  }

  if (noShowPercentage > 0) {
    return translate('In case of no-show, you will be charged amount', {
      '%amount%': formatPrice(value * (noShowPercentage / 100), currency),
      '%currency%': currency || ''
    })
  }

  return ''
}

export const getBillingDynamicsText = (
  translate,
  currencyConversion,
  totalRatePrice,
  reservationCurrency,
  policy,
  paymentMethod,
  arrivalDate,
  createdAt
) => {
  if (!paymentMethod) {
    return ''
  }
  const arrival = moment.utc(arrivalDate * 1000)

  if (paymentMethod !== BE_BANK_TRANSFER) {
    return getBillingDynamicsCreditCardText(
      translate,
      currencyConversion,
      totalRatePrice,
      reservationCurrency,
      policy,
      arrival,
      createdAt
    )
  }

  return getBillingDynamicsBankTransferText(
    translate,
    currencyConversion,
    totalRatePrice,
    reservationCurrency,
    policy,
    arrival,
    createdAt
  )
}

export const getBillingDynamicsCreditCardText = (
  translate,
  currencyConversion,
  totalRatePrice,
  reservationCurrency,
  policy,
  arrival,
  createdAt
) => {
  if (!policy) {
    return
  }

  const { prepaymentAmount, fullAmountWillBePaid, amountWillBePaidDaysBefore, prepaymentAmountDueDate } = policy
  const { value, currency } = currencyConversion(totalRatePrice, reservationCurrency)

  const isPrepaymentInThePast = isDateInPast(
    arrival.clone().subtract(prepaymentAmountDueDate ? +prepaymentAmountDueDate : 0, 'days'),
    false,
    createdAt
  )

  const isAmountWillBePaidDaysBeforeInThePast = isDateInPast(
    arrival.clone().subtract(amountWillBePaidDaysBefore ? +amountWillBePaidDaysBefore : 0, 'days'),
    false,
    createdAt
  )

  let billingDynamicsText = ''
  const prepaymenyAmountNumber = Number(prepaymentAmount)

  if (prepaymenyAmountNumber === 100 && prepaymentAmountDueDate === 0) {
    billingDynamicsText = translate('After confirming the reservation you will be charged amount', {
      '%amount%': formatPrice(value, currency),
      '%currency%': currency || ''
    })
  }

  if (prepaymenyAmountNumber === 100 && prepaymentAmountDueDate > 0) {
    billingDynamicsText = isPrepaymentInThePast
      ? translate('After confirming the reservation you will be charged amount', {
          '%amount%': formatPrice(value, currency),
          '%currency%': currency || ''
        })
      : translate('Amount will be charged on', {
          '%amount%': formatPrice(value, currency),
          '%currency%': currency || '',
          '%date%': getDaysBeforeArrivalTime(arrival, prepaymentAmountDueDate, translate, true)
        })
  }

  if (prepaymenyAmountNumber === 0 && fullAmountWillBePaid === FULL_AMOUNT_WILL_BE_PAID_ENUM.DURING_STAY) {
    billingDynamicsText = translate('Amount will be charged during the stay', {
      '%amount%': formatPrice(value, currency),
      '%currency%': currency || ''
    })
  }

  if (prepaymenyAmountNumber === 0 && fullAmountWillBePaid === FULL_AMOUNT_WILL_BE_PAID_ENUM.BEFORE_ARRIVAL) {
    billingDynamicsText = isAmountWillBePaidDaysBeforeInThePast
      ? translate('After confirming the reservation you will be charged amount', {
          '%amount%': formatPrice(value, currency),
          '%currency%': currency || ''
        })
      : translate('You will be charged amount at date', {
          '%date%': getDaysBeforeArrivalTime(arrival, amountWillBePaidDaysBefore, translate, true),
          '%amount%': formatPrice(value, currency),
          '%currency%': currency || ''
        })
  }

  if (
    prepaymenyAmountNumber > 0 &&
    fullAmountWillBePaid === FULL_AMOUNT_WILL_BE_PAID_ENUM.DURING_STAY &&
    prepaymentAmountDueDate === 0
  ) {
    billingDynamicsText = translate(
      'You will be charged amount immediately after confirming the reservation. The remaining amount will be charged during your stay',
      {
        '%amount1%': formatPrice(value * (prepaymenyAmountNumber / 100), currency),
        '%amount2%': formatPrice(value - value * (prepaymenyAmountNumber / 100), currency),
        '%currency%': currency || '',
        '%currency2%': currency || ''
      }
    )
  }

  if (
    prepaymenyAmountNumber > 0 &&
    fullAmountWillBePaid === FULL_AMOUNT_WILL_BE_PAID_ENUM.DURING_STAY &&
    prepaymentAmountDueDate > 0
  ) {
    billingDynamicsText = isPrepaymentInThePast
      ? `${translate('After confirming the reservation you will be charged amount', {
          '%amount%': formatPrice(value * (prepaymenyAmountNumber / 100), currency),
          '%currency%': currency || ''
        })} ${translate('The remaining amount will be charged during your stay', {
          '%amount%': formatPrice(value - value * (prepaymenyAmountNumber / 100), currency),
          '%currency%': currency || ''
        })}`
      : translate('You will be charged amount at date. The remaining amount will be charged during your stay', {
          '%date%': getDaysBeforeArrivalTime(arrival, prepaymentAmountDueDate, translate, true),
          '%amount1%': formatPrice(value * (prepaymenyAmountNumber / 100), currency),
          '%amount2%': formatPrice(value - value * (prepaymenyAmountNumber / 100), currency),
          '%currency%': currency || '',
          '%currency2%': currency || ''
        })
  }

  if (
    prepaymenyAmountNumber > 0 &&
    fullAmountWillBePaid === FULL_AMOUNT_WILL_BE_PAID_ENUM.BEFORE_ARRIVAL &&
    prepaymentAmountDueDate === 0
  ) {
    billingDynamicsText = isAmountWillBePaidDaysBeforeInThePast
      ? translate('After confirming the reservation you will be charged amount', {
          '%amount%': formatPrice(value, currency),
          '%currency%': currency || ''
        })
      : translate(
          'After confirming the reservation you will be charged amount1. The remaining amount of amount2 will be charged at date',
          {
            '%date%': getDaysBeforeArrivalTime(arrival, amountWillBePaidDaysBefore, translate, true),
            '%amount1%': formatPrice(value * (prepaymenyAmountNumber / 100), currency),
            '%amount2%': formatPrice(value - value * (prepaymenyAmountNumber / 100), currency),
            '%currency%': currency || '',
            '%currency2%': currency || ''
          }
        )
  }

  if (
    prepaymenyAmountNumber > 0 &&
    fullAmountWillBePaid === FULL_AMOUNT_WILL_BE_PAID_ENUM.BEFORE_ARRIVAL &&
    prepaymentAmountDueDate > 0 &&
    !isAmountWillBePaidDaysBeforeInThePast &&
    !isPrepaymentInThePast
  ) {
    billingDynamicsText = translate(
      'You will be charged amount at. The remaining amount of amount2 will be charged at date',
      {
        '%date1%': getDaysBeforeArrivalTime(arrival, prepaymentAmountDueDate, translate, true),
        '%date2%': getDaysBeforeArrivalTime(arrival, amountWillBePaidDaysBefore, translate, true),
        '%amount1%': formatPrice(value * (prepaymenyAmountNumber / 100), currency),
        '%amount2%': formatPrice(value - value * (prepaymenyAmountNumber / 100), currency),
        '%currency%': currency || '',
        '%currency2%': currency || ''
      }
    )
  }

  if (
    prepaymenyAmountNumber > 0 &&
    fullAmountWillBePaid === FULL_AMOUNT_WILL_BE_PAID_ENUM.BEFORE_ARRIVAL &&
    prepaymentAmountDueDate > 0 &&
    !isAmountWillBePaidDaysBeforeInThePast &&
    isPrepaymentInThePast
  ) {
    billingDynamicsText = translate(
      'After confirming the reservation you will be charged amount1. The remaining amount of amount2 will be charged at date',
      {
        '%date%': getDaysBeforeArrivalTime(arrival, amountWillBePaidDaysBefore, translate, true),
        '%amount1%': formatPrice(value * (prepaymenyAmountNumber / 100), currency),
        '%amount2%': formatPrice(value - value * (prepaymenyAmountNumber / 100), currency),
        '%currency%': currency || '',
        '%currency2%': currency || ''
      }
    )
  }

  if (
    prepaymenyAmountNumber > 0 &&
    fullAmountWillBePaid === FULL_AMOUNT_WILL_BE_PAID_ENUM.BEFORE_ARRIVAL &&
    prepaymentAmountDueDate > 0 &&
    isAmountWillBePaidDaysBeforeInThePast &&
    isPrepaymentInThePast
  ) {
    billingDynamicsText = translate('After confirming the reservation you will be charged amount', {
      '%amount%': formatPrice(value, currency),
      '%currency%': currency || ''
    })
  }

  return billingDynamicsText
}

export const getBillingDynamicsBankTransferText = (
  translate,
  currencyConversion,
  totalRatePrice,
  reservationCurrency,
  policy,
  arrival,
  createdAt
) => {
  if (!policy) {
    return
  }

  const { prepaymentAmount, fullAmountWillBePaid, amountWillBePaidDaysBefore, prepaymentAmountDueDate } = policy
  const { value, currency } = currencyConversion(totalRatePrice, reservationCurrency)

  const isPrepaymentInThePast = isDateInPast(
    arrival.clone().subtract(prepaymentAmountDueDate ? +prepaymentAmountDueDate : 0, 'days'),
    false,
    createdAt
  )
  const isAmountWillBePaidDaysBeforeInThePast = isDateInPast(
    arrival.clone().subtract(amountWillBePaidDaysBefore ? +amountWillBePaidDaysBefore : 0, 'days'),
    false,
    createdAt
  )

  let billingDynamicsText = ''
  const prepaymentAmountNumber = Number(prepaymentAmount)

  if (prepaymentAmountNumber === 100 && prepaymentAmountDueDate === 0) {
    billingDynamicsText = translate('To confirm the reservation we ask you to pay amount now', {
      '%amount%': formatPrice(value, currency),
      '%currency%': currency || ''
    })
  }

  if (prepaymentAmountNumber === 100 && prepaymentAmountDueDate > 0) {
    billingDynamicsText = isPrepaymentInThePast
      ? translate('To confirm the reservation we ask you to pay amount now', {
          '%amount%': formatPrice(value, currency),
          '%currency%': currency || ''
        })
      : translate('To confirm your reservation we ask you to pay amount no later than date', {
          '%date%': getDaysBeforeArrivalTime(arrival, prepaymentAmountDueDate, translate, true),
          '%amount%': formatPrice(value, currency),
          '%currency%': currency || ''
        })
  }

  if (prepaymentAmountNumber === 0 && fullAmountWillBePaid === FULL_AMOUNT_WILL_BE_PAID_ENUM.DURING_STAY) {
    billingDynamicsText = translate('We will charge you amount during your stay', {
      '%amount%': formatPrice(value, currency),
      '%currency%': currency || ''
    })
  }

  if (prepaymentAmountNumber === 0 && fullAmountWillBePaid === FULL_AMOUNT_WILL_BE_PAID_ENUM.BEFORE_ARRIVAL) {
    billingDynamicsText = isAmountWillBePaidDaysBeforeInThePast
      ? translate('To confirm the reservation we ask you to pay amount now', {
          '%amount%': formatPrice(value, currency),
          '%currency%': currency || ''
        })
      : translate('To confirm your reservation we ask you to pay amount no later than date', {
          '%date%': getDaysBeforeArrivalTime(arrival, amountWillBePaidDaysBefore, translate, true),
          '%amount%': formatPrice(value, currency),
          '%currency%': currency || ''
        })
  }

  if (
    prepaymentAmountNumber > 0 &&
    fullAmountWillBePaid === FULL_AMOUNT_WILL_BE_PAID_ENUM.DURING_STAY &&
    prepaymentAmountDueDate === 0
  ) {
    billingDynamicsText = `${translate('To confirm the reservation we ask you to pay amount now', {
      '%amount%': formatPrice(value * (prepaymentAmountNumber / 100), currency),
      '%currency%': currency || ''
    })} ${translate('The remaining amount will be charged during your stay', {
      '%amount%': formatPrice(value - value * (prepaymentAmountNumber / 100), currency),
      '%currency%': currency || ''
    })}`
  }

  if (
    prepaymentAmountNumber > 0 &&
    fullAmountWillBePaid === FULL_AMOUNT_WILL_BE_PAID_ENUM.DURING_STAY &&
    prepaymentAmountDueDate > 0
  ) {
    billingDynamicsText = `${
      isPrepaymentInThePast
        ? translate('To confirm the reservation we ask you to pay amount now', {
            '%amount%': formatPrice(value * (prepaymentAmountNumber / 100), currency),
            '%currency%': currency || ''
          })
        : translate('To confirm your reservation we ask you to pay amount no later than date', {
            '%amount%': formatPrice(value * (prepaymentAmountNumber / 100), currency),
            '%currency%': currency || '',
            '%date%': getDaysBeforeArrivalTime(arrival, prepaymentAmountDueDate, translate, true)
          })
    } ${translate('The remaining amount will be charged during your stay', {
      '%amount%': formatPrice(value - value * (prepaymentAmountNumber / 100), currency),
      '%currency%': currency || ''
    })}`
  }

  if (
    prepaymentAmountNumber > 0 &&
    fullAmountWillBePaid === FULL_AMOUNT_WILL_BE_PAID_ENUM.BEFORE_ARRIVAL &&
    prepaymentAmountDueDate === 0
  ) {
    billingDynamicsText = isAmountWillBePaidDaysBeforeInThePast
      ? `${translate('To confirm the reservation we ask you to pay amount now', {
          '%amount%': formatPrice(value, currency),
          '%currency%': currency || ''
        })}`
      : `${translate('To confirm the reservation we ask you to pay amount now', {
          '%amount%': formatPrice(value * (prepaymentAmountNumber / 100), currency),
          '%currency%': currency || ''
        })} ${translate('The remaining amount should be paid no later than date', {
          '%date%': getDaysBeforeArrivalTime(arrival, amountWillBePaidDaysBefore, translate, true),
          '%amount%': formatPrice(value - value * (prepaymentAmountNumber / 100), currency),
          '%currency%': currency || ''
        })}`
  }

  if (
    prepaymentAmountNumber > 0 &&
    fullAmountWillBePaid === FULL_AMOUNT_WILL_BE_PAID_ENUM.BEFORE_ARRIVAL &&
    prepaymentAmountDueDate > 0 &&
    !isAmountWillBePaidDaysBeforeInThePast &&
    !isPrepaymentInThePast
  ) {
    billingDynamicsText = `${translate('To confirm your reservation we ask you to pay amount no later than date', {
      '%amount%': formatPrice(value * (prepaymentAmountNumber / 100), currency),
      '%currency%': currency || '',
      '%date%': getDaysBeforeArrivalTime(arrival, prepaymentAmountDueDate, translate, true)
    })} ${translate('The remaining amount should be paid no later than date', {
      '%date%': getDaysBeforeArrivalTime(arrival, amountWillBePaidDaysBefore, translate, true),
      '%amount%': formatPrice(value - value * (prepaymentAmountNumber / 100), currency),
      '%currency%': currency || ''
    })}`
  }

  if (
    prepaymentAmountNumber > 0 &&
    fullAmountWillBePaid === FULL_AMOUNT_WILL_BE_PAID_ENUM.BEFORE_ARRIVAL &&
    prepaymentAmountDueDate > 0 &&
    !isAmountWillBePaidDaysBeforeInThePast &&
    isPrepaymentInThePast
  ) {
    billingDynamicsText = `${translate('To confirm the reservation we ask you to pay amount now', {
      '%amount%': formatPrice(value * (prepaymentAmountNumber / 100), currency),
      '%currency%': currency || ''
    })} ${translate('The remaining amount should be paid no later than date', {
      '%date%': getDaysBeforeArrivalTime(arrival, amountWillBePaidDaysBefore, translate, true),
      '%amount%': formatPrice(value - value * (prepaymentAmountNumber / 100), currency),
      '%currency%': currency || ''
    })}`
  }

  if (
    prepaymentAmountNumber > 0 &&
    fullAmountWillBePaid === FULL_AMOUNT_WILL_BE_PAID_ENUM.BEFORE_ARRIVAL &&
    prepaymentAmountDueDate > 0 &&
    isAmountWillBePaidDaysBeforeInThePast &&
    isPrepaymentInThePast
  ) {
    billingDynamicsText = `${translate('To confirm the reservation we ask you to pay amount now', {
      '%amount%': formatPrice(value, currency),
      '%currency%': currency || ''
    })}`
  }

  return billingDynamicsText
}
