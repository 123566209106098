import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ArrowDownStyled = styled.span`
  margin-left: 5px;
  margin-right: 5px;
  line-height: 10px;
  font-size: 10px;
`

const ArrowDown = ({ strokeWidth, stroke }) => (
  <ArrowDownStyled>
    <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1 5L5 9L9 5'
        stroke={stroke || 'black'}
        strokeWidth={strokeWidth || 1}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </ArrowDownStyled>
)

ArrowDown.propTypes = {
  strokeWidth: PropTypes.number,
  stroke: PropTypes.string
}

export default ArrowDown
