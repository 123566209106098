import React from 'react'

const Times = () => (
  <svg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g opacity='0.8'>
      <path d='M7 1L1 7' stroke='#333333' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M1 1L7 7' stroke='#333333' strokeLinecap='round' strokeLinejoin='round' />
    </g>
  </svg>
)

export default Times
