const colors = {
  primary: '#0973A0',
  secondary: '#DDA41D',
  textColor: '#333333',
  black: '#000000',
  darkBlue: '#07587A',
  vividOrange: '#F7B721',
  veryDarkGray2: '#595959',
  // I have no idea how to call this color
  labelTextColor: 'rgba(51, 51, 51, 0.8)',
  lightText: 'rgba(51, 51, 51, 0.6)',
  policyText: 'rgba(51, 51, 51, 0.8)',
  lighterText: 'rgba(51, 51, 51, 0.3)',
  semiTransparentBlackText: 'rgba(0, 0, 0, 0.6)',
  lightGray: 'rgba(0, 0, 0, 0.5)',
  locationGray: '#f3f5f6',
  backgroundColor: '#FFFFFF',
  contactBackgroundColor: '#333333',
  contactTextColor: '#FFFFFF',
  buttonBackground: '#0973A0',
  buttonText: '#FFFFFF',
  normalButtonBackground: '#333333',
  normalButtonText: '#FFFFFF',
  poweredByRentlioColor: '#0D3151',
  unitTypeEvenBackground: '#FFFFFF',
  unitTypeOddBackground: 'rgba(51, 51, 51, 0.05)',
  modalBackground: 'rgba(51, 51, 51, 0.9)',
  unitTypeOddBorder: 'rgba(51, 51, 51, 0.1)',
  searchInputDivider: 'rgba(51, 51, 51, 0.15)',
  ratePlanBackground: '#FFFFFF',
  servicesBackground: '#F5F5F5',
  reservationSummaryBackground: '#F5F5F5',
  servicesHeadingBackground: 'white',
  border: 'rgba(51, 51, 51, 0.3)',
  thumbnailShadow: 'rgba(221, 164, 29, 0.3)',
  amenitiesBckground: 'rgba(51, 51, 51, 0.05);',
  white: '#fff',
  veryDarkGray: '#333',
  lightGrayishBlue: '#eaf2f6',
  disabledGray: '#c4c4c4',
  radioCheckBoxActiveBg: 'rgba(221, 164, 29, 0.1);',
  notePromotoBg: 'rgba(221, 164, 29, 0.05);',
  red: '#EA4B4D',
  alertColor: '#DD4200',
  goldButton: '#dda41d',
  disabledSelect: '#efefef4D'
}

export default colors
