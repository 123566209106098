import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Box, Label } from './style'
import Checkmark from 'Rentlio/components/Icons/checkmarkVisible'

const Checkbox = ({ text, isChecked, click, disabled }) => (
  <Wrapper>
    <Box onClick={() => !disabled && click()} isChecked={isChecked} disabled={disabled}>
      {isChecked && <Checkmark />}
    </Box>
    <Label>{text}</Label>
  </Wrapper>
)

Checkbox.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isChecked: PropTypes.bool,
  click: PropTypes.func,
  disabled: PropTypes.bool
}

export default Checkbox
