import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const Title = styled.span`
  display: inline-block;
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._24};
  line-height: ${props => props.theme.fonts._28};
  margin-bottom: 33px;
`

export const Information = styled.span`
  display: inline-block;
  color: ${props => props.theme.colors.textColor};
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};

  & span {
    margin-left: 12px;
  }
`

export const InformationWrapper = styled.div`
  display: flex;
  background-color: ${props => props.theme.colors.locationGray};
  padding: ${props => (props.largePadding ? '24px 20px' : '21px 15px')};
  border-radius: 10px;

  @media ${device.tabletAndWider} {
    ${Information}:first-child {
      margin-right: 22px;
    }
  }

  @media ${device.tablet} {
    display: flex;
    flex-direction: column;

    ${Information}:first-child {
      margin-bottom: 8px;
    }
  }
`

export const TotalCostWrapper = styled.div`
  display: flex;
  width: 100%;

  @media ${device.tablet} {
    flex-direction: column;
  }

  @media ${device.tabletAndWider} {
    justify-content: space-between;
    align-items: center;
  }
`

export const TotalCost = styled.div`
  text-transform: uppercase;
  margin-left: auto;

  @media ${device.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    width: 100%;
  }

  ${Information}:last-child {
    font-family: Open sans, sans-serif;
    font-size: ${props => props.theme.fonts._24};
    line-height: ${props => props.theme.fonts._32};
    font-weight: ${props => props.theme.fonts.boldWeight};
    text-align: end;
  }
`
