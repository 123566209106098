import styled, { keyframes } from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const GuestRegistrationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50 0;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 80px 20px;

  @media ${device.laptop} {
    padding: 40px 20px;
  }

  @media ${device.onlyMobile} {
    padding: 40px 20px;
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;

  @media ${device.laptop} {
    max-width: 900px;
  }

  @media ${device.mobile} {
    text-align: center;
    width: auto;
  }
`

export const SpinnerOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 100;
  overflow: hidden;
`

export const fadeInOut = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
export const FormErrorsWrapper = styled.div`
  padding-top: 10px;
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  color: #dd4200;
  font-size: 1.166rem;
  animation: ${fadeInOut} 0.4s ease-in-out;
  align-self: start;

  @media ${device.mobile} {
    text-align: center;
    width: auto;
  }
`

export const UpdatedMessage = styled.div`
  font-weight: 500;
  font-size: 32px;
  margin: 0 auto;
  padding: 50px 10px;
  text-align: center;
  margin: 250px 0px;

  @media ${device.mobile} {
    margin: 100px 0px;
    font-size: 24px;
  }
`

export const InfoText = styled.div`
  font-size: 24px;
  text-align: center;

  @media ${device.mobile} {
    font-size: 20px;
  }
`
