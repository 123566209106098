const groupReservations = reservations => {
  if (!reservations || !reservations.length) {
    return []
  }
  const groupedReservations = []

  reservations.map(reservation => {
    let shouldGroup = false

    groupedReservations.map((groupedReservation, index) => {
      if (shouldGroupReservations(reservation, groupedReservation)) {
        shouldGroup = true

        groupedReservations[index] = {
          ...groupedReservation,
          unitsNumber: groupedReservation.unitsNumber + 1
        }
      }
    })

    if (!shouldGroup) {
      return groupedReservations.push({ ...reservation, unitsNumber: 1 })
    }
  })

  return groupedReservations
}

const shouldGroupReservations = (reservation1, reservation2) => {
  const {
    arrivalDate,
    departureDate,
    adults,
    children,
    currency,
    discounts,
    includedExtras,
    ratePlanName,
    ratePlansId,
    totalAccommodationPrice,
    totalReservationPrice,
    unitType,
    pricesPerDay,
    charges
  } = reservation1

  if (
    arrivalDate === reservation2.arrivalDate &&
    departureDate === reservation2.departureDate &&
    adults === reservation2.adults &&
    children === reservation2.children &&
    currency === reservation2.currency &&
    JSON.stringify(removeIdsFromDiscounts(discounts)) ===
      JSON.stringify(removeIdsFromDiscounts(reservation2.discounts)) &&
    JSON.stringify(removeIdsFromIncludedExtras(includedExtras)) ===
      JSON.stringify(removeIdsFromIncludedExtras(reservation2.includedExtras)) &&
    JSON.stringify(pricesPerDay) === JSON.stringify(reservation2.pricesPerDay) &&
    JSON.stringify(removeIdsFromCharges(charges)) === JSON.stringify(removeIdsFromCharges(reservation2.charges)) &&
    ratePlanName === reservation2.ratePlanName &&
    ratePlansId === reservation2.ratePlansId &&
    totalAccommodationPrice === reservation2.totalAccommodationPrice &&
    totalReservationPrice === reservation2.totalReservationPrice &&
    unitType.id === reservation2.unitType.id
  ) {
    return true
  }
  return false
}

const removeIdsFromIncludedExtras = includedExtras => {
  if (!includedExtras || !includedExtras.length) {
    return includedExtras
  }

  return includedExtras.map(extra => {
    return {
      ...extra,
      id: null,
      perPersonPrices: extra.perPersonPrices
        ? extra.perPersonPrices.map(price => ({ ...price, id: null }))
        : extra.perPersonPrices,
      taxes: extra.taxes ? extra.taxes.map(tax => ({ ...tax, id: null })) : extra.taxes
    }
  })
}

const removeIdsFromCharges = charges => {
  if (!charges || !charges.length) {
    return charges
  }

  return charges.map(charge => {
    return {
      ...charge,
      id: null,
      taxes: charge.taxes ? charge.taxes.map(tax => ({ ...tax, id: null, taxesId: null })) : charge.taxes,
      perPersonPrices: charge.perPersonPrices
        ? charge.perPersonPrices.map(price => ({ ...price, id: null }))
        : charge.perPersonPrices
    }
  })
}

const removeIdsFromDiscounts = discounts => {
  if (!discounts || !discounts.length) {
    return discounts
  }

  return discounts.map(discount => {
    return {
      ...discount,
      id: null,
      discountsId: null
    }
  })
}

export default groupReservations
