import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import {
  PropertyInfoWrapper,
  MaxOccupancyInfo,
  MaxOccupancyLabel,
  MaxOccupancyValue,
  MaxOccupancyWrapper,
  PropertyLink,
  PropertyName,
  LocationWrapper,
  LocationName
} from './style'

import { People } from 'Rentlio/components/Icons/people'
import House from 'Rentlio/components/Icons/house'
import Location from 'Rentlio/components/Icons/location'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

const MaxOccupancy = ({
  standardOccupancy,
  maxOccupancy,
  shouldBeHiddenOnMobile,
  unitTypeName,
  unitTypeSlug,
  unitTypeCity,
  isGroupBookingEngine,
  groupBESlug
}) => {
  const { translate } = useContext(LanguageContext)

  const occupancyLabel = standardOccupancy === maxOccupancy ? standardOccupancy : standardOccupancy + '-' + maxOccupancy

  return (
    <MaxOccupancyWrapper shouldBeHiddenOnMobile={shouldBeHiddenOnMobile}>
      <MaxOccupancyInfo>
        <MaxOccupancyLabel shouldBeHiddenOnMobile={shouldBeHiddenOnMobile}>{translate('Occupancy')}</MaxOccupancyLabel>
        <People />
        <MaxOccupancyValue>
          {occupancyLabel} {translate('persons')}
        </MaxOccupancyValue>
      </MaxOccupancyInfo>
      {isGroupBookingEngine && (
        <PropertyInfoWrapper>
          <PropertyLink
            href={window.location.origin.replace(`//${groupBESlug}.`, `//${unitTypeSlug}.`)}
            target='_blank'
          >
            <House />
            <PropertyName>{unitTypeName}</PropertyName>
          </PropertyLink>
          <LocationWrapper>
            <Location />
            <LocationName>{unitTypeCity}</LocationName>
          </LocationWrapper>
        </PropertyInfoWrapper>
      )}
    </MaxOccupancyWrapper>
  )
}

MaxOccupancy.propTypes = {
  standardOccupancy: PropTypes.number,
  maxOccupancy: PropTypes.number,
  shouldBeHiddenOnMobile: PropTypes.bool,
  unitTypeName: PropTypes.string,
  unitTypeSlug: PropTypes.string,
  unitTypeCity: PropTypes.string,
  isGroupBookingEngine: PropTypes.bool,
  groupBESlug: PropTypes.string
}

MaxOccupancy.defaultProps = {
  shouldBeHiddenOnMobile: false
}

export default MaxOccupancy
