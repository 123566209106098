import PropTypes from 'prop-types'
import React from 'react'
import { Title, Row, PolicyWrapper, Policy, Description } from './style'
import Smoke from 'Rentlio/components/Icons/smoke'
import Children from 'Rentlio/components/Icons/children'
import Pet from 'Rentlio/components/Icons/pet'
import Clock from 'Rentlio/components/Icons/clock'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

class PolicyModal extends React.PureComponent {
  static contextType = LanguageContext
  render() {
    const {
      checkInBegin,
      checkInEnd,
      checkOutBegin,
      checkOutEnd,
      childrenAge,
      maxOccupancyChildren,
      smokingAllowed,
      petsAllowed
    } = this.props
    const { translate } = this.context

    const childrenAllowed = maxOccupancyChildren !== 0

    return (
      <React.Fragment>
        <Title>{translate('General policy')}</Title>
        <Row>
          <PolicyWrapper>
            <Clock />
            <Policy>{translate('Check in')}</Policy>
          </PolicyWrapper>
          {checkInBegin !== null && checkInEnd !== null && (
            <Description>
              {translate('CheckInBetween', { '%checkInStart%': checkInBegin, '%checkInEnd%': checkInEnd })}
            </Description>
          )}
          {checkInBegin === null && checkInEnd === null && <Description>{translate('CheckInWholeDay')}</Description>}
          {checkInBegin !== null && checkInEnd === null && (
            <Description>{translate('CheckInFrom', { '%checkInStart%': checkInBegin })}</Description>
          )}
          {checkInBegin === null && checkInEnd !== null && (
            <Description>{translate('CheckInUntil', { '%checkInEnd%': checkInEnd })}</Description>
          )}
        </Row>
        <Row>
          <PolicyWrapper>
            <Clock />
            <Policy>{translate('Check out')}</Policy>
          </PolicyWrapper>
          {checkOutBegin !== null && checkOutEnd !== null && (
            <Description>
              {translate('CheckOutBetween', { '%checkOutStart%': checkOutBegin, '%checkOutEnd%': checkOutEnd })}
            </Description>
          )}
          {checkOutBegin === null && checkOutEnd === null && <Description>{translate('CheckOutWholeDay')}</Description>}
          {checkOutBegin !== null && checkOutEnd === null && (
            <Description>{translate('CheckOutFrom', { '%checkOutStart%': checkOutBegin })}</Description>
          )}
          {checkOutBegin === null && checkOutEnd !== null && (
            <Description>{translate('CheckOutUntil', { '%checkOutEnd%': checkOutEnd })}</Description>
          )}
        </Row>
        {(!childrenAllowed || childrenAge) && (
          <Row>
            <PolicyWrapper>
              <Children />
              <Policy>{translate('Children policy')}</Policy>
            </PolicyWrapper>
            {!childrenAllowed && <Description>{translate('ChildrenNotAllowed')}</Description>}
            {childrenAllowed && childrenAge && (
              <Description>{translate('ChildrenAllowedFrom', { '%age%': childrenAge })}</Description>
            )}
          </Row>
        )}
        {smokingAllowed !== null && (
          <Row>
            <PolicyWrapper>
              <Smoke />
              <Policy>{translate('SmokingPolicy')}</Policy>
            </PolicyWrapper>
            <Description>
              {smokingAllowed === 'Y' ? translate('SmokingAllowed') : translate('SmokingNotAllowed')}
            </Description>
          </Row>
        )}
        {petsAllowed !== null && (
          <Row>
            <PolicyWrapper>
              <Pet />
              <Policy>{translate('Pet policy')}</Policy>
            </PolicyWrapper>
            <Description>
              {petsAllowed === 'R' && translate('PetsOnRequest')}
              {petsAllowed === 'N' && translate('PetsNotAllowed')}
              {petsAllowed === 'Y' && translate('PetsAllowed')}
            </Description>
          </Row>
        )}
      </React.Fragment>
    )
  }
}

PolicyModal.propTypes = {
  checkInBegin: PropTypes.string,
  checkInEnd: PropTypes.string,
  checkOutBegin: PropTypes.string,
  checkOutEnd: PropTypes.string,
  childrenAge: PropTypes.number,
  smokingAllowed: PropTypes.string,
  petsAllowed: PropTypes.string,
  maxOccupancyChildren: PropTypes.number
}

export default PolicyModal
