import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { RateWrapper, RatePreviewWrapper, RatesToggle, RateValue } from './style'
import { formatPrice } from 'Rentlio/components/utils/Price/price'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import { CurrenciesContext } from 'Rentlio/context/CurrenciesContext'

const RatePreview = ({ totalCost, numberOfRooms, currency }) => {
  const { translate } = useContext(LanguageContext)
  const { currencyConversion } = useContext(CurrenciesContext)
  const { value, currency: currentCurrency } = currencyConversion(totalCost, currency)
  return (
    <RatePreviewWrapper>
      <RateWrapper>
        <RateValue>
          {numberOfRooms} {translate('rooms')}
        </RateValue>
      </RateWrapper>
      <RatesToggle>
        <span>
          {formatPrice(value, currentCurrency)} {currentCurrency}
        </span>
      </RatesToggle>
      {/* <RatePolicies onClick={showModalHandler}>{translate('Policies')}</RatePolicies> */}
    </RatePreviewWrapper>
  )
}

RatePreview.defaultProps = {
  shouldShowRateToggle: true
}

RatePreview.propTypes = {
  numberOfRooms: PropTypes.number,
  totalCost: PropTypes.number,
  currency: PropTypes.string
}

export default RatePreview
