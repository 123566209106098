import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import MonriFooter from 'Rentlio/screen/layout/components/MonriFooter'
import { BE_MONRI } from 'Rentlio/utils/paymentTypesEnum'

const MonriFooterContainer = ({ paymentMethods, locale }) => {
  const monriPaymentMethod = paymentMethods.find(method => Number(method.id) === BE_MONRI)

  return monriPaymentMethod ? (
    <MonriFooter locale={locale} selectedCardTypes={monriPaymentMethod?.settings?.selectedCardTypes} />
  ) : null
}

const mapStateToProps = state => ({
  locale: state.configuration.locale,
  paymentMethods: state.paymentMethods
})

MonriFooterContainer.propTypes = {
  paymentMethods: PropTypes.array,
  locale: PropTypes.string
}

export default connect(mapStateToProps, null)(MonriFooterContainer)
