import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  PromoCta,
  PromoWrapper,
  PromoInput,
  PromoInputWrapper,
  PromoSubmitButton,
  PromoApplied,
  ClearPromoWrapper,
  PromoCodeError
} from './style'
import Close from 'Rentlio/components/Icons/close'
import ArrowRightNarrow from 'Rentlio/components/Icons/arrowRightNarrow'
import { HOME_PAGE } from 'Rentlio/utils/routes'

import { LanguageContext } from 'Rentlio/context/LanguageContext'

class Promo extends Component {
  inputRef = React.createRef()

  static contextType = LanguageContext
  state = {
    showInput: false,
    codeInput: ''
  }

  componentDidMount() {
    if (this.isOfferPage()) {
      return
    }
    document.addEventListener('mousedown', this.handleOutsideClick)
  }

  componentWillUnmount() {
    if (this.isOfferPage()) {
      return
    }
    document.removeEventListener('mousedown', this.handleOutsideClick)
  }

  openInput = () => this.setState({ showInput: true })

  save = () => {
    this.props.setPromoCode(this.state.codeInput)
    this.setState({ showInput: false, codeInput: '' })
  }

  shouldShowCta = () => !this.state.showInput && !this.hasPromoCode()
  shouldShowInput = () => this.state.showInput && !this.hasPromoCode()
  hasPromoCode = () => this.props.promoCode !== null && this.props.promoCode !== ''

  handleOutsideClick = event => {
    if (this.inputRef?.current && !this.inputRef.current.contains(event.target)) {
      this.setState({ showInput: false })
    }
  }

  isOfferPage = () => this.props.location.pathname.startsWith('/offer/')
  shouldShowPromoLayout = () => this.props.location.pathname === HOME_PAGE

  render() {
    const { translate } = this.context
    const { codeInput } = this.state
    const { promoCode, clearPromoCode, isMobile, promoCodeError, isPromoCodeAppliedToAnyRate } = this.props

    if (this.isOfferPage() || !this.shouldShowPromoLayout()) {
      return null
    }

    return (
      <PromoWrapper isMobile={isMobile} ref={this.inputRef}>
        {this.shouldShowCta() && <PromoCta onClick={this.openInput}>{translate('Have a discount code?')}</PromoCta>}
        {this.shouldShowInput() && (
          <PromoInputWrapper>
            <PromoInput value={codeInput} onChange={e => this.setState({ codeInput: e.target.value })} />
            <PromoSubmitButton onClick={this.save}>
              <ArrowRightNarrow />
            </PromoSubmitButton>
          </PromoInputWrapper>
        )}
        {this.hasPromoCode() && (
          <PromoApplied>
            {promoCode} {promoCodeError ? '' : isPromoCodeAppliedToAnyRate ? translate('applied') : false}
            <ClearPromoWrapper onClick={() => clearPromoCode()}>
              <Close />
            </ClearPromoWrapper>
          </PromoApplied>
        )}
        {promoCodeError && promoCode !== '' && <PromoCodeError>{translate('Invalid code')}</PromoCodeError>}
      </PromoWrapper>
    )
  }
}

Promo.propTypes = {
  promoCode: PropTypes.string,
  setPromoCode: PropTypes.func,
  clearPromoCode: PropTypes.func,
  isMobile: PropTypes.bool,
  location: PropTypes.object,
  promoCodeError: PropTypes.bool,
  isPromoCodeAppliedToAnyRate: PropTypes.bool
}

export default Promo
