import styled, { css } from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const Title = styled.div`
  font-size: 32px;
  font-weight: 500;
  line-height: 37.5px;
  margin 40px 0px 30px;
  color: #333333;
`

export const Container = styled.div`
  background: #f5f5f5;
  align-items: center;
  @media ${device.tabletAndWider} {
    display: none;
  }
`

export const Wrapper = styled.div`
  text-align: center;
  margin: 40px 0;
`

export const Column = styled.div`
  justify-content: center;
  display: flex;
  font-size: 14px;
  margin-bottom: 10px;

  ${({ flexDirection }) =>
    flexDirection
      ? css`
          flex-direction: column;
          gap: 10px;
        `
      : null}
`

export const Label = styled.div`
  margin-right: 5px;
`

export const Value = styled.div`
  font-weight: 800;
`

export const ChildrenAges = styled.div`
  font-weight: 400;
  display: inline;
`
