import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const ContactWrapper = styled.div`
  height: 470px;
  background-color: ${props => props.theme.colors.contactBackgroundColor};
  position: relative;

  @media ${device.tablet} {
    background-color: transparent;
    margin-bottom: 550px;
  }

  @media ${device.mobile} {
    height: 660px;
    margin-bottom: 100px;
  }
`

export const Map = styled.div`
  position: absolute;
  top: -40px;
  right: 0;
  width: 780px;
  height: 437px;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.white};
  background-image: url(${props => props.map});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media ${device.laptop} {
    width: 640px;
  }

  @media ${device.tablet} {
    position: relative;
    transform: translateY(-100px);
    width: 90%;
    height: 437px;
    border-radius: 10px;
    margin: 0 auto;
  }
`

export const FullscreenButton = styled.div`
  position: absolute;
  right: 15px;
  bottom: 15px;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.labelTextColor};
  border-radius: 10px;
  cursor: pointer;
  transition: all ${({ theme }) => theme.animations.defaultTransition};

  :hover {
    background: ${props => props.theme.colors.secondary};
  }

  @media ${device.tablet} {
    top: 15px;
  }
`
export const ContactInfoContainer = styled.div`
  width: 400px;
  height: 470px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: ${props => props.theme.zIndex.contactInfo};

  @media ${device.tablet} {
    width: 100%;
    height: auto;
    background-color: ${props => props.theme.colors.contactBackgroundColor};
    text-align: center;
    padding: 90px 0 200px 0;
  }
`
