import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  InformationWrapper,
  Description,
  Name,
  MaxOccupancyInfo,
  MaxOccupancyWrapper,
  MaxOccupancyLabel,
  MaxOccupancyValue,
  MoreDetails
} from './style'
import { People } from 'Rentlio/components/Icons/people'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

class Information extends Component {
  static contextType = LanguageContext
  getDescription() {
    const {
      reservation: { unitType }
    } = this.props

    if (!unitType.description) {
      return ''
    }

    return unitType.description.length > 240 ? unitType.description.slice(0, 240) + '...' : unitType.description
  }

  getOccupancyLabel = () => {
    const {
      reservation: {
        unitType: { standardOccupancy, maxOccupancy }
      }
    } = this.props
    return standardOccupancy === maxOccupancy ? standardOccupancy : standardOccupancy + '-' + maxOccupancy
  }

  render() {
    const { reservation, showUnitTypeModal } = this.props
    const { translate } = this.context

    return (
      <InformationWrapper>
        <MaxOccupancyWrapper>
          <MaxOccupancyInfo>
            <MaxOccupancyLabel>{translate('Occupancy')}</MaxOccupancyLabel>
            <People />
            <MaxOccupancyValue>
              {this.getOccupancyLabel()} {translate('persons')}
            </MaxOccupancyValue>
          </MaxOccupancyInfo>
        </MaxOccupancyWrapper>
        <Name>{reservation.unitType.name}</Name>
        <Description>{this.getDescription()}</Description>
        <MoreDetails onClick={() => showUnitTypeModal()}>{translate('More details')}</MoreDetails>
      </InformationWrapper>
    )
  }
}

Information.propTypes = {
  reservation: PropTypes.object,
  showUnitTypeModal: PropTypes.func
}

export default Information
