import PropTypes from 'prop-types'
import React from 'react'
import { FooterWrapper, Link, Logo, CardLogosWrapper, LogoSecure, WSPaySecure } from './style'

import WSPayCardsEnum from 'Rentlio/utils/wsPayCardsEnum.js'

const WSPayFooter = ({ selectedCardTypes }) => {
  const creditCards = selectedCardTypes.split(',').map(cardId => WSPayCardsEnum.find(card => card.id === cardId))

  return (
    <FooterWrapper>
      <CardLogosWrapper>
        {creditCards.map(card => (
          <Link href={card.link} key={card.id}>
            <Logo>{card.logo}</Logo>
          </Link>
        ))}
        <WSPaySecure href='https://www.wspay.eu/' title='Monri WSpay - Web Secure Payment Gateway' target='_blank'>
          <LogoSecure
            src='https://www.wspay.info/payment-info/wsPayWebSecureLogo-118x50-transparent.png'
            alt='Monri WSpay - Web Secure Payment Gateway'
            border='0'
          />
        </WSPaySecure>
      </CardLogosWrapper>
    </FooterWrapper>
  )
}

WSPayFooter.propTypes = {
  selectedCardTypes: PropTypes.string
}

export default WSPayFooter
