import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const ReceiptItemRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  padding-left: 20px;
  padding-right: 20px;

  @media ${device.tablet} {
    padding: 0;
  }
`

export const ItemName = styled.div`
  width: 100%;
  margin-bottom: 5px;
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  display: flex;
  flex-direction: row;
`

export const Dates = styled.div`
  width: 100%;
  margin-bottom: 15px;
  opacity: 0.8;
`

export const RateName = styled.div`
  width: 100%;
  margin-bottom: 15px;
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
`

export const ChargeRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`

export const ExtrasWrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;
`

export const Label = styled.div`
  opacity: 0.8;

  @media ${device.onlyMobile} {
    max-width: 70%;
  }
`

export const Name = styled.div`
  overflow: hidden;
  font-weight: ${props => props.theme.fonts.mediumWeight};
`

export const Value = styled.div`
  margin-left: auto;
  font-weight: ${props => props.theme.fonts.boldWeight};

  svg {
    margin-left: 10px;
    margin-right: 10px;
  }
`

export const Bold = styled.div`
  font-weight: ${props => props.theme.fonts.boldWeight};
  margin-right: 5px;
`

export const CancellationPolicyWrapper = styled.div`
  margin-top: 10px;
  flex-basis: 100%;
  opacity: 0.8;
`

export const ReceiptGroupRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};

  @media ${device.tablet} {
    padding: 0;
  }
`

export const ReceiptDivider = styled.div`
  width: 100%;
  border-bottom: 1px dashed ${props => props.theme.colors.border};
  margin-bottom: ${props => (props.end ? '0' : '20px')};
`

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  // couldn't center icon inside of span without this hack
  // todo maybe try something better
  > span {
    padding-bottom: 3px;
  }

  @media ${device.tablet} {
    text-align: center;
    justify-content: center;
  }
`

export const LinkText = styled.div`
  color: ${props => props.theme.colors.textColor};
  font-weight: 500;
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  text-decoration: none;
  cursor: pointer;
  text-transform: capitalize;

  @media ${device.tablet} {
    text-align: center;
  }
`

export const TotalCost = styled.div`
  width: 100%;
  text-align: right;
  text-transform: uppercase;
  font-weight: ${props => props.theme.fonts.boldWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  margin-top: 15px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const IncludedExtras = styled.div`
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 15px;
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
`
