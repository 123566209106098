import React from 'react'
import PropTypes from 'prop-types'
import {
  ActiveFilters,
  ActiveFiltersNumber,
  AmenityFilterWrapper,
  AmenityImage,
  AmenityItem,
  AmenityName,
  ClearAllFilter,
  FilterPreviewButton,
  FilterPreviewButtonText,
  MobileAmenityItems,
  MobileModalControls,
  SelectedAmenities,
  ModalSaveButton
} from './style'

import Animation from 'Rentlio/components/UI/Animation'
import Modal from 'Rentlio/components/Modal'
import { breakpoint } from 'Rentlio/style/responsive'
import Close from 'Rentlio/components/Icons/close'
import colors from 'Rentlio/style/colors'

import Plus from 'Rentlio/components/Icons/plus'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

class AmenityFilter extends React.PureComponent {
  static contextType = LanguageContext
  wrapperRef = React.createRef()

  state = {
    filterShown: false,
    modalShown: false
  }

  shouldFilterBeShownAsModal = () => window.innerWidth <= breakpoint.tablet

  componentDidMount() {
    if (!this.shouldFilterBeShownAsModal()) {
      document.addEventListener('mousedown', this.handleOutsideClick)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick)
  }

  handleOutsideClick = event => {
    if (this.wrapperRef && this.wrapperRef.current !== null && !this.wrapperRef.current.contains(event.target)) {
      this.hideFilter()
    }
  }

  showFilter = () => this.setState({ filterShown: true, modalShown: this.shouldFilterBeShownAsModal() })
  hideFilter = () => this.setState({ filterShown: false, modalShown: false })
  isSelected = id => this.props.selectedAmenities.indexOf(id) !== -1

  render() {
    const { filterShown, modalShown } = this.state
    const { amenities, selectedAmenities, filterByAmenity, removeFilterByAmenity, clearAmenityFilters } = this.props
    const { translate } = this.context

    if (amenities.length === 0) {
      return null
    }

    return (
      <AmenityFilterWrapper selected={filterShown} ref={this.wrapperRef}>
        {filterShown &&
          !modalShown &&
          amenities.map(({ id, imageUrl, name }) => {
            const isSelected = this.isSelected(id)

            return (
              <AmenityItem
                key={id}
                onClick={() => (isSelected ? removeFilterByAmenity(id) : filterByAmenity(id))}
                selected={isSelected}
              >
                <AmenityImage src={imageUrl} selected={isSelected} />
                <AmenityName>{name}</AmenityName>
              </AmenityItem>
            )
          })}

        <Animation show={filterShown && modalShown} animationType={'fadeInOut'} isModal>
          <Modal show={filterShown && modalShown} hideModalHandler={this.hideFilter}>
            <MobileAmenityItems>
              {amenities.map(({ id, imageUrl, name }) => {
                const isSelected = this.isSelected(id)

                return (
                  <AmenityItem
                    key={id}
                    onClick={() => (isSelected ? removeFilterByAmenity(id) : filterByAmenity(id))}
                    selected={isSelected}
                  >
                    <AmenityImage src={imageUrl} selected={isSelected} />
                    <AmenityName>{name}</AmenityName>
                  </AmenityItem>
                )
              })}
            </MobileAmenityItems>
            <MobileModalControls>
              <ModalSaveButton onClick={this.hideFilter}>{translate('Save')}</ModalSaveButton>
              <ClearAllFilter onClick={clearAmenityFilters} showOnMobile>
                {translate('Clear all filters')}
              </ClearAllFilter>
            </MobileModalControls>
          </Modal>
        </Animation>

        {!filterShown && (
          <FilterPreviewButton onClick={this.showFilter}>
            <Plus />
            <FilterPreviewButtonText>{translate('Add filter')}</FilterPreviewButtonText>
          </FilterPreviewButton>
        )}
        {!filterShown && (
          <SelectedAmenities>
            {amenities
              .filter(({ id }) => this.isSelected(id))
              .map(({ id, imageUrl, name }) => (
                <AmenityItem key={id} onClick={() => removeFilterByAmenity(id)} small>
                  <AmenityImage src={imageUrl} />
                  <AmenityName>{name}</AmenityName>
                  <Close height={7} width={7} color={colors.primary} />
                </AmenityItem>
              ))}
          </SelectedAmenities>
        )}
        {!filterShown && selectedAmenities.length > 0 && (
          <>
            <ClearAllFilter onClick={clearAmenityFilters}>{translate('Clear all filters')}</ClearAllFilter>
            <ActiveFilters>
              {translate('Active')}
              <ActiveFiltersNumber>{selectedAmenities.length}</ActiveFiltersNumber>
            </ActiveFilters>
          </>
        )}
      </AmenityFilterWrapper>
    )
  }
}

AmenityFilter.propTypes = {
  amenities: PropTypes.array,
  selectedAmenities: PropTypes.array,
  filterByAmenity: PropTypes.func,
  removeFilterByAmenity: PropTypes.func,
  clearAmenityFilters: PropTypes.func
}

export default AmenityFilter
