import createReducer from 'Rentlio/state/utils/createReducer'
import { ACTIVATE_COOKIE_NOTICE, ACCEPT_COOKIE_NOTICE } from 'Rentlio/state/types'

const initialState = {
  cookieNoticeIsActive: false
}

const cookieNotice = createReducer(initialState)({
  [ACTIVATE_COOKIE_NOTICE]: (state, { isActive }) => ({ ...state, cookieNoticeIsActive: isActive }),
  [ACCEPT_COOKIE_NOTICE]: state => ({ ...state, cookieNoticeIsActive: false })
})

export default cookieNotice
