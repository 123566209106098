import React from 'react'

const Location = () => (
  <svg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10 4.90909C10 8.72727 5 12 5 12C5 12 0 8.72727 0 4.90909C1.97602e-08 3.60712 0.526784 2.35847 1.46447 1.43784C2.40215 0.517206 3.67392 0 5 0C6.32608 0 7.59785 0.517206 8.53553 1.43784C9.47322 2.35847 10 3.60712 10 4.90909Z'
      fill='#333333'
    />
  </svg>
)

export default Location
