import styled from 'styled-components'

export const NotBookableWrapper = styled.div`
  width: 180px;
  margin-bottom: 20px;
  font-size: ${({ theme }) => theme.fonts._14};
  line-height: ${({ theme }) => theme.fonts._16};
  color: ${({ theme }) => theme.colors.alertColor};
  text-align: center;
`
