import PropTypes from 'prop-types'
import React from 'react'
import { Wrapper, NoteWrapper, IconWrapper } from './style'
import Info from 'Rentlio/components/Icons/info'
import { breakpoint as responsive, device } from 'Rentlio/style/responsive'
import colors from 'Rentlio/style/colors'

class RatePlanNote extends React.PureComponent {
  mediaMatchQuery = window.matchMedia(device.tablet)

  state = {
    isMobile: window.innerWidth <= responsive.tablet
  }

  componentDidMount() {
    this.checkMediaWidth(this.mediaMatchQuery)

    // NOTE: Backward compatibility for older version of safari (13 etc. - chrome and firefox are ok here) - don't remove !
    // Please be aware that the MediaQueryList.addListener() method has been deprecated.
    // https://www.w3.org/TR/cssom-view-1/#mediaquerylist
    if (this.mediaMatchQuery?.addEventListener) {
      this.mediaMatchQuery.addEventListener('change', this.checkMediaWidth)
    } else {
      this.mediaMatchQuery.addListener(this.checkMediaWidth)
    }
  }

  componentWillUnmount() {
    if (this.mediaMatchQuery?.addEventListener) {
      this.mediaMatchQuery.removeEventListener('change', this.checkMediaWidth)
    } else {
      this.mediaMatchQuery.removeListener(this.checkMediaWidth)
    }
  }

  checkMediaWidth = (matchQuery = this.mediaMatchQuery) => {
    if (matchQuery.matches) {
      return this.setState({ isMobile: true })
    }

    this.setState({ isMobile: false })
  }

  render() {
    const { note, promote, isPackageDeal } = this.props
    const { isMobile } = this.state
    return (
      <Wrapper color={promote ? null : isPackageDeal ? colors.black : colors.unitTypeOddBackground}>
        <IconWrapper>
          <Info
            width={isMobile ? 20 : 24}
            height={isMobile ? 20 : 24}
            color={promote ? colors.secondary : isPackageDeal ? colors.white : colors.border}
          />
        </IconWrapper>
        <NoteWrapper color={promote ? null : isPackageDeal ? colors.white : colors.policyText}>{note}</NoteWrapper>
      </Wrapper>
    )
  }
}

RatePlanNote.propTypes = {
  note: PropTypes.string,
  promote: PropTypes.bool,
  isPackageDeal: PropTypes.bool
}
export default RatePlanNote
