import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getBookingEngine } from 'Rentlio/state/reducers/bookingEngine'
import Contact from 'Rentlio/screen/layout/components/Contact'
import colors from 'Rentlio/style/colors'
import getCountryDialCode from 'Rentlio/helper/phoneNumber'

const ContactContainer = ({ bookingEngine }) => {
  const {
    address,
    phone,
    email,
    web,
    latitude,
    longitude,
    primaryColor,
    countryDialCode,
    secondPhone,
    secondCountryDialCode
  } = bookingEngine

  const values = [
    { label: 'Address', value: address },
    { label: 'Phone', value: phone && countryDialCode ? `${getCountryDialCode(countryDialCode)} ${phone}` : null },
    {
      label: 'Second phone',
      value: secondPhone && secondCountryDialCode ? `${getCountryDialCode(secondCountryDialCode)} ${secondPhone}` : null
    },
    { label: 'Email', value: email },
    { label: 'Web', value: web }
  ]

  return (
    <Contact values={values} latitude={latitude} longitude={longitude} markerColor={primaryColor || colors.primary} />
  )
}

ContactContainer.propTypes = {
  bookingEngine: PropTypes.object,
  locale: PropTypes.string
}

const mapStateToProps = state => ({
  bookingEngine: getBookingEngine(state),
  locale: state.configuration.locale
})

export default connect(mapStateToProps)(ContactContainer)
