import React from 'react'

const Email = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.41748 11C9.80583 11.3 10.2913 11.3 10.5825 11L19.9029 3.4C19.9029 2.6 19.2233 2 18.4466 2H1.5534C0.776699 2 0.0970874 2.6 0 3.4L9.41748 11Z'
      fill='#333333'
    />
    <path
      d='M11.8447 13.5C11.3592 13.9 10.6796 14.1 10 14.1C9.32039 14.1 8.73786 13.9 8.15534 13.5L0 6.89999V16.1C0 17 0.679612 17.7 1.5534 17.7H18.4466C19.3204 17.7 20 17 20 16.1V6.89999L11.8447 13.5Z'
      fill='#333333'
    />
  </svg>
)

export default Email
