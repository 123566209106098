import React from 'react'
import { PoweredByRentlioWrapper } from './style'
import rentlioImage from 'Images/rentlio.svg'
import { Lighting } from 'Rentlio/components/Icons/lighting'

const PoweredByRentlio = () => {
  return (
    <PoweredByRentlioWrapper href='https://rentl.io' target='_blank'>
      <Lighting />
      <span>{'Powered by'}</span>
      <img src={rentlioImage} />
    </PoweredByRentlioWrapper>
  )
}

export default PoweredByRentlio
