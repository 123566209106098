import moment from 'moment'
import { getCountryNameById } from 'Rentlio/screen/payment/utils/getCountryCodeById'
import {
  ADD_ITEM_TO_BASKET,
  FETCH_AVAILABILITY,
  OPEN_UNIT_TYPE_MODAL,
  OPEN_PACKAGE_DEAL_MODAL,
  SEND_PAYMENT_FORM_INPUT_EVENT,
  SEND_SUCCESSFUL_RESERVATION_EVENT,
  ACCEPT_COOKIE_NOTICE
} from 'Rentlio/state/types'
import { getHourFormatFromRawValue } from 'Rentlio/utils/checkInHoursParser'
import { DATE_URL_FORMAT } from 'Rentlio/utils/dateFormatEnum'

const whitelistedTypes = [
  FETCH_AVAILABILITY,
  ADD_ITEM_TO_BASKET,
  OPEN_UNIT_TYPE_MODAL,
  SEND_SUCCESSFUL_RESERVATION_EVENT,
  SEND_PAYMENT_FORM_INPUT_EVENT,
  ACCEPT_COOKIE_NOTICE,
  OPEN_PACKAGE_DEAL_MODAL
]

const gtm = ({ getState }) => next => action => {
  next(action)

  if (!whitelistedTypes.includes(action.type)) {
    return
  }

  const { bookingEngine, request } = getState()

  if (!bookingEngine.hasGTM || !window.gtmDataLayer) {
    return
  }

  switch (action.type) {
    case FETCH_AVAILABILITY:
      sendSearchGTMEvent(action, getState)
      break
    case ACCEPT_COOKIE_NOTICE:
      // on cookie notice accept clicked we are sending search event to GTM but here we are getting action body data from redux
      sendSearchGTMEvent(
        {
          meta: {
            body: {
              from: request.from ? moment.unix(request.from).format(DATE_URL_FORMAT) : '',
              to: request.to ? moment.unix(request.to).format(DATE_URL_FORMAT) : '',
              promoCode: request.promoCode
            }
          }
        },
        getState
      )
      break
    case ADD_ITEM_TO_BASKET:
      sendAddItemToBasketEvent(action, getState)
      break
    case OPEN_UNIT_TYPE_MODAL:
      sendUnitTypeModalOpenedEvent(action, getState)
      break
    case OPEN_PACKAGE_DEAL_MODAL:
      sendPackageDealModalOpenedEvent(action, getState)
      break
    case SEND_SUCCESSFUL_RESERVATION_EVENT:
      sendReservationCompletedEvent(action, getState)
      break
    case SEND_PAYMENT_FORM_INPUT_EVENT:
      sendPaymentFormInputEvent(getState)
  }
}

function sendSearchGTMEvent(action, getState) {
  const {
    meta: { body }
  } = action
  const { configuration, request } = getState()
  const trackingData = {
    currency: configuration.currency,
    language: configuration.locale,
    checkIn: body.from,
    checkOut: body.to,
    numberOfAdults: request.adults,
    numberOfChildren: request.children.length,
    childrenAges: request.children,
    numberOfUnits: 1
  }

  if (body.promoCode) {
    trackingData.promoCode = body.promoCode
  }

  window.gtmDataLayer.push({
    event: 'rbe.search',
    trackingData
  })
}

function sendAddItemToBasketEvent(action, getState) {
  const { payload, quantity } = action
  const { configuration, request, basket } = getState()

  const trackingData = {
    currency: configuration.currency,
    language: configuration.locale,
    checkIn: moment.unix(request.from).format(DATE_URL_FORMAT),
    checkOut: moment.unix(request.to).format(DATE_URL_FORMAT),
    numberOfAdults: request.adults,
    numberOfChildren: request.children.length,
    childrenAges: request.children,
    numberOfUnits: quantity,
    isLoadedFromSession: payload.isLoadedFromSession,
    cart: basket.map(item => ({
      numberOfUnits: 1,
      rate: item.ratePlanName,
      unitType: item.unitTypeName,
      unitTypeId: item.unitTypeId,
      price: item.totalRate,
      numberOfAdults: request.adults,
      numberOfChildren: request.children.length,
      childrenAges: request.children
    }))
  }

  if (request.promoCode) {
    trackingData.promoCode = request.promoCode
  }

  const nights = moment.unix(request.to).diff(moment.unix(request.from), 'days')

  window.gtmDataLayer.push({
    event: 'rbe.addToCart',
    trackingData
  })

  // ecommerce enhanced event - add to cart
  window.gtmDataLayer.push({ ecommerce: null, trackingData: null }) // Clear the previous ecommerce object.
  window.gtmDataLayer.push({
    event: 'eec.addToCart',
    ecommerce: {
      currencyCode: configuration.currency,
      add: {
        products: basket.map(item => ({
          name: item.unitTypeName,
          id: item.unitTypeId,
          price: item.totalRate,
          brand: '',
          category: nights,
          variant: item.ratePlanName,
          quantity: 1
        }))
      }
    }
  })

  // ecommerce GA4 event - add to cart
  window.gtmDataLayer.push({ ecommerce: null, trackingData: null }) // Clear the previous ecommerce object.
  window.gtmDataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      items: basket.map(item => ({
        item_id: item.unitTypeId,
        item_name: item.unitTypeName,
        affiliation: '',
        coupon: request.promoCode && !request.promoCodeError ? request.promoCode : '', // ako postoji kupon
        currency: configuration.currency,
        discount: '',
        index: '',
        item_brand: '',
        item_category: item.ratePlanName,
        item_category2: (request.adults || 0) + (request.children?.length || 0),
        item_category3: `${moment.unix(request.from).format(DATE_URL_FORMAT)} - ${moment
          .unix(request.to)
          .format(DATE_URL_FORMAT)}`,
        item_category4: nights,
        item_category5: 1,
        item_list_id: '',
        item_list_name: '',
        item_variant: '',
        location_id: '',
        price: item.totalRate,
        quantity: 1
      }))
    }
  })
}

function sendUnitTypeModalOpenedEvent(action, getState) {
  const { payload } = action
  const { configuration, request } = getState()

  const trackingData = {
    currency: configuration.currency,
    language: configuration.locale,
    checkIn: moment.unix(request.from).format(DATE_URL_FORMAT),
    checkOut: moment.unix(request.to).format(DATE_URL_FORMAT),
    numberOfAdults: request.adults,
    numberOfChildren: request.children.length,
    childrenAges: request.children,
    numberOfUnits: request.rooms,
    unitType: payload.name,
    unitTypeId: payload.unitTypeId
  }

  if (request.promoCode) {
    trackingData.promoCode = request.promoCode
  }

  const nights = moment.unix(request.to).diff(moment.unix(request.from), 'days')

  window.gtmDataLayer.push({
    event: 'rbe.viewUnitTypeDetails',
    trackingData
  })

  // ecommerce enhanced event - view details
  window.gtmDataLayer.push({ ecommerce: null, trackingData: null }) // Clear the previous ecommerce object.
  window.gtmDataLayer.push({
    event: 'eec.viewDetails',
    ecommerce: {
      detail: {
        actionField: { list: '' },
        products: [
          {
            name: payload.name,
            id: payload.unitTypeId,
            price: '',
            brand: '',
            category: nights,
            variant: '',
            quantity: 1
          }
        ]
      }
    }
  })

  // send GA4 ecommerce event
  window.gtmDataLayer.push({ ecommerce: null, trackingData: null }) // Clear the previous ecommerce object.
  window.gtmDataLayer.push({
    event: 'view_item',
    ecommerce: {
      items: [
        {
          item_id: payload.unitTypeId,
          item_name: payload.name,
          affiliation: '',
          coupon: request.promoCode && !request.promoCodeError ? request.promoCode : '', // ako postoji kupon
          currency: configuration.currency,
          discount: '',
          index: '',
          item_brand: '',
          item_category: '',
          item_category2: (request.adults || 0) + (request.children?.length || 0),
          item_category3: `${moment.unix(request.from).format(DATE_URL_FORMAT)} - ${moment
            .unix(request.to)
            .format(DATE_URL_FORMAT)}`,
          item_category4: nights,
          item_category5: request.rooms,
          item_list_id: '',
          item_list_name: '',
          item_variant: '',
          location_id: '',
          price: '',
          quantity: 1
        }
      ]
    }
  })
}

function sendReservationCompletedEvent(action, getState) {
  const { guestDetails, internalId } = action
  const { configuration, request, basket } = getState()

  const trackingData = {
    transactionId: internalId,
    currency: configuration.currency,
    language: configuration.locale,
    checkIn: moment.unix(request.from).format(DATE_URL_FORMAT),
    checkOut: moment.unix(request.to).format(DATE_URL_FORMAT),
    country: getCountryNameById(guestDetails.country),
    timeOfArrival: getHourFormatFromRawValue(guestDetails.estimatedTimeOfArrival),
    numberOfUnits: basket.length,
    cart: [],
    purchased: basket.map(item => ({
      numberOfUnits: 1,
      rate: item.ratePlanName,
      unitType: item.unitTypeName,
      unitTypeId: item.unitTypeId,
      price: item.totalRate,
      numberOfAdults: request.adults,
      numberOfChildren: request.children.length,
      childrenAges: request.children
    }))
  }

  const nights = moment.unix(request.to).diff(moment.unix(request.from), 'days')

  if (request.promoCode) {
    trackingData.promoCode = request.promoCode
  }

  window.gtmDataLayer.push({
    event: 'rbe.purchase',
    trackingData
  })

  // ecommerce enhanced event - purchase
  window.gtmDataLayer.push({ ecommerce: null, trackingData: null }) // Clear the previous ecommerce object.
  window.gtmDataLayer.push({
    event: 'eec.purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id: internalId,
          affiliation: '',
          revenue: basket.map(item => item.totalRate).reduce((a, b) => a + b, 0),
          coupon: ''
        },
        products: basket.map(item => ({
          name: item.unitTypeName,
          id: item.unitTypeId,
          price: item.totalRate,
          brand: '',
          category: nights,
          variant: item.ratePlanName,
          quantity: 1,
          coupon: ''
        }))
      }
    }
  })

  // ecommerce enhanced GA4 event - purchase
  window.gtmDataLayer.push({ ecommerce: null, trackingData: null }) // Clear the previous ecommerce object.
  window.gtmDataLayer.push({
    event: 'purchase',
    ecommerce: {
      transaction_id: internalId,
      affiliation: '',
      value: basket.map(item => item.totalRate).reduce((a, b) => a + b, 0),
      tax: '',
      shipping: '',
      currency: configuration.currency,
      coupon: request.promoCode && !request.promoCodeError ? request.promoCode : '', // ako postoji kupon trenutno unesen u requestu
      items: basket.map(item => ({
        item_id: item.unitTypeId,
        item_name: item.unitTypeName,
        affiliation: '',
        coupon: request.promoCode && !request.promoCodeError ? request.promoCode : '',
        currency: configuration.currency,
        discount: '',
        index: '',
        item_brand: '',
        item_category: item.ratePlanName,
        item_category2: (request.adults || 0) + (request.children?.length || 0),
        item_category3: `${moment.unix(request.from).format(DATE_URL_FORMAT)} - ${moment
          .unix(request.to)
          .format(DATE_URL_FORMAT)}`,
        item_category4: nights,
        item_category5: 1,
        item_list_id: '',
        item_list_name: '',
        item_variant: '',
        location_id: '',
        price: item.totalRate,
        promotion_id: '',
        promotion_name: '',
        quantity: 1
      }))
    }
  })
}

function sendPaymentFormInputEvent(getState) {
  const { configuration, request, basket } = getState()
  const trackingData = {
    currency: configuration.currency,
    language: configuration.locale,
    checkIn: moment.unix(request.from).format(DATE_URL_FORMAT),
    checkOut: moment.unix(request.to).format(DATE_URL_FORMAT),
    numberOfAdults: request.adults,
    numberOfChildren: request.children.length,
    childrenAges: request.children,
    numberOfUnits: request.rooms
  }

  if (request.promoCode) {
    trackingData.promoCode = request.promoCode
  }

  const nights = moment.unix(request.to).diff(moment.unix(request.from), 'days')

  window.gtmDataLayer.push({
    event: 'rbe.checkoutInput',
    trackingData
  })

  // ecommerce enhanced event - checkout
  window.gtmDataLayer.push({ ecommerce: null, trackingData: null }) // Clear the previous ecommerce object.
  window.gtmDataLayer.push({
    event: 'eec.checkout',
    ecommerce: {
      checkout: {
        actionField: { step: 1, option: '' },
        products: basket.map(item => ({
          name: item.unitTypeName,
          id: item.unitTypeId,
          price: item.totalRate,
          brand: '',
          category: nights,
          variant: item.ratePlanName,
          quantity: 1
        }))
      }
    }
  })

  // ecommerce GA4 enhanced event - checkout
  window.gtmDataLayer.push({ ecommerce: null, trackingData: null }) // Clear the previous ecommerce object.
  window.gtmDataLayer.push({
    event: 'begin_checkout',
    ecommerce: {
      items: basket.map(item => ({
        item_id: item.unitTypeId,
        item_name: item.unitTypeName,
        affiliation: '',
        coupon: request.promoCode && !request.promoCodeError ? request.promoCode : '', // ako postoji kupon
        currency: configuration.currency,
        discount: '',
        index: '',
        item_brand: '',
        item_category: item.ratePlanName,
        item_category2: (request.adults || 0) + (request.children?.length || 0),
        item_category3: `${moment.unix(request.from).format(DATE_URL_FORMAT)} - ${moment
          .unix(request.to)
          .format(DATE_URL_FORMAT)}`,
        item_category4: nights,
        item_category5: 1,
        item_list_id: '',
        item_list_name: '',
        item_variant: '',
        location_id: '',
        price: item.totalRate,
        quantity: 1
      }))
    }
  })
}

function sendPackageDealModalOpenedEvent(action, getState) {
  const { payload } = action
  const { configuration, request } = getState()

  const trackingData = {
    currency: configuration.currency,
    language: configuration.locale,
    checkIn: moment.unix(request.from).format(DATE_URL_FORMAT),
    checkOut: moment.unix(request.to).format(DATE_URL_FORMAT),
    numberOfAdults: request.adults,
    numberOfChildren: request.children.length,
    childrenAges: request.children,
    numberOfUnits: request.rooms,
    rate: payload.name,
    rateId: payload.rateId
  }

  if (request.promoCode) {
    trackingData.promoCode = request.promoCode
  }

  const nights = moment.unix(request.to).diff(moment.unix(request.from), 'days')

  window.gtmDataLayer.push({
    event: 'rbe.viewPackageDealDetails',
    trackingData
  })

  // ecommerce enhanced event - view details
  window.gtmDataLayer.push({ ecommerce: null, trackingData: null }) // Clear the previous ecommerce object.
  window.gtmDataLayer.push({
    event: 'eec.viewDetails',
    ecommerce: {
      detail: {
        actionField: { list: '' },
        products: [
          {
            name: payload.name,
            id: payload.rateId,
            price: '',
            brand: '',
            category: nights,
            variant: '',
            quantity: 1
          }
        ]
      }
    }
  })

  // send GA4 ecommerce event
  window.gtmDataLayer.push({ ecommerce: null, trackingData: null }) // Clear the previous ecommerce object.
  window.gtmDataLayer.push({
    event: 'view_item',
    ecommerce: {
      items: [
        {
          item_id: payload.rateId,
          item_name: payload.name,
          affiliation: '',
          coupon: request.promoCode && !request.promoCodeError ? request.promoCode : '', // ako postoji kupon
          currency: configuration.currency,
          discount: '',
          index: '',
          item_brand: '',
          item_category: '',
          item_category2: (request.adults || 0) + (request.children?.length || 0),
          item_category3: `${moment.unix(request.from).format(DATE_URL_FORMAT)} - ${moment
            .unix(request.to)
            .format(DATE_URL_FORMAT)}`,
          item_category4: nights,
          item_category5: request.rooms,
          item_list_id: '',
          item_list_name: '',
          item_variant: '',
          location_id: '',
          price: '',
          quantity: 1
        }
      ]
    }
  })
}

export default gtm
