import { FETCH_AVAILABILITY, LOADING_DONE } from 'Rentlio/state/types'
import createReducer from 'Rentlio/state/utils/createReducer'

const initialState = {
  animationLoading: false
}

const animationLoader = createReducer(initialState)({
  [FETCH_AVAILABILITY]: state => ({ ...state, animationLoading: true }),
  [LOADING_DONE]: state => ({ ...state, animationLoading: false })
})

export default animationLoader
