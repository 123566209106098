import { FETCH_AVAILABILITY_COMPLETED } from 'Rentlio/state/types'
import createReducer from 'Rentlio/state/utils/createReducer'

const initialState = []

const paymentMethods = createReducer(initialState)({
  [FETCH_AVAILABILITY_COMPLETED]: (state, action) => [...action.payload.paymentMethods]
})

export default paymentMethods
