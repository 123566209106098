import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { openPrivacyPolicyModal, openTermsOfServiceModal } from 'Rentlio/state/actions/ui'
import { getBookingEngine } from 'Rentlio/state/reducers/bookingEngine'
import Footer from 'Rentlio/screen/layout/components/Footer'

const FooterContainer = ({ bookingEngine, openTermsOfServiceModal, openPrivacyPolicyModal }) => {
  const { facebook, instagram, twitter, footerLogoUrl, footerLabel, footerWebsite } = bookingEngine

  return (
    <Footer
      facebook={facebook}
      instagram={instagram}
      twitter={twitter}
      footerLogoUrl={footerLogoUrl}
      footerLabel={footerLabel}
      footerWebsite={footerWebsite}
      openTermsOfServiceModal={openTermsOfServiceModal}
      openPrivacyPolicyModal={openPrivacyPolicyModal}
    />
  )
}

FooterContainer.propTypes = {
  bookingEngine: PropTypes.object,
  locale: PropTypes.string,
  openTermsOfServiceModal: PropTypes.func,
  openPrivacyPolicyModal: PropTypes.func
}

const mapStateToProps = state => ({
  bookingEngine: getBookingEngine(state),
  locale: state.configuration.locale
})

const mapDispatchToProps = dispatch => ({
  openTermsOfServiceModal: () => dispatch(openTermsOfServiceModal()),
  openPrivacyPolicyModal: () => dispatch(openPrivacyPolicyModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer)
