const BorderCrossingPassForeign = 1
const TravelDocumentsForRefugeesIssuedByRH = 2
const Other = 3
const SailorsBookletCroatian = 4
const ChildrensPassportForeign = 5
const OfficialPassportCroatian = 6
const DiplomaticPassportForeign = 7
const TravelDocumentIssuedByRH = 8
const ProofOfPassportRetention = 9
const DiplomaticPersonalIDCard = 10
const HealthInsuranceCard = 11
const TravelDocumentForStatelessPersons = 12
const ChildrensPassportCroatian = 13
const PersonalPassportCroatian = 14
const JointPassportCroatian = 15
const DriversLicenceCroatian = 16
const FamilyPassportCroatian = 17
const PersonalPassportForeign = 18
const DriversLicenceForeign = 19
const PersonalIDCardForForeignersIssuedByRH = 20
const DiplomaticPassportCroatian = 21
const DocumentProofOfLostOrStoledTravelDocuments = 22
const PersonalIDCardForeign = 23
const TravelPaperForForeignersForeign = 24
const PersonalIDCardCroatian = 25
const ShippingBookletForeign = 26
const SailorsBookletForeign = 27
const TravelDocumentsIssuedOnTheBasisOfInternationalContracts = 28
const OfficialPassportForeign = 29
const FamilyPassportForeign = 30
const JointPassportForeign = 31
const RefugeeTravelDocuments = 32

export const TravelDocumentTypesEnum = {
  BorderCrossingPassForeign,
  TravelDocumentsForRefugeesIssuedByRH,
  Other,
  SailorsBookletCroatian,
  ChildrensPassportForeign,
  OfficialPassportCroatian,
  DiplomaticPassportForeign,
  TravelDocumentIssuedByRH,
  ProofOfPassportRetention,
  DiplomaticPersonalIDCard,
  HealthInsuranceCard,
  TravelDocumentForStatelessPersons,
  ChildrensPassportCroatian,
  PersonalPassportCroatian,
  JointPassportCroatian,
  DriversLicenceCroatian,
  FamilyPassportCroatian,
  PersonalPassportForeign,
  DriversLicenceForeign,
  PersonalIDCardForForeignersIssuedByRH,
  DiplomaticPassportCroatian,
  DocumentProofOfLostOrStoledTravelDocuments,
  PersonalIDCardForeign,
  TravelPaperForForeignersForeign,
  PersonalIDCardCroatian,
  ShippingBookletForeign,
  SailorsBookletForeign,
  TravelDocumentsIssuedOnTheBasisOfInternationalContracts,
  OfficialPassportForeign,
  FamilyPassportForeign,
  JointPassportForeign,
  RefugeeTravelDocuments
}
