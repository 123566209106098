import styled from 'styled-components'
export const InputWithIconWrapper = styled.div`
  position: relative;
`

export const Icon = styled.div`
  position: absolute;
  height: 20px;
  width: 20px;
  right: 20px;
  top: 20px;
`
