import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import OfferFooter from 'Rentlio/screen/offer/components/OfferFooter'
import { isOfferStillActive } from 'Rentlio/utils/bookingTypeEnum'
const OfferFooterContainer = props => {
  // eslint-disable-next-line react/prop-types
  const { booking, offer } = props

  if (!isOfferStillActive(offer, booking)) {
    return null
  }
  return <OfferFooter {...props} />
}

const mapStateToProps = ({ booking, offer }) => ({ booking, offer })

export default withRouter(connect(mapStateToProps)(OfferFooterContainer))
