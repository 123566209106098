import React from 'react'
import PropTypes from 'prop-types'
import colors from 'Rentlio/style/colors'

const ArrowRightNarrow = ({ height, width, stroke }) => (
  <svg width={height} height={width} viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M1 6H11' stroke={stroke} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M6 1L11 6L6 11' stroke={stroke} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)

ArrowRightNarrow.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  stroke: PropTypes.string
}

ArrowRightNarrow.defaultProps = {
  height: 12,
  width: 12,
  stroke: colors.veryDarkGray
}

export default ArrowRightNarrow
