import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const ReservationGrid = styled.div`
  display: flex;
  padding-top: 60px;
  padding-bottom: 60px;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
`

export const PaddingContainer = styled.div`
  max-width: 100%;
  align-self: center;

  @media ${device.tablet} {
    display: flex;
    justify-content: center;
  }

  @media ${device.mobile} {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
`
