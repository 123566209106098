import styled, { css } from 'styled-components'

const setAnimationType = ({ animationType, show }) => {
  switch (animationType) {
    case 'fadeInOut':
      return show ? `fadeIn 0.2s linear both` : `fadeOut 0.2s linear both`
    case 'fadeInOutCart':
      return show ? `fadeIn 0.2s 1s linear both` : `fadeOut 0.2s 1s linear both`
    case 'fadeInOutBottomSlow':
      return show ? `fadeIn-from-bottom 1s 0.2s ease both` : `fadeOut-to-bottom 1s 0.2s ease both`
    case 'fadeInOutBottom':
      return show
        ? `fadeIn-from-bottom 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both`
        : `fadeOut-to-bottom 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both`
  }
}

export const AnimationWrapper = styled.div`
  position: relative;
  z-index: ${({ zIndex }) => zIndex || 1};
  animation: ${setAnimationType};
  ${({ isModal }) =>
    isModal
      ? css`
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: auto;
          z-index: 100;
        `
      : null}

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fadeIn-from-bottom {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeOut-to-bottom {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(10px);
    }
  }
`
