import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { DayPickerRangeController } from 'react-dates'
import { END_DATE, START_DATE } from 'react-dates/constants'
import {
  GroupBEDateDivider,
  ArrowWrapper,
  Date,
  DateRow,
  Day,
  Label,
  Month,
  NightPickerCalendarWrapper,
  NightPickerInputWrapper,
  NightPickerWrapper,
  DayPickerDivider,
  Year
} from './style'
import { DateArrow } from 'Rentlio/components/Icons/dateArrow'
import { breakpoint as responsive, device } from 'Rentlio/style/responsive'
import ArrowLeft from 'Rentlio/components/Icons/arrowLeft'
import ArrowRight from 'Rentlio/components/Icons/arrowRight'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

class NightsPicker extends React.Component {
  static contextType = LanguageContext
  calendarWrapperRef = React.createRef()
  mediaMatchQuery = window.matchMedia(device.tablet)

  state = {
    focusedInput: null,
    startDate: moment.unix(this.props.from),
    endDate: moment.unix(this.props.to),
    showCalendar: false,
    isMobile: window.innerWidth <= responsive.tablet
  }

  componentDidMount() {
    this.checkMediaWidth(this.mediaMatchQuery)

    // NOTE: Backward compatibility for older version of safari (13 etc. - chrome and firefox are ok here) - don't remove !
    // Please be aware that the MediaQueryList.addListener() method has been deprecated.
    // https://www.w3.org/TR/cssom-view-1/#mediaquerylist
    if (this.mediaMatchQuery?.addEventListener) {
      this.mediaMatchQuery.addEventListener('change', this.checkMediaWidth)
    } else {
      this.mediaMatchQuery.addListener(this.checkMediaWidth)
    }
  }

  componentWillUnmount() {
    if (this.mediaMatchQuery?.addEventListener) {
      this.mediaMatchQuery.removeEventListener('change', this.checkMediaWidth)
    } else {
      this.mediaMatchQuery.removeListener(this.checkMediaWidth)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.from !== this.props.from || prevProps.to !== this.props.to) {
      this.setState({
        startDate: moment.unix(this.props.from),
        endDate: moment.unix(this.props.to)
      })
    }
  }

  checkMediaWidth = (matchQuery = this.mediaMatchQuery) => {
    if (matchQuery.matches) {
      return this.setState({ isMobile: true })
    }

    this.setState({ isMobile: false })
  }

  onDatesChange = ({ startDate, endDate }) => {
    const { onDatesChange } = this.props
    onDatesChange(startDate, endDate)

    this.setState({ startDate, endDate })
  }

  onFocusChange = focusedInput => {
    this.setState({ focusedInput: focusedInput })

    if (focusedInput === null) {
      this.closeCalendar()
    }
  }

  showCalendar = focusedInput => {
    this.setState({ showCalendar: true, focusedInput })
  }

  closeCalendar = () => {
    this.setState({ showCalendar: false })
  }

  onOutsideClick = event => {
    const { showCalendar } = this.state
    const calendarWrapperNode = this.calendarWrapperRef.current

    if (calendarWrapperNode && !calendarWrapperNode.contains(event.target) && showCalendar) {
      this.closeCalendar()
    }
  }

  getNumberOfMonths() {
    const { isGroup } = this.props
    const windowWidth = window.screen.width

    if (windowWidth < responsive.tablet) {
      return 1
    }

    if (windowWidth < responsive.laptop) {
      return 2
    }

    return isGroup ? 3 : 2
  }

  render() {
    const { startDate, endDate, focusedInput, showCalendar, isMobile } = this.state
    const { isGroup } = this.props
    // dont use .utc() here, otherwise date picker will have timiezone bugs
    const minDate = moment()
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)

    startDate !== null && startDate.locale(moment.locale())
    endDate !== null && endDate.locale(moment.locale())
    const { translate } = this.context

    const isGroupMobile = isMobile && isGroup

    return (
      <NightPickerWrapper>
        {!isGroupMobile ? (
          <NightPickerInputWrapper>
            <DateRow>
              <Label>{translate('Check in')}</Label>
              <Date onClick={() => this.showCalendar(START_DATE)}>
                <Day>{startDate && startDate.utc().format('D')}</Day>
                <Month>
                  {startDate && (isMobile ? startDate.utc().format('MMMM') : startDate.utc().format('MMMM, YYYY'))}
                </Month>
              </Date>
            </DateRow>
            <ArrowWrapper>
              <DateArrow />
            </ArrowWrapper>
            <DateRow>
              <Label>{translate('Check out')}</Label>
              <Date onClick={() => this.showCalendar(END_DATE)} className={!endDate && 'placeholder'}>
                <Day>{endDate && endDate.utc().format('D')}</Day>
                <Month>
                  {endDate && (isMobile ? endDate.utc().format('MMMM') : endDate.utc().format('MMMM, YYYY'))}
                </Month>
              </Date>
            </DateRow>
          </NightPickerInputWrapper>
        ) : (
          <NightPickerInputWrapper>
            <DateRow isGroup={isGroup}>
              <Label isGroup={isGroup}>{translate('Check in/out')}</Label>
              <Date onClick={() => this.showCalendar(START_DATE)} isGroup={isGroup}>
                <Day isGroup={isGroup}>{startDate && startDate.utc().format('D')}</Day>
                <Month isGroup={isGroup}>
                  {startDate &&
                    (isMobile
                      ? `.${startDate.utc().format('MM')}.`
                      : startDate
                          .utc()
                          .format('MMM')
                          .replace('.', ''))}
                </Month>
              </Date>
              <GroupBEDateDivider>&nbsp;-&nbsp;</GroupBEDateDivider>
              <Date onClick={() => this.showCalendar(END_DATE)} isGroup={isGroup} className={!endDate && 'placeholder'}>
                <Day isGroup={isGroup}>{endDate && endDate.utc().format('D')}</Day>
                <Month isGroup={isGroup}>
                  {endDate &&
                    (isMobile
                      ? `.${endDate.utc().format('MM')}.`
                      : endDate
                          .utc()
                          .format('MMM')
                          .replace('.', ''))}
                </Month>
                <Year isGroup={isGroup}>,&nbsp;{endDate && endDate.utc().format('YYYY')}</Year>
              </Date>
            </DateRow>
          </NightPickerInputWrapper>
        )}
        <NightPickerCalendarWrapper ref={this.calendarWrapperRef} isGroup={isGroup}>
          {showCalendar && (
            <>
              <DayPickerDivider />
              <DayPickerRangeController
                startDate={startDate}
                // This is a WORKAROUND
                // https://github.com/airbnb/react-dates/issues/2050
                transitionDuration={0}
                endDate={endDate}
                onDatesChange={this.onDatesChange}
                focusedInput={focusedInput}
                onFocusChange={this.onFocusChange}
                numberOfMonths={this.getNumberOfMonths()}
                hideKeyboardShortcutsPanel={true}
                onOutsideClick={this.onOutsideClick}
                isOutsideRange={day => day.isBefore(minDate)}
                daySize={isMobile ? 40 : isGroup ? 34 : 36}
                navPrev={<ArrowLeft stroke='#333333' />}
                navNext={<ArrowRight stroke='#333333' />}
                noBorder
              />
            </>
          )}
        </NightPickerCalendarWrapper>
      </NightPickerWrapper>
    )
  }
}

NightsPicker.propTypes = {
  from: PropTypes.number,
  to: PropTypes.number,
  onDatesChange: PropTypes.func,
  isGroup: PropTypes.bool
}

export default NightsPicker
