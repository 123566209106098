import React from 'react'
import PropTypes from 'prop-types'

const Mastercard = ({ width, height }) => (
  <svg width={width} height={height} viewBox='0 0 36 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M22.5536 7.14026H13.8076V22.8552H22.5536V7.14026Z' fill='#FF5F00' />
    <path
      d='M14.3631 14.9977C14.3631 11.9435 15.7791 9.02823 18.1669 7.14021C13.8356 3.72512 7.53291 4.47478 4.11783 8.83387C0.730506 13.1652 1.48016 19.4401 5.83925 22.8552C9.47646 25.7149 14.5574 25.7149 18.1946 22.8552C15.7791 20.9671 14.3631 18.0518 14.3631 14.9977Z'
      fill='#EB001B'
    />
    <path
      d='M34.3535 14.9977C34.3535 20.5229 29.8834 24.9931 24.3582 24.9931C22.1092 24.9931 19.9435 24.2434 18.1943 22.8552C22.5257 19.4401 23.2753 13.1652 19.8602 8.80611C19.3605 8.19528 18.8052 7.61221 18.1943 7.14021C22.5257 3.72512 28.8283 4.47478 32.2156 8.83387C33.6039 10.5831 34.3535 12.7487 34.3535 14.9977Z'
      fill='#F79E1B'
    />
  </svg>
)

const propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
}

const defaultProps = {
  width: 32,
  height: 21
}

Mastercard.propTypes = propTypes
Mastercard.defaultProps = defaultProps
export default Mastercard
