import { CountriesEnum } from 'Rentlio/utils/countriesEnum'

const getCountryCodeById = id => {
  if (!id) return ''
  const country = CountriesEnum.find(country => parseInt(country.id) === parseInt(id))
  return country ? country.code : ''
}

export const getCountryNameById = id => {
  if (!id) return ''
  const country = CountriesEnum.find(country => parseInt(country.id) === parseInt(id))
  return country ? country.name : ''
}

export default getCountryCodeById
