import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const M2PayErrorMessage = styled.p`
  width: 100%;
  margin-bottom: 50px;
  color: ${({ theme }) => theme.colors.alertColor};
  font-size: ${({ theme }) => theme.fonts._14};
  line-height: ${({ theme }) => theme.fonts._18};
`

export const M2PayRowWrapper = styled.div`
  border-radius: 10px;
  padding: 21px 15px;
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  border: 1px solid ${props => props.theme.colors.border};
  width: 100%;
  height: 60px;
`

export const FormLabel = styled.div`
  text-transform: uppercase;
  color: ${props => props.theme.colors.lightText};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  margin-bottom: 10px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`

export const FormGroup = styled.div`
  width: 100%;
  ${props => (props.fullWidth ? '' : 'max-width: 280px')};
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }

  @media ${device.mobile} {
    margin: 0;
    max-width: 100%;
    margin-bottom: 20px;
  }
`

export const FormRow = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 40px;

  @media ${device.mobile} {
    margin-bottom: 0;
    flex-direction: column;
    align-items: flex-start;
  }
`

export const CreditCardRow = styled(FormRow)`
  width: 100%;

  ${FormGroup} {
    flex-basis: 20%;

    :first-child {
      flex-basis: 50%;
    }

    :last-child {
      flex-basis: 20%;

      @media ${device.laptop} {
        flex-basis: 40%;
      }
    }
  }
`
