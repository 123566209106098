import React from 'react'

const Pet = () => (
  <svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.77254 4.44898C5.85665 4.44898 5.94076 4.44898 6.0417 4.43235C7.0174 4.2494 7.62301 3.11841 7.3875 1.90426C7.15198 0.690116 6.17628 -0.158123 5.20057 0.0248302C4.71272 0.107991 4.30898 0.424002 4.05664 0.922967C3.8043 1.38867 3.73701 1.97079 3.83795 2.55292C4.05664 3.65064 4.88094 4.44898 5.77254 4.44898Z'
      fill='#0973A0'
    />
    <path
      d='M3.45088 4.53212C3.26583 3.9999 2.91256 3.53419 2.47518 3.25145C2.02097 2.9687 1.53311 2.88554 1.07891 3.03523C0.170489 3.35124 -0.250073 4.53212 0.153667 5.66311C0.338715 6.19534 0.691988 6.66104 1.12937 6.94379C1.43218 7.14337 1.75181 7.24317 2.05461 7.24317C2.20601 7.24317 2.37424 7.2099 2.52564 7.16001C3.41724 6.84399 3.8378 5.66311 3.45088 4.53212Z'
      fill='#0973A0'
    />
    <path
      d='M9.96097 4.43235C10.0451 4.44898 10.1292 4.44898 10.2301 4.44898C11.1049 4.44898 11.946 3.65064 12.1479 2.55292C12.2657 1.97079 12.1815 1.38867 11.9292 0.922967C11.6769 0.440634 11.2731 0.124623 10.7853 0.0248302C9.80957 -0.158123 8.83386 0.690116 8.59835 1.90426C8.37966 3.11841 8.98527 4.2494 9.96097 4.43235Z'
      fill='#0973A0'
    />
    <path
      d='M14.9071 3.05189C13.9987 2.75252 12.9389 3.4178 12.552 4.54879C12.1651 5.67977 12.5688 6.84403 13.494 7.16004C13.6454 7.20993 13.7968 7.2432 13.9651 7.2432C14.2679 7.2432 14.5875 7.1434 14.8903 6.94382C15.3277 6.66107 15.6641 6.212 15.866 5.66314C16.2361 4.53216 15.8155 3.35127 14.9071 3.05189Z'
      fill='#0973A0'
    />
    <path
      d='M10.2809 5.99579C8.95191 4.93133 7.03414 4.93133 5.68834 5.99579C4.07338 7.27647 2.2229 9.13927 2.2229 10.869C2.2229 15.0437 13.7463 15.0437 13.7463 10.869C13.7631 9.13927 11.8958 7.27647 10.2809 5.99579Z'
      fill='#0973A0'
    />
  </svg>
)

export default Pet
