import React from 'react'
import PropTypes from 'prop-types'
import colors from 'Rentlio/style/colors'

const Union = ({ useSecondaryColor, useTeritaryColor }) => {
  const fill = useSecondaryColor ? colors.secondary : useTeritaryColor ? colors.lighterText : colors.textColor

  return (
    <svg width='12' height='12' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 1C0 1.55228 0.447715 2 1 2H11C11.5523 2 12 1.55228 12 1C12 0.447715 11.5523 0 11 0H1C0.447715 0 0 0.447715 0 1ZM0 7C0 7.55228 0.447715 8 1 8H11C11.5523 8 12 7.55228 12 7C12 6.44772 11.5523 6 11 6H1C0.447715 6 0 6.44772 0 7Z'
        fill={fill}
      />
    </svg>
  )
}

Union.propTypes = {
  useSecondaryColor: PropTypes.bool,
  useTeritaryColor: PropTypes.bool
}

export default Union
