import { isPromoCodeAppliedToAnyRate } from '../selectors/request'
import {
  CLEAR_PROMO_CODE,
  FETCH_AVAILABILITY_COMPLETED,
  FETCH_AVAILABILITY_FAILED,
  SET_PROMO_CODE,
  ON_OCCUPANCY_CHANGE
} from 'Rentlio/state/types'
import createReducer from 'Rentlio/state/utils/createReducer'

/**
const initialState = {
  from: int,
  to: int,
  adults: int,
  children: int[],
  rooms: int,
  promoCode: null|string
}
**/

const initialState = {
  from: null,
  to: null,
  adults: null,
  children: [],
  rooms: null,
  promoCode: null,
  promoCodeError: null,
  isPromoCodeAppliedToAnyRate: false,
  unitTypeId: null,
  bookingEngineId: null
}

const request = createReducer(initialState)({
  [FETCH_AVAILABILITY_COMPLETED]: (state, action) => ({
    ...action.payload.request,
    isPromoCodeAppliedToAnyRate: isPromoCodeAppliedToAnyRate(action.payload?.unitTypes)
  }),
  [FETCH_AVAILABILITY_FAILED]: (state, action) => {
    if (
      action.payload?.response[0]?.message &&
      (action.payload?.response[0]?.message === 'Promo code is invalid' ||
        action.payload?.response[0]?.message === 'Provide a promo code')
    ) {
      return { ...state, promoCodeError: true, isPromoCodeAppliedToAnyRate: false }
    }
    return { ...state }
  },
  [SET_PROMO_CODE]: (state, { promoCode }) => ({
    ...state,
    promoCode,
    promoCodeError: null,
    isPromoCodeAppliedToAnyRate: false
  }),
  [CLEAR_PROMO_CODE]: (state, _) => ({
    ...state,
    promoCode: null,
    promoCodeError: null,
    isPromoCodeAppliedToAnyRate: false
  }),
  [ON_OCCUPANCY_CHANGE]: (state, action) => ({ ...state, adults: action.adults, children: action.children })
})

export default request

export function getSearchRequest(state) {
  return state.request
}
