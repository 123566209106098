import { DAILY, ONETIME } from 'Rentlio/utils/servicePricingDynamicEnum'
import { servicePricingTypeEnum } from 'Rentlio/utils/servicePricingTypeEnum'
import { paymentTypesLabels } from 'Rentlio/utils/services'

const getServiceDescription = (service, ignoreExcluded) => {
  if (!service) {
    return ''
  }
  const { paymentType, pricingType, pricingDynamic, excludeArrival, includeDeparture } = service

  if (paymentType) {
    return paymentTypesLabels[paymentType]
  }

  let description = ''
  if (Number(pricingDynamic) === DAILY) {
    description = description + 'Daily / '
  }
  if (Number(pricingDynamic) === ONETIME) {
    description = description + 'One time / '
  }
  if (Number(pricingType) === servicePricingTypeEnum.PER_PERSON) {
    description = description + 'Per person / '
  }
  if (Number(pricingType) === servicePricingTypeEnum.PER_UNIT) {
    description = description + 'Per unit / '
  }
  if (Number(pricingType) === servicePricingTypeEnum.PER_CONSUMPTION) {
    description = description + 'Per consumption / '
  }
  if (!ignoreExcluded && excludeArrival && includeDeparture) {
    return description + 'Excluded on arrival / Included on departure'
  }
  if (!ignoreExcluded && excludeArrival) {
    return description + 'Excluded on arrival'
  }
  if (!ignoreExcluded && includeDeparture) {
    return description + 'Included on departure'
  }
  if (description.charAt(description.length - 2) === '/') {
    description = description.substring(0, description.length - 2)
  }
  return description
}

export default getServiceDescription
