export const LANG_CROATIAN = 'hr'
export const LANG_ENGLISH = 'en'
export const LANG_CZECH = 'cs'
export const LANG_GERMAN = 'de'
export const LANG_SPANISH = 'es'
export const LANG_FRENCH = 'fr'
export const LANG_ITALIAN = 'it'
export const LANG_VIETNAMESE = 'vi'
export const LANG_GREEK = 'el'
export const LANG_POLISH = 'pl'
export const LANG_SLOVENIAN = 'sl'

export const DEFAULT_LOCALE = LANG_ENGLISH

export const LANGUAGES = [
  LANG_CROATIAN,
  LANG_ENGLISH,
  LANG_CZECH,
  LANG_GERMAN,
  LANG_SPANISH,
  LANG_FRENCH,
  LANG_ITALIAN,
  LANG_VIETNAMESE,
  LANG_GREEK,
  LANG_POLISH,
  LANG_SLOVENIAN
]

export function translateInternal(translationString, locale, parameters) {
  const foundLocale = LANGUAGES.find(code => code === locale)
  locale = typeof foundLocale === 'undefined' ? DEFAULT_LOCALE : locale

  let translation = window.translations[locale][translationString]

  if (typeof translation === 'undefined') {
    translation = window.translations[DEFAULT_LOCALE][translationString]
  }

  if (typeof translation === 'undefined') {
    return translationString
  }

  if (typeof parameters !== 'undefined' && parameters !== null && typeof parameters === 'object') {
    const keys = Object.keys(parameters)

    keys.map(key => {
      translation = translation.replace(key, parameters[key])
    })
  }

  return translation
}

export function trans(translationString, params) {
  return translateInternal(translationString, window.store.getState().configuration.locale, params)
}
