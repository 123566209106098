import styled, { css } from 'styled-components'
import { device } from 'Rentlio/style/responsive'

const CardStyle = css`
  align-items: center;
  justify-content: space-between;
  padding: 23px 30px;
  margin-left: 50px;
  background: white;
  text-transform: uppercase;
  box-shadow: 0px 0.30914px 0.549583px rgba(0, 0, 0, 0.02), 0px 0.781839px 1.38994px rgba(0, 0, 0, 0.02),
    0px 1.59488px 2.83534px rgba(0, 0, 0, 0.03), 0px 3.28515px 5.84026px rgba(0, 0, 0, 0.03),
    0px 9px 16px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
`

export const ServicesWrapper = styled.div`
  background: ${props => props.theme.colors.servicesBackground};
  padding-bottom: 200px;

  @media ${device.tablet} {
    padding-bottom: 50px;
  }
`

export const HeadingOuterWrapper = styled.div`
  background: ${props => props.theme.colors.servicesHeadingBackground};
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid ${props => props.theme.colors.border};

  @media ${device.tablet} {
    padding-bottom: 30px;
  }
`

export const HeadingInnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${device.tablet} {
    justify-content: space-around;
    flex-direction: column;
  }

  @media ${device.onlyMobile} {
    flex-direction: column;
  }
`

export const Title = styled.h2`
  font-size: ${props => props.theme.fonts._32};
  line-height: ${props => props.theme.fonts._37};
  font-weight: ${props => props.theme.fonts.mediumWeight};

  @media ${device.tablet} {
    margin-right: 1rem;
    font-size: 1.5rem;
  }
`

export const AddRoomWrapper = styled.div`
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    order: 2;
  }
`

export const AddAnotherRoomButton = styled.div`
  padding: 12px 15px;
  text-transform: uppercase;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.border};
  margin-left: 20px;
  transition: all ${({ theme }) => theme.animations.defaultTransition};
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.colors.lighterText};
  }

  a {
    color: ${props => props.theme.colors.textColor};
    text-decoration: none;
    font-size: ${props => props.theme.fonts._14};
    line-height: ${props => props.theme.fonts._16};
    font-weight: ${props => props.theme.fonts.mediumWeight};

    &:hover,
    &:active,
    &:visited,
    &:focus {
      text-decoration: none;
      font-size: ${props => props.theme.fonts._14};
      line-height: ${props => props.theme.fonts._16};
      font-weight: ${props => props.theme.fonts.mediumWeight};
      outline: none;
    }
  }
  svg {
    margin-right: 10px;
  }

  @media ${device.tablet} {
    margin-left: 0;
  }
`

export const TotalCost = styled.div`
  text-transform: uppercase;
  margin-left: auto;
  display: flex;
  align-items: baseline;

  @media ${device.tablet} {
    position: relative;
    justify-content: center;
    width: 100%;
    margin-left: 0;
    margin-bottom: 40px;
    padding: 40px 0;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 85%;
      height: 1px;
      background-color: ${({ theme }) => theme.colors.border};
    }
  }
`

export const TotalCostMobile = styled.div`
  display: none;
  justify-content: center;
  align-items: baseline;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  margin-top: 40px;
  padding-top: 30px;
  text-transform: uppercase;

  @media ${device.tablet} {
    display: flex;
  }
`

export const TotalCostLabel = styled.div`
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  margin-right: 10px;
  color: ${props => props.theme.colors.lightText};

  @media ${device.tablet} {
    color: ${props => props.theme.colors.black};
    font-weight: bold;
  }
`

export const TotalCostValue = styled.div`
  font-size: ${props => props.theme.fonts._18};
  line-height: ${props => props.theme.fonts._21};
  font-weight: bold;
`

export const Footer = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
    padding: 0 2rem;
  }

  @media ${device.onlyMobile} {
    > a {
      width: 100%;
    }
  }
`
export const FooterLabel = styled.div`
  font-weight: bold;
  color: black;
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};

  @media ${device.tablet} {
    font-weight: 500;
    text-transform: none;
  }
`

export const FooterValue = styled.div`
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  color: black;
  margin-left: 10px;

  @media ${device.tablet} {
    font-weight: bold;
  }
`
export const AccomodationCost = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;

  @media ${device.tablet} {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 28px;
  }
`

export const PriceCard = styled.div`
  display: none;
  width: 100%;
  ${CardStyle}

  @media ${device.tablet} {
    display: flex;
    margin-left: 0;
    margin-bottom: 50px;
  }
`
export const ExtrasCard = styled.div`
  display: flex;
  ${CardStyle}

  @media ${device.tablet} {
    width: 100%;
   
    background: none;
    box-shadow: none;
    padding: 0;
    margin-left: 0;
    
  }
`

export const PaymentButton = styled.div`
  color: ${props => props.theme.colors.buttonText};
  background: ${props => props.theme.colors.buttonBackground};
  text-transform: uppercase;
  font-size: ${props => props.theme.fonts._18};
  line-height: ${props => props.theme.fonts._21};
  font-weight: bold;
  padding: 20px 30px;
  margin-left: 45px;
  text-align: center;
  border-radius: 10px;
  transition: all ${({ theme }) => theme.animations.defaultTransition};

  &:hover {
    background: ${({ theme }) => theme.colors.darkBlue};
  }

  svg {
    margin-left: 30px;
  }

  @media ${device.tablet} {
    margin-left: 0;
  }

  @media ${device.onlyMobile} {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: ${props => props.theme.fonts._14};
    line-height: 1;
  }
`
export const Divider = styled.div`
  display: none;
  width: 100%;
  border-bottom: 1px dashed rgba(51, 51, 51, 0.1);
  margin: 35px 0;

  @media ${device.tablet} {
    display: block;
  }
`
