import React from 'react'
import PropTypes from 'prop-types'
import { ThumbnailsWrapper, Image } from './style.js'

class Thumbnails extends React.PureComponent {
  render() {
    const { selectThumbnail, images, currentImage } = this.props

    return (
      <ThumbnailsWrapper>
        {images.map((image, index) => (
          <Image
            key={image.name}
            src={image.url}
            alt={image.name}
            onClick={() => selectThumbnail(index)}
            active={currentImage === index}
          />
        ))}
      </ThumbnailsWrapper>
    )
  }
}

Thumbnails.propTypes = {
  selectThumbnail: PropTypes.func,
  images: PropTypes.array,
  currentImage: PropTypes.number
}

export default Thumbnails
