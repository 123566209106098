import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { SocialLink, IconWrapper, SocialNetworksWrapper, Title } from './style'

import { FacebookIcon, InstagramIcon, TwitterIcon } from 'Rentlio/components/Icons/social'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

const SocialNetworks = ({ facebook, instagram, twitter }) => {
  const { translate } = useContext(LanguageContext)
  return (
    <SocialNetworksWrapper>
      <Title>{translate('Follow us')}</Title>
      <IconWrapper>
        {facebook && (
          <SocialLink href={facebook}>
            <FacebookIcon />
          </SocialLink>
        )}
        {instagram && (
          <SocialLink href={instagram}>
            <InstagramIcon />
          </SocialLink>
        )}
        {twitter && (
          <SocialLink href={twitter}>
            <TwitterIcon />
          </SocialLink>
        )}
      </IconWrapper>
    </SocialNetworksWrapper>
  )
}

SocialNetworks.propTypes = {
  facebook: PropTypes.string,
  instagram: PropTypes.string,
  twitter: PropTypes.string
}

export default SocialNetworks
