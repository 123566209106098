import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'
export const ImagesWrapper = styled.div`
  margin-bottom: 36px;

  @media ${device.laptop} {
    align-self: center;
  }

  @media ${device.onlyMobile} {
    width: 100%;
  }
`
