import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const BreadCrumbsWrapper = styled.div`
  display: flex;
  align-items: center;
  color: white;
  height: 100%;
  padding-top: 35px;
  padding-bottom: 35px;

  @media ${device.tablet} {
    justify-content: center;
    padding-left: 30px;
  }

  @media ${device.onlyMobile} {
    padding-left: 0;
  }
`

export const ItemWrapper = styled.div`
  position: relative;

  :not(:first-child) {
    padding-left: 50px;
  }

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    height: ${props => (props.previous || props.current ? '3px' : '1px')};
    width: 100%;
    left: 0;
    right: 0;
    bottom: ${props => (props.next ? '-12px' : '-13px')};
    opacity: ${props => (props.next ? '0.2' : '1')};
    background: white;
  }

  a {
    color: ${props => props.theme.colors.buttonText};
    text-decoration: none;
    font-weight: ${props => (props.current ? 'bold' : props.previous ? props.theme.fonts.mediumWeight : 'normal')};
    opacity: ${props => (props.current ? '1' : props.previous ? '0.8' : '0.6')};
    font-size: ${props => props.theme.fonts._14};
    line-height: ${props => props.theme.fonts._16};
    text-shadow: 1px 1px 0px ${props => props.theme.colors.lightGray};

    &:hover,
    &:active,
    &:focus,
    &:visited {
      text-decoration: none;
      color: ${props => props.theme.colors.buttonText};
    }

    svg {
      margin-right: 10px;
    }
  }

  @media ${device.onlyMobile} {
    :not(:first-child) {
      padding-left: 30px;
    }
  }
`
