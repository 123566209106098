import createReducer from 'Rentlio/state/utils/createReducer'

/**
 const initialState = [
  {
      city: string
      contactNumber: string
      countryOfBirthId: string
      dateOfBirth: string
      documentNumber: string
      email: string
      genderId: number
      id: string
      isPrimary: boolean
      name: string
      travelDocumentTypesId: string
    }
 ]
 */

const initialState = []

const reservationGuestSelfCheckIn = createReducer(initialState)({})

export default reservationGuestSelfCheckIn
