import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const Wrapper = styled.div`
  display: ${props => (props.hide ? 'block' : 'none')};
`

export const Label = styled.div`
  text-transform: uppercase;
  color: ${props => props.theme.colors.contactTextColor};
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  margin-bottom: 10px;
`

export const Value = styled.div`
  color: ${props => props.theme.colors.contactTextColor};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  margin-top: 15px;
  margin-bottom: 20px;
`

export const StyledLink = styled.a`
  display: block;
  color: ${props => props.theme.colors.contactTextColor};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  margin-top: 15px;
  margin-bottom: 20px;
  text-decoration: none;
`

export const Divider = styled.div`
  height: 2px;
  width: 30px;
  background-color: ${props => props.theme.colors.secondary};

  @media ${device.tablet} {
    margin: 0 auto;
  }
`
