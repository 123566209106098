import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const Title = styled.div`
  margin: 30px 0;
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._24};
  line-height: ${props => props.theme.fonts._28};
`

export const Description = styled.p`
  margin-top: 0;
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._24};
  color: ${props => props.theme.colors.textColor};
  text-align: left;
  opacity: 0.8;
  white-space: pre-wrap;
`

export const AmenitiesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  padding: 30px 30px 0 30px;
  margin-top: 32px;
  border-radius: 10px;
  background: ${props => props.theme.colors.amenitiesBckground};

  @media ${device.tablet} {
    grid-template-columns: repeat(4, 1fr);
  }

  @media ${device.mobile} {
    grid-template-columns: repeat(3, 1fr);
    height: auto;
  }

  @media ${device.onlyMobile} {
    grid-template-columns: repeat(2, 1fr);
  }
`
export const Amenity = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex-basis: 17%;

  @media ${device.mobile} {
    flex-basis: 33.33%;
  }

  @media ${device.onlyMobile} {
    flex-basis: 50%;
  }
`

export const AmenityImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
`

export const AmenityName = styled.p`
  padding: 18px 0 30px;
  margin: 0;
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  text-align: center;
`

export const MapLink = styled.a`
  width: 100%;
`

export const Map = styled.div`
  width: 680px;
  height: 360px;
  margin-top: 34px;
  border-radius: 10px;
  background-color: ${props => props.theme.colors.white};
  background-image: url(${props => props.map});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media ${device.tablet} {
    width: 100%;
    background-size: cover;
  }
`
