import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CancellationPolicy from 'Rentlio/screen/layout/components/CancellationPolicy'
import { closeModal } from 'Rentlio/state/actions/ui'

const CancellationPolicyContainer = props => <CancellationPolicy {...props} />

CancellationPolicyContainer.propTypes = {
  accommodationItems: PropTypes.array,
  closeModal: PropTypes.func,
  activeModal: PropTypes.string
}

const mapStateToProps = state => ({
  accommodationItems: state.reservation.receipt.accomodation.items,
  activeModal: state.modals.openModal,
  paymentMethodId: state.reservation.paymentMethodId,
  arrivalDate: state.reservation.request.from,
  createdAt: state.reservation.createdAt
})

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(CancellationPolicyContainer)
