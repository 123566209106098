import styled, { css } from 'styled-components'

export const SelectWrapper = styled.div`
  width: 100%;
  position: relative;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`

export const Selected = styled.div`
  padding: 11px 20px;
  height: 60px;
  font-size: 14px;
  line-height: 36px;
  position: relative;
  background: ${({ theme, disabled }) => (disabled ? theme.colors.disabledSelect : theme.colors.white)};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  cursor: pointer;
  text-overflow: ellipsis;
  whitespace: nowrap;
  overflow: hidden;

  ${({ isDropdownShown }) =>
    isDropdownShown
      ? css`
          border: 1px solid ${props => props.theme.colors.secondary};
          box-shadow: 0px 5px 10px rgba(221, 164, 29, 0.2);
          border-bottom-color: transparent;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        `
      : ''}
`

export const SelectedIcon = styled.div`
  position: absolute;
  right: 15px;
  top: ${props => (props.rotate ? '12px' : '10px')};
  transition: all ${({ theme }) => theme.animations.defaultTransition};

  ${props => (props.rotate ? 'transform: rotate(180deg);' : '')}
`

export const DropdownWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: ${props => (props.active ? 'block' : 'none')};
  max-height: 200px;
  z-index: ${({ theme }) => theme.zIndex.dropdown};
  overflow-y: scroll;
  overflow-x: hidden;
  background: ${({ theme }) => theme.colors.white};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid ${props => props.theme.colors.secondary};
  box-shadow: 0px 5px 10px rgba(221, 164, 29, 0.2);
  border-top: none;
  font-size: 14px;
`

export const Option = styled.div`
  padding: 12px 20px;
  cursor: pointer;
  background: ${({ theme, selected }) => (selected ? theme.colors.grayLighter : theme.colors.white)};
  text-overflow: ellipsis;
  white-space: nowrap;

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.grayDarker};
  }
`

export const SelectedInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 36px;
  min-height: 26px;
  box-sizing: border-box;
  border: none;
  cursor: pointer;
  outline: none;
  background: transparent;

  ::placeholder {
    color: ${({ theme }) => theme.colors.textColor};
    opacity: 0.5;
  }
`

export const PlaceholderWrapper = styled.div`
  color: ${({ theme }) => theme.colors.textColor};
  opacity: 0.5;
`
