import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import PrivacyPolicy from 'Rentlio/screen/layout/components/PrivacyPolicy'
import { closeModal } from 'Rentlio/state/actions/ui'

const PrivacyPolicyContainer = props => <PrivacyPolicy {...props} />

PrivacyPolicyContainer.propTypes = {
  privacyPolicy: PropTypes.string,
  closeModal: PropTypes.func,
  activeModal: PropTypes.string
}

const mapStateToProps = state => ({
  privacyPolicy: state.bookingEngine.privacyPolicy,
  activeModal: state.modals.openModal
})

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicyContainer)
