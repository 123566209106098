import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { NotBookableWrapper } from './style'
import { getDays } from 'Rentlio/utils/hoursToDays'
import {
  CLOSED_ERROR,
  MAX_ADVANCE_RESERVATION_ERROR,
  MAX_STAY_ARRIVAL_ERROR,
  MAX_STAY_ERROR,
  MIN_ADVANCE_RESERVATION_ERROR,
  MIN_STAY_ARRIVAL_ERROR,
  MIN_STAY_ERROR,
  NO_CHECK_IN_ERROR,
  NO_CHECK_OUT_ERROR
} from 'Rentlio/utils/restrictionErrorsEnum'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

const NotBookable = ({ ratePlan, isAvailable }) => {
  const { translate } = useContext(LanguageContext)
  let error = ''

  if (!isAvailable) {
    error += translate('No units left')
  }

  ratePlan.restrictionErrors.map(err => {
    if (err === MIN_STAY_ERROR) {
      error += translate('MinStayError', { '%minStay%': ratePlan.minStay })
    }

    if (err === MAX_STAY_ERROR) {
      error += translate('MaxStayError', { '%maxStay%': ratePlan.maxStay })
    }

    if (err === NO_CHECK_IN_ERROR) {
      error += translate('NoCheckInError')
    }

    if (err === NO_CHECK_OUT_ERROR) {
      error += translate('NoCheckOutError')
    }

    if (err === MIN_ADVANCE_RESERVATION_ERROR) {
      const minAdvance = getDays(ratePlan.minAdvanceReservation)
      error += translate('MinAdvanceReservationError', { '%days%': minAdvance.days, '%hours%': minAdvance.hours })
    }

    if (err === MAX_ADVANCE_RESERVATION_ERROR) {
      const maxAdvance = getDays(ratePlan.maxAdvanceReservation)
      error += translate('MaxAdvanceReservationError', { '%days%': maxAdvance.days, '%hours%': maxAdvance.hours })
    }

    if (err === MIN_STAY_ARRIVAL_ERROR) {
      error += translate('MinStayArrivalError', { '%nights%': ratePlan.minStayArrival })
    }

    if (err === MAX_STAY_ARRIVAL_ERROR) {
      error += translate('MaxStayArrivalError', { '%nights%': ratePlan.maxStayArrival })
    }

    if (err === CLOSED_ERROR) {
      error += translate('ClosedError')
    }
  })

  return <NotBookableWrapper>{error}</NotBookableWrapper>
}

NotBookable.propTypes = {
  ratePlan: PropTypes.object,
  isAvailable: PropTypes.bool
}

export default NotBookable
