import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { ArrowWrapper, Date, Day, Label, ReservationDatesWrapper } from './style'
import { DateArrow } from 'Rentlio/components/Icons/dateArrow'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

const ReservationDates = ({ from, to }) => {
  const { translate } = useContext(LanguageContext)
  const startDate = moment.unix(from)
  const endDate = moment.unix(to)

  return (
    <ReservationDatesWrapper>
      <div>
        <Label>{translate('Check in')}</Label>
        <Date>
          {startDate && startDate.utc().format('D')} {startDate && startDate.utc().format('MMMM')}
        </Date>
        <Day>{startDate && startDate.utc().format('dddd')}</Day>
      </div>
      <ArrowWrapper>
        <DateArrow />
      </ArrowWrapper>
      <div>
        <Label>{translate('Check out')}</Label>
        <Date>
          {endDate && endDate.utc().format('D')} {endDate && endDate.utc().format('MMMM')}
        </Date>
        <Day>{endDate && endDate.utc().format('dddd')}</Day>
      </div>
    </ReservationDatesWrapper>
  )
}

ReservationDates.propTypes = {
  from: PropTypes.number,
  to: PropTypes.number
}

export default ReservationDates
