import React from 'react'

export const People = () => (
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.43777 7.23434H2.62661C1.18455 7.23434 0 8.55492 0 10.1626V11.3683C0 11.6554 0.206009 11.8851 0.463519 11.8851H8.60086C8.85837 11.8851 9.06438 11.6554 9.06438 11.3683V10.1626C9.11588 8.55492 7.87983 7.23434 6.43777 7.23434Z'
      fill='#333333'
    />
    <path
      d='M4.53219 0C3.09013 0 1.90558 1.32057 1.90558 2.92823C1.90558 4.53589 3.09013 5.85646 4.53219 5.85646C5.97425 5.85646 7.1588 4.53589 7.1588 2.92823C7.2103 1.32057 6.02575 0 4.53219 0Z'
      fill='#333333'
    />
    <path
      d='M10.0944 7.17701C9.88839 7.1196 9.68238 7.23443 9.52787 7.40668C9.37337 7.57893 9.42487 7.86601 9.52787 8.09568C9.93989 8.78467 10.1974 9.12917 10.1974 11.4258C10.1974 11.7703 10.4034 12 10.7124 12H11.485C11.794 12 12 11.7703 12 11.4258V9.99041C12 8.66984 11.2274 7.52151 10.0944 7.17701Z'
      fill='#333333'
    />
    <path
      d='M8.24035 0.287061C8.03434 0.229645 7.82833 0.344477 7.72532 0.516726C7.62232 0.688975 7.62232 0.976056 7.72532 1.14831C8.44635 2.29663 8.44635 3.55979 7.72532 4.93778C7.62232 5.16745 7.62232 5.39711 7.72532 5.56936C7.82833 5.74161 7.98284 5.79902 8.13734 5.79902C8.18884 5.79902 8.24035 5.79902 8.24035 5.79902C9.11588 5.56936 9.78541 4.76553 9.99142 3.73204C10.3519 2.23921 9.5794 0.688975 8.24035 0.287061Z'
      fill='#333333'
    />
  </svg>
)
