import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const NumberInputWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  font-size: ${props => props.theme.fonts._24};
  line-height: ${props => props.theme.fonts._28};
  color: ${props => (props.isDarkStyle ? props.theme.colors.white : props.theme.colors.black)};
  user-select: none;

  ${props => (props.editing ? '' : 'cursor: pointer;')}
`

export const MinusAction = styled.div`
  user-select: none;
  cursor: pointer;
  color: ${props => props.theme.colors.lighterText};

  svg {
    height: ${props => props.theme.fonts._12};

    rect {
      fill: ${props =>
        props.isDisabled
          ? props.isDarkStyle
            ? props.theme.colors.disabledGray
            : props.theme.colors.lighterText
          : props.isDarkStyle
          ? props.theme.colors.white
          : props.theme.colors.lightText};
    }
  }
`

export const PlusAction = styled.div`
  user-select: none;
  cursor: pointer;
  color: ${props => props.theme.colors.lighterText};

  svg {
    height: ${props => props.theme.fonts._12};

    path {
      fill: ${props =>
        props.isDisabled
          ? props.isDarkStyle
            ? props.theme.colors.disabledGray
            : props.theme.colors.lighterText
          : props.isDarkStyle
          ? props.theme.colors.white
          : props.theme.colors.lightText};
    }
  }
`

export const ValueInput = styled.input`
  border: none;
  font-size: ${props => (props.bigFontSize ? props.theme.fonts._24 : props.theme.fonts._18)};
  line-height: ${props => (props.bigFontSize ? props.theme.fonts._28 : props.theme.fonts._21)};
  color: ${props => (props.isDarkStyle ? props.theme.colors.white : props.theme.colors.black)};
  background-color: ${props => (props.isDarkStyle ? props.theme.colors.black : props.theme.colors.white)};
  width: 40px;
  outline: none;
  overflow: visible;
  text-align: center;

  @media ${device.tablet} {
    padding: 0;
    margin: 0 1rem;
  }
`

export const IconWrapper = styled.span`
  svg {
    height: ${props => props.theme.fonts._18};
  }
`
