import React from 'react'
import { connect } from 'react-redux'
import Offer from 'Rentlio/screen/offer/components/Offer'

const OfferContainer = props => <Offer {...props} />

const mapStateToProps = ({ offer, booking, bookingEngine }) => ({
  offer,
  booking,
  bookingEngine
})

export default connect(mapStateToProps)(OfferContainer)
