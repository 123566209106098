import styled from 'styled-components'
import React from 'react'
import en from 'Images/flags/en.png'
import hr from 'Images/flags/hr.png'
import de from 'Images/flags/de.png'
import fr from 'Images/flags/fr.png'
import it from 'Images/flags/it.png'
import cs from 'Images/flags/cs.png'
import es from 'Images/flags/es.png'
import vi from 'Images/flags/vi.png'
import el from 'Images/flags/el.png'
import pl from 'Images/flags/pl.png'
import sl from 'Images/flags/sl.png'

const FlagIcon = styled.img`
  margin-left: 5px;
  margin-right: 5px;
  width: 17px;
  height: 17px;
`
export const EnglishFlag = () => <FlagIcon src={en} />
export const CroatianFlag = () => <FlagIcon src={hr} />
export const GermanFlag = () => <FlagIcon src={de} />
export const FrenchFlag = () => <FlagIcon src={fr} />
export const ItalianFlag = () => <FlagIcon src={it} />
export const CzechFlag = () => <FlagIcon src={cs} />
export const SpanishFlag = () => <FlagIcon src={es} />
export const VietnameseFlag = () => <FlagIcon src={vi} />
export const GreekFlag = () => <FlagIcon src={el} />
export const PolishFlag = () => <FlagIcon src={pl} />
export const SlovenianFlag = () => <FlagIcon src={sl} />
