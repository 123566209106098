import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchAvailability } from 'Rentlio/state/actions/api'
import { filterByLocation } from 'Rentlio/state/actions/ui'
import { getBookingEngine } from 'Rentlio/state/reducers/bookingEngine'
import { getSearchRequest } from 'Rentlio/state/reducers/request'
import Hero from 'Rentlio/screen/layout/components/Hero'

const HeroContainer = ({
  bookingEngine,
  request,
  fetchAvailability,
  reservation,
  animationLoading,
  unitTypes,
  configuration,
  filterByLocation,
  booking,
  guests
}) => (
  <Hero
    bookingEngine={bookingEngine}
    request={request}
    fetchAvailability={fetchAvailability}
    reservation={reservation}
    animationLoading={animationLoading}
    unitTypes={unitTypes}
    configuration={configuration}
    filterByLocation={filterByLocation}
    booking={booking}
    isCheckIn={guests && guests.length > 0}
  />
)

HeroContainer.propTypes = {
  bookingEngine: PropTypes.object,
  request: PropTypes.object,
  fetchAvailability: PropTypes.func,
  reservation: PropTypes.object,
  animationLoading: PropTypes.bool,
  configuration: PropTypes.object,
  booking: PropTypes.object,
  unitTypes: PropTypes.array,
  filterByLocation: PropTypes.func,
  guests: PropTypes.array
}

const mapStateToProps = state => ({
  bookingEngine: getBookingEngine(state),
  request: getSearchRequest(state),
  reservation: state.reservation,
  animationLoading: state.animationLoader.animationLoading,
  unitTypes: state.unitTypes,
  configuration: state.configuration,
  booking: state.booking,
  guests: state.guests
})

const mapDispatchToProps = dispatch => ({
  fetchAvailability: data => dispatch(fetchAvailability(data)),
  filterByLocation: city => dispatch(filterByLocation(city))
})

export default connect(mapStateToProps, mapDispatchToProps)(HeroContainer)
