import PropTypes from 'prop-types'
import React from 'react'
import { Title, Description, AmenitiesWrapper, Amenity, AmenityName, AmenityImage, Map, MapLink } from './style'
import MaxOccupancy from 'Rentlio/components/MaxOccupancy'
import Images from 'Rentlio/components/UI/Images'
import colors from 'Rentlio/style/colors'

class UnitTypeModal extends React.PureComponent {
  render() {
    const {
      images,
      amenities,
      maxOccupancy,
      standardOccupancy,
      description,
      name,
      latitude,
      longitude,
      primaryColor,
      unitTypeName,
      unitTypeCity,
      isGroupBookingEngine,
      unitTypeSlug,
      groupBESlug
    } = this.props
    const mapUrl =
      `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=13&size=640x640&scale=2` +
      `&markers=color:0x${primaryColor || colors.primaryColor?.substring(1)}|${latitude},${longitude}` +
      '&key=' +
      window.extraInfo.GOOGLE_MAPS_KEY

    const googleMapsUrl = `https://maps.google.com?q=${latitude},${longitude}`

    return (
      <React.Fragment>
        <MaxOccupancy
          maxOccupancy={maxOccupancy}
          standardOccupancy={standardOccupancy}
          unitTypeName={unitTypeName}
          unitTypeCity={unitTypeCity}
          isGroupBookingEngine={isGroupBookingEngine}
          unitTypeSlug={unitTypeSlug}
          groupBESlug={groupBESlug}
        />
        <Title>{name}</Title>
        <Images hasThumbnails isModalImage images={images} />
        <Description>{description}</Description>
        {amenities?.length > 0 && (
          <AmenitiesWrapper>
            {amenities.map((amenity, index) => (
              <Amenity key={index}>
                <AmenityImage src={amenity.imageUrl} />
                <AmenityName>{amenity.name}</AmenityName>
              </Amenity>
            ))}
          </AmenitiesWrapper>
        )}
        <MapLink href={googleMapsUrl} target='_blank' rel='noopener noreferrer'>
          <Map map={mapUrl}></Map>
        </MapLink>
      </React.Fragment>
    )
  }
}

UnitTypeModal.propTypes = {
  images: PropTypes.array,
  hasThumbnails: PropTypes.bool,
  amenities: PropTypes.array,
  standardOccupancy: PropTypes.number,
  maxOccupancy: PropTypes.number,
  description: PropTypes.string,
  name: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  primaryColor: PropTypes.string,
  unitTypeName: PropTypes.string,
  unitTypeCity: PropTypes.string,
  isGroupBookingEngine: PropTypes.bool,
  unitTypeSlug: PropTypes.string,
  groupBESlug: PropTypes.string
}
export default UnitTypeModal
