import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { CreditCardRow, FormError, FormGroup, FormLabel, FormRow } from 'Rentlio/components/UI/form'

import { Input } from 'Rentlio/components/UI/input'
import Checkbox from 'Rentlio/components/UI/Checkbox'
import Amex from 'Rentlio/components/Icons/amex'
import Visa from 'Rentlio/components/Icons/visa'
import Mastercard from 'Rentlio/components/Icons/mastercard'
import Maestro from 'Rentlio/components/Icons/maestro'
import { CardIssuerWrapper, InputWrapper } from 'Rentlio/screen/payment/components/Payment/PaymentInfo/style'
import { AMEX, MAESTRO, MASTERCARD, VISA } from 'Rentlio/utils/cardNumber'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

const CreditCard = ({
  cardholderSameAsGuest,
  handleSameAsGuest,
  cardholderName,
  handleCardHolderName,
  cardNumber,
  handleCardNumber,
  cardIssuer,
  cvv,
  handleCvvNumber,
  expirationDate,
  handleExpirationDate,
  getValidationError
}) => {
  const { translate } = useContext(LanguageContext)
  return (
    <>
      <FormRow>
        <Checkbox
          isChecked={cardholderSameAsGuest}
          click={handleSameAsGuest}
          text={translate('Card holder same as booker')}
        />
      </FormRow>
      {!cardholderSameAsGuest && (
        <FormRow>
          <FormGroup fullWidth={true}>
            <FormLabel>
              <label htmlFor='cardHolder'>{translate('Card holder')}</label>
            </FormLabel>
            <Input
              id='cardHolder'
              placeholder={translate('Card holder')}
              value={cardholderName}
              onChange={handleCardHolderName}
            />
            <FormError>{getValidationError('name')}</FormError>
          </FormGroup>
        </FormRow>
      )}
      <CreditCardRow>
        <FormGroup>
          <FormLabel>
            <label htmlFor='cardNumber'>{translate('Card number')}</label>
          </FormLabel>
          <InputWrapper>
            <Input
              id='cardNumber'
              placeholder={translate('Card number')}
              value={cardNumber}
              onChange={handleCardNumber}
            />
            <CardIssuerWrapper>
              {cardIssuer === AMEX && <Amex />}
              {cardIssuer === VISA && <Visa />}
              {cardIssuer === MAESTRO && <Maestro />}
              {cardIssuer === MASTERCARD && <Mastercard />}
            </CardIssuerWrapper>
          </InputWrapper>
          <FormError>{getValidationError('cardNumber')}</FormError>
        </FormGroup>
        <FormGroup>
          <FormLabel>
            <label htmlFor='cvv'>CVV</label>
          </FormLabel>
          <Input id='cvv' placeholder='123' value={cvv} onChange={handleCvvNumber} />
          <FormError>{getValidationError('cvv')}</FormError>
        </FormGroup>
        <FormGroup>
          <FormLabel>
            <label htmlFor='expirationDate'>{translate('Expiration date')}</label>
          </FormLabel>
          <Input
            id='expirationDate'
            placeholder={moment().format('MM') + '/' + moment().format('YY')}
            value={expirationDate}
            onChange={handleExpirationDate}
          />
          <FormError>{getValidationError('expirationDate')}</FormError>
        </FormGroup>
      </CreditCardRow>
    </>
  )
}

CreditCard.propTypes = {
  cardholderName: PropTypes.string,
  handleCardHolderName: PropTypes.func,
  cardholderSameAsGuest: PropTypes.bool,
  handleSameAsGuest: PropTypes.func,
  cardNumber: PropTypes.string,
  handleCardNumber: PropTypes.func,
  cardIssuer: PropTypes.string,
  cvv: PropTypes.string,
  handleCvvNumber: PropTypes.func,
  expirationDate: PropTypes.string,
  handleExpirationDate: PropTypes.func,
  getValidationError: PropTypes.func
}

export default CreditCard
