import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const OfferNoticeWrapper = styled.div`
  width: 100%;
  padding: 20px 0;
  color: white;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fonts._16};
  line-height: ${({ theme }) => theme.fonts._24};
  background: ${({ theme }) => theme.colors.red};
  margin-bottom: 50px;

  @media ${device.mobile} {
    text-align: center;
  }

  @media ${device.tablet} {
    padding-left: 30px;
    padding-right: 30px;
  }
`
