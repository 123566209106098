import React from 'react'
import PropTypes from 'prop-types'

const Minus = ({ stroke }) => (
  <svg width='12' height='2' viewBox='0 0 12 2' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='12' height='2' rx='1' fill={stroke || '#333333'} />
  </svg>
)

Minus.propTypes = {
  stroke: PropTypes.string
}

export default Minus
