import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import {
  LowAvailabilityCta,
  OnlyOneLeftAvailabilityCta,
  RateWrapper,
  RateLabel,
  RatePolicies,
  RatePreviewWrapper,
  RatesToggle,
  RatesToggleIcon,
  RateSubtitle,
  RateValue,
  EmptyOccupancy
} from './style'
import ArrowDown from 'Rentlio/components/Icons/arrowDown'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import { CurrenciesContext } from 'Rentlio/context/CurrenciesContext'
import { formatPrice } from 'Rentlio/components/utils/Price/price'

const RatePreview = ({
  minimumRate,
  toggleRatePlans,
  showRatePlans,
  availableUnits,
  showModalHandler,
  unitTypeCurrency,
  shouldShowRateToggle,
  numberOfPersons
}) => {
  const { translate } = useContext(LanguageContext)
  const { currencyConversion } = useContext(CurrenciesContext)
  const { value, currency } = currencyConversion(minimumRate, unitTypeCurrency)

  return (
    <RatePreviewWrapper>
      <RateLabel>{translate('Rates from')}</RateLabel>
      <RateWrapper>
        {numberOfPersons !== 0 && minimumRate ? (
          <RateValue>{`${formatPrice(value, currency)} ${currency}`}</RateValue>
        ) : (
          <EmptyOccupancy>{translate('Occupancy can not be empty')}</EmptyOccupancy>
        )}
        <RateSubtitle>{translate('per night')}</RateSubtitle>
      </RateWrapper>
      {availableUnits === 2 && <LowAvailabilityCta>{translate('Only 2 left')}</LowAvailabilityCta>}
      {availableUnits === 1 && <OnlyOneLeftAvailabilityCta>{translate('Only 1 left')}</OnlyOneLeftAvailabilityCta>}
      {availableUnits === 0 && <OnlyOneLeftAvailabilityCta>{translate('No units left')}</OnlyOneLeftAvailabilityCta>}
      {shouldShowRateToggle && (
        <RatesToggle onClick={toggleRatePlans} biggerTopMargin={availableUnits > 2}>
          <span>{showRatePlans ? translate('Hide rates') : translate('Show rates')}</span>
          <RatesToggleIcon isActive={showRatePlans}>
            <ArrowDown strokeWidth={2} stroke='white' />
          </RatesToggleIcon>
        </RatesToggle>
      )}

      <RatePolicies onClick={showModalHandler}>{translate('Policies')}</RatePolicies>
    </RatePreviewWrapper>
  )
}

RatePreview.defaultProps = {
  shouldShowRateToggle: true
}

RatePreview.propTypes = {
  toggleRatePlans: PropTypes.func,
  minimumRate: PropTypes.number,
  showRatePlans: PropTypes.bool,
  availableUnits: PropTypes.number,
  showModalHandler: PropTypes.func,
  unitTypeCurrency: PropTypes.string,
  shouldShowRateToggle: PropTypes.bool,
  numberOfPersons: PropTypes.number
}

export default RatePreview
