import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Promo from 'Rentlio/screen/layout/components/Promo'
import { clearPromoCode, setPromoCode } from 'Rentlio/state/actions/ui'

const PromoContainer = props => <Promo {...props} />

PromoContainer.propTypes = {
  promoCode: PropTypes.string,
  setPromoCode: PropTypes.func,
  clearPromoCode: PropTypes.func,
  isMobile: PropTypes.bool,
  isPromoCodeAppliedToAnyRate: PropTypes.bool
}

PromoContainer.defaultProps = {
  isMobile: false
}
const mapStateToProps = state => ({
  promoCode: state.request.promoCode,
  promoCodeError: state.request.promoCodeError,
  isPromoCodeAppliedToAnyRate: state.request.isPromoCodeAppliedToAnyRate
})

const mapDispatchToProps = dispatch => ({
  setPromoCode: promoCode => dispatch(setPromoCode(promoCode)),
  clearPromoCode: () => dispatch(clearPromoCode())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PromoContainer))
