import PropTypes from 'prop-types'
import React from 'react'
import { Title, Description, Included, PromoBadge } from './style'
import Images from 'Rentlio/components/UI/Images'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

class PackageDealModal extends React.PureComponent {
  static contextType = LanguageContext

  render() {
    const { images, description, name, includedServices } = this.props
    const { translate } = this.context

    return (
      <React.Fragment>
        <PromoBadge marginLeft={'0px'}>{translate('Package deal')}</PromoBadge>
        <Title>{name}</Title>
        {images && images.length ? <Images hasThumbnails isModalImage images={images} /> : null}
        {includedServices && includedServices.length ? (
          <Included>{`${translate('Included')}: ${includedServices.join(',')}`}</Included>
        ) : null}
        <Description>{description}</Description>
      </React.Fragment>
    )
  }
}

PackageDealModal.propTypes = {
  images: PropTypes.array,
  description: PropTypes.string,
  name: PropTypes.string,
  includedServices: PropTypes.array
}
export default PackageDealModal
