import moment from 'moment'

export const ANALYTICS_COOKIES = 'analyticscookies'

export const getAvailableCookies = bookingEngine => {
  const { hasGTM, hasGA, hasFBPixel, hasFBChat } = bookingEngine
  const cookies = {}
  if (hasGTM) {
    cookies.GTM = { enabled: true, label: 'Google Tag Manager' }
  }
  if (hasGA) {
    cookies.GA = { enabled: true, label: 'Google Analytics' }
  }
  if (hasFBPixel) {
    cookies.FB = { enabled: true, label: 'Facebook Pixel' }
  }
  if (hasFBChat) {
    cookies.FBChat = { enabled: true, label: 'FB Messenger' }
  }
  if (!Object.keys(cookies).length) {
    return null
  }
  return cookies
}

export const saveCookies = cookies => {
  const cookiesWithExpiration = cookies
    ? {
        ...cookies,
        expirationDate: moment()
          .add(1, 'years')
          .unix()
      }
    : {
        expirationDate: moment()
          .add(1, 'years')
          .unix()
      }
  window.localStorage && window.localStorage.setItem(ANALYTICS_COOKIES, JSON.stringify(cookiesWithExpiration))

  // eslint-disable-next-line no-undef
  const event = new Event('consent-given')

  window.dispatchEvent(event)
}

export const clearAnalyticsCookies = () => {
  window.localStorage && window.localStorage.removeItem(ANALYTICS_COOKIES)
}
