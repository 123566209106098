import styled, { css } from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const FormRow = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 40px;

  @media ${device.laptop} {
    margin-bottom: 0;
    flex-direction: column;
    align-items: flex-start;
  }
`

export const FormGroup = styled.div`
  width: 100%;
  ${({ width }) =>
    width
      ? css`
          max-width: ${width}px;
        `
      : null}
  margin-left: 15px;
  margin-right: 15px;

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }

  @media ${device.laptop} {
    margin: 0;
    margin-bottom: 20px;
  }

  @media ${device.mobile} {
    max-width: 100%;
  }
`

export const FormLabel = styled.div`
  text-transform: uppercase;
  color: ${props => props.theme.colors.textColor};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  margin-bottom: 10px;
`

export const BankDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;

  > div {
    margin-bottom: 30px;
  }

  label {
    display: block;
    color: ${({ theme }) => theme.colors.textColor};
    font-size: ${({ theme }) => theme.fonts._14};
    font-weight: ${({ theme }) => theme.fonts.regularWeight};
    margin-bottom: 5px;
  }

  span {
    display: block;
    color: ${({ theme }) => theme.colors.veryDarkGray};
    font-size: ${({ theme }) => theme.fonts._18};
    font-weight: ${({ theme }) => theme.fonts.mediumWeight};
  }
`
export const ReferenceNumber = styled.div`
  display: flex;
  margin-bottom: 30px;

  label,
  span {
    display: block;
    color: ${({ theme }) => theme.colors.veryDarkGray};
    font-size: ${({ theme }) => theme.fonts._18};
    font-weight: ${({ theme }) => theme.fonts.mediumWeight};
  }
`

export const IbanInputWrapper = styled.div`
  input {
    min-height: 65px;
    font-size: ${props => props.theme.fonts._14};
  }
`
