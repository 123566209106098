import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { AnimationWrapper } from './style'

const Animation = ({ show, children, animationType, isModal, zIndex }) => {
  const [mount, setMount] = useState(show)

  useEffect(() => {
    if (show) setMount(true)
  }, [show])

  const onAnimationEnd = () => {
    if (!show) setMount(false)
  }

  return (
    mount && (
      <AnimationWrapper
        show={show}
        onAnimationEnd={onAnimationEnd}
        animationType={animationType}
        isModal={isModal}
        zIndex={zIndex}
      >
        {children}
      </AnimationWrapper>
    )
  )
}

Animation.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  animationType: PropTypes.string.isRequired,
  isModal: PropTypes.bool,
  zIndex: PropTypes.number
}

export default Animation
