import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const DiscountRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;

  @media ${device.tablet} {
    justify-content: center;
    margin-right: 0;
    margin-bottom: 30px;
  }
`

export const DiscountOriginalPrice = styled.div`
  position: relative;
  font-size: ${props => props.theme.fonts._18};
  line-height: ${props => props.theme.fonts._21};
  font-weight: ${props => props.theme.fonts.boldWeight};
  color: ${props => props.theme.colors.textColor};
  text-transform: uppercase;
  text-decoration: line-through;

  @media ${device.tabletAndWider} {
    font-size: ${props => props.theme.fonts._24};
    line-height: ${props => props.theme.fonts._28};
  }

  @media ${device.onlyMobile} {
    font-size: ${props => props.theme.fonts._20};
    line-height: ${props => props.theme.fonts._24};
  }
`

export const DiscountBadge = styled.div`
  padding: 6px 10px;
  text-transform: uppercase;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.secondary};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  font-weight: ${({ theme }) => theme.fonts.boldWeight};
  display: inline-block;
  margin-bottom: 7.5px;
  color: ${props => props.theme.colors.white};

  @media ${device.tabletAndWider} {
    margin-bottom: 7.5px;
  }
`
