import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const FooterWrapper = styled.div`
  height: 140px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 10px;
  padding-top: 20px;
  width: 70%;
  margin: auto;

  @media ${device.tablet} {
    padding-top: 40px;
    height: 280px;
    display: block;
    width: 80%;
  }

  @media ${device.mobile} {
    position: relative;
    z-index: 2;
    height: 300px;
    justify-content: space-evenly;
    flex-direction: column;
    padding-bottom: 10px;
    padding-top: 50px;
  }
`

export const CardLogosWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  align-items: center;

  @media ${device.tablet} {
    justify-content: center;
    margin-bottom: 50px;
  }
`

export const Link = styled.a`
  height: 50px;
  text-align: center;
  margin-right: 10px;

  @media ${device.mobile} {
    height: 50px;
  }
`

export const Logo = styled.div`
  object-fit: contain;
  width: 100%;
  height: 100%;
`

export const WSPaySecure = styled.a`
  width: 120px;
  text-align: center;

  @media ${device.tablet} {
    margin-right: 0px;
    width: 100px;
  }
`

export const LogoSecure = styled.img`
  height: 50px;

  @media ${device.tablet} {
    height: 50px;
  }

  @media ${device.mobile} {
    height: 50px;
  }
`
