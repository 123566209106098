import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const ThumbnailsWrapper = styled.div`
  display: flex;

  margin: 20px 0 50px;

  @media ${device.tablet} {
    display: none;
  }
`
export const Image = styled.img`
  width: 95px;
  height: 70px;
  margin-right: 20px;
  border: 2px solid ${props => (props.active ? props.theme.colors.secondary : 'transparent')};
  border-radius: 10px;
  background-size: cover;
  box-shadow: ${props => (props.active ? `0px 5px 10px ${props.theme.colors.thumbnailShadow}` : 'none')};
  box-sizing: border-box;
  object-fit: cover;
  cursor: pointer;
  transition: all ${({ theme }) => theme.animations.defaultTransition};

  &:last-of-type {
    margin-right: 0;
  }
`
