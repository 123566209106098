import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { ControlsWrapper, Description, Info, Price, PriceInfo, ServiceWrapper, Title, DisableMandatory } from './style'
import getServiceDescription from 'Rentlio/utils/serviceDescription'
import NumberInput from 'Rentlio/components/UI/NumberInput'
import SmallText from 'Rentlio/components/UI/smallText'
import { formatPrice } from 'Rentlio/components/utils/Price/price'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import { CurrenciesContext } from 'Rentlio/context/CurrenciesContext'

const Service = ({ service, updateQuantity, name, quantity, unitTypeCurrency }) => {
  const { translate } = useContext(LanguageContext)
  const { currencyConversion } = useContext(CurrenciesContext)
  const { value, currency } = currencyConversion(service.price, unitTypeCurrency)
  const { isMandatory, description } = service
  return (
    <ServiceWrapper>
      <Info>
        <Title>{name}</Title>
        <Description>{description}</Description>
      </Info>
      <PriceInfo>
        <Price>
          {formatPrice(value, currency)} {currency}
        </Price>
        <SmallText>{translate(getServiceDescription(service))}</SmallText>
      </PriceInfo>
      <DisableMandatory isDisabled={isMandatory}>
        <ControlsWrapper>
          <NumberInput editing minValue={0} value={quantity} onChange={updateQuantity} />
        </ControlsWrapper>
      </DisableMandatory>
    </ServiceWrapper>
  )
}

Service.propTypes = {
  service: PropTypes.object,
  updateQuantity: PropTypes.func,
  name: PropTypes.string,
  quantity: PropTypes.number,
  unitTypeCurrency: PropTypes.string
}
export default Service
