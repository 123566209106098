import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { Paragraph, RateName, Title } from './style'
import CancellationPolicy from 'Rentlio/components/ReservationSummary/CancellationPolicyFull'
import Modal from 'Rentlio/components/Modal'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import Animation from 'Rentlio/components/UI/Animation'
import { modals } from 'Rentlio/screen/layout/utils/modalsEnum'

const CancelationPolicy = ({
  accommodationItems,
  closeModal,
  activeModal,
  paymentMethodId,
  arrivalDate,
  createdAt
}) => {
  const { translate } = useContext(LanguageContext)
  return (
    <Animation show={activeModal === modals.CANCELLATION_POLICY} animationType={'fadeInOut'} isModal>
      <Modal hideModalHandler={closeModal} show={activeModal === modals.CANCELLATION_POLICY}>
        <Title>{translate('Cancellation policy')}</Title>
        {accommodationItems
          .filter(item => item !== null)
          .map((item, i) => (
            <Paragraph key={i}>
              <RateName>{item.ratePlanName}</RateName>
              <CancellationPolicy
                policy={item.cancellationPolicy}
                totalRatePrice={item.price}
                currency={item.currency}
                paymentMethod={paymentMethodId}
                servicesCost={item.servicesCost}
                arrivalDate={arrivalDate}
                createdAt={createdAt}
              />
            </Paragraph>
          ))}
      </Modal>
    </Animation>
  )
}

CancelationPolicy.propTypes = {
  accommodationItems: PropTypes.array,
  closeModal: PropTypes.func,
  activeModal: PropTypes.string,
  paymentMethodId: PropTypes.number,
  arrivalDate: PropTypes.number,
  createdAt: PropTypes.number
}

export default CancelationPolicy
