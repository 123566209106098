import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const CartWrapper = styled.div`
  overflow: hidden;
  display: flex;
  position: fixed;
  bottom: 100px;
  left: 110px;
  border-radius: 10px;
  font-weight: ${props => props.theme.fonts.boldWeight};
  z-index: ${props => props.theme.zIndex.cart};

  @media ${device.tablet} {
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
  }
`

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 220px;
  height: 102px;
  padding-left: 20px;
  background: rgba(51, 51, 51, 0.95);
  color: #fff;
  font-size: ${props => props.theme.fonts._12};

  @media ${device.tablet} {
    width: 100%;
  }
`

export const SelectedRooms = styled.div`
  margin-bottom: 5px;
  text-transform: uppercase;
`

export const TotalCostWrapper = styled.div`
  display: flex;
  margin-bottom: 12px;
  text-transform: uppercase;
`

export const TotalCost = styled.div`
  margin-left: 5px;
  font-weight: ${props => props.theme.fonts.regularWeight};
`

export const ProceedWrapper = styled.div`
  display: flex;
  width: 220px;
  height: 102px;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.secondary};
  transition: all ${({ theme }) => theme.animations.defaultTransition};

  &:hover {
    background: ${({ theme }) => theme.colors.vividOrange};
  }

  @media ${device.onlyMobile} {
    width: 140px;
  }
`

export const Proceed = styled.div`
  align-self: center;
  font-size: ${props => props.theme.fonts._18};
  line-height: ${props => props.theme.fonts._21};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  margin-right: 15px;
`

export const ClearWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`
export const Clear = styled.p`
  margin: 0 10px 0 0;
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  cursor: pointer;
`
