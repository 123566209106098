import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import { convertCurrency, shouldShowExchangeRateForHRK } from 'Rentlio/helper/currency'

const CurrenciesContext = createContext()

const CurrenciesProvider = ({ children }) => {
  const [selectedCurrency, setSelectedCurrency] = useState(window.store.getState().configuration.currency)

  const changeCurrency = currency => {
    setSelectedCurrency(currency)
  }

  const currencyConversion = (value, fromCurrency) => {
    return convertCurrency(value, fromCurrency, selectedCurrency)
  }

  const shouldShowExchangeRate = () => {
    return shouldShowExchangeRateForHRK(selectedCurrency)
  }

  return (
    <CurrenciesContext.Provider
      value={{ changeCurrency, currencyConversion, shouldShowExchangeRate, selectedCurrency }}
    >
      {children}
    </CurrenciesContext.Provider>
  )
}

CurrenciesProvider.propTypes = {
  children: PropTypes.node
}

export { CurrenciesContext }
export default CurrenciesProvider
