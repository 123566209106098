import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const OfferFooterWrapper = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.modalBackground};
  color: ${props => props.theme.colors.white};
  padding: 25px 0;
  margin: 30px 0;

  @media ${device.tablet} {
    padding: 25px;
  }
`
export const OfferFooterInnerWrapper = styled.div`
  font-size: ${props => props.theme.fonts._18};
  line-height: ${props => props.theme.fonts._21};
  text-transform: uppercase;
  text-align: center;

  @media ${device.tabletAndWider} {
    display: flex;
    align-items: center;
    text-align: left;
  }
`

export const OfferFooterLabel = styled.div`
  font-weight: 600;

  @media ${device.mobile} {
    font-size: ${props => props.theme.fonts._12};
    line-height: ${props => props.theme.fonts._14};
  }
`

export const OfferFooterValue = styled.div`
  margin-left: 10px;
  margin-right: 30px;
  font-weight: 400;

  @media ${device.mobile} {
    font-size: ${props => props.theme.fonts._14};
    line-height: ${props => props.theme.fonts._16};
  }

  @media ${device.tablet} {
    margin: 7.5px 0;
  }
`

export const ButtonWrapper = styled.div`
  max-width: 180px;
  margin: 15px auto 0;

  @media ${device.tabletAndWider} {
    margin: 0 0 0 auto;
  }
`
