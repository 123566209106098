const baseFontSize = 12

function getRemsFromBaseSize(valueInPixels) {
  return valueInPixels / baseFontSize + 'rem'
}

const fonts = {
  bodyFontSize: baseFontSize,
  _12: getRemsFromBaseSize(12),
  _14: getRemsFromBaseSize(14),
  _16: getRemsFromBaseSize(16),
  _17: getRemsFromBaseSize(17),
  _18: getRemsFromBaseSize(18),
  _20: getRemsFromBaseSize(20),
  _21: getRemsFromBaseSize(21),
  _24: getRemsFromBaseSize(24),
  _28: getRemsFromBaseSize(28),
  _32: getRemsFromBaseSize(32),
  _37: getRemsFromBaseSize(37),
  _48: getRemsFromBaseSize(48),
  _60: getRemsFromBaseSize(60),
  regularWeight: 400,
  mediumWeight: 500,
  boldWeight: 700,
  defaultFamily: "'Roboto', sans-serif"
}

export default fonts
