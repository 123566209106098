import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const LocationWrapper = styled.div`
  display: flex;
  position: relative;
  width: 150px;
  height: 100%;
  margin-right: 50px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media ${device.laptop} {
    margin-right: 0;
  }

  @media ${device.tablet} {
    width: 100%;
    min-width: 120px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    order: 2;
    height: auto;
  }
`

export const Label = styled.div`
  text-transform: uppercase;
  color: ${props => props.theme.colors.semiTransparentBlackText};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  margin-bottom: 15px;

  @media ${device.tablet} {
    text-align: center;
    margin-bottom: 0;
  }
`

export const Location = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  font-weight: 500;
  font-size: ${props => props.theme.fonts._24};
  line-height: ${props => props.theme.fonts._28};
  color: ${props => props.theme.colors.black};
  user-select: none;

  ${props => (props.editing ? '' : 'cursor: pointer;')}
`

export const LocationName = styled.span`
  font-weight: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const IconWrapper = styled.span`
  svg {
    height: ${props => props.theme.fonts._18};
  }
`

export const Locations = styled.ul`
  overflow: auto;
  width: 232px;
  max-height: 269px;
  padding: 22px 0;
  position: absolute;
  top: -10px;
  background: ${props => props.theme.colors.white};
  border-radius: 20px;
  margin-top: 0;
  list-style-type: none;
  z-index: ${({ theme }) => theme.zIndex.searchLocations};
  box-shadow: 0px 0.927421px 0.686979px rgba(0, 0, 0, 0.0279017), 0px 2.34552px 1.73742px rgba(0, 0, 0, 0.0399338),
    0px 4.78464px 3.54417px rgba(0, 0, 0, 0.0500662), 0px 9.85544px 7.30033px rgba(0, 0, 0, 0.0620983),
    0px 27px 20px rgba(0, 0, 0, 0.09);

  li {
    padding: 15px 0 15px 43px;
    cursor: pointer;
    font-weight: 500;
    font-size: ${props => props.theme.fonts._14};
    line-height: ${props => props.theme.fonts._16};

    &:hover {
      background-color: ${props => props.theme.colors.locationGray};
    }
  }
`
