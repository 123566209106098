import { FETCH_AVAILABILITY_COMPLETED } from 'Rentlio/state/types'
import createReducer from 'Rentlio/state/utils/createReducer'

/**
 const initialState = [
 ...
 {
    id: int,
    name: string,
    paymentType: int, // Enum
    priceBeforeTax: float,
    priceAfterTax: float,
    taxes: object[]
   }
 ...
 ]
 */

const initialState = []

const services = createReducer(initialState)({
  [FETCH_AVAILABILITY_COMPLETED]: (state, action) => [...action.payload.services]
})

export default services
