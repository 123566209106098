import PropTypes from 'prop-types'
import React from 'react'
import { IconWrapper, MinusAction, NumberInputWrapper, PlusAction, ValueInput } from './style'
import ArrowDown from 'Rentlio/components/Icons/arrowDown'
import Minus from 'Rentlio/components/Icons/minus'
import Plus from 'Rentlio/components/Icons/plus'
import colors from 'Rentlio/style/colors'

class NumberInput extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const { onChange, value, maxValue, minValue, editing } = this.props
    if (value > maxValue) {
      onChange(maxValue)
    }

    if (prevProps.editing && !editing) {
      if (value < minValue) {
        onChange(minValue)
      }
    }
  }

  increase = () => {
    const { onChange, value, maxValue } = this.props

    if (value === maxValue) {
      return
    }

    onChange(value + 1)
  }

  decrease = () => {
    const { onChange, value, minValue } = this.props

    if (value === minValue) {
      return
    }

    onChange(value - 1)
  }

  onChange = event => {
    const { onChange, maxValue } = this.props

    let number = +event.target.value

    if (isNaN(number)) {
      number = 0
    }

    if (number > maxValue) {
      number = maxValue
    }

    onChange(number)
  }

  onBlur = () => {
    const { value, onChange, minValue } = this.props
    if (minValue > value || isNaN(value)) {
      onChange(minValue)
    }
  }

  render() {
    const { editing, minValue, maxValue, value, bigFontSize, isDarkStyle } = this.props

    if (editing) {
      return (
        <NumberInputWrapper isDarkStyle={isDarkStyle}>
          <MinusAction isDisabled={value < minValue + 1} onClick={this.decrease} isDarkStyle={isDarkStyle}>
            <Minus stroke={isDarkStyle && colors.white} />
          </MinusAction>
          <ValueInput
            value={value}
            onChange={this.onChange}
            bigFontSize={bigFontSize}
            onBlur={this.onBlur}
            isDarkStyle={isDarkStyle}
          />
          <PlusAction isDisabled={value === maxValue} onClick={this.increase} isDarkStyle={isDarkStyle}>
            <Plus stroke={isDarkStyle && colors.white} />
          </PlusAction>
        </NumberInputWrapper>
      )
    }

    return (
      <NumberInputWrapper isDarkStyle={isDarkStyle}>
        <span>{value}</span>
        <IconWrapper>
          <ArrowDown strokeWidth={2} stroke={isDarkStyle ? colors.white : null} />
        </IconWrapper>
      </NumberInputWrapper>
    )
  }
}

NumberInput.propTypes = {
  value: PropTypes.number,
  editing: PropTypes.bool,
  onChange: PropTypes.func,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  bigFontSize: PropTypes.bool,
  isDarkStyle: PropTypes.bool
}

export default NumberInput
