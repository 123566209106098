import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import WSPayFooter from 'Rentlio/screen/layout/components/WSPayFooter'
import { BE_WSPAY } from 'Rentlio/utils/paymentTypesEnum'

const WSPayFooterContainer = ({ paymentMethods, locale }) => {
  const wspayPaymentMethod = paymentMethods.find(method => Number(method.id) === BE_WSPAY)
  return wspayPaymentMethod ? (
    <WSPayFooter locale={locale} selectedCardTypes={wspayPaymentMethod?.settings?.selectedCardTypes} />
  ) : null
}

const mapStateToProps = state => ({
  locale: state.configuration.locale,
  paymentMethods: state.paymentMethods
})

WSPayFooterContainer.propTypes = {
  paymentMethods: PropTypes.array,
  locale: PropTypes.string
}

export default connect(mapStateToProps, null)(WSPayFooterContainer)
