import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const ReservationDatesWrapper = styled.div`
  background: white;
  box-shadow: 0px 0.30914px 0.549583px rgba(0, 0, 0, 0.02), 0px 0.781839px 1.38994px rgba(0, 0, 0, 0.02),
    0px 1.59488px 2.83534px rgba(0, 0, 0, 0.03), 0px 3.28515px 5.84026px rgba(0, 0, 0, 0.03),
    0px 9px 16px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 40px;
  padding: 25px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Date = styled.div`
  color: black;
  display: inline-flex;
  align-items: baseline;
  font-size: ${props => props.theme.fonts._24};
  line-height: ${props => props.theme.fonts._28};
  font-weight: ${props => props.theme.fonts.mediumWeight};

  @media ${device.tablet} {
    font-size: ${props => props.theme.fonts._16};
  }
`

export const Day = styled.div`
  color: ${({ theme }) => theme.colors.labelTextColor};
  display: block;
  align-items: baseline;
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._28};
  font-weight: ${props => props.theme.fonts.mediumWeight};

  @media ${device.tablet} {
    font-size: ${props => props.theme.fonts._12};
  }
`

export const Label = styled.div`
  color: ${props => props.theme.colors.semiTransparentBlackText};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  margin-bottom: 10px;
`

export const ArrowWrapper = styled.div`
  margin-left: 30px;
  margin-right: 30px;
`
