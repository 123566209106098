import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import colors from '../../style/colors'
import { ModalWrapper, ModalView, CloseWrapper, CloseText } from './style'
import Close from 'Rentlio/components/Icons/close'
import { LanguageContext } from 'Rentlio/context/LanguageContext/'
import Animation from 'Rentlio/components/UI/Animation'

class Modal extends React.PureComponent {
  static contextType = LanguageContext
  modalRef = React.createRef()

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClick, true)
    document.body.classList.add('modal-open')
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClick, true)
    document.body.classList.remove('modal-open')
  }

  handleClick = e => {
    if (this.modalRef.current.contains(e.target)) {
      return
    }

    this.props.hideModalHandler()
  }

  render() {
    const { hideModalHandler, children, padding, paddingBottom, show, isLarge, width } = this.props
    const { translate } = this.context

    // Use a portal to render the children into the element
    return ReactDOM.createPortal(
      <Animation show={show} animationType={'fadeInOut'} isModal>
        <ModalWrapper>
          <ModalView
            ref={this.modalRef}
            padding={padding}
            paddingBottom={paddingBottom}
            isLarge={isLarge}
            width={width}
          >
            <CloseWrapper onClick={hideModalHandler}>
              <CloseText>{translate('Close')}</CloseText>
              <Close color={colors.white} />
            </CloseWrapper>
            {children}
          </ModalView>
        </ModalWrapper>
      </Animation>,
      document.querySelector('body')
    )
  }
}

Modal.defaultProps = {
  isLarge: false
}

Modal.propTypes = {
  hideModalHandler: PropTypes.func,
  children: PropTypes.node,
  paddingBottom: PropTypes.string,
  padding: PropTypes.string,
  isLarge: PropTypes.bool,
  show: PropTypes.bool,
  width: PropTypes.number
}
export default Modal
