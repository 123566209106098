import React from 'react'
import PropTypes from 'prop-types'
import { ChildrenAgeTitle, ChildrenAgeWrapper, SelectsWrapper } from './style'
import Select from 'Rentlio/components/UI/Select'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

function getChildrenAgeOptions(translate) {
  const options = []

  // we do NOT want to include 18 in the dropdown
  for (let i = 0; i < 18; i++) {
    options.push({
      value: i,
      label: i + ' ' + translate('yrs')
    })
  }
  return options
}

class ChildrenAge extends React.PureComponent {
  static contextType = LanguageContext

  updateChildrenAge = (index, newValue) => {
    const { children, updateChildren } = this.props

    updateChildren(children.map((child, i) => (i === index ? newValue : child)))
  }

  render() {
    const { children, isDarkStyle } = this.props
    const { translate } = this.context

    return (
      <ChildrenAgeWrapper isDarkStyle={isDarkStyle}>
        <ChildrenAgeTitle isDarkStyle={isDarkStyle}>{translate('Age at check-out')}</ChildrenAgeTitle>
        <SelectsWrapper>
          {children.map((child, i) => (
            <Select
              key={i}
              options={getChildrenAgeOptions(translate)}
              selected={child}
              selectOption={item => this.updateChildrenAge(i, item.value)}
            />
          ))}
        </SelectsWrapper>
      </ChildrenAgeWrapper>
    )
  }
}

ChildrenAge.propTypes = {
  children: PropTypes.array,
  updateChildren: PropTypes.func,
  isDarkStyle: PropTypes.bool
}

export default ChildrenAge
