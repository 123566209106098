import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const BookingSummaryWrapper = styled.div`
  max-width: 480px;
  width: 100%;
  background: ${props => props.theme.colors.reservationSummaryBackground};
  padding: 50px 0 50px 50px;
  border-top-left-radius: 10px;
  margin-top: -10px;
  padding-bottom: 150px;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.border};

  @media ${device.tablet} {
    order: 1;
    max-width: 100%;
    padding: 50px 30px;
    border-radius: 0;
    border: none;
  }
`

export const TotalCost = styled.div`
  height: 60px;
  margin-top: 30px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  color: ${props => props.theme.colors.normalButtonText};
  background: ${props => props.theme.colors.normalButtonBackground};
  border-radius: 10px;
  box-shadow: 0px 0.30914px 0.549583px rgba(0, 0, 0, 0.02), 0px 0.781839px 1.38994px rgba(0, 0, 0, 0.02),
    0px 1.59488px 2.83534px rgba(0, 0, 0, 0.03), 0px 3.28515px 5.84026px rgba(0, 0, 0, 0.03),
    0px 9px 16px rgba(0, 0, 0, 0.05);
  text-transform: uppercase;
`

export const TotalCostLabel = styled.div`
  font-weight: bold;
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
`

export const TotalCostValue = styled.div`
  font-weight: bold;
  font-size: ${props => props.theme.fonts._18};
  line-height: ${props => props.theme.fonts._21};
`

export const VatInfoRow = styled.div`
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 15px;

  @media ${device.tablet} {
    padding: 0;
  }
`

export const VatInfoValue = styled.div`
  font-weight: ${props => props.theme.fonts.mediumWeight};
  text-align: right;
  min-width: 100px;
  margin-left: 20px;
`

export const CancellationPolicyWrapper = styled.div`
  margin-top: 10px;
`

export const CancellationPolicySegment = styled.div`
  margin-bottom: 10px;
  white-space: normal;
`

export const BoldText = styled.b`
  line-height: ${props => props.theme.fonts._16};
`

export const TotalCostSecondaryValue = styled.div`
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  margin-top: 5px;
`

export const TotalCostValuesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
