import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { selectLocale, selectCurrency } from 'Rentlio/state/actions/ui'
import { getBookingEngine } from 'Rentlio/state/reducers/bookingEngine'
import Header from 'Rentlio/screen/layout/components/Header'

class HeaderContainer extends React.PureComponent {
  state = {
    isCheckIn: false
  }

  componentDidMount = () => {
    const urlPath = window.location.pathname
    const foundUuid = urlPath.match(/^\/reservation\/check-in\/([0-9a-fA-F-]+)$/)
    if (foundUuid) {
      this.setState({ isCheckIn: true })
    }
  }

  render() {
    const { bookingEngine, locale, selectLocale, selectCurrency, currency, unitTypeId, bookingEngineId } = this.props
    const { name, logoUrl, activeLanguages, activeCurrencies, logoRedirectsTo } = bookingEngine
    const { isCheckIn } = this.state

    return (
      <Header
        name={name}
        logoUrl={logoUrl}
        locale={locale}
        selectLocale={selectLocale}
        activeLanguages={activeLanguages}
        activeCurrencies={activeCurrencies}
        selectCurrency={selectCurrency}
        currency={currency}
        unitTypeId={unitTypeId}
        bookingEngineId={bookingEngineId}
        logoRedirectsTo={logoRedirectsTo}
        isCheckIn={isCheckIn}
      />
    )
  }
}

HeaderContainer.propTypes = {
  bookingEngine: PropTypes.object,
  locale: PropTypes.string,
  selectLocale: PropTypes.func,
  selectCurrency: PropTypes.func,
  currency: PropTypes.string,
  unitTypeId: PropTypes.string,
  bookingEngineId: PropTypes.number
}

const mapStateToProps = state => ({
  bookingEngine: getBookingEngine(state),
  locale: state.configuration.locale,
  currency: state.configuration.currency,
  unitTypeId: state.request.unitTypeId,
  bookingEngineId: state.request.bookingEngineId,
  guests: state.guests
})

const mapDispatchToProps = dispatch => ({
  selectLocale: (locale, isCheckIn) => dispatch(selectLocale(locale, isCheckIn)),
  selectCurrency: currency => dispatch(selectCurrency(currency))
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer)
