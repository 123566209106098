import { createGlobalStyle } from 'styled-components'
import robotoRegular from 'Fonts/Roboto-Regular.ttf'
import robotoMedium from 'Fonts/Roboto-Medium.ttf'
import robotoBold from 'Fonts/Roboto-Bold.ttf'
import surannaRegular from 'Fonts/Suranna-Regular.ttf'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Suranna;
    src: url(${surannaRegular} format('ttf'));
    font-weight: 400; 
  }
  
  @font-face {
    font-family: Roboto;
    src: url(${robotoRegular} format('ttf'));
    font-weight: 400; 
    font-display: swap;
  }
  
  @font-face {
    font-family: Roboto;
    src: url(${robotoMedium} format('ttf'));
    font-weight: 500;
    font-display: swap;
  }
  
  @font-face {
    font-family: Roboto;
    src: url(${robotoBold} format('ttf'));
    font-weight: 700;
    font-display: swap;
  }
  
  html {
    font-size: ${props => props.theme.fonts.bodyFontSize}px;
  }
  
  body {
    margin: 0;
    padding: 0;
    color: ${props => props.theme.colors.textColor};
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    background: ${props => props.theme.colors.backgroundColor};

    &.modal-open {
      overflow: hidden;
    }
  }
  
  h1, h2, h3 {
    margin: 0;
    padding: 0;
  }
  
  a {
    text-decoration: none;
    
    &:active,
    &:hover,
    &:visited,
    &:focus {
      text-decoration: none;
    }
  }
  
  * {
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }
`

export default GlobalStyle
