import { PRICE_PER_DAY_RESERVATION_CHANGE } from 'Rentlio/state/types'
import createReducer from 'Rentlio/state/utils/createReducer'

const initialState = {
  reservationIndex: 0
}
const pricePerDay = createReducer(initialState)({
  [PRICE_PER_DAY_RESERVATION_CHANGE]: (state, { index }) => ({ ...state, reservationIndex: index })
})

export default pricePerDay
