import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Field, Label, Content, ContentTextArea } from './style'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

const Information = ({ guest }) => {
  const { translate } = useContext(LanguageContext)

  if (!guest) {
    return null
  }
  return (
    <Wrapper>
      {guest.isCompany ? (
        <>
          <Field>
            <Label>{translate('Company name')}</Label>
            <Content>{guest.companyName}</Content>
          </Field>
          <Field>
            <Label>{translate('Company VAT id')}</Label>
            <Content>{guest.companyVatId}</Content>
          </Field>
        </>
      ) : (
        <>
          <Field>
            <Label>{translate('First name')}</Label>
            <Content>{guest.firstName}</Content>
          </Field>
          <Field>
            <Label>{translate('Last name')}</Label>
            <Content>{guest.lastName}</Content>
          </Field>
        </>
      )}
      <Field>
        <Label>E-mail</Label>
        <Content>{guest.email}</Content>
      </Field>
      <Field>
        <Label>{translate('Phone')}</Label>
        <Content>{guest.phone}</Content>
      </Field>
      <Field>
        <Label>{translate('Country')}</Label>
        <Content>{guest.country}</Content>
      </Field>
      <Field>
        <Label>{translate('Estimated time of arrival')}</Label>
        <Content>{guest.note && guest.note.slice(guest.note.length - 5, guest.note.length).trim()}</Content>
      </Field>
      <Field>
        <Label>{translate('Message')}</Label>
        <ContentTextArea
          disabled
          defaultValue={guest.note && guest.note.slice(0, guest.note.indexOf('Estimated time of arrival:'))}
        />
      </Field>
    </Wrapper>
  )
}

Information.propTypes = {
  guest: PropTypes.object
}

export default Information
