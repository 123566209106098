export const CountriesEnum = [
  {
    id: 1,
    code: 'US',
    longCode: 'USA',
    name: 'United States',
    label: 'United States',
    value: 1,
    dial_code: '+1'
  },
  {
    id: 2,
    code: 'CA',
    longCode: 'CAN',
    name: 'Canada',
    label: 'Canada',
    value: 2,
    dial_code: '+1'
  },
  {
    id: 3,
    code: 'AF',
    longCode: 'AFG',
    name: 'Afghanistan',
    label: 'Afghanistan',
    value: 3,
    dial_code: '+93'
  },
  {
    id: 4,
    code: 'AL',
    longCode: 'ALB',
    name: 'Albania',
    label: 'Albania',
    value: 4,
    dial_code: '+355'
  },
  {
    id: 5,
    code: 'DZ',
    longCode: 'DZA',
    name: 'Algeria',
    label: 'Algeria',
    value: 5,
    dial_code: '+213'
  },
  {
    id: 6,
    code: 'AS',
    longCode: 'ASM',
    name: 'American Samoa',
    label: 'American Samoa',
    value: 6,
    dial_code: '+1684'
  },
  {
    id: 7,
    code: 'AD',
    longCode: 'AND',
    name: 'Andorra',
    label: 'Andorra',
    value: 7,
    dial_code: '+376'
  },
  {
    id: 8,
    code: 'AO',
    longCode: 'AGO',
    name: 'Angola',
    label: 'Angola',
    value: 8,
    dial_code: '+244'
  },
  {
    id: 9,
    code: 'AI',
    longCode: 'AIA',
    name: 'Anguilla',
    label: 'Anguilla',
    value: 9,
    dial_code: '+1264'
  },
  {
    id: 10,
    code: 'AQ',
    longCode: 'ATA',
    name: 'Antarctica',
    label: 'Antarctica',
    value: 10,
    dial_code: '+672'
  },
  {
    id: 11,
    code: 'AG',
    longCode: 'ATG',
    name: 'Antigua and/or Barbuda',
    label: 'Antigua and/or Barbuda',
    value: 11,
    dial_code: '+1268'
  },
  {
    id: 12,
    code: 'AR',
    longCode: 'ARG',
    name: 'Argentina',
    label: 'Argentina',
    value: 12,
    dial_code: '+54'
  },
  {
    id: 13,
    code: 'AM',
    longCode: 'ARM',
    name: 'Armenia',
    label: 'Armenia',
    value: 13,
    dial_code: '+374'
  },
  {
    id: 14,
    code: 'AW',
    longCode: 'ABW',
    name: 'Aruba',
    label: 'Aruba',
    value: 14,
    dial_code: '+297'
  },
  {
    id: 15,
    code: 'AU',
    longCode: 'AUS',
    name: 'Australia',
    label: 'Australia',
    value: 15,
    dial_code: '+61'
  },
  {
    id: 16,
    code: 'AT',
    longCode: 'AUT',
    name: 'Austria',
    label: 'Austria',
    value: 16,
    dial_code: '+43'
  },
  {
    id: 17,
    code: 'AZ',
    longCode: 'AZE',
    name: 'Azerbaijan',
    label: 'Azerbaijan',
    value: 17,
    dial_code: '+994'
  },
  {
    id: 18,
    code: 'BS',
    longCode: 'BHS',
    name: 'Bahamas',
    label: 'Bahamas',
    value: 18,
    dial_code: '+1242'
  },
  {
    id: 19,
    code: 'BH',
    longCode: 'BHR',
    name: 'Bahrain',
    label: 'Bahrain',
    value: 19,
    dial_code: '+973'
  },
  {
    id: 20,
    code: 'BD',
    longCode: 'BGD',
    name: 'Bangladesh',
    label: 'Bangladesh',
    value: 20,
    dial_code: '+880'
  },
  {
    id: 21,
    code: 'BB',
    longCode: 'BRB',
    name: 'Barbados',
    label: 'Barbados',
    value: 21,
    dial_code: '+1246'
  },
  {
    id: 22,
    code: 'BY',
    longCode: 'BLR',
    name: 'Belarus',
    label: 'Belarus',
    value: 22,
    dial_code: '+375'
  },
  {
    id: 23,
    code: 'BE',
    longCode: 'BEL',
    name: 'Belgium',
    label: 'Belgium',
    value: 23,
    dial_code: '+32'
  },
  {
    id: 24,
    code: 'BZ',
    longCode: 'BLZ',
    name: 'Belize',
    label: 'Belize',
    value: 24,
    dial_code: '+501'
  },
  {
    id: 25,
    code: 'BJ',
    longCode: 'BEN',
    name: 'Benin',
    label: 'Benin',
    value: 25,
    dial_code: '+229'
  },
  {
    id: 26,
    code: 'BM',
    longCode: 'BMU',
    name: 'Bermuda',
    label: 'Bermuda',
    value: 26,
    dial_code: '+1441'
  },
  {
    id: 27,
    code: 'BT',
    longCode: 'BTN',
    name: 'Bhutan',
    label: 'Bhutan',
    value: 27,
    dial_code: '+975'
  },
  {
    id: 28,
    code: 'BO',
    longCode: 'BOL',
    name: 'Bolivia',
    label: 'Bolivia',
    value: 28,
    dial_code: '+591'
  },
  {
    id: 29,
    code: 'BA',
    longCode: 'BIH',
    name: 'Bosnia and Herzegovina',
    label: 'Bosnia and Herzegovina',
    value: 29,
    dial_code: '+387'
  },
  {
    id: 30,
    code: 'BW',
    longCode: 'BWA',
    name: 'Botswana',
    label: 'Botswana',
    value: 30,
    dial_code: '+267'
  },
  {
    id: 31,
    code: 'BV',
    longCode: 'BVT',
    name: 'Bouvet Island',
    label: 'Bouvet Island',
    value: 31,
    dial_code: '+55'
  },
  {
    id: 32,
    code: 'BR',
    longCode: 'BRA',
    name: 'Brazil',
    label: 'Brazil',
    value: 32,
    dial_code: '+55'
  },
  {
    id: 33,
    code: 'IO',
    longCode: 'IOT',
    name: 'British lndian Ocean Territory',
    label: 'British lndian Ocean Territory',
    value: 33,
    dial_code: '+246'
  },
  {
    id: 34,
    code: 'BN',
    longCode: 'BRN',
    name: 'Brunei Darussalam',
    label: 'Brunei Darussalam',
    value: 34,
    dial_code: '+673'
  },
  {
    id: 35,
    code: 'BG',
    longCode: 'BGR',
    name: 'Bulgaria',
    label: 'Bulgaria',
    value: 35,
    dial_code: '+359'
  },
  {
    id: 36,
    code: 'BF',
    longCode: 'BFA',
    name: 'Burkina Faso',
    label: 'Burkina Faso',
    value: 36,
    dial_code: '+226'
  },
  {
    id: 37,
    code: 'BI',
    longCode: 'BDI',
    name: 'Burundi',
    label: 'Burundi',
    value: 37,
    dial_code: '+257'
  },
  {
    id: 38,
    code: 'KH',
    longCode: 'KHM',
    name: 'Cambodia',
    label: 'Cambodia',
    value: 38,
    dial_code: '+855'
  },
  {
    id: 39,
    code: 'CM',
    longCode: 'CMR',
    name: 'Cameroon',
    label: 'Cameroon',
    value: 39,
    dial_code: '+237'
  },
  {
    id: 40,
    code: 'CV',
    longCode: 'CPV',
    name: 'Cape Verde',
    label: 'Cape Verde',
    value: 40,
    dial_code: '+238'
  },
  {
    id: 41,
    code: 'KY',
    longCode: 'CYM',
    name: 'Cayman Islands',
    label: 'Cayman Islands',
    value: 41,
    dial_code: '+ 345'
  },
  {
    id: 42,
    code: 'CF',
    longCode: 'CAF',
    name: 'Central African Republic',
    label: 'Central African Republic',
    value: 42,
    dial_code: '+236'
  },
  {
    id: 43,
    code: 'TD',
    longCode: 'TCD',
    name: 'Chad',
    label: 'Chad',
    value: 43,
    dial_code: '+235'
  },
  {
    id: 44,
    code: 'CL',
    longCode: 'CHL',
    name: 'Chile',
    label: 'Chile',
    value: 44,
    dial_code: '+56'
  },
  {
    id: 45,
    code: 'CN',
    longCode: 'CHN',
    name: 'China',
    label: 'China',
    value: 45,
    dial_code: '+86'
  },
  {
    id: 46,
    code: 'CX',
    longCode: 'CXR',
    name: 'Christmas Island',
    label: 'Christmas Island',
    value: 46,
    dial_code: '+61'
  },
  {
    id: 47,
    code: 'CC',
    longCode: 'CCK',
    name: 'Cocos (Keeling) Islands',
    label: 'Cocos (Keeling) Islands',
    value: 47,
    dial_code: '+61'
  },
  {
    id: 48,
    code: 'CO',
    longCode: 'COL',
    name: 'Colombia',
    label: 'Colombia',
    value: 48,
    dial_code: '+57'
  },
  {
    id: 49,
    code: 'KM',
    longCode: 'COM',
    name: 'Comoros',
    label: 'Comoros',
    value: 49,
    dial_code: '+269'
  },
  {
    id: 50,
    code: 'CD',
    longCode: 'COG',
    name: 'Congo',
    label: 'Congo',
    value: 50,
    dial_code: '+243'
  },
  {
    id: 51,
    code: 'CK',
    longCode: 'COK',
    name: 'Cook Islands',
    label: 'Cook Islands',
    value: 51,
    dial_code: '+682'
  },
  {
    id: 52,
    code: 'CR',
    longCode: 'CRI',
    name: 'Costa Rica',
    label: 'Costa Rica',
    value: 52,
    dial_code: '+506'
  },
  {
    id: 53,
    code: 'HR',
    longCode: 'HRV',
    name: 'Croatia (Hrvatska)',
    label: 'Croatia (Hrvatska)',
    value: 53,
    dial_code: '+385'
  },
  {
    id: 54,
    code: 'CU',
    longCode: 'CUB',
    name: 'Cuba',
    label: 'Cuba',
    value: 54,
    dial_code: '+53'
  },
  {
    id: 55,
    code: 'CY',
    longCode: 'CYP',
    name: 'Cyprus',
    label: 'Cyprus',
    value: 55,
    dial_code: '+357'
  },
  {
    id: 56,
    code: 'CZ',
    longCode: 'CZE',
    name: 'Czech Republic',
    label: 'Czech Republic',
    value: 56,
    dial_code: '+420'
  },
  {
    id: 57,
    code: 'DK',
    longCode: 'DNK',
    name: 'Denmark',
    label: 'Denmark',
    value: 57,
    dial_code: '+45'
  },
  {
    id: 58,
    code: 'DJ',
    longCode: 'DJI',
    name: 'Djibouti',
    label: 'Djibouti',
    value: 58,
    dial_code: '+253'
  },
  {
    id: 59,
    code: 'DM',
    longCode: 'DMA',
    name: 'Dominica',
    label: 'Dominica',
    value: 59,
    dial_code: '+1767'
  },
  {
    id: 60,
    code: 'DO',
    longCode: 'DOM',
    name: 'Dominican Republic',
    label: 'Dominican Republic',
    value: 60,
    dial_code: '+1849'
  },
  {
    id: 62,
    code: 'EC',
    longCode: 'ECU',
    name: 'Ecuador',
    label: 'Ecuador',
    value: 62,
    dial_code: '+593'
  },
  {
    id: 63,
    code: 'EG',
    longCode: 'EGY',
    name: 'Egypt',
    label: 'Egypt',
    value: 63,
    dial_code: '+20'
  },
  {
    id: 64,
    code: 'SV',
    longCode: 'SLV',
    name: 'El Salvador',
    label: 'El Salvador',
    value: 64,
    dial_code: '+503'
  },
  {
    id: 65,
    code: 'GQ',
    longCode: 'GNQ',
    name: 'Equatorial Guinea',
    label: 'Equatorial Guinea',
    value: 65,
    dial_code: '+240'
  },
  {
    id: 66,
    code: 'ER',
    longCode: 'ERI',
    name: 'Eritrea',
    label: 'Eritrea',
    value: 66,
    dial_code: '+291'
  },
  {
    id: 67,
    code: 'EE',
    longCode: 'EST',
    name: 'Estonia',
    label: 'Estonia',
    value: 67,
    dial_code: '+372'
  },
  {
    id: 68,
    code: 'ET',
    longCode: 'ETH',
    name: 'Ethiopia',
    label: 'Ethiopia',
    value: 68,
    dial_code: '+251'
  },
  {
    id: 69,
    code: 'FK',
    longCode: 'FLK',
    name: 'Falkland Islands (Malvinas)',
    label: 'Falkland Islands (Malvinas)',
    value: 69,
    dial_code: '+500'
  },
  {
    id: 70,
    code: 'FO',
    longCode: 'FRO',
    name: 'Faroe Islands',
    label: 'Faroe Islands',
    value: 70,
    dial_code: '+298'
  },
  {
    id: 71,
    code: 'FJ',
    longCode: 'FJI',
    name: 'Fiji',
    label: 'Fiji',
    value: 71,
    dial_code: '+679'
  },
  {
    id: 72,
    code: 'FI',
    longCode: 'FIN',
    name: 'Finland',
    label: 'Finland',
    value: 72,
    dial_code: '+358'
  },
  {
    id: 73,
    code: 'FR',
    longCode: 'FRA',
    name: 'France',
    label: 'France',
    value: 73,
    dial_code: '+33'
  },
  {
    id: 75,
    code: 'GF',
    longCode: 'GUF',
    name: 'French Guiana',
    label: 'French Guiana',
    value: 75,
    dial_code: '+594'
  },
  {
    id: 76,
    code: 'FP',
    longCode: 'PYF',
    name: 'French Polynesia',
    label: 'French Polynesia',
    value: 76,
    dial_code: '+689'
  },
  {
    id: 77,
    code: 'TF',
    longCode: 'ATF',
    name: 'French Southern Territories',
    label: 'French Southern Territories',
    value: 77,
    dial_code: '+262'
  },
  {
    id: 78,
    code: 'GA',
    longCode: 'GAB',
    name: 'Gabon',
    label: 'Gabon',
    value: 78,
    dial_code: '+241'
  },
  {
    id: 79,
    code: 'GM',
    longCode: 'GMB',
    name: 'Gambia',
    label: 'Gambia',
    value: 79,
    dial_code: '+220'
  },
  {
    id: 80,
    code: 'GE',
    longCode: 'GEO',
    name: 'Georgia',
    label: 'Georgia',
    value: 80,
    dial_code: '+995'
  },
  {
    id: 81,
    code: 'DE',
    longCode: 'DEU',
    name: 'Germany',
    label: 'Germany',
    value: 81,
    dial_code: '+49'
  },
  {
    id: 82,
    code: 'GH',
    longCode: 'GHA',
    name: 'Ghana',
    label: 'Ghana',
    value: 82,
    dial_code: '+233'
  },
  {
    id: 83,
    code: 'GI',
    longCode: 'GIB',
    name: 'Gibraltar',
    label: 'Gibraltar',
    value: 83,
    dial_code: '+350'
  },
  {
    id: 84,
    code: 'GR',
    longCode: 'GRC',
    name: 'Greece',
    label: 'Greece',
    value: 84,
    dial_code: '+30'
  },
  {
    id: 85,
    code: 'GL',
    longCode: 'GRL',
    name: 'Greenland',
    label: 'Greenland',
    value: 85,
    dial_code: '+299'
  },
  {
    id: 86,
    code: 'GD',
    longCode: 'GRD',
    name: 'Grenada',
    label: 'Grenada',
    value: 86,
    dial_code: '+1473'
  },
  {
    id: 87,
    code: 'GP',
    longCode: 'GLP',
    name: 'Guadeloupe',
    label: 'Guadeloupe',
    value: 87,
    dial_code: '+590'
  },
  {
    id: 88,
    code: 'GU',
    longCode: 'GUM',
    name: 'Guam',
    label: 'Guam',
    value: 88,
    dial_code: '+1671'
  },
  {
    id: 89,
    code: 'GT',
    longCode: 'GTM',
    name: 'Guatemala',
    label: 'Guatemala',
    value: 89,
    dial_code: '+502'
  },
  {
    id: 90,
    code: 'GN',
    longCode: 'GIN',
    name: 'Guinea',
    label: 'Guinea',
    value: 90,
    dial_code: '+224'
  },
  {
    id: 91,
    code: 'GW',
    longCode: 'GNB',
    name: 'Guinea-Bissau',
    label: 'Guinea-Bissau',
    value: 91,
    dial_code: '+245'
  },
  {
    id: 92,
    code: 'GY',
    longCode: 'GUY',
    name: 'Guyana',
    label: 'Guyana',
    value: 92,
    dial_code: '+595'
  },
  {
    id: 93,
    code: 'HT',
    longCode: 'HTI',
    name: 'Haiti',
    label: 'Haiti',
    value: 93,
    dial_code: '+509'
  },
  {
    id: 94,
    code: 'HM',
    longCode: 'HMD',
    name: 'Heard and Mc Donald Islands',
    label: 'Heard and Mc Donald Islands',
    value: 94,
    dial_code: '+0'
  },
  {
    id: 95,
    code: 'HN',
    longCode: 'HND',
    name: 'Honduras',
    label: 'Honduras',
    value: 95,
    dial_code: '+504'
  },
  {
    id: 96,
    code: 'HK',
    longCode: 'HKG',
    name: 'Hong Kong',
    label: 'Hong Kong',
    value: 96,
    dial_code: '+852'
  },
  {
    id: 97,
    code: 'HU',
    longCode: 'HUN',
    name: 'Hungary',
    label: 'Hungary',
    value: 97,
    dial_code: '+36'
  },
  {
    id: 98,
    code: 'IS',
    longCode: 'ISL',
    name: 'Iceland',
    label: 'Iceland',
    value: 98,
    dial_code: '+354'
  },
  {
    id: 99,
    code: 'IN',
    longCode: 'IND',
    name: 'India',
    label: 'India',
    value: 99,
    dial_code: '+91'
  },
  {
    id: 100,
    code: 'ID',
    longCode: 'IDN',
    name: 'Indonesia',
    label: 'Indonesia',
    value: 100,
    dial_code: '+62'
  },
  {
    id: 101,
    code: 'IR',
    longCode: 'IRN',
    name: 'Iran (Islamic Republic of)',
    label: 'Iran (Islamic Republic of)',
    value: 101,
    dial_code: '+98'
  },
  {
    id: 102,
    code: 'IQ',
    longCode: 'IRQ',
    name: 'Iraq',
    label: 'Iraq',
    value: 102,
    dial_code: '+964'
  },
  {
    id: 103,
    code: 'IE',
    longCode: 'IRL',
    name: 'Ireland',
    label: 'Ireland',
    value: 103,
    dial_code: '+353'
  },
  {
    id: 104,
    code: 'IL',
    longCode: 'ISR',
    name: 'Israel',
    label: 'Israel',
    value: 104,
    dial_code: '+972'
  },
  {
    id: 105,
    code: 'IT',
    longCode: 'ITA',
    name: 'Italy',
    label: 'Italy',
    value: 105,
    dial_code: '+39'
  },
  {
    id: 106,
    code: 'CI',
    longCode: 'CIV',
    name: 'Ivory Coast',
    label: 'Ivory Coast',
    value: 106,
    dial_code: '+225'
  },
  {
    id: 107,
    code: 'JM',
    longCode: 'JAM',
    name: 'Jamaica',
    label: 'Jamaica',
    value: 107,
    dial_code: '+1876'
  },
  {
    id: 108,
    code: 'JP',
    longCode: 'JPN',
    name: 'Japan',
    label: 'Japan',
    value: 108,
    dial_code: '+81'
  },
  {
    id: 109,
    code: 'JO',
    longCode: 'JOR',
    name: 'Jordan',
    label: 'Jordan',
    value: 109,
    dial_code: '+962'
  },
  {
    id: 110,
    code: 'KZ',
    longCode: 'KAZ',
    name: 'Kazakhstan',
    label: 'Kazakhstan',
    value: 110,
    dial_code: '+77'
  },
  {
    id: 111,
    code: 'KE',
    longCode: 'KEN',
    name: 'Kenya',
    label: 'Kenya',
    value: 111,
    dial_code: '+254'
  },
  {
    id: 112,
    code: 'KI',
    longCode: 'KIR',
    name: 'Kiribati',
    label: 'Kiribati',
    value: 112,
    dial_code: '+686'
  },
  {
    id: 113,
    code: 'KR',
    longCode: 'KOR',
    name: 'Korea, Republic of South Korea',
    label: 'Korea, Republic of South Korea',
    value: 113,
    dial_code: '+82'
  },
  {
    id: 114,
    code: 'XK',
    longCode: 'XKX',
    name: 'Kosovo',
    label: 'Kosovo',
    value: 114,
    dial_code: '+383'
  },
  {
    id: 115,
    code: 'KW',
    longCode: 'KWT',
    name: 'Kuwait',
    label: 'Kuwait',
    value: 115,
    dial_code: '+965'
  },
  {
    id: 116,
    code: 'KG',
    longCode: 'KGZ',
    name: 'Kyrgyzstan',
    label: 'Kyrgyzstan',
    value: 116,
    dial_code: '+996'
  },
  {
    id: 117,
    code: 'LA',
    longCode: 'LAO',
    name: "Lao People's Democratic Republic",
    label: "Lao People's Democratic Republic",
    value: 117,
    dial_code: '+856'
  },
  {
    id: 118,
    code: 'LV',
    longCode: 'LVA',
    name: 'Latvia',
    label: 'Latvia',
    value: 118,
    dial_code: '+371'
  },
  {
    id: 119,
    code: 'LB',
    longCode: 'LBN',
    name: 'Lebanon',
    label: 'Lebanon',
    value: 119,
    dial_code: '+961'
  },
  {
    id: 120,
    code: 'LS',
    longCode: 'LSO',
    name: 'Lesotho',
    label: 'Lesotho',
    value: 120,
    dial_code: '+266'
  },
  {
    id: 121,
    code: 'LR',
    longCode: 'LBR',
    name: 'Liberia',
    label: 'Liberia',
    value: 121,
    dial_code: '+231'
  },
  {
    id: 122,
    code: 'LY',
    longCode: 'LBY',
    name: 'Libyan Arab Jamahiriya',
    label: 'Libyan Arab Jamahiriya',
    value: 122,
    dial_code: '+218'
  },
  {
    id: 123,
    code: 'LI',
    longCode: 'LIE',
    name: 'Liechtenstein',
    label: 'Liechtenstein',
    value: 123,
    dial_code: '+423'
  },
  {
    id: 124,
    code: 'LT',
    longCode: 'LTU',
    name: 'Lithuania',
    label: 'Lithuania',
    value: 124,
    dial_code: '+370'
  },
  {
    id: 125,
    code: 'LU',
    longCode: 'LUX',
    name: 'Luxembourg',
    label: 'Luxembourg',
    value: 125,
    dial_code: '+352'
  },
  {
    id: 126,
    code: 'MO',
    longCode: 'MAC',
    name: 'Macau',
    label: 'Macau',
    value: 126,
    dial_code: '+853'
  },
  {
    id: 127,
    code: 'MK',
    longCode: 'MKD',
    name: 'Macedonia',
    label: 'Macedonia',
    value: 127,
    dial_code: '+389'
  },
  {
    id: 128,
    code: 'MG',
    longCode: 'MDG',
    name: 'Madagascar',
    label: 'Madagascar',
    value: 128,
    dial_code: '+261'
  },
  {
    id: 129,
    code: 'MW',
    longCode: 'MWI',
    name: 'Malawi',
    label: 'Malawi',
    value: 129,
    dial_code: '+265'
  },
  {
    id: 130,
    code: 'MY',
    longCode: 'MYS',
    name: 'Malaysia',
    label: 'Malaysia',
    value: 130,
    dial_code: '+60'
  },
  {
    id: 131,
    code: 'MV',
    longCode: 'MDV',
    name: 'Maldives',
    label: 'Maldives',
    value: 131,
    dial_code: '+960'
  },
  {
    id: 132,
    code: 'ML',
    longCode: 'MLI',
    name: 'Mali',
    label: 'Mali',
    value: 132,
    dial_code: '+223'
  },
  {
    id: 133,
    code: 'MT',
    longCode: 'MLT',
    name: 'Malta',
    label: 'Malta',
    value: 133,
    dial_code: '+356'
  },
  {
    id: 134,
    code: 'MH',
    longCode: 'MHL',
    name: 'Marshall Islands',
    label: 'Marshall Islands',
    value: 134,
    dial_code: '+692'
  },
  {
    id: 135,
    code: 'MQ',
    longCode: 'MTQ',
    name: 'Martinique',
    label: 'Martinique',
    value: 135,
    dial_code: '+596'
  },
  {
    id: 136,
    code: 'MR',
    longCode: 'MRT',
    name: 'Mauritania',
    label: 'Mauritania',
    value: 136,
    dial_code: '+222'
  },
  {
    id: 137,
    code: 'MU',
    longCode: 'MUS',
    name: 'Mauritius',
    label: 'Mauritius',
    value: 137,
    dial_code: '+230'
  },
  {
    id: 138,
    code: 'YT',
    longCode: 'MYT',
    name: 'Mayotte',
    label: 'Mayotte',
    value: 138,
    dial_code: '+262'
  },
  {
    id: 139,
    code: 'MX',
    longCode: 'MEX',
    name: 'Mexico',
    label: 'Mexico',
    value: 139,
    dial_code: '+52'
  },
  {
    id: 140,
    code: 'FM',
    longCode: 'FSM',
    name: 'Micronesia, Federated States of Micronesia',
    label: 'Micronesia, Federated States of Micronesia',
    value: 140,
    dial_code: '+691'
  },
  {
    id: 141,
    code: 'MD',
    longCode: 'MDA',
    name: 'Moldova',
    label: 'Moldova',
    value: 141,
    dial_code: '+373'
  },
  {
    id: 142,
    code: 'MC',
    longCode: 'MCO',
    name: 'Monaco',
    label: 'Monaco',
    value: 142,
    dial_code: '+377'
  },
  {
    id: 143,
    code: 'MN',
    longCode: 'MNG',
    name: 'Mongolia',
    label: 'Mongolia',
    value: 143,
    dial_code: '+976'
  },
  {
    id: 144,
    code: 'ME',
    longCode: 'MNE',
    name: 'Montenegro',
    label: 'Montenegro',
    value: 144,
    dial_code: '+382'
  },
  {
    id: 145,
    code: 'MS',
    longCode: 'MSR',
    name: 'Montserrat',
    label: 'Montserrat',
    value: 145,
    dial_code: '+1664'
  },
  {
    id: 146,
    code: 'MA',
    longCode: 'MAR',
    name: 'Morocco',
    label: 'Morocco',
    value: 146,
    dial_code: '+212'
  },
  {
    id: 147,
    code: 'MZ',
    longCode: 'MOZ',
    name: 'Mozambique',
    label: 'Mozambique',
    value: 147,
    dial_code: '+258'
  },
  {
    id: 148,
    code: 'MM',
    longCode: 'MMR',
    name: 'Myanmar',
    label: 'Myanmar',
    value: 148,
    dial_code: '+95'
  },
  {
    id: 149,
    code: 'NA',
    longCode: 'NAM',
    name: 'Namibia',
    label: 'Namibia',
    value: 149,
    dial_code: '+264'
  },
  {
    id: 150,
    code: 'NR',
    longCode: 'NRU',
    name: 'Nauru',
    label: 'Nauru',
    value: 150,
    dial_code: '+674'
  },
  {
    id: 151,
    code: 'NP',
    longCode: 'NPL',
    name: 'Nepal',
    label: 'Nepal',
    value: 151,
    dial_code: '+977'
  },
  {
    id: 152,
    code: 'NL',
    longCode: 'NLD',
    name: 'Netherlands',
    label: 'Netherlands',
    value: 152,
    dial_code: '+31'
  },
  {
    id: 153,
    code: 'AN',
    longCode: '',
    name: 'Netherlands Antilles',
    label: 'Netherlands Antilles',
    value: 153,
    dial_code: '+599'
  },
  {
    id: 154,
    code: 'NC',
    longCode: 'NCL',
    name: 'New Caledonia',
    label: 'New Caledonia',
    value: 154,
    dial_code: '+687'
  },
  {
    id: 155,
    code: 'NZ',
    longCode: 'NZL',
    name: 'New Zealand',
    label: 'New Zealand',
    value: 155,
    dial_code: '+64'
  },
  {
    id: 156,
    code: 'NI',
    longCode: 'NIC',
    name: 'Nicaragua',
    label: 'Nicaragua',
    value: 156,
    dial_code: '+505'
  },
  {
    id: 157,
    code: 'NE',
    longCode: 'NER',
    name: 'Niger',
    label: 'Niger',
    value: 157,
    dial_code: '+227'
  },
  {
    id: 158,
    code: 'NG',
    longCode: 'NGA',
    name: 'Nigeria',
    label: 'Nigeria',
    value: 158,
    dial_code: '+234'
  },
  {
    id: 159,
    code: 'NU',
    longCode: 'NIU',
    name: 'Niue',
    label: 'Niue',
    value: 159,
    dial_code: '+683'
  },
  {
    id: 160,
    code: 'NF',
    longCode: 'NFK',
    name: 'Norfolk Island',
    label: 'Norfolk Island',
    value: 160,
    dial_code: '+672'
  },
  {
    id: 161,
    code: 'MP',
    longCode: 'MNP',
    name: 'Northern Mariana Islands',
    label: 'Northern Mariana Islands',
    value: 161,
    dial_code: '+1670'
  },
  {
    id: 162,
    code: 'NO',
    longCode: 'NOR',
    name: 'Norway',
    label: 'Norway',
    value: 162,
    dial_code: '+47'
  },
  {
    id: 163,
    code: 'OM',
    longCode: 'OMN',
    name: 'Oman',
    label: 'Oman',
    value: 163,
    dial_code: '+968'
  },
  {
    id: 164,
    code: 'PK',
    longCode: 'PAK',
    name: 'Pakistan',
    label: 'Pakistan',
    value: 164,
    dial_code: '+92'
  },
  {
    id: 165,
    code: 'PW',
    longCode: 'PLW',
    name: 'Palau',
    label: 'Palau',
    value: 165,
    dial_code: '+680'
  },
  {
    id: 166,
    code: 'PA',
    longCode: 'PAN',
    name: 'Panama',
    label: 'Panama',
    value: 166,
    dial_code: '+507'
  },
  {
    id: 167,
    code: 'PG',
    longCode: 'PNG',
    name: 'Papua New Guinea',
    label: 'Papua New Guinea',
    value: 167,
    dial_code: '+675'
  },
  {
    id: 168,
    code: 'PY',
    longCode: 'PRY',
    name: 'Paraguay',
    label: 'Paraguay',
    value: 168,
    dial_code: '+595'
  },
  {
    id: 169,
    code: 'PE',
    longCode: 'PER',
    name: 'Peru',
    label: 'Peru',
    value: 169,
    dial_code: '+51'
  },
  {
    id: 170,
    code: 'PH',
    longCode: 'PHL',
    name: 'Philippines',
    label: 'Philippines',
    value: 170,
    dial_code: '+63'
  },
  {
    id: 171,
    code: 'PN',
    longCode: 'PCN',
    name: 'Pitcairn',
    label: 'Pitcairn',
    value: 171,
    dial_code: '+872'
  },
  {
    id: 172,
    code: 'PL',
    longCode: 'POL',
    name: 'Poland',
    label: 'Poland',
    value: 172,
    dial_code: '+48'
  },
  {
    id: 173,
    code: 'PT',
    longCode: 'PRT',
    name: 'Portugal',
    label: 'Portugal',
    value: 173,
    dial_code: '+351'
  },
  {
    id: 174,
    code: 'PR',
    longCode: 'PRI',
    name: 'Puerto Rico',
    label: 'Puerto Rico',
    value: 174,
    dial_code: '+1939'
  },
  {
    id: 175,
    code: 'QA',
    longCode: 'QAT',
    name: 'Qatar',
    label: 'Qatar',
    value: 175,
    dial_code: '+974'
  },
  {
    id: 176,
    code: 'RE',
    longCode: 'REU',
    name: 'Reunion',
    label: 'Reunion',
    value: 176,
    dial_code: '+262'
  },
  {
    id: 177,
    code: 'RO',
    longCode: 'ROU',
    name: 'Romania',
    label: 'Romania',
    value: 177,
    dial_code: '+40'
  },
  {
    id: 178,
    code: 'RU',
    longCode: 'RUS',
    name: 'Russian Federation',
    label: 'Russian Federation',
    value: 178,
    dial_code: '+7'
  },
  {
    id: 179,
    code: 'RW',
    longCode: 'RWA',
    name: 'Rwanda',
    label: 'Rwanda',
    value: 179,
    dial_code: '+250'
  },
  {
    id: 180,
    code: 'KN',
    longCode: 'KNA',
    name: 'Saint Kitts and Nevis',
    label: 'Saint Kitts and Nevis',
    value: 180,
    dial_code: '+1869'
  },
  {
    id: 181,
    code: 'LC',
    longCode: 'LCA',
    name: 'Saint Lucia',
    label: 'Saint Lucia',
    value: 181,
    dial_code: '+1758'
  },
  {
    id: 182,
    code: 'VC',
    longCode: 'VCT',
    name: 'Saint Vincent and the Grenadines',
    label: 'Saint Vincent and the Grenadines',
    value: 182,
    dial_code: '+1784'
  },
  {
    id: 183,
    code: 'WS',
    longCode: 'WSM',
    name: 'Samoa',
    label: 'Samoa',
    value: 183,
    dial_code: '+685'
  },
  {
    id: 184,
    code: 'SM',
    longCode: 'SMR',
    name: 'San Marino',
    label: 'San Marino',
    value: 184,
    dial_code: '+378'
  },
  {
    id: 185,
    code: 'ST',
    longCode: 'STP',
    name: 'Sao Tome and Principe',
    label: 'Sao Tome and Principe',
    value: 185,
    dial_code: '+239'
  },
  {
    id: 186,
    code: 'SA',
    longCode: 'SAU',
    name: 'Saudi Arabia',
    label: 'Saudi Arabia',
    value: 186,
    dial_code: '+966'
  },
  {
    id: 187,
    code: 'SN',
    longCode: 'SEN',
    name: 'Senegal',
    label: 'Senegal',
    value: 187,
    dial_code: '+221'
  },
  {
    id: 188,
    code: 'RS',
    longCode: 'SRB',
    name: 'Serbia',
    label: 'Serbia',
    value: 188,
    dial_code: '+381'
  },
  {
    id: 189,
    code: 'SC',
    longCode: 'SYC',
    name: 'Seychelles',
    label: 'Seychelles',
    value: 189,
    dial_code: '+248'
  },
  {
    id: 190,
    code: 'SL',
    longCode: 'SLE',
    name: 'Sierra Leone',
    label: 'Sierra Leone',
    value: 190,
    dial_code: '+232'
  },
  {
    id: 191,
    code: 'SG',
    longCode: 'SGP',
    name: 'Singapore',
    label: 'Singapore',
    value: 191,
    dial_code: '+65'
  },
  {
    id: 192,
    code: 'SK',
    longCode: 'SVK',
    name: 'Slovakia',
    label: 'Slovakia',
    value: 192,
    dial_code: '+421'
  },
  {
    id: 193,
    code: 'SI',
    longCode: 'SVN',
    name: 'Slovenia',
    label: 'Slovenia',
    value: 193,
    dial_code: '+386'
  },
  {
    id: 194,
    code: 'SB',
    longCode: 'SLB',
    name: 'Solomon Islands',
    label: 'Solomon Islands',
    value: 194,
    dial_code: '+677'
  },
  {
    id: 195,
    code: 'SO',
    longCode: 'SOM',
    name: 'Somalia',
    label: 'Somalia',
    value: 195,
    dial_code: '+252'
  },
  {
    id: 196,
    code: 'ZA',
    longCode: 'ZAF',
    name: 'South Africa',
    label: 'South Africa',
    value: 196,
    dial_code: '+27'
  },
  {
    id: 197,
    code: 'GS',
    longCode: 'SGS',
    name: 'South Georgia and the South Sandwich Islands',
    label: 'South Georgia and the South Sandwich Islands',
    value: 197,
    dial_code: '+500'
  },
  {
    id: 198,
    code: 'ES',
    longCode: 'ESP',
    name: 'Spain',
    label: 'Spain',
    value: 198,
    dial_code: '+34'
  },
  {
    id: 199,
    code: 'LK',
    longCode: 'LKA',
    name: 'Sri Lanka',
    label: 'Sri Lanka',
    value: 199,
    dial_code: '+94'
  },
  {
    id: 200,
    code: 'SH',
    longCode: 'SHN',
    name: 'St. Helena',
    label: 'St. Helena',
    value: 200,
    dial_code: '+290'
  },
  {
    id: 201,
    code: 'PM',
    longCode: 'SPM',
    name: 'St. Pierre and Miquelon',
    label: 'St. Pierre and Miquelon',
    value: 201,
    dial_code: '+508'
  },
  {
    id: 202,
    code: 'SD',
    longCode: 'SDN',
    name: 'Sudan',
    label: 'Sudan',
    value: 202,
    dial_code: '+249'
  },
  {
    id: 203,
    code: 'SR',
    longCode: 'SUR',
    name: 'Suriname',
    label: 'Suriname',
    value: 203,
    dial_code: '+597'
  },
  {
    id: 204,
    code: 'SJ',
    longCode: 'SJM',
    name: 'Svalbarn and Jan Mayen Islands',
    label: 'Svalbarn and Jan Mayen Islands',
    value: 204,
    dial_code: '+47'
  },
  {
    id: 205,
    code: 'SZ',
    longCode: 'SWZ',
    name: 'Swaziland',
    label: 'Swaziland',
    value: 205,
    dial_code: '+268'
  },
  {
    id: 206,
    code: 'SE',
    longCode: 'SWE',
    name: 'Sweden',
    label: 'Sweden',
    value: 206,
    dial_code: '+46'
  },
  {
    id: 207,
    code: 'CH',
    longCode: 'CHE',
    name: 'Switzerland',
    label: 'Switzerland',
    value: 207,
    dial_code: '+41'
  },
  {
    id: 208,
    code: 'SY',
    longCode: 'SYR',
    name: 'Syrian Arab Republic',
    label: 'Syrian Arab Republic',
    value: 208,
    dial_code: '+963'
  },
  {
    id: 209,
    code: 'TW',
    longCode: 'TWN',
    name: 'Taiwan',
    label: 'Taiwan',
    value: 209,
    dial_code: '+886'
  },
  {
    id: 210,
    code: 'TJ',
    longCode: 'TJK',
    name: 'Tajikistan',
    label: 'Tajikistan',
    value: 210,
    dial_code: '+992'
  },
  {
    id: 211,
    code: 'TZ',
    longCode: 'TZA',
    name: 'Tanzania, United Republic of Tanzania',
    label: 'Tanzania, United Republic of Tanzania',
    value: 211,
    dial_code: '+255'
  },
  {
    id: 212,
    code: 'TH',
    longCode: 'THA',
    name: 'Thailand',
    label: 'Thailand',
    value: 212,
    dial_code: '+66'
  },
  {
    id: 213,
    code: 'TG',
    longCode: 'TGO',
    name: 'Togo',
    label: 'Togo',
    value: 213,
    dial_code: '+228'
  },
  {
    id: 214,
    code: 'TK',
    longCode: 'TKL',
    name: 'Tokelau',
    label: 'Tokelau',
    value: 214,
    dial_code: '+690'
  },
  {
    id: 215,
    code: 'TO',
    longCode: 'TON',
    name: 'Tonga',
    label: 'Tonga',
    value: 215,
    dial_code: '+676'
  },
  {
    id: 216,
    code: 'TT',
    longCode: 'TTO',
    name: 'Trinidad and Tobago',
    label: 'Trinidad and Tobago',
    value: 216,
    dial_code: '+1868'
  },
  {
    id: 217,
    code: 'TN',
    longCode: 'TUN',
    name: 'Tunisia',
    label: 'Tunisia',
    value: 217,
    dial_code: '+216'
  },
  {
    id: 218,
    code: 'TR',
    longCode: 'TUR',
    name: 'Turkey',
    label: 'Turkey',
    value: 218,
    dial_code: '+90'
  },
  {
    id: 219,
    code: 'TM',
    longCode: 'TKM',
    name: 'Turkmenistan',
    label: 'Turkmenistan',
    value: 219,
    dial_code: '+993'
  },
  {
    id: 220,
    code: 'TC',
    longCode: 'TCA',
    name: 'Turks and Caicos Islands',
    label: 'Turks and Caicos Islands',
    value: 220,
    dial_code: '+1649'
  },
  {
    id: 221,
    code: 'TV',
    longCode: 'TUV',
    name: 'Tuvalu',
    label: 'Tuvalu',
    value: 221,
    dial_code: '+688'
  },
  {
    id: 222,
    code: 'UG',
    longCode: 'UGA',
    name: 'Uganda',
    label: 'Uganda',
    value: 222,
    dial_code: '+256'
  },
  {
    id: 223,
    code: 'UA',
    longCode: 'UKR',
    name: 'Ukraine',
    label: 'Ukraine',
    value: 223,
    dial_code: '+380'
  },
  {
    id: 224,
    code: 'AE',
    longCode: 'ARE',
    name: 'United Arab Emirates',
    label: 'United Arab Emirates',
    value: 224,
    dial_code: '+971'
  },
  {
    id: 225,
    code: 'GB',
    longCode: 'GBR',
    name: 'United Kingdom',
    label: 'United Kingdom',
    value: 225,
    dial_code: '+44'
  },
  {
    id: 226,
    code: 'UM',
    longCode: 'UMI',
    name: 'United States minor outlying islands',
    label: 'United States minor outlying islands',
    value: 226,
    dial_code: '+246'
  },
  {
    id: 227,
    code: 'UY',
    longCode: 'URY',
    name: 'Uruguay',
    label: 'Uruguay',
    value: 227,
    dial_code: '+598'
  },
  {
    id: 228,
    code: 'UZ',
    longCode: 'UZB',
    name: 'Uzbekistan',
    label: 'Uzbekistan',
    value: 228,
    dial_code: '+998'
  },
  {
    id: 229,
    code: 'VU',
    longCode: 'VUT',
    name: 'Vanuatu',
    label: 'Vanuatu',
    value: 229,
    dial_code: '+678'
  },
  {
    id: 230,
    code: 'SEE',
    longCode: 'VAT',
    name: 'Vatican City State',
    label: 'Vatican City State',
    value: 230,
    dial_code: '+379'
  },
  {
    id: 231,
    code: 'VE',
    longCode: 'VEN',
    name: 'Venezuela',
    label: 'Venezuela',
    value: 231,
    dial_code: '+58'
  },
  {
    id: 232,
    code: 'VN',
    longCode: 'VNM',
    name: 'Vietnam',
    label: 'Vietnam',
    value: 232,
    dial_code: '+84'
  },
  {
    id: 233,
    code: 'VG',
    longCode: 'VGB',
    name: 'Virgin Islands (British)',
    label: 'Virgin Islands (British)',
    value: 233,
    dial_code: '+1284'
  },
  {
    id: 234,
    code: 'VI',
    longCode: 'VIR',
    name: 'Virgin Islands (U.S.)',
    label: 'Virgin Islands (U.S.)',
    value: 234,
    dial_code: '+1340'
  },
  {
    id: 235,
    code: 'WF',
    longCode: 'WLF',
    name: 'Wallis and Futuna Islands',
    label: 'Wallis and Futuna Islands',
    value: 235,
    dial_code: '+681'
  },
  {
    id: 236,
    code: 'EH',
    longCode: 'ESH',
    name: 'Western Sahara',
    label: 'Western Sahara',
    value: 236,
    dial_code: '+212'
  },
  {
    id: 237,
    code: 'YE',
    longCode: 'YEM',
    name: 'Yemen',
    label: 'Yemen',
    value: 237,
    dial_code: '+967'
  },
  {
    id: 240,
    code: 'ZM',
    longCode: 'ZMB',
    name: 'Zambia',
    label: 'Zambia',
    value: 240,
    dial_code: '+260'
  },
  {
    id: 241,
    code: 'ZW',
    longCode: 'ZWE',
    name: 'Zimbabwe',
    label: 'Zimbabwe',
    value: 241,
    dial_code: '+263'
  },
  {
    id: 242,
    code: 'AX',
    longCode: '',
    name: 'Aland Islands',
    label: 'Aland Islands',
    value: 242,
    dial_code: '+358'
  },
  {
    id: 243,
    code: 'CW',
    longCode: '',
    name: 'Curacao',
    label: 'Curacao',
    value: 243,
    dial_code: '+599'
  },
  {
    id: 245,
    code: 'GG',
    longCode: '',
    name: 'Guernsey',
    label: 'Guernsey',
    value: 245,
    dial_code: '+44'
  },
  {
    id: 246,
    code: 'IM',
    longCode: '',
    name: 'Isle of Man',
    label: 'Isle of Man',
    value: 246,
    dial_code: '+44'
  },
  {
    id: 247,
    code: 'JE',
    longCode: '',
    name: 'Jersey',
    label: 'Jersey',
    value: 247,
    dial_code: '+44'
  },
  {
    id: 248,
    code: 'PS',
    longCode: '',
    name: 'Palestinian',
    label: 'Palestinian',
    value: 248,
    dial_code: '+970'
  },
  {
    id: 249,
    code: 'BL',
    longCode: '',
    name: 'Saint Barthelemy',
    label: 'Saint Barthelemy',
    value: 249,
    dial_code: '+590'
  },
  {
    id: 250,
    code: 'MF',
    longCode: '',
    name: 'Saint Martin',
    label: 'Saint Martin',
    value: 250,
    dial_code: '+590'
  },
  {
    id: 251,
    code: 'TL',
    longCode: '',
    name: 'Timor-Leste',
    label: 'Timor-Leste',
    value: 251,
    dial_code: '+670'
  },
  {
    id: 252,
    code: 'BQ',
    longCode: 'BES',
    name: 'Bonaire sint eustatius and saba',
    label: 'Bonaire sint eustatius and saba',
    value: 252,
    dial_code: '+599'
  },
  {
    id: 253,
    code: 'KP',
    longCode: 'PRK',
    name: "Democratic People's Republic of Korea",
    label: "Democratic People's Republic of Korea",
    value: 253,
    dial_code: '+850'
  }
]
