import styled from 'styled-components'
import { FormError } from 'Rentlio/components/UI/form'
import { device } from 'Rentlio/style/responsive'

export const PaymentInfoWrapper = styled.div`
  max-width: 650px;
  width: 100%;
  margin-bottom: 150px;
  padding-top: 40px;
  padding-right: 50px;

  @media ${device.tablet} {
    order: 2;
    padding: 80px 30px 100px 30px;
    margin-bottom: 0;
  }
`

export const GuestDetailsTitle = styled.h2`
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._32};
  line-height: ${props => props.theme.fonts._37};
  margin-bottom: 50px;
`

export const PaymentDetailsTitle = styled.h2`
  margin-top: 60px;
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._32};
  line-height: ${props => props.theme.fonts._37};
  margin-bottom: 32px;
`

export const BookNowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 5px;

  ${FormError} {
    width: 100%;
  }

  @media ${device.laptop} {
    flex-direction: column;
  }

  @media ${device.tablet} {
    flex-direction: row;
  }

  @media ${device.mobile} {
    flex-direction: column;
  }
`

export const BookNowButton = styled.div`
  ${({ enabled }) => (enabled ? '' : 'pointer-events: none;')}
  cursor: ${({ enabled }) => (enabled ? 'pointer' : 'none')};
  text-transform: uppercase;
  border-radius: 10px;
  background: ${props => props.theme.colors.buttonBackground};
  color: ${props => props.theme.colors.buttonText};
  text-align: center;
  width: 160px;
  padding: 20px 0;
  font-weight: bold;
  font-size: ${props => props.theme.fonts._18};
  line-height: ${props => props.theme.fonts._21};
  margin-right: 30px;
  transition: all ${({ theme }) => theme.animations.defaultTransition};
  opacity: ${({ enabled }) => (enabled ? '1' : '0.5')};
  user-select: none;
  order: 1; 

  &:hover {
    background: ${({ theme }) => theme.colors.darkBlue};
  }

  @media ${device.laptop} {
    order: 2;
    width: 100%;
  }

  @media ${device.tablet} {
    order: 1;
    width:160px;
    margin-right: 30px;
    margin-bottom: 0px;
  }

  @media ${device.mobile} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    order: 2;
  }

`

export const CheckboxWrapper = styled.div`
  order: 2;

  @media ${device.mobile} {
    order: 1;
  }
  @media ${device.tablet} {
    order: 2;
    width: auto;
  }

  @media ${device.laptop} {
    order: 1;
  }
`

export const InputWrapper = styled.div`
  position: relative;
`

export const CardIssuerWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 19px;
`

export const PaymentInformation = styled.div`
  margin-top: 20px;
  color: ${props => props.theme.colors.labelTextColor};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
`

export const Link = styled.span`
  color: ${props => props.theme.colors.primary};
  text-decoration: underline;
  cursor: pointer;
`

export const SpinnerOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 100;
  overflow: hidden;
`

export const Text = styled.p`
  margin-right: 10px;
  white-space-collapse: preserve;
`
