import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import {
  AccomodationCost,
  ExtrasCard,
  AddRoomWrapper,
  AddAnotherRoomButton,
  PriceCard,
  Footer,
  FooterLabel,
  FooterValue,
  HeadingInnerWrapper,
  HeadingOuterWrapper,
  PaymentButton,
  ServicesWrapper,
  Title,
  TotalCost,
  TotalCostLabel,
  TotalCostValue,
  Divider
} from './style'

import LongArrowRight from 'Rentlio/components/Icons/longArrowRight'
import Plus from 'Rentlio/components/Icons/plus'
import Wrapper from 'Rentlio/components/UI/wrapper'
import { formatPrice } from 'Rentlio/components/utils/Price/price'
import ScrollToTop from 'Rentlio/components/utils/ScrollToTop'
import SingleBasketItem from 'Rentlio/screen/services/components/Services/SingleBasketItem'
import { HOME_PAGE, PAYMENT_PAGE } from 'Rentlio/utils/routes'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import { CurrenciesContext } from 'Rentlio/context/CurrenciesContext'

const Services = ({
  request,
  basket,
  removeItemFromBasket,
  updateServiceQuantity,
  totalCost,
  serviceNames,
  ratePlanNames,
  selectedCurrency
}) => {
  const { translate } = useContext(LanguageContext)
  const { currencyConversion } = useContext(CurrenciesContext)
  const { value: totalCostValue, currency: totalCostCurrency } = currencyConversion(
    totalCost.total,
    basket[0]?.currency || selectedCurrency
  )
  const { value: accomodationCost, currency: accomodationCurrency } = currencyConversion(
    totalCost.accomodation,
    basket[0]?.currency || selectedCurrency
  )
  const { value: extrasCost, currency: extrasCurrency } = currencyConversion(
    totalCost.extras,
    basket[0]?.currency || selectedCurrency
  )
  return (
    <ServicesWrapper>
      <ScrollToTop />
      <HeadingOuterWrapper>
        <Wrapper>
          <HeadingInnerWrapper>
            <AddRoomWrapper>
              <Title>{translate('Add extras or')}</Title>
              <AddAnotherRoomButton>
                <Link to={HOME_PAGE}>
                  <Plus useSecondaryColor />
                  {translate('Add another unit')}
                </Link>
              </AddAnotherRoomButton>
            </AddRoomWrapper>
            <TotalCost>
              <TotalCostLabel>{translate('Total cost')}:</TotalCostLabel>
              <TotalCostValue>
                {formatPrice(totalCostValue, totalCostCurrency)} {totalCostCurrency}
              </TotalCostValue>
            </TotalCost>
          </HeadingInnerWrapper>
        </Wrapper>
      </HeadingOuterWrapper>
      <div>
        <Wrapper>
          {basket.map(item => (
            <SingleBasketItem
              key={item.basketId}
              item={item}
              request={request}
              removeItemFromBasket={removeItemFromBasket}
              updateServiceQuantity={updateServiceQuantity}
              serviceNames={serviceNames}
              ratePlanNames={ratePlanNames}
            />
          ))}
        </Wrapper>
      </div>
      <div>
        <Wrapper>
          <Footer>
            <AccomodationCost>
              <FooterLabel>{translate('Accommodation')}</FooterLabel>
              <FooterValue>
                {formatPrice(accomodationCost, accomodationCurrency)} {accomodationCurrency}
              </FooterValue>
            </AccomodationCost>
            <ExtrasCard>
              <FooterLabel>{translate('Extras')}</FooterLabel>
              <FooterValue>
                {formatPrice(extrasCost, extrasCurrency)} {extrasCurrency}
              </FooterValue>
            </ExtrasCard>
            <Divider />
            <PriceCard>
              <TotalCostLabel>{translate('Total cost')}:</TotalCostLabel>
              <TotalCostValue>
                {formatPrice(totalCostValue, totalCostCurrency)} {totalCostCurrency}
              </TotalCostValue>
            </PriceCard>
            <Link to={PAYMENT_PAGE}>
              <PaymentButton>
                {translate('Continue')}
                <LongArrowRight />
              </PaymentButton>
            </Link>
          </Footer>
        </Wrapper>
      </div>
    </ServicesWrapper>
  )
}

Services.propTypes = {
  basket: PropTypes.array,
  removeItemFromBasket: PropTypes.func,
  updateServiceQuantity: PropTypes.func,
  request: PropTypes.shape({
    nights: PropTypes.number,
    adults: PropTypes.number,
    children: PropTypes.array
  }),
  totalCost: PropTypes.object,
  ratePlanNames: PropTypes.array,
  serviceNames: PropTypes.array,
  selectedCurrency: PropTypes.string
}

export default Services
