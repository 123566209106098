export const CONFIRMED = 1
export const WAITING_FOR_APPROVAL = 2
export const REFUSED = 3
export const ACCEPTED = 4
export const CANCELLED = 5 // This is cancelled, on backend enum its called DELETED
export const DELETED_WITH_PENALTY = 6
export const TENTATIVE = 7 // This was formerly called OPTION
export const IN_HOUSE = 8
export const DEPARTED = 9
export const NO_SHOW = 10
