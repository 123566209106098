import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'

const ArrowLeftWrapper = styled.span``
const ArrowLeft = ({ stroke }) => (
  <ArrowLeftWrapper>
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.92894 1.92894L1.85788 9.00001L8.92897 16.071'
        stroke={stroke || 'white'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </ArrowLeftWrapper>
)

ArrowLeft.propTypes = {
  stroke: PropTypes.string
}

export default ArrowLeft
