import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 70px;
`

export const Field = styled.div`
  display: inline-block;
  width: 48%;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
  margin-bottom: 44px;

  &:last-of-type {
    width: 100%;
    margin-top: 20px;
  }

  @media ${device.mobile} {
    width: 100%;
  }
`

export const Label = styled.p`
  margin: 0 0 10px;
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  font-weight: ${props => props.theme.fonts.regularWeight};
  color: ${({ veryDarkGray }) => veryDarkGray}
  opacity: 0.5;
  text-transform: uppercase;
`

export const Content = styled.p`
    margin: 0;
    font-size: ${props => props.theme.fonts._18};
    line-height: ${props => props.theme.fonts._21};
    font-weight: ${props => props.theme.fonts.mediumWeight};
    color: ${({ lightGray }) => lightGray}
  }
`

export const ContentTextArea = styled.textarea`
    margin: 0;
    font-size: ${props => props.theme.fonts._18};
    line-height: ${props => props.theme.fonts._21};
    font-weight: ${props => props.theme.fonts.mediumWeight};
    color: ${({ lightGray }) => lightGray};
    width: inherit;
    height: 150px;
    background-color: transparent;
    border:none
  }
`
