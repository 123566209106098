import React from 'react'
import PropTypes from 'prop-types'

import {
  FooterWrapper,
  Link,
  Logo,
  CardLogosWrapper,
  SecureWrapper,
  MonriSecure,
  Secure,
  Label,
  LogoSecure
} from './style'
import MonriCardsEnum from 'Rentlio/utils/MonriCardsEnum.js'
import SecurityLogos from 'Rentlio/utils/SecurityCardsEnum.js'
import monriSecure from 'Images/monri/monriSecure.png'

const MonriFooter = ({ selectedCardTypes }) => {
  const creditCards = selectedCardTypes
    ? selectedCardTypes.split(',').map(cardId => MonriCardsEnum.find(card => card.id === cardId))
    : []
  const creditCardIds = creditCards.map(card => card.id)
  const uniqueSecurityLogos = SecurityLogos.filter(securityCard => creditCardIds.includes(securityCard.id))

  return (
    <FooterWrapper>
      <CardLogosWrapper>
        {creditCards.map(card => (
          <Link href={card.link} key={card.id}>
            <Logo>{card.logo}</Logo>
          </Link>
        ))}
      </CardLogosWrapper>
      <SecureWrapper>
        {uniqueSecurityLogos.map(card => (
          <Secure key={card.id}>
            <Logo>{card.logo}</Logo>
            <Label>{card.label}</Label>
          </Secure>
        ))}
        <MonriSecure href={'https://monri.com/'}>
          <LogoSecure src={monriSecure} />
        </MonriSecure>
      </SecureWrapper>
    </FooterWrapper>
  )
}

MonriFooter.propTypes = {
  locale: PropTypes.string,
  selectedCardTypes: PropTypes.string
}

export default MonriFooter
