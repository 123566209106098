import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import PaymentInfo from './PaymentInfo'
import { Wrapper, Section } from './style'
import ReservationSummary from 'Rentlio/components/ReservationSummary'
import ScrollToTop from 'Rentlio/components/utils/ScrollToTop'
import { BE_MONRI, BE_CREDIT_CARD } from 'Rentlio/utils/paymentTypesEnum'

class Payment extends React.PureComponent {
  state = { isEmptyMonri: false }
  componentDidMount() {
    const { receipt, history, paymentMethods } = this.props
    if (receipt.accomodation.items.length === 0) {
      history.push('/')
    }
    paymentMethods.map(method => {
      if (Number(method.id) === BE_MONRI && !method.settings.authenticityToken) {
        this.setState({ isEmptyMonri: true })
      }
    })
  }

  // for monri we are setting payment type as credit card if monri is enabled, but keys are not entered yet because user is not approved by bank
  // this way user can have functional BE while waiting for approval from bank
  getPaymentMethods = paymentMethods => {
    return paymentMethods.map(method => {
      if (Number(method.id) === BE_MONRI && !method.settings.authenticityToken) {
        return { ...method, id: BE_CREDIT_CARD, settings: null }
      }
      return method
    })
  }

  render() {
    const { isEmptyMonri } = this.state
    const {
      receipt,
      request,
      bookReservation,
      bookingEngine,
      updatePaymentInfo,
      paymentDetails,
      paymentMethods,
      locale,
      openTermsOfServiceModal,
      openPrivacyPolicyModal,
      prePaymentDetails,
      clearBasket,
      getMonriClientSecret,
      getStripeClientSecret,
      getM2PayClientSecret,
      monri,
      stripe,
      m2pay,
      sendSuccessfulReservationEvent,
      sendPaymentFormInputEvent,
      touristTaxes,
      openM2Pay3DSModal
    } = this.props

    return (
      <Section>
        <ScrollToTop />
        <Wrapper>
          <PaymentInfo
            bookReservation={bookReservation}
            bookingEngine={bookingEngine}
            updatePaymentInfo={updatePaymentInfo}
            paymentDetails={paymentDetails}
            paymentMethods={this.getPaymentMethods(paymentMethods)}
            locale={locale}
            receipt={receipt}
            openTermsOfServiceModal={openTermsOfServiceModal}
            openPrivacyPolicyModal={openPrivacyPolicyModal}
            prePaymentDetails={prePaymentDetails}
            clearBasket={clearBasket}
            getMonriClientSecret={getMonriClientSecret}
            monri={monri}
            stripe={stripe}
            m2pay={m2pay}
            getStripeClientSecret={getStripeClientSecret}
            getM2PayClientSecret={getM2PayClientSecret}
            sendSuccessfulReservationEvent={sendSuccessfulReservationEvent}
            sendPaymentFormInputEvent={sendPaymentFormInputEvent}
            openM2Pay3DSModal={openM2Pay3DSModal}
            isEmptyMonri={isEmptyMonri}
          />
          <ReservationSummary
            receipt={receipt}
            request={request}
            hasTitle
            isVatRegistered={bookingEngine.isVatRegistered}
            vatRate={bookingEngine.vatRate}
            selectedPaymentMethod={paymentDetails.selectedPaymentMethod}
            bookingEngine={bookingEngine}
            touristTaxes={touristTaxes}
          />
        </Wrapper>
      </Section>
    )
  }
}

Payment.propTypes = {
  bookingEngine: PropTypes.object,
  receipt: PropTypes.object,
  request: PropTypes.object,
  bookReservation: PropTypes.func,
  updatePaymentInfo: PropTypes.func,
  paymentDetails: PropTypes.object,
  paymentMethods: PropTypes.arrayOf(PropTypes.object),
  locale: PropTypes.string,
  openTermsOfServiceModal: PropTypes.func,
  openPrivacyPolicyModal: PropTypes.func,
  prePaymentDetails: PropTypes.object,
  clearBasket: PropTypes.func,
  getMonriClientSecret: PropTypes.func,
  monri: PropTypes.object,
  stripe: PropTypes.object,
  m2pay: PropTypes.object,
  getStripeClientSecret: PropTypes.func,
  getM2PayClientSecret: PropTypes.func,
  history: PropTypes.object,
  sendSuccessfulReservationEvent: PropTypes.func,
  sendPaymentFormInputEvent: PropTypes.func,
  touristTaxes: PropTypes.array,
  openM2Pay3DSModal: PropTypes.func
}

export default withRouter(Payment)
