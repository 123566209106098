import React from 'react'

const Children = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M15.3681 5.31115C16.5205 5.31115 17.4548 4.34732 17.4548 3.15838C17.4548 1.96944 16.5205 1.00562 15.3681 1.00562C14.2157 1.00562 13.2815 1.96944 13.2815 3.15838C13.2815 4.34732 14.2157 5.31115 15.3681 5.31115Z'
      fill='#0973A0'
    />
    <path
      d='M11.3135 12.6718C11.3386 12.6748 11.3636 12.6763 11.3884 12.6763C11.7152 12.6763 11.9973 12.4228 12.0354 12.0797C12.2264 10.354 12.6023 9.23307 12.9817 8.50678C12.682 10.3154 12.3359 12.7593 12.3359 14.3981C12.3359 14.5421 12.758 14.6481 13.3792 14.7162V19.1254C13.3792 19.6085 13.7585 20 14.2269 20C14.6951 20 15.0746 19.6085 15.0746 19.1254V14.8059C15.2697 14.8082 15.4663 14.8082 15.6614 14.8059V19.1254C15.6614 19.6085 16.0408 20 16.5091 20C16.9773 20 17.3568 19.6085 17.3568 19.1254V14.7162C17.978 14.6481 18.4001 14.5421 18.4001 14.3981C18.4001 12.7586 18.0536 10.3136 17.7538 8.50489C18.1329 9.23064 18.5093 10.3545 18.7002 12.0796C18.7382 12.4227 19.0201 12.6761 19.3472 12.6761C19.3718 12.6761 19.3968 12.6747 19.4219 12.6717C19.7797 12.6296 20.0366 12.2962 19.9959 11.9273C19.7092 9.33088 19.0101 7.82448 18.3144 6.95436C17.69 6.16955 17.0784 5.9318 16.8804 5.8761C16.585 5.77465 16.112 5.67966 15.3677 5.67966C14.6245 5.67966 14.1519 5.77438 13.8562 5.87569C13.66 5.93072 13.0466 6.16739 12.4205 6.95436C11.7251 7.82462 11.0258 9.33102 10.7393 11.9271C10.6988 12.2962 10.9557 12.6296 11.3135 12.6718Z'
      fill='#0973A0'
    />
    <path
      d='M4.48831 4.70918C5.74876 4.70918 6.77056 3.65499 6.77056 2.35459C6.77056 1.05418 5.74876 0 4.48831 0C3.22785 0 2.20605 1.05418 2.20605 2.35459C2.20605 3.65499 3.22785 4.70918 4.48831 4.70918Z'
      fill='#0973A0'
    />
    <path
      d='M0.619275 12.6062C0.630621 12.6067 0.641836 12.607 0.652921 12.607C0.997998 12.607 1.28582 12.3277 1.30356 11.9683C1.42406 9.47635 1.84204 8.11029 2.2059 7.40203V12.7148V18.904C2.2059 19.4984 2.67304 19.9804 3.24921 19.9804C3.82539 19.9804 4.29253 19.4984 4.29253 18.904V13.3875H4.68377V18.904C4.68377 19.4984 5.15092 19.9804 5.72709 19.9804C6.30326 19.9804 6.77041 19.4984 6.77041 18.904V12.7148V7.40432C6.78319 7.42894 6.79584 7.45275 6.80875 7.47899C7.16387 8.20568 7.55628 9.56435 7.67314 11.9683C7.69061 12.3276 7.9787 12.607 8.32351 12.607C8.3346 12.607 8.34581 12.6067 8.35716 12.6062C8.71697 12.5875 8.99371 12.2715 8.97559 11.9005C8.83226 9.01512 8.32143 7.42235 7.76964 6.49612C7.22399 5.56828 6.589 5.34829 6.42403 5.32784C6.35817 5.31613 6.29374 5.31815 6.23088 5.32649C6.19411 5.3199 6.15694 5.31479 6.11833 5.31479H2.85797C2.81911 5.31479 2.78155 5.3199 2.74464 5.32649C2.68204 5.31842 2.61788 5.3164 2.55241 5.32811V5.32784C2.38743 5.34829 1.75245 5.56841 1.20679 6.49612C0.655138 7.42221 0.144305 9.01472 0.00110958 11.9002C-0.0172789 12.2715 0.259591 12.5872 0.619275 12.6062Z'
      fill='#0973A0'
    />
  </svg>
)

export default Children
