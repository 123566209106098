import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const OccupancySelectContainer = styled.div`
  margin-left: auto;
  margin-right: 40px;
  display: flex;
  align-items: flex-start;

  @media ${device.tablet} {
    margin: auto 0 0;
  }
`
export const OccupancySelectWrapper = styled.div`
  padding: ${({ editing }) => (editing ? '40px' : '60px')} 40px 30px 40px;
  background-color: ${props =>
    props.editing ? (props.isDarkStyle ? props.theme.colors.black : props.theme.colors.white) : 'transparent'};
  ${props =>
    props.editing
      ? 'box-shadow: 0px 0.927421px 0.686979px rgba(0, 0, 0, 0.0279017), 0px 2.34552px 1.73742px rgba(0, 0, 0, 0.0399338), 0px 4.78464px 3.54417px rgba(0, 0, 0, 0.0500662), 0px 9.85544px 7.30033px rgba(0, 0, 0, 0.0620983), 0px 27px 20px rgba(0, 0, 0, 0.09);'
      : ''}
  transition: all ${({ theme }) => theme.animations.cubicBezierTransition};
  transform: ${({ editing }) => (editing ? 'translate(10px, 15px)' : 'translate(0, 0px)')};
  z-index: ${({ theme, editing }) => (editing ? theme.zIndex.occupancySelect + 1 : theme.zIndex.occupancySelect)};
  border-radius: 20px;

  @media ${device.tablet} {
    transform: translateX(0px);
    padding: 30px 40px 30px 40px;
    margin-top: 0px;
  }

 
`
export const Inputs = styled.div`
  display: flex;
  align-items: baseline;
`

export const InputWrapper = styled.div`
  position: relative;
  border-right: 1px solid ${props => (props.noBorder ? 'transparent' : props.theme.colors.searchInputDivider)};
  padding: ${({ isRight }) => (isRight ? '0 0 0 40px' : '0 40px 0 0')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

 /*  @media ${device.laptop} {
    padding: ${({ isRight }) => (isRight ? '0 0 0 25px' : '0 25px 0 0')};
  } */

  @media ${device.mobile} {
    padding: ${({ isRight }) => (isRight ? '0 0 0 20px' : '0 20px 0 0')};
  }
`

export const Label = styled.div`
  text-transform: uppercase;
  color: ${({ theme, editing, isDarkStyle }) =>
    editing
      ? isDarkStyle
        ? theme.colors.white
        : theme.colors.black
      : isDarkStyle
      ? theme.colors.white
      : theme.colors.semiTransparentBlackText};
  font-weight: ${({ theme, editing }) => (editing ? theme.fonts.mediumWeight : 'normal')};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  margin-bottom: 15px;
`

export const HelperText = styled.div`
  margin-top: 15px;
  color: ${({ theme, isDarkStyle }) => (isDarkStyle ? theme.colors.white : theme.colors.semiTransparentBlackText)};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
`

export const InputDivider = styled.div`
  position: absolute;
  right: 0;
  width: 1px;
  height: 100px;
  background: linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, rgba(13, 13, 13, 0.1) 51.04%, rgba(51, 51, 51, 0) 100%);
`
