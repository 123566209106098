import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 40px;
`
export const Wrapper = styled.div`
  border-radius: 10px;
  padding: 15px 5px 15px 15px;
  border: 1px solid ${props => (props.termsRead ? props.theme.colors.border : props.theme.colors.alertColor)};
`

export const Terms = styled.div`
  width: 100%; // Adjust as needed
  height: 100px; // Adjust as needed
  overflow-y: auto;
  box-sizing: border-box; // Ensures padding is included in the element's dimensions

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #c0c0c0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #a0a0a0;
  }
`

export const TermsLabel = styled.div`
  color: ${props => props.theme.colors.lightText};
  font-size: 1rem;
  margin-bottom: 10px;
  white-space: nowrap;
  margin-top: 25px;
`

export const Label = styled.div``

export const Text = styled.p`
  margin-right: 10px;
  white-space-collapse: preserve;
`
