import styled, { css } from 'styled-components'
import { DefaultButton } from '../UI/button'
import { device } from 'Rentlio/style/responsive'

export const CookieNoticeWrapper = styled.div`
  overflow: hidden;
  display: flex;
  position: fixed;
  bottom: 0px;
  width: 100%;
  font-weight: ${props => props.theme.fonts.regularWeight};
  z-index: ${props => props.theme.zIndex.cookieNotice};

  @media ${device.tablet} {
    left: 0;
    right: 0;
    bottom: 0px;
    border-radius: 0;
    width: 100%;
  }
  @media ${device.tablet} {
    flex-direction: column;
  }
`

export const Checkbox = styled.input`
  accent-color: ${({ theme }) => theme.colors.vividOrange};
`

export const Notice = styled.div`
  background: rgba(51, 51, 51, 0.95);
  flex-basis: 70%;
  color: white;
  padding: 10px;
  flex-grow: 2;
  font-size: 1rem;
`

export const Action = styled.div`
  background: rgba(51, 51, 51, 0.95);
  padding: 10px;
  flex-grow: 1;
  display: flex;
  gap: 20px;
`

export const UserChoices = styled.div`
  margin-top: 5px;
  margin-left: -5px;
  ${({ hide }) =>
    hide
      ? css`
          display: none;
        `
      : null}
  display: flex;
  flex-direction: row;
`

export const UserChoice = styled.label`
  margin-right: 5px;
  display: flex;
  align-items: center;
`

export const AcceptButton = styled(DefaultButton)`
  flex-grow: 1;
  min-width: initial;
  margin-bottom: 0;
  align-self: center;
  background: ${({ theme }) => theme.colors.vividOrange};
`

export const DeclineButton = styled(DefaultButton)`
  flex-grow: 1;
  min-width: initial;
  margin-bottom: 0;
  align-self: center;
  background: ${({ theme }) => theme.colors.vividOrange};
`

export const PrivacyAndTerms = styled.div`
  margin-top: 5px;
  cursor: pointer;
`
