// TODO add support for arrays
// TODO add support for array item validation
// TODO add support for primitives
// TODO add support for nesting
function validateData(data, constraints) {
  let errors = {}

  if (typeof data === 'object' && data !== null) {
    errors = validateObject(data, constraints)
  }

  return hasErrors(errors) ? errors : true
}

function validateObject(data, constraints) {
  const keys = Object.keys(constraints)

  const errors = {}

  keys.forEach(key => {
    const attributeConstraints = constraints[key]

    attributeConstraints.forEach(constraint => {
      const error = constraint(data[key])

      if (error === null) {
        return
      }

      if (typeof errors[key] === 'undefined') {
        errors[key] = []
      }

      errors[key].push(error)
    })
  })

  return errors
}

function hasErrors(errors) {
  return Object.keys(errors).length !== 0
}

function getValidationErrors(errors, key) {
  return typeof errors[key] !== 'undefined' ? errors[key] : []
}

export { validateData, hasErrors, getValidationErrors }
