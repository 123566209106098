import styled from 'styled-components'

export const CardWrapper = styled.div`
  width: 100%;
  height: 231px;
  padding: 30px 20px;
  box-shadow: 0px 0.30914px 0.549583px rgba(0, 0, 0, 0.02), 0px 0.781839px 1.38994px rgba(0, 0, 0, 0.02),
    0px 1.59488px 2.83534px rgba(0, 0, 0, 0.03), 0px 3.28515px 5.84026px rgba(0, 0, 0, 0.03),
    0px 9px 16px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background: ${props => props.theme.colors.primary};
  margin-top: 30px;
`

export const StatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${props => props.theme.colors.white};
`

export const Date = styled.div`
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._18};
  line-height: ${props => props.theme.fonts._21};
`

export const CardHolderWrapper = styled.div`
  display: flex;
  margin: 20px 0 30px;
  color: ${props => props.theme.colors.white};
`

export const HolderLabel = styled.p`
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._21};
  margin: 0;
`

export const CardHolder = styled.p`
  font-weight: ${props => props.theme.fonts.regularWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._21};
  margin: 0;
  margin-left: 5px;
`

export const CardNumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`

export const CardLabel = styled.p`
  font-weight: ${props => props.theme.fonts.regularWeight};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  color: ${props => props.theme.colors.white};
  margin: 0 0 10px;
  text-transform: uppercase;
`

export const CardNumber = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 10px;
  padding: 20px 20px 20px 28px;
`
export const Number = styled.p`
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._18};
  line-height: ${props => props.theme.fonts._21};
  color: ${props => props.theme.colors.textColor};
`
