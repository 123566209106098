import PropTypes from 'prop-types'
import React from 'react'
import Carousel from './Carousel'
import Thumbnails from './Thumbnails'
import { ImagesWrapper } from './style'

class Images extends React.PureComponent {
  state = {
    currentImage: 0
  }

  // todo make sure that isFeatured and orderNumber is Respected

  goNext = () => {
    const { images } = this.props
    const newCurrentImage = (this.state.currentImage + 1) % images.length
    this.setState({ currentImage: newCurrentImage })
  }

  goPrevious = () => {
    const { images } = this.props
    const { currentImage } = this.state
    const newCurrentImage = currentImage === 0 ? images.length - 1 : currentImage - 1
    this.setState({ currentImage: newCurrentImage })
  }

  selectThumbnail = index => {
    this.setState({ currentImage: index })
  }

  render() {
    const { images, hasThumbnails, isModalImage, showModalHandler } = this.props
    const { currentImage } = this.state

    return (
      <ImagesWrapper>
        <Carousel
          images={images}
          nextImage={this.goNext}
          previousImage={this.goPrevious}
          currentImage={currentImage}
          isModalImage={isModalImage}
          showModalHandler={showModalHandler}
        />
        {hasThumbnails && (
          <Thumbnails images={images} selectThumbnail={this.selectThumbnail} currentImage={currentImage} />
        )}
      </ImagesWrapper>
    )
  }
}

Images.propTypes = {
  images: PropTypes.array,
  hasThumbnails: PropTypes.bool,
  isModalImage: PropTypes.bool,
  showModalHandler: PropTypes.func
}

export default Images
