import React from 'react'
export const Lighting = () => (
  <svg width='5' height='12' viewBox='0 0 5 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.509554 12L2.16561 6.03226H0L2.35669 0H4.92038L2.62739 4.69355H5L0.509554 12Z'
      fill='#E9C56A'
    />
  </svg>
)
