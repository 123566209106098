const getUnitTypeOccupancy = selectedUnitType => {
  return {
    standardOccupancy: selectedUnitType.standardOccupancy,
    maxOccupancy: selectedUnitType.maxOccupancy,
    maxOccupancyAdults: selectedUnitType.maxOccupancyAdults,
    maxOccupancyChildren: selectedUnitType.maxOccupancyChildren,
    minOccupancy: selectedUnitType.minOccupancy,
    minOccupancyAdults: selectedUnitType.minOccupancyAdults,
    minOccupancyChildren: selectedUnitType.minOccupancyChildren,
    childrenAge: selectedUnitType.childrenAge,
    // @deprecated TODO remove later
    hasExtraPersonCharge: selectedUnitType.hasExtraPersonCharge,
    extraPersonCharge: selectedUnitType.extraPersonCharge,
    childrenAllowed: selectedUnitType.childrenAllowed,
    childrenFreeOfCharge: selectedUnitType.childrenFreeOfCharge,
    childrenExtraCharge: selectedUnitType.childrenExtraCharge
  }
}

export default getUnitTypeOccupancy
