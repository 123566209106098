import PropTypes from 'prop-types'
import React from 'react'
import { getTotalRate } from '../../RatePlans/SingleRatePlan/helper/getTotalRate'
import getUnitTypeOccupancy from '../../../utils/getUnitTypeOccupancy'
import Information from './Information'
import RatePreview from './RatePreview'
import { SingleUnitTypeGrid, SingleUnitTypeWrapper, PaddingContainer } from './style'
import Unavailable from './Unavailable'
import PolicyModal from './PolicyModal'
import Images from 'Rentlio/components/UI/Images'
import MobileInformation from 'Rentlio/screen/accomodation/component/UnitTypes/SingleUnitType/MobileInformation'
import UnavailableForRequest from 'Rentlio/screen/accomodation/component/UnitTypes/SingleUnitType/UnavailableForRequest'
import RatePlansContainer from 'Rentlio/screen/accomodation/containers/ratePlans'
import Wrapper from 'Rentlio/components/UI/wrapper'
import Modal from 'Rentlio/components/Modal'
import Animation from 'Rentlio/components/UI/Animation'
import { scrollTo } from 'Rentlio/utils/scrollTo'
import { CLOSED_ERROR } from 'Rentlio/utils/restrictionErrorsEnum'
import { getNights } from 'Rentlio/state/selectors/request'

class SingleUnitType extends React.PureComponent {
  state = {
    showRatePlans: false,
    showModal: false,
    showUnitTypeDetailsModal: false
  }

  showUnitTypeDetailsModalHandler = () => {
    const {
      unitType: { unitTypeName, unitTypeId },
      openUnitTypeModal
    } = this.props
    // this is used just so we can send GTM event if needed
    openUnitTypeModal({ name: unitTypeName, unitTypeId })
    this.setState({ showUnitTypeDetailsModal: true })
  }

  hideUnitTypeDetailsModalHandler = () => {
    this.setState({ showUnitTypeDetailsModal: false })
    document.body.classList.remove('modal-open')
  }

  showModalHandler = () => {
    this.setState({ showModal: true })
  }

  hideModalHandler = () => {
    this.setState({ showModal: false })
    document.body.classList.remove('modal-open')
  }

  getMinimumRate() {
    const { unitType, request } = this.props
    const { rates } = unitType

    let minimumRate = null

    rates.map(rate => {
      if (rate.averageRatePerNight === null) {
        return
      }
      const rateAvgRatePerNight =
        getTotalRate(getUnitTypeOccupancy(unitType), rate, request.adults, request.children) / (getNights(request) || 1)

      if (minimumRate !== null && rateAvgRatePerNight >= minimumRate) {
        return
      }

      if (!rate.isBookable && rate.restrictionErrors.includes(CLOSED_ERROR)) return

      minimumRate = rateAvgRatePerNight
    })

    return minimumRate
  }

  toggleRatePlans = () => {
    const { showRatePlans } = this.state
    this.setState({ showRatePlans: !showRatePlans })

    if (!showRatePlans) {
      setTimeout(() => {
        scrollTo(
          document.documentElement,
          document.querySelector('#' + this.getUnitTypeRatePlansWrapperID()).offsetParent.offsetTop + 450,
          500
        )
      }, 200)
    }
  }

  getUnitTypeRatePlansWrapperID = () => 'rate-plans-wrapper-' + this.props.unitType.unitTypeId

  render() {
    const { showRatePlans, showModal, showUnitTypeDetailsModal } = this.state
    const {
      unitType,
      even,
      request,
      updateDates,
      fetchAvailabilityForMonth,
      unitTypeAvailability,
      primaryColor,
      isGroupBookingEngine,
      groupBESlug,
      openUnitTypeModal,
      availableUnits,
      alwaysShowRates,
      unitType: {
        checkInBegin,
        checkInEnd,
        checkOutBegin,
        checkOutEnd,
        childrenAge,
        smokingAllowed,
        petsAllowed,
        maxOccupancyChildren
      }
    } = this.props

    return (
      <SingleUnitTypeWrapper even={even}>
        <Wrapper>
          <SingleUnitTypeGrid>
            <MobileInformation
              name={unitType.unitTypeName}
              maxOccupancy={unitType.maxOccupancy}
              standardOccupancy={unitType.standardOccupancy}
              isGroupBookingEngine={isGroupBookingEngine}
              unitTypeSlug={unitType.bookingEngine.slug}
              groupBESlug={groupBESlug}
              unitTypeName={unitType.bookingEngine.name}
              unitTypeCity={unitType.city}
            />
            <PaddingContainer>
              <Images images={unitType.images} showModalHandler={this.showUnitTypeDetailsModalHandler} />
            </PaddingContainer>
            <Information
              name={unitType.unitTypeName}
              unitTypeId={unitType.unitTypeId}
              description={unitType.description}
              standardOccupancy={unitType.standardOccupancy}
              maxOccupancy={unitType.maxOccupancy}
              images={unitType.images}
              amenities={unitType.amenities}
              unitTypeName={unitType.bookingEngine.name}
              unitTypeCity={unitType.city}
              latitude={unitType.latitude}
              longitude={unitType.longitude}
              primaryColor={primaryColor}
              isGroupBookingEngine={isGroupBookingEngine}
              unitTypeSlug={unitType.bookingEngine.slug}
              groupBESlug={groupBESlug}
              openUnitTypeModal={openUnitTypeModal}
              showUnitTypeDetailsModalHandler={this.showUnitTypeDetailsModalHandler}
              hideUnitTypeDetailsModalHandler={this.hideUnitTypeDetailsModalHandler}
              showUnitTypeDetailsModal={showUnitTypeDetailsModal}
            />
            {unitType.available && this.getMinimumRate() !== null && (
              <RatePreview
                availableUnits={availableUnits}
                toggleRatePlans={this.toggleRatePlans}
                shouldShowRateToggle={!alwaysShowRates}
                showRatePlans={showRatePlans}
                minimumRate={this.getMinimumRate()}
                showModalHandler={this.showModalHandler}
                unitTypeCurrency={unitType.bookingEngine.currency}
                numberOfPersons={request.adults + (request.children?.length || 0)}
              />
            )}
            {((!unitType.available && unitType.availableUnits === 0) || this.getMinimumRate() === null) && (
              <Unavailable
                from={request.from}
                to={request.to}
                unitTypeAvailability={unitTypeAvailability}
                updateDates={updateDates}
                unitTypeId={unitType.unitTypeId}
                unitTypeUuid={unitType.unitTypeUuid}
                fetchAvailabilityForMonth={fetchAvailabilityForMonth}
                showModalHandler={this.showModalHandler}
              />
            )}
            {!unitType.available && unitType.availableUnits !== 0 && (
              <UnavailableForRequest request={request} unitType={unitType} showModalHandler={this.showModalHandler} />
            )}
            <Animation show={showModal} animationType={'fadeInOut'} isModal>
              <Modal hideModalHandler={this.hideModalHandler} paddingBottom={'10px'} show={showModal}>
                <PolicyModal
                  checkInBegin={checkInBegin}
                  checkInEnd={checkInEnd}
                  checkOutBegin={checkOutBegin}
                  checkOutEnd={checkOutEnd}
                  childrenAge={childrenAge}
                  smokingAllowed={smokingAllowed}
                  petsAllowed={petsAllowed}
                  maxOccupancyChildren={maxOccupancyChildren}
                />
              </Modal>
            </Animation>
          </SingleUnitTypeGrid>
          <RatePlansContainer
            wrapperId={this.getUnitTypeRatePlansWrapperID()}
            showRatePlans={alwaysShowRates || (showRatePlans && unitType.available)}
            rates={unitType.rates}
            unitTypeCurrency={unitType.bookingEngine.currency}
          />
        </Wrapper>
      </SingleUnitTypeWrapper>
    )
  }
}

SingleUnitType.propTypes = {
  unitType: PropTypes.object,
  even: PropTypes.bool,
  maxAvailability: PropTypes.object,
  request: PropTypes.object,
  unitTypeAvailability: PropTypes.object,
  updateDates: PropTypes.func,
  fetchAvailabilityForMonth: PropTypes.func,
  primaryColor: PropTypes.string,
  isGroupBookingEngine: PropTypes.bool,
  groupBESlug: PropTypes.string,
  openUnitTypeModal: PropTypes.func,
  availableUnits: PropTypes.number,
  alwaysShowRates: PropTypes.bool,
  importantNote: PropTypes.string
}

export default SingleUnitType
