import { useContext } from 'react'
import PropTypes from 'prop-types'
import { getAccommodationCancelationPolicyText } from 'Rentlio/helper/getCancellationPolicyText'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import { CurrenciesContext } from 'Rentlio/context/CurrenciesContext'

const CancellationPolicy = ({ policy, totalRatePrice, unitTypeCurrency, arrivalDate }) => {
  const { translate } = useContext(LanguageContext)
  const { currencyConversion } = useContext(CurrenciesContext)

  if (!policy) {
    return ''
  }

  return getAccommodationCancelationPolicyText(
    translate,
    currencyConversion,
    totalRatePrice,
    unitTypeCurrency,
    policy,
    arrivalDate
  )
}

CancellationPolicy.propTypes = {
  policy: PropTypes.object,
  // DO NOT sent already converted values here, send initial values
  totalRatePrice: PropTypes.number,
  unitTypeCurrency: PropTypes.string
}

export default CancellationPolicy
