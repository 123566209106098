import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import CancellationPolicy from '../CancellationPolicyFull'
import {
  CancellationPolicyWrapper,
  Label,
  ReceiptItemRowWrapper,
  Value,
  Name,
  ItemName,
  Dates,
  RateName,
  ReceiptDivider,
  TotalCost,
  ChargeRow,
  IncludedExtras,
  LinkWrapper,
  LinkText,
  ExtrasWrapper,
  Bold
} from './style'
import { DATE_FORMAT } from 'Rentlio/utils/dateFormatEnum'
import { paymentTypes } from 'Rentlio/utils/services'
import Times from 'Rentlio/components/Icons/times'
import { formatPrice } from 'Rentlio/components/utils/Price/price'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import { CurrenciesContext } from 'Rentlio/context/CurrenciesContext'
import ArrowRight from 'Rentlio/components/Icons/arrowLongRight'
import colors from 'Rentlio/style/colors'

class ReceiptItemRow extends React.PureComponent {
  static contextType = LanguageContext

  isPerNight = () =>
    !this.props.item.isMandatory &&
    (this.props.item.paymentType === paymentTypes.PER_NIGHT ||
      this.props.item.paymentType === paymentTypes.PER_PERSON_PER_NIGHT)

  getCheckInOutText = (hoursFrom, hoursTo, arrivalDepartureDate) => {
    const { translate } = this.context

    return `${arrivalDepartureDate.utc().format('dddd')},  ${arrivalDepartureDate.utc().format('LL')} (${
      hoursFrom ? translate('From') + ' ' : ''
    }${hoursFrom || translate('WholeDay')}${hoursTo ? ' ' + translate('Until') + ' ' + hoursTo + ')' : ')'}`
  }

  render() {
    const { item, selectedPaymentMethod, createdAt, showPricePerDayModal, index } = this.props
    const { translate } = this.context

    return (
      <CurrenciesContext.Consumer>
        {({ currencyConversion }) => {
          const { value: totalCost, currency } = currencyConversion(item.totalCost, item.currency)
          const { value: totalRateCost } = currencyConversion(item.totalRateCost, item.currency)
          const { value: totalServicesCost } = currencyConversion(item.totalServicesCost, item.currency)

          return (
            <ReceiptItemRowWrapper>
              <ItemName>
                {item.unitsNumber && item.unitsNumber > 1 && <Bold>{`${item.unitsNumber + ' x '}`}</Bold>}
                {item.name}
              </ItemName>
              <Dates>
                {item.arrival.format(DATE_FORMAT)} - {item.departure.format(DATE_FORMAT)}
              </Dates>
              <RateName>{item.ratePlanName}</RateName>
              {item.charges.map(charge => {
                const { value: chargeCost, currency: chargeCurrency } = currencyConversion(
                  charge.primaryPrice,
                  item.currency
                )
                return (
                  <Fragment key={charge.key}>
                    <ChargeRow>
                      <Label>
                        <Name>{charge.name}</Name>
                      </Label>
                      <Value>
                        {charge.quantity}
                        <Times />
                        {formatPrice(chargeCost, chargeCurrency)} {currency}
                      </Value>
                    </ChargeRow>
                    {charge.includedExtras.length !== 0 && (
                      <ExtrasWrapper>
                        <ReceiptDivider />
                        <IncludedExtras>{translate('Included extras')}</IncludedExtras>
                        <ChargeRow>
                          <Label>
                            <Name>{charge.includedExtras.join(', ')}</Name>
                          </Label>
                        </ChargeRow>
                      </ExtrasWrapper>
                    )}
                  </Fragment>
                )
              })}
              <ReceiptDivider end={'true'} />
              {item.cancellationPolicy && (
                <CancellationPolicyWrapper>
                  <CancellationPolicy
                    policy={item.cancellationPolicy}
                    totalRatePrice={totalRateCost}
                    currency={currency}
                    paymentMethod={selectedPaymentMethod}
                    servicesCost={totalServicesCost}
                    arrivalDate={item.arrival.unix()}
                    createdAt={createdAt}
                  />
                </CancellationPolicyWrapper>
              )}
              <TotalCost>
                {typeof showPricePerDayModal === 'function' && (
                  <LinkWrapper onClick={() => showPricePerDayModal(index)}>
                    <LinkText>{translate('See prices by day')}</LinkText>
                    <ArrowRight stroke={colors.secondary} width={8} height={6} />
                  </LinkWrapper>
                )}
                {translate('Total')} {formatPrice(totalCost, currency)} {currency}
              </TotalCost>
            </ReceiptItemRowWrapper>
          )
        }}
      </CurrenciesContext.Consumer>
    )
  }
}

ReceiptItemRow.defaultProps = {
  isAccommodation: false
}

ReceiptItemRow.propTypes = {
  item: PropTypes.object,
  nights: PropTypes.number,
  isAccommodation: PropTypes.bool,
  selectedPaymentMethod: PropTypes.number,
  createdAt: PropTypes.number,
  showPricePerDayModal: PropTypes.func,
  index: PropTypes.number
}

export default ReceiptItemRow
