import styled, { css } from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const PromoWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${({ isMobile }) =>
    isMobile
      ? css`
          margin: 60px auto 20px auto;
          justify-content: center;
          align-items: center;

          @media ${device.tabletAndWider} {
            display: none;
          }
        `
      : css`
          margin-top: 40px;
          margin-right: 50px;
          margin-bottom: 46px;
          float: right;

          @media ${device.tablet} {
            display: none;
            align-items: center;
          }
        `}
`
export const PromoCta = styled.div`
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  cursor: pointer;
`

export const PromoInputWrapper = styled.div`
  padding: 0px 15px 5px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.veryDarkGray};
  position: relative;
`

export const PromoInput = styled.input`
  padding: 0;
  outline: none;
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  text-transform: uppercase;
  border: none;
`

export const PromoSubmitButton = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`

export const PromoApplied = styled.div`
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  text-transform: uppercase;
`

export const ClearPromoWrapper = styled.span`
  cursor: pointer;
  margin-left: 10px;
`

export const PromoCodeError = styled.div`
  font-weight: ${props => props.theme.fonts.regularWeight};
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  color: ${props => props.theme.colors.red};
  margin-top: 10px;
`
