import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import PaymentInfo from './PaymentInfo'
import { Section, Wrapper } from './style'
import BookingSummary from 'Rentlio/components/BookingSummary'
import OfferNoticeContainer from 'Rentlio/screen/offer/containers/offerNotice'
import Animation from 'Rentlio/components/UI/Animation'
import Modal from 'Rentlio/components/Modal'
import PricePerDayModal from 'Rentlio/screen/offer/components/Offer/Reservation/RatePlan/PricePerDayModal'
import ScrollToTop from 'Rentlio/components/utils/ScrollToTop'

class OfferPayment extends Component {
  state = {
    showModal: false
  }

  showModalHandler = reservationIndex => {
    const { setPricePerDayReservationIndex } = this.props

    setPricePerDayReservationIndex(reservationIndex)
    this.setState({ showModal: true })
  }

  hideModalHandler = () => {
    this.setState({ showModal: false })
    document.body.classList.remove('modal-open')
  }

  render() {
    const {
      bookingEngine,
      paymentDetails,
      paymentMethods,
      openTermsOfServiceModal,
      openPrivacyPolicyModal,
      booking,
      acceptBookingOffer,
      offer,
      pricePerDay,
      updatePaymentInfo,
      paymentGateways,
      getMonriClientSecret,
      getStripeClientSecret,
      getM2PayClientSecret,
      openM2Pay3DSModal,
      receipt,
      locale
    } = this.props

    const { showModal } = this.state

    const reservation = booking.reservations[pricePerDay.reservationIndex]

    return (
      <Section>
        <ScrollToTop />
        <Animation show={showModal} animationType={'fadeInOut'} isModal>
          <Modal hideModalHandler={this.hideModalHandler} show={true} isLarge padding={'50px 30px'}>
            <PricePerDayModal reservation={reservation} />
          </Modal>
        </Animation>
        <OfferNoticeContainer />
        <Wrapper>
          <PaymentInfo
            offer={offer}
            booking={booking}
            paymentMethods={paymentMethods}
            openTermsOfServiceModal={openTermsOfServiceModal}
            openPrivacyPolicyModal={openPrivacyPolicyModal}
            acceptBookingOffer={acceptBookingOffer}
            paymentDetails={paymentDetails}
            updatePaymentInfo={updatePaymentInfo}
            paymentGateways={paymentGateways}
            getMonriClientSecret={getMonriClientSecret}
            getStripeClientSecret={getStripeClientSecret}
            getM2PayClientSecret={getM2PayClientSecret}
            openM2Pay3DSModal={openM2Pay3DSModal}
            bookingEngine={bookingEngine}
            receipt={receipt}
            locale={locale}
          />
          <BookingSummary
            showPricePerDayModal={this.showModalHandler}
            receipt={receipt}
            selectedPaymentMethod={paymentDetails.selectedPaymentMethod}
            bookingEngine={bookingEngine}
          />
        </Wrapper>
      </Section>
    )
  }
}

OfferPayment.propTypes = {
  paymentDetails: PropTypes.object,
  bookingEngine: PropTypes.object,
  offer: PropTypes.object,
  booking: PropTypes.object,
  paymentMethods: PropTypes.array,
  openTermsOfServiceModal: PropTypes.func,
  openPrivacyPolicyModal: PropTypes.func,
  acceptBookingOffer: PropTypes.func,
  pricePerDay: PropTypes.object,
  setPricePerDayReservationIndex: PropTypes.func,
  updatePaymentInfo: PropTypes.func,
  paymentGateways: PropTypes.object,
  getMonriClientSecret: PropTypes.func,
  getStripeClientSecret: PropTypes.func,
  receipt: PropTypes.object,
  getM2PayClientSecret: PropTypes.func,
  openM2Pay3DSModal: PropTypes.func,
  locale: PropTypes.string
}

export default OfferPayment
