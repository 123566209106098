import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const FooterWrapper = styled.div`
  height: 140px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  padding-bottom: 10px;
  padding-top: 20px;
  width: 100%;

  @media ${device.laptop} {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  @media ${device.tablet} {
    padding-top: 40px;
    height: 280px;
    display: block;
  }

  @media ${device.mobile} {
    position: relative;
    z-index: 2;
    height: 300px;
    justify-content: space-evenly;
    flex-direction: column;
    padding-bottom: 10px;
    padding-top: 50px;
  }
`

export const CardLogosWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  @media ${device.tablet} {
    justify-content: center;
    margin-bottom: 50px;
  }

  @media ${device.mobile} {
    display: block;
  }
`

export const Link = styled.a`
  height: 35px;
  text-align: center;
  margin-right: 20px;

  @media ${device.mobile} {
    height: 30px;
  }
`

export const Logo = styled.div`
  object-fit: contain;
  width: 100%;
  height: 100%;
`

export const SecureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;

  @media ${device.tablet} {
    justify-content: center;
    margin-bottom: 30px;
  }

  @media ${device.mobile} {
    display: block;
  }
`

export const MonriSecure = styled.a`
  text-align: center;

  @media ${device.tablet} {
    margin-right: 0px;
  }

  @media ${device.mobile} {
    display: block;
    height: 80px;
  }
`

export const Secure = styled.a`
  width: 130px;
  text-align: center;
  margin-right: 20px;

  @media ${device.tablet} {
    width: 120px;
  }

  @media ${device.mobile} {
    width: 120px;
  }
`

export const LogoSecure = styled.img`
  height: 50px;

  @media ${device.tablet} {
    height: 30px;
  }

  @media ${device.mobile} {
    height: 30px;
  }
`
export const Label = styled.div`
  margin-top: 10px;
  font-size: ${props => props.theme.fonts._12};
  line-height: ${props => props.theme.fonts._14};
  font-weight: 500;
  text-transform: none;

  @media ${device.tablet} {
    line-height: ${props => props.theme.fonts._12};
  }

  @media ${device.mobile} {
    line-height: ${props => props.theme.fonts._12};
  }
`
