import { trans } from 'Rentlio/helper/translation'

export function emailConstraint(email) {
  if (typeof email === 'undefined' || email === null || email === '') {
    return null
  }

  const re = new RegExp(
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )

  const isValid = re.test(String(email).toLowerCase())

  return isValid ? null : trans('This email is not valid')
}
