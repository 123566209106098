import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Wrapper, Container, Title, Row, Column, Label, Value, HorizontalLine, ChildrenAges } from './style'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

const Header = ({ reservation }) => {
  const { translate } = useContext(LanguageContext)
  const {
    booker,
    code,
    persons,
    object,
    arrivalDate,
    departureDate,
    adults,
    children,
    unitType,
    childrenAges
  } = reservation
  const getChildrenAges = () => {
    return childrenAges.map(age => `${age} ${translate('yrs')}`).join(', ')
  }

  return (
    <Container>
      <Wrapper>
        <Title>{translate('Guest Registration')}</Title>
        <Row>
          <Column>
            <Label>{translate('Booker')}:</Label>
            <Value>{booker || '-'}</Value>
          </Column>

          <Column>
            <Label>{translate('Reservation ID')}:</Label>
            <Value>{code || '-'}</Value>
          </Column>
          <Column>
            <Label>{translate('Persons')}:</Label>
            <Value>{persons}</Value>
          </Column>
        </Row>
        <HorizontalLine />
        <Row>
          <Column>
            <Label>{translate('Property')}:</Label>
            <Value>{object}</Value>
          </Column>

          <Column>
            <Label>{translate('Arrival date')}:</Label>
            <Value>
              {moment
                .unix(parseInt(arrivalDate))
                .utc()
                .format('DD.MM.YYYY')}
            </Value>
          </Column>
          <Column>
            <Label>{translate('Adults')}:</Label>
            <Value>{adults}</Value>
          </Column>
        </Row>
        <Row>
          <Column>
            <Label>{translate('Unit type')}:</Label>
            <Value>{unitType}</Value>
          </Column>

          <Column>
            <Label>{translate('Departure date')}:</Label>
            <Value>
              {moment
                .unix(parseInt(departureDate))
                .utc()
                .format('DD.MM.YYYY')}
            </Value>
          </Column>
          <Column>
            <Label>{translate('Children')}:</Label>
            <Value>
              {children}
              {childrenAges && childrenAges.length > 0 && <ChildrenAges> ({getChildrenAges()}) </ChildrenAges>}
            </Value>
          </Column>
        </Row>
      </Wrapper>
    </Container>
  )
}

Header.propTypes = {
  reservation: PropTypes.object
}

export default Header
