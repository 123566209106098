import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const SocialNetworksWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.fonts._12};
  font-weight: ${props => props.theme.fonts.mediumWeight};
  line-height: ${props => props.theme.fonts._14};

  span {
    margin-left: 15px;
    margin-right: 15px;
  }

  @media ${device.tablet} {
    flex-direction: column;
  }

  @media ${device.mobile} {
    order: 1;
    width: 100%;
    flex-direction: column;
  }
`

export const Title = styled.span`
  @media ${device.tablet} {
    margin-bottom: 20px;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const SocialLink = styled.a`
  width: 40px;
  text-align: center;

  svg path {
    transition: fill ${({ theme }) => theme.animations.defaultTransition};
  }

  :hover {
    svg path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`
