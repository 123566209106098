import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { PaymentInfoWrapper } from './style'
import { getCardNumberIssuer } from 'Rentlio/utils/cardNumber'
import { getOfferErrors, getPaymentDetails } from 'Rentlio/screen/offerPayment/util/getOfferDetails'
import { scrollTo } from 'Rentlio/utils/scrollTo'
import { getOfferNoticeText } from 'Rentlio/utils/bookingTypeEnum'
import Checkbox from 'Rentlio/components/UI/Checkbox'
import {
  CheckboxWrapper,
  BookNowButton,
  BookNowWrapper,
  Link,
  PaymentInformation,
  SpinnerOverlay
} from 'Rentlio/screen/payment/components/Payment/PaymentInfo/style'
import RadioGroup from 'Rentlio/components/UI/RadioGroup'
import BookingErrors from 'Rentlio/screen/payment/containers/bookingErrors'
import {
  BE_BANK_TRANSFER,
  BE_MONRI,
  BE_STRIPE,
  BE_M2PAY,
  BE_WSPAY,
  BE_CREDIT_CARD
} from 'Rentlio/utils/paymentTypesEnum'
import { getValidationErrors } from 'Rentlio/helper/validator'
import PaymentMethod from 'Rentlio/screen/offerPayment/components/OfferPayment/PaymentInfo/PaymentMethod'
import { Title } from 'Rentlio/screen/offerPayment/components/OfferPayment/style'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import { getCheckInHoursOptions } from 'Rentlio/utils/checkInHoursParser'
import { CountriesEnum } from 'Rentlio/utils/countriesEnum'
import Email from 'Rentlio/components/Icons/email'
import Phone from 'Rentlio/components/Icons/phone'
import { FormError, FormGroup, FormLabel, FormRow, FormTitle } from 'Rentlio/components/UI/form'
import { Input } from 'Rentlio/components/UI/input'
import InputWithIcon from 'Rentlio/components/UI/InputWithIcon'
import Select from 'Rentlio/components/UI/Select'
import { Textarea } from 'Rentlio/components/UI/textarea'
import Animation from 'Rentlio/components/UI/Animation'
import Spinner from 'Rentlio/components/UI/Spinner'

const COMPANY = 1
const PERSONAL = 2

const bookerTypeOptions = [
  {
    id: COMPANY,
    name: 'Company'
  },
  {
    id: PERSONAL,
    name: 'Person'
  }
]

class PaymentInfo extends Component {
  static contextType = LanguageContext
  state = {
    firstName: '',
    lastName: '',
    companyName: '',
    companyVatId: '',
    email: '',
    phone: '',
    country: '',
    address: '',
    city: '',
    postalCode: '',
    estimatedTimeOfArrival: '',
    note: '',
    cardholderName: '',
    cardNumber: '',
    cvv: '',
    expirationDate: '',
    cardholderSameAsGuest: true,
    termsAccepted: false,
    clickedOnce: false,
    loading: false,
    selectedPaymentMethod: BE_BANK_TRANSFER,
    selectedPaymentMethodSettings: {},
    errors: {},
    isCompany: false,
    selectedBookerTypeId: 0,
    termsRead: false,
    isEmptyMonri: false,
    hasScroll: false
  }

  componentDidMount = () => {
    const { booker } = this.props.offer

    const isCompany = !booker.isGuest

    this.setState({
      firstName: booker.firstName ?? '',
      lastName: booker.lastName ?? '',
      companyName: booker.name ?? '',
      companyVatId: booker.legalId ?? '',
      email: booker.email ?? '',
      phone: booker.phone ?? '',
      address: booker.addres ?? '',
      country: booker.countryId ?? '',
      isCompany: isCompany,
      selectedBookerTypeId: isCompany ? COMPANY : PERSONAL
    })

    this.fillSelectedPaymentMethod()
  }

  componentDidUpdate = prevState => {
    if (prevState.locale !== this.props.locale) {
      this.setState({ termsRead: false, termsAccepted: false })
    }
  }

  getValidationError = key => getValidationErrors(this.state.errors, key)

  onTermsAcceptClicked = () => {
    this.setState(prevState => ({ termsAccepted: !prevState.termsAccepted }))
  }

  getTosCheckboxContent = () => {
    const { translate } = this.context
    const { openTermsOfServiceModal, openPrivacyPolicyModal, isEmptyMonri } = this.props
    const { termsRead, selectedPaymentMethod } = this.state

    const shouldReadTerms =
      selectedPaymentMethod === BE_MONRI ||
      selectedPaymentMethod === BE_WSPAY ||
      (selectedPaymentMethod === BE_CREDIT_CARD && isEmptyMonri)

    return !shouldReadTerms || (shouldReadTerms && termsRead) ? (
      <>
        {translate('BookNowTOS')} <Link onClick={openTermsOfServiceModal}>{translate('Terms of service')}</Link>{' '}
        {translate('and')} <Link onClick={openPrivacyPolicyModal}>{translate('Privacy policy')}</Link>
      </>
    ) : (
      <>
        {translate('BookNowTOS')} {translate('Terms of service')} {translate('and')} {translate('Privacy policy')}
      </>
    )
  }

  filteredPaymentMethods = () => {
    const { paymentMethods, receipt } = this.props

    const filteredPaymentMethods = paymentMethods.map(method => {
      if (Number(method.id) === BE_MONRI && !method.settings.authenticityToken) {
        this.setState({ isEmptyMonri: true })
        return { ...method, id: BE_CREDIT_CARD, settings: null }
      }
      return method
    })

    return filteredPaymentMethods.filter(item => item.bookingEngineId === receipt.bookingEngineId)
  }

  fillSelectedPaymentMethod = () => {
    const filteredPaymentMethods = this.filteredPaymentMethods()
    if (filteredPaymentMethods.length === 0) {
      return
    }

    this.setState({ selectedPaymentMethod: filteredPaymentMethods[0].id })

    if (filteredPaymentMethods[0].settings) {
      this.setState({ selectedPaymentMethodSettings: filteredPaymentMethods[0].settings })
    }
  }

  getCurrency = () => this.props.booking.reservations[0].currency

  getPrepaymentDetails = () => {
    const {
      booking: { reservations }
    } = this.props
    return { prepaymentAmount: reservations.map(res => res.prepaymentAmount).reduce((a, b) => a + b, 0) }
  }

  book = () => {
    const {
      firstName,
      lastName,
      companyName,
      companyVatId,
      email,
      phone,
      country,
      address,
      city,
      postalCode,
      estimatedTimeOfArrival,
      note,
      termsAccepted,
      selectedPaymentMethod,
      cardholderSameAsGuest,
      cardholderName,
      expirationDate,
      cvv,
      cardNumber,
      isCompany
    } = this.state

    const {
      acceptBookingOffer,
      offer: { offerUuid },
      booking: { code }
    } = this.props

    const guestDetails = {
      firstName,
      lastName,
      companyName,
      companyVatId,
      email,
      phone,
      country,
      address,
      city,
      postalCode,
      estimatedTimeOfArrival,
      note,
      isCompany
    }
    this.setState({ loading: true })

    // If Bank Transfer is selected add only payment method type
    const payment = getPaymentDetails(
      selectedPaymentMethod,
      cardholderSameAsGuest,
      firstName,
      lastName,
      cardholderName,
      expirationDate,
      cvv,
      cardNumber,
      isCompany,
      companyName
    )
    // Maybe we can solve this better, if bank transfer is selected DO NOT add CreditCard details to validation
    // because we won't be able to Book
    const errors = getOfferErrors(selectedPaymentMethod, guestDetails, payment, termsAccepted)

    if (errors !== true) {
      this.setState({ errors, loading: false })
      scrollTo(document.documentElement, 0, 500)
      return
    } else {
      this.setState({ errors: {} })
    }

    if (selectedPaymentMethod === BE_MONRI || selectedPaymentMethod === BE_WSPAY) {
      // This is ONE TIME ONLY HACK - we wanted to  keep  monri stuff  in monri component - do not do this again
      return (
        this.paymentMethodsRef.monriPayment && this.paymentMethodsRef.monriPayment.submitPayment(guestDetails, payment)
      )
    }
    if (selectedPaymentMethod === BE_STRIPE) {
      return this.paymentMethodsRef.stripePayment && this.paymentMethodsRef.stripePayment.onBookPressed()
    }
    if (selectedPaymentMethod === BE_M2PAY) {
      return (
        this.paymentMethodsRef.m2payPayment && this.paymentMethodsRef.m2payPayment.onBookPressed(guestDetails, payment)
      )
    }

    acceptBookingOffer(offerUuid, code, { guestDetails, payment }).then(({ success, response }) => {
      this.setState({ loading: false })
      if (!success) {
        return
      }

      window.location.replace(`/reservations/${response.publicId}`)
    })
  }

  handleSelectBookerType = itemId =>
    this.setState({
      isCompany: itemId === COMPANY,
      selectedBookerTypeId: itemId
    })

  setData = object => this.setState({ ...object })

  termsScrolledThrough = () => {
    this.setState({ termsRead: true })
  }

  setHasScroll = () => {
    this.setState({ hasScroll: true, termsRead: false })
  }

  setTermsRead = read => {
    this.setState({ termsRead: read })
  }

  render() {
    const { translate } = this.context

    const {
      firstName,
      lastName,
      companyName,
      companyVatId,
      email,
      phone,
      country,
      estimatedTimeOfArrival,
      note,
      termsAccepted,
      selectedPaymentMethod,
      cardNumber,
      isCompany,
      selectedBookerTypeId,
      loading,
      termsRead,
      isEmptyMonri,
      hasScroll
    } = this.state

    const cardIssuer = getCardNumberIssuer(cardNumber)
    const {
      paymentMethods,
      offer,
      booking,
      paymentDetails,
      updatePaymentInfo,
      paymentGateways,
      getMonriClientSecret,
      getStripeClientSecret,
      receipt,
      acceptBookingOffer,
      getM2PayClientSecret,
      openM2Pay3DSModal,
      locale,
      bookingEngine
    } = this.props

    const filteredPaymentMethods = paymentMethods.map(method => {
      if (Number(method.id) === BE_MONRI && !method.settings.authenticityToken) {
        return { ...method, id: BE_CREDIT_CARD, settings: method.settings }
      }
      return method
    })

    return (
      <>
        <Animation show={loading} animationType={'fadeInOut'} isModal>
          <SpinnerOverlay>
            <Spinner width={70} disableScroll color={'#07587A'} />
          </SpinnerOverlay>
        </Animation>

        <PaymentInfoWrapper>
          <FormTitle>
            <Title>{translate('Booker details')}</Title>
            <RadioGroup
              options={bookerTypeOptions}
              onSelect={this.handleSelectBookerType}
              selected={selectedBookerTypeId}
            />
          </FormTitle>
          {isCompany ? (
            <FormRow>
              <FormGroup>
                <FormLabel>
                  <label htmlFor='companyName'>{translate('Company name')}</label>
                </FormLabel>
                <Input
                  id='companyName'
                  value={companyName}
                  onChange={e => {
                    this.setState({ companyName: e.target.value })
                  }}
                />
                <FormError>{this.getValidationError('companyName')}</FormError>
              </FormGroup>
              <FormGroup>
                <FormLabel>
                  <label htmlFor='companyVatId'>{translate('Company VAT id')}</label>
                </FormLabel>
                <Input
                  id='companyVatId'
                  value={companyVatId}
                  onChange={e => {
                    this.setState({ companyVatId: e.target.value })
                  }}
                />
                <FormError>{this.getValidationError('companyVatId')}</FormError>
              </FormGroup>
            </FormRow>
          ) : (
            <FormRow>
              <FormGroup>
                <FormLabel>
                  <label htmlFor='firstName'>{translate('First name')}</label>
                </FormLabel>
                <Input
                  id='firstName'
                  value={firstName}
                  onChange={e => {
                    this.setState({ firstName: e.target.value })
                  }}
                />
                <FormError>{this.getValidationError('firstName')}</FormError>
              </FormGroup>
              <FormGroup>
                <FormLabel>
                  <label htmlFor='lastName'>{translate('Last name')}</label>
                </FormLabel>
                <Input
                  id='lastName'
                  value={lastName}
                  onChange={e => {
                    this.setState({ lastName: e.target.value })
                  }}
                />
                <FormError>{this.getValidationError('lastName')}</FormError>
              </FormGroup>
            </FormRow>
          )}
          <FormRow>
            <FormGroup>
              <FormLabel>
                <label htmlFor='eMail'>E-mail</label>
              </FormLabel>
              <InputWithIcon
                idForInput='eMail'
                icon={<Email />}
                value={email}
                onChange={e => {
                  this.setState({ email: e.target.value })
                }}
              />
              <FormError>{this.getValidationError('email')}</FormError>
            </FormGroup>
            <FormGroup>
              <FormLabel>
                <label htmlFor='phone'>{translate('Phone')}</label>
              </FormLabel>
              <InputWithIcon
                idForInput='phone'
                icon={<Phone />}
                value={phone}
                onChange={e => {
                  this.setState({ phone: e.target.value })
                }}
              />
              <FormError>{this.getValidationError('phone')}</FormError>
            </FormGroup>
          </FormRow>
          <FormRow>
            <FormGroup>
              <FormLabel onClick={this.showCountryDropdown}>
                <label htmlFor='country'>{translate('Country')}</label>
              </FormLabel>
              <Select
                id='country'
                ref={node => (this.selectCountry = node)}
                placeholder='Select country'
                options={CountriesEnum}
                selected={country}
                selectOption={item => {
                  this.setState({ country: item.value })
                }}
                enableSearch
              />
              <FormError>{this.getValidationError('country')}</FormError>
            </FormGroup>
            <FormGroup>
              <FormLabel onClick={this.showEstimatedTimeOfArrivalDropdown}>
                <label htmlFor='estimatedTimeOfArrival'>{translate('Estimated time of arrival')}</label>
              </FormLabel>
              <Select
                id='estimatedTimeOfArrival'
                ref={node => (this.estimatedTimeOfArrival = node)}
                selected={estimatedTimeOfArrival}
                options={getCheckInHoursOptions()}
                selectOption={item => {
                  this.setState({ estimatedTimeOfArrival: item.value })
                }}
                enableSearch
              />
              <FormError>{this.getValidationError('estimatedTimeOfArrival')}</FormError>
            </FormGroup>
          </FormRow>
          <FormRow>
            <FormGroup fullWidth={true}>
              <FormLabel>
                <label htmlFor='message'>{translate('Message')}</label>
              </FormLabel>
              <Textarea
                id='message'
                value={note}
                onChange={e => {
                  this.setState({ note: e.target.value })
                }}
              />
            </FormGroup>
          </FormRow>
          <PaymentMethod
            ref={node => (this.paymentMethodsRef = node)}
            inputData={{ ...this.state, cardIssuer }}
            paymentMethods={filteredPaymentMethods}
            getValidationError={this.getValidationError}
            setData={this.setData}
            currency={this.getCurrency()}
            prePaymentDetails={this.getPrepaymentDetails()}
            paymentDetails={paymentDetails}
            updatePaymentInfo={updatePaymentInfo}
            paymentGateways={paymentGateways}
            getMonriClientSecret={getMonriClientSecret}
            getStripeClientSecret={getStripeClientSecret}
            getM2PayClientSecret={getM2PayClientSecret}
            openM2Pay3DSModal={openM2Pay3DSModal}
            bookingEngine={bookingEngine}
            receipt={receipt}
            acceptBookingOffer={acceptBookingOffer}
            bookingCode={booking.code}
            offerUuid={offer.offerUuid}
            locale={locale}
            termsAccepted={termsAccepted}
            termsScrolledThrough={this.termsScrolledThrough}
            termsRead={termsRead}
            isEmptyMonri={isEmptyMonri}
            booking={booking}
            offer={offer}
            setHasScroll={this.setHasScroll}
            hasScroll={hasScroll}
            setTermsRead={this.setTermsRead}
          />
          {!getOfferNoticeText(offer, booking) && (
            <>
              <BookNowWrapper>
                <BookNowButton onClick={this.book} enabled={termsAccepted && !loading}>
                  {translate('Book now')}
                </BookNowButton>
                <CheckboxWrapper>
                  <Checkbox
                    isChecked={termsAccepted}
                    click={this.onTermsAcceptClicked}
                    text={this.getTosCheckboxContent()}
                    disabled={
                      hasScroll &&
                      (selectedPaymentMethod === BE_MONRI ||
                        selectedPaymentMethod === BE_WSPAY ||
                        (selectedPaymentMethod === BE_CREDIT_CARD && isEmptyMonri)) &&
                      !termsRead
                    }
                  />
                </CheckboxWrapper>

                <FormError>{this.getValidationError('termsAccepted')}</FormError>
              </BookNowWrapper>
              <BookingErrors />
              {BE_BANK_TRANSFER !== selectedPaymentMethod &&
                BE_MONRI !== selectedPaymentMethod &&
                BE_WSPAY !== selectedPaymentMethod && (
                  <PaymentInformation>{translate('PaymentInformationNotice')}</PaymentInformation>
                )}
              {(BE_MONRI === selectedPaymentMethod || BE_WSPAY === selectedPaymentMethod) && (
                <PaymentInformation>{translate('PaymentInformationMonri')}</PaymentInformation>
              )}
            </>
          )}
        </PaymentInfoWrapper>
      </>
    )
  }
}

PaymentInfo.propTypes = {
  paymentMethods: PropTypes.array,
  openTermsOfServiceModal: PropTypes.func,
  openPrivacyPolicyModal: PropTypes.func,
  booking: PropTypes.object,
  offer: PropTypes.object,
  acceptBookingOffer: PropTypes.func,
  updatePaymentInfo: PropTypes.func,
  paymentDetails: PropTypes.object,
  paymentGateways: PropTypes.object,
  getMonriClientSecret: PropTypes.func,
  getStripeClientSecret: PropTypes.func,
  receipt: PropTypes.object,
  bookingEngine: PropTypes.object,
  getM2PayClientSecret: PropTypes.func,
  openM2Pay3DSModal: PropTypes.func,
  locale: PropTypes.string,
  isEmptyMonri: PropTypes.bool
}

export default PaymentInfo
