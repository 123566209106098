import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TermsOfService from 'Rentlio/screen/layout/components/TermsOfService'
import { closeModal } from 'Rentlio/state/actions/ui'

const TermsOfServiceContainer = props => <TermsOfService {...props} />

TermsOfServiceContainer.propTypes = {
  termsOfService: PropTypes.string,
  closeModal: PropTypes.func,
  activeModal: PropTypes.string
}

const mapStateToProps = state => ({
  termsOfService: state.bookingEngine.termsOfService,
  activeModal: state.modals.openModal
})

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfServiceContainer)
