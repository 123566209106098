import styled, { css } from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const Title = styled.div`
  font-size: 32px;
  font-weight: 500;
  line-height: 37.5px;
  margin 40px 0px 30px;
  color: #333333;
`

export const Container = styled.div`
  background: #f5f5f5;
  align-items: center;

  @media ${device.tablet} {
    display: none;
  }
`

export const Wrapper = styled.div`
  max-width: 1180px;
  margin: 0 auto;
  @media ${device.laptop} {
    max-width: 900px;
  }

  @media ${device.tablet} {
    padding: 0 20px;
  }
`

export const Row = styled.div`
  display: flex;
  margin: 10px 0px;

  &:last-of-type {
    margin-bottom: 40px;
  }
`

export const Column = styled.div`
  width: 33%;
  display: flex;
  font-size: 14px;

  ${({ flexDirection }) =>
    flexDirection
      ? css`
          flex-direction: column;
          gap: 10px;
        `
      : null}
`

export const Label = styled.div`
  margin-right: 5px;
`

export const Value = styled.div`
  font-weight: 800;
`

export const HorizontalLine = styled.hr`
  background: #3333334d;
  height: 1px;
  margin: 20px 0px;
`

export const ChildrenAges = styled.div`
  font-weight: 400;
  display: inline;
`
