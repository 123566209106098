import React from 'react'
import PropTypes from 'prop-types'
import { CurrencyWrapper, SelectedCurrency, Select, Selected, Dropdown, DropdownItem } from './style'
import SmallText from 'Rentlio/components/UI/smallText'
import Animation from 'Rentlio/components/UI/Animation'
import ArrowDown from 'Rentlio/components/Icons/arrowDown'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import { CurrenciesContext } from 'Rentlio/context/CurrenciesContext'

class Currency extends React.Component {
  static contextType = LanguageContext

  wrapperRef = React.createRef()

  state = {
    showDropdown: false
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick)
  }

  closeDropdown = () => this.setState({ showDropdown: false })

  handleOutsideClick = event => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.closeDropdown()
    }
  }

  onClick = (currency, changeCurrency) => {
    changeCurrency(currency)
    this.props.selectCurrency(currency)
    this.closeDropdown()
  }

  render() {
    const { showDropdown } = this.state
    const { activeCurrencies, currency } = this.props
    const { translate } = this.context

    return (
      <CurrenciesContext.Consumer>
        {({ changeCurrency }) => {
          return (
            <CurrencyWrapper>
              <SmallText>{translate('Currency')}</SmallText>
              <Select ref={this.wrapperRef}>
                <Selected onClick={() => activeCurrencies.length > 1 && this.setState({ showDropdown: true })}>
                  <SelectedCurrency>{currency}</SelectedCurrency>
                  {activeCurrencies.length > 1 && <ArrowDown />}
                </Selected>
                <Animation show={this.state.showDropdown} animationType={'fadeInOutBottom'}>
                  <Dropdown showDropdown={showDropdown}>
                    {activeCurrencies.map(activeCurrency => (
                      <DropdownItem key={activeCurrency} onClick={() => this.onClick(activeCurrency, changeCurrency)}>
                        <SelectedCurrency>{activeCurrency}</SelectedCurrency>
                      </DropdownItem>
                    ))}
                  </Dropdown>
                </Animation>
              </Select>
            </CurrencyWrapper>
          )
        }}
      </CurrenciesContext.Consumer>
    )
  }
}

Currency.propTypes = {
  activeCurrencies: PropTypes.array,
  selectCurrency: PropTypes.func,
  currency: PropTypes.string
}

export default Currency
