import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const RatePreviewWrapper = styled.div`
  margin-top: 47px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 90px;
  min-width: 240px;

  @media ${device.tablet} {
    margin-left: 0;
    margin-top: 60px;
  }
`
export const RateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    flex-direction: row;
    align-items: baseline;
  }
`

export const RateLabel = styled.span`
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
`

export const RateValue = styled.span`
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: ${props => props.theme.fonts.boldWeight};
  font-size: ${props => props.theme.fonts._24};
  line-height: ${props => props.theme.fonts._28};

  @media ${device.tablet} {
    margin-right: 10px;
  }
`

export const RateSubtitle = styled.span`
  color: ${props => props.theme.colors.lightText};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
`

export const RatesToggle = styled.div`
  user-select: none;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  width: 240px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${props => (props.biggerTopMargin ? '30px' : '15px')};
  margin-bottom: 20px;
  background: ${props => props.theme.colors.buttonBackground};
  color: ${props => props.theme.colors.buttonText};
  border-radius: 10px;
  text-transform: uppercase;
  font-weight: ${props => props.theme.fonts.boldWeight};
  font-size: ${props => props.theme.fonts._18};
  line-height: ${props => props.theme.fonts._21};
  transition: all ${({ theme }) => theme.animations.defaultTransition};

  &:hover {
    background: ${({ theme }) => theme.colors.darkBlue};
  }

  span {
    user-select: none;
    text-align: center;
  }
`

export const RatePolicies = styled.span`
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._14};
  line-height: ${props => props.theme.fonts._16};
  text-decoration: underline;
  cursor: pointer;
  margin-top: 20px;
`
