import React from 'react'

export const Maximize = () => (
  <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M18.75 3.75H26.25V11.25' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M11.25 26.25H3.75V18.75' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M26.25 3.75L17.5 12.5' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M3.75 26.25L12.5 17.5' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
