import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import moment from 'moment'
// any CSS you import will output into a single css file (app.css in this case)
/** CSS Imports */
import 'Css/app.scss'
import 'react-dates/lib/css/_datepicker.css'
/** END CSS Imports */
import 'react-dates/initialize'
import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'
import App from './app'
import configureStore from './state/store'

/** DO NOT REMOVE THIS REQUIRED LINE BELOW IT'S A IMPORTANT POLYFIL FOR INTERSECTION OBSERVER
 ** IT WILL CRASH WEBSITE ON OLDER MOBILEPHONES IF YOU REMOVE IT!!
 */
require('intersection-observer')
require('./utils/string-polyfills')
require('./utils/object-polyfills')
/** END POLYFILLS **/

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: window.extraInfo.SENTRY_DSN,
    environment: window.extraInfo.SENTRY_ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.3,
    release: window.extraInfo.APP_VERSION
  })
}

try {
  initApp()
} catch (err) {
  process.env.NODE_ENV === 'production' ? Sentry.captureException(err) : console.error(err)
}

function initApp() {
  const store = configureStore(window.initialBookingEngineData)
  window.store = store
  moment.locale(store.getState().configuration.locale)

  // define _AutofillCallbackHandler to resolve Sentry issue https://rentlio.sentry.io/issues/2300883340/
  window._AutofillCallbackHandler = window._AutofillCallbackHandler || function() {}

  const rootElement = document.getElementById('root')
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement
  )
}
