import React from 'react'
const Smoke = () => (
  <svg width='23' height='20' viewBox='0 0 23 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2.71754 16.1422L3.494 19.04C3.51096 19.1035 3.53913 19.1632 3.57689 19.2159C3.61465 19.2686 3.66125 19.3132 3.71405 19.3472C3.76685 19.3811 3.82479 19.4037 3.88458 19.4137C3.94436 19.4237 4.00481 19.4208 4.06247 19.4053L8.8921 18.1112L7.85682 14.2475L3.02719 15.5416C2.96951 15.557 2.91575 15.5848 2.86896 15.6233C2.82218 15.6619 2.78329 15.7104 2.75453 15.7662C2.72578 15.822 2.70771 15.8839 2.70136 15.9484C2.69501 16.013 2.70051 16.0788 2.71754 16.1422Z'
      fill='#0973A0'
    />
    <path
      d='M19.3602 11.1652L8.38379 14.1064L9.41907 17.9701L20.3955 15.0289C20.4532 15.0135 20.5069 14.9857 20.5537 14.9472C20.6005 14.9087 20.6394 14.8601 20.6682 14.8044C20.6969 14.7486 20.715 14.6866 20.7213 14.6221C20.7277 14.5576 20.7222 14.4917 20.7051 14.4283L19.9287 11.5305C19.9117 11.4671 19.8836 11.4073 19.8458 11.3546C19.808 11.3019 19.7614 11.2573 19.7086 11.2234C19.6558 11.1895 19.5979 11.1669 19.5381 11.1569C19.4783 11.1469 19.4179 11.1497 19.3602 11.1652Z'
      fill='#0973A0'
    />
    <path
      d='M21.4676 10.6006C21.4099 10.616 21.3562 10.6437 21.3094 10.6823C21.2626 10.7208 21.2237 10.7694 21.195 10.8251C21.1662 10.8809 21.1481 10.9428 21.1418 11.0074C21.1354 11.0719 21.1409 11.1377 21.158 11.2012L21.9344 14.0989C21.9688 14.227 22.0479 14.3375 22.1545 14.406C22.2611 14.4745 22.3865 14.4955 22.5029 14.4643C22.6193 14.4331 22.7174 14.3523 22.7755 14.2396C22.8335 14.127 22.8469 13.9917 22.8125 13.8637L22.0361 10.9659C22.0191 10.9024 21.991 10.8426 21.9532 10.7899C21.9154 10.7372 21.8688 10.6926 21.816 10.6587C21.7632 10.6248 21.7053 10.6022 21.6455 10.5922C21.5857 10.5822 21.5253 10.5851 21.4676 10.6006Z'
      fill='#0973A0'
    />
    <path
      d='M15.8237 7.44397C16.9616 7.39271 17.8603 7.35222 18.67 8.88736C18.7216 8.98551 18.8011 9.06363 18.896 9.1093C18.9908 9.15497 19.0955 9.16556 19.1934 9.13938C19.2293 9.12967 19.2639 9.11532 19.2964 9.09662C19.3482 9.06626 19.3935 9.0248 19.4297 8.97462C19.466 8.92444 19.4924 8.86653 19.5075 8.80418C19.5226 8.74183 19.5261 8.67627 19.5177 8.61124C19.5094 8.54622 19.4894 8.48301 19.4589 8.42521C18.3553 6.33203 16.9065 6.39763 15.7424 6.45072C14.6045 6.50198 13.7059 6.54247 12.8962 5.00733C12.8659 4.94923 12.8256 4.89766 12.7776 4.85559C12.7297 4.81352 12.675 4.78177 12.6167 4.76216C12.5583 4.74256 12.4976 4.73549 12.4379 4.74136C12.3782 4.74722 12.3207 4.76591 12.2687 4.79635C12.2168 4.82679 12.1714 4.86838 12.1351 4.91873C12.0988 4.96908 12.0725 5.0272 12.0575 5.08974C12.0425 5.15229 12.0391 5.21803 12.0477 5.28319C12.0562 5.34835 12.0765 5.41165 12.1073 5.46945C13.2108 7.56276 14.6597 7.49704 15.8237 7.44397Z'
      fill='#0973A0'
    />
    <path
      d='M17.4087 4.83455C18.5444 5.32377 19.7184 5.82913 20.3813 8.30338C20.4157 8.43146 20.4948 8.54191 20.6014 8.61042C20.708 8.67893 20.8334 8.69989 20.9498 8.66869C21.0662 8.63749 21.1643 8.55668 21.2224 8.44405C21.2804 8.33141 21.2938 8.19617 21.2594 8.06808C20.4638 5.09861 18.9374 4.44098 17.7107 3.91247C16.8385 3.53644 16.2077 3.26462 15.9353 2.24776C15.9009 2.11967 15.8218 2.00923 15.7152 1.94072C15.6085 1.87221 15.4832 1.85125 15.3668 1.88245C15.2503 1.91365 15.1523 1.99446 15.0942 2.10709C15.0362 2.21973 15.0228 2.35496 15.0571 2.48305C15.4626 3.99608 16.4522 4.42243 17.4087 4.83455Z'
      fill='#0973A0'
    />
  </svg>
)

export default Smoke
