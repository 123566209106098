import React from 'react'
import DinersProtectBuyIcon from 'Rentlio/components/Icons/dinersProtectBuy'
import MastercardSecurityIcon from 'Rentlio/components/Icons/mastercardSecurity'
import VisaSecurityIcon from 'Rentlio/components/Icons/visaSecurity'

export default [
  { value: 1, id: 'visa', label: 'Your transactions are secure with Visa', logo: <VisaSecurityIcon /> },
  { value: 2, id: 'mastercard', label: 'Mastercard® Identity Check™', logo: <MastercardSecurityIcon /> },
  { value: 3, id: 'diners', label: '', logo: <DinersProtectBuyIcon /> }
]
