import moment from 'moment'
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import 'url-polyfill'
import NightsPicker from './NightPicker'
import LocationSelect from './LocationSelect'
import { SearchInnerWrapper, SearchSubmit, SearchWrapper, SearchLabel, Wrapper } from './style'
import Spinner from 'Rentlio/components/UI/Spinner'
import { DATE_URL_FORMAT } from 'Rentlio/utils/dateFormatEnum'
import { setQueryParameters } from 'Rentlio/utils/url'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

class Search extends React.Component {
  static contextType = LanguageContext

  state = {
    from: moment.unix(this.props.request.from),
    to: moment.unix(this.props.request.to)
  }

  componentDidUpdate = prevProps => {
    const { request } = this.props
    if (prevProps.request.from !== request.from || prevProps.request.to !== request.to) {
      this.setState({
        from: moment.unix(request.from),
        to: moment.unix(request.to)
      })
    }
  }

  submitSearch = () => {
    const { from, to } = this.state
    const {
      fetchAvailability,
      animationLoading,
      request,
      configuration: { location }
    } = this.props

    if (animationLoading) {
      return
    }

    const params = {
      from: from !== null ? from.utc().format(DATE_URL_FORMAT) : null,
      to: to !== null ? to.utc().format(DATE_URL_FORMAT) : null,
      location: location !== null ? location : null
    }

    if (request.unitTypeId !== null) {
      params.unitTypeId = request.unitTypeId
    }

    if (request.bookingEngineId !== null) {
      params.bookingEngineId = request.bookingEngineId
    }

    if (request.promoCode !== null && request.promoCode !== '') {
      params.promoCode = request.promoCode
    }

    setQueryParameters(params)
    fetchAvailability(params)
  }

  onDatesChange = (startDate, endDate) => {
    this.setState({ from: startDate, to: endDate })
  }

  render() {
    const { request, animationLoading, isGroup, unitTypes, filterByLocation, configuration } = this.props
    const { from, to } = request
    const { translate } = this.context

    return (
      <Wrapper isGroup={isGroup}>
        <SearchWrapper>
          <SearchInnerWrapper isGroup={isGroup}>
            {isGroup && (
              <LocationSelect
                unitTypes={unitTypes}
                location={configuration.location}
                onLocationChange={filterByLocation}
              />
            )}
            <NightsPicker isGroup={isGroup} from={from} to={to} onDatesChange={this.onDatesChange} />
            {/* eslint-disable-next-line react/no-children-prop */}
          </SearchInnerWrapper>
          <SearchSubmit onClick={this.submitSearch}>
            {animationLoading && <Spinner />}
            <SearchLabel loading={animationLoading ? 1 : 0}>{translate('Search now')}</SearchLabel>
          </SearchSubmit>
        </SearchWrapper>
      </Wrapper>
    )
  }
}

Search.propTypes = {
  request: PropTypes.object,
  fetchAvailability: PropTypes.func,
  animationLoading: PropTypes.bool,
  isGroup: PropTypes.bool,
  configuration: PropTypes.object,
  unitTypes: PropTypes.array,
  filterByLocation: PropTypes.func
}

export default memo(Search)
