import React from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import moment from 'moment'
import { getAvailableCookies, ANALYTICS_COOKIES, saveCookies } from '../../../helper/cookieNotice'
import CookieNotice from '..'
import {
  activateCookieNotice,
  openPrivacyPolicyModal,
  openTermsOfServiceModal,
  acceptCookieNotice
} from 'Rentlio/state/actions/ui'

class CookieNoticeContainer extends React.Component {
  componentDidMount = () => {
    const { onActivateCookieNotice } = this.props
    if (!window.localStorage) {
      return onActivateCookieNotice(true)
    }
    const cookies = JSON.parse(window.localStorage.getItem(ANALYTICS_COOKIES))
    // if cookie is expired then we are removing it form storage and showing notice again
    if (cookies && cookies.expirationDate && moment.unix(cookies.expirationDate).isBefore(moment())) {
      window.localStorage.removeItem(ANALYTICS_COOKIES)
      return onActivateCookieNotice(true)
    }
    if (!cookies) {
      return onActivateCookieNotice(true)
    }
  }

  render = () => {
    const {
      cookieNotice,
      onActivateCookieNotice,
      acceptCookieNotice,
      openTermsOfServiceModal,
      openPrivacyPolicyModal,
      bookingEngine
    } = this.props

    return cookieNotice?.cookieNoticeIsActive ? (
      <CookieNotice
        text={
          'We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.'
        }
        cookies={getAvailableCookies(bookingEngine)}
        activateCookieNotice={onActivateCookieNotice}
        acceptCookieNotice={acceptCookieNotice}
        openTermsOfServiceModal={openTermsOfServiceModal}
        openPrivacyPolicyModal={openPrivacyPolicyModal}
        saveCookies={saveCookies}
      />
    ) : null
  }
}

CookieNoticeContainer.propTypes = {
  cookieNotice: propTypes.object,
  onActivateCookieNotice: propTypes.func,
  openTermsOfServiceModal: propTypes.func,
  openPrivacyPolicyModal: propTypes.func,
  bookingEngine: propTypes.object,
  acceptCookieNotice: propTypes.func
}

const mapStateToProps = ({ cookieNotice, bookingEngine }) => ({
  cookieNotice,
  bookingEngine
})

const mapDispatchToProps = dispatch => ({
  onActivateCookieNotice: isActive => dispatch(activateCookieNotice(isActive)),
  acceptCookieNotice: () => dispatch(acceptCookieNotice()),
  openTermsOfServiceModal: () => dispatch(openTermsOfServiceModal()),
  openPrivacyPolicyModal: () => dispatch(openPrivacyPolicyModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(CookieNoticeContainer)
