import { FETCH_M2PAY_CLIENT_SECRET_COMPLETED } from 'Rentlio/state/types'
import createReducer from 'Rentlio/state/utils/createReducer'

const initialState = {}

const m2pay = createReducer(initialState)({
  [FETCH_M2PAY_CLIENT_SECRET_COMPLETED]: (state, action) => {
    return { ...action.payload }
  }
})

export default m2pay
