const groupAccommodationAndExtrasItems = receipt => {
  if (!receipt?.accomodation?.items?.length && !receipt.extras?.items?.length) {
    return receipt
  }
  const groupedAccommodationItems = []

  receipt.accomodation.items.map(accomodation => {
    let shouldGroup = false

    groupedAccommodationItems.map((groupedItem, index) => {
      if (shouldGroupAccommodationItems(accomodation, groupedItem)) {
        shouldGroup = true
        groupedAccommodationItems[index] = {
          ...groupedItem,
          quantity: groupedItem.quantity + 1,
          price: groupedItem.price + accomodation.price,
          priceBeforeTax: groupedItem.priceBeforeTax + accomodation.priceBeforeTax,
          servicesCost: groupedItem.servicesCost + accomodation.servicesCost
        }
      }
    })

    if (!shouldGroup) {
      return groupedAccommodationItems.push(accomodation)
    }
  })

  const groupedExtrasItems = []

  receipt.extras.items.map(extra => {
    let shouldGroup = false

    groupedExtrasItems.map((groupedItem, index) => {
      if (shouldGroupExtras(extra, groupedItem)) {
        shouldGroup = true

        groupedExtrasItems[index] = {
          ...groupedItem,
          price: groupedItem.price + extra.price,
          priceBeforeTax: groupedItem.priceBeforeTax + extra.priceBeforeTax,
          quantity: groupedItem.quantity + extra.quantity,
          servicesCost: groupedItem.servicesCost + extra.servicesCost
        }
      }
    })

    if (!shouldGroup) {
      return groupedExtrasItems.push(extra)
    }
  })

  return {
    ...receipt,
    accomodation: { ...receipt.accomodation, items: groupedAccommodationItems },
    extras: { ...receipt.extras, items: groupedExtrasItems }
  }
}

const shouldGroupAccommodationItems = (item1, item2) => {
  const {
    checkInBegin,
    checkInDate,
    checkInEnd,
    checkOutBegin,
    checkOutDate,
    checkOutEnd,
    currency,
    isIncluded,
    isVatRegistered,
    name,
    price,
    priceBeforeTax,
    quantity,
    ratePlanName,
    servicesCost,
    vatRate
  } = item1

  if (
    checkInBegin === item2.checkInBegin &&
    checkInDate === item2.checkInDate &&
    checkInEnd === item2.checkInEnd &&
    checkOutBegin === item2.checkOutBegin &&
    currency === item2.currency &&
    ratePlanName === item2.ratePlanName &&
    checkOutDate === item2.checkOutDate &&
    isIncluded === item2.isIncluded &&
    checkOutEnd === item2.checkOutEnd &&
    isVatRegistered === item2.isVatRegistered &&
    name === item2.name &&
    price === item2.price &&
    priceBeforeTax === item2.priceBeforeTax &&
    servicesCost.id === item2.servicesCost.id &&
    quantity === item2.quantity &&
    vatRate === item2.vatRate
  ) {
    return true
  }
  return false
}

const shouldGroupExtras = (item1, item2) => {
  const { currency, isIncluded, isVatRegistered, name, price, priceBeforeTax, ratePlanName, vatRate } = item1

  if (
    currency === item2.currency &&
    ratePlanName === item2.ratePlanName &&
    isIncluded === item2.isIncluded &&
    isVatRegistered === item2.isVatRegistered &&
    name === item2.name &&
    price === item2.price &&
    priceBeforeTax === item2.priceBeforeTax &&
    vatRate === item2.vatRate
  ) {
    return true
  }
  return false
}

export default groupAccommodationAndExtrasItems
