/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'

import getCountryCodeById from '../../../utils/getCountryCodeById'
import { MonriErrorMessage } from './style'
import getCustomMonriFormStyle from './customMonriFormStyle'
import { FormError, FormGroup, FormLabel, MonriRow } from 'Rentlio/components/UI/form'
import { Input } from 'Rentlio/components/UI/input'
import Checkbox from 'Rentlio/components/UI/Checkbox'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import Loading from 'Rentlio/components/UI/Loading'
import { logErrorWithJsonData } from 'Rentlio/utils/logging'

class MonriPayment extends React.PureComponent {
  static contextType = LanguageContext
  monri = null

  state = {
    card: null,
    formErrorMessage: '', // this message we fill if we get error message on input change event for card number/expiration/cvv
    monriErrorMessage: '',
    cardComponentLoading: true
  }

  componentDidMount = () => {
    this.setStateFromProps()
  }

  componentDidUpdate = prevProps => {
    const { monri } = this.props
    if (monri.clientSecret && prevProps.monri.clientSecret !== monri.clientSecret) {
      this.initializeMonriComponents()
    }
  }

  componentWillUnmount = () => {
    // we are setting card to null to remove all card listeners to avoid memory leaks
    this.setState({ card: null })
  }

  setStateFromProps = () => {
    const { getMonriClientSecret, paymentMethodSettings, locale, receipt, isWSPay } = this.props
    const { currency } = receipt

    // WSPay is using monri components, that is why we are reusing all the monri logic implemented here.
    // The only variations include the script being initially loaded for hosted components and the keys entered in the webapp settings for communication with Monri/WSPay.
    getMonriClientSecret(
      isWSPay ? paymentMethodSettings.shopId : paymentMethodSettings.authenticityToken,
      receipt.bookingEngineId,
      receipt.price?.total || receipt.totalBookingCost,
      currency,
      isWSPay
    )
    this.monri = window.Monri(isWSPay ? paymentMethodSettings.shopId : paymentMethodSettings.authenticityToken, {
      // Monri only support  hr, en & sr, and it will default to EN
      locale: locale
    })
  }

  submitPayment = (guestDetails, paymentDetails) => {
    // we are calling this from parent after clicking on book. If transaction is approved we are calling normal bookReservation endpoint,
    // othervise we are displaying error message and removing clicked once and loading flags
    const { card, monriErrorMessage } = this.state
    const {
      email,
      phone,
      country,
      address,
      postalCode,
      city,
      callBookReservationApi,
      onMonriPaymentFailed,
      isWSPay
    } = this.props
    const { formErrorMessage } = this.state

    // we are setting formErrorMessage here if it exist because we did not want to set it earlier on every key stroke
    if (formErrorMessage) {
      this.setState({ monriErrorMessage: formErrorMessage })
      return onMonriPaymentFailed()
    }

    const transactionParams = {
      fullName: paymentDetails.name,
      phone: phone,
      email: email,
      orderInfo: this.monri.orderInfo,
      city: city,
      zip: postalCode,
      address: address
    }
    if (getCountryCodeById(country)) {
      transactionParams.country = getCountryCodeById(country)
    }
    if (monriErrorMessage) {
      this.setState({ monriErrorMessage: '' })
    }

    this.monri.confirmPayment(card, transactionParams).then(response => {
      if (response?.result?.status === 'approved') {
        try {
          // handle book reservation here
          const maskedPan = response.result.payment_method.data.masked.replace(/-/g, '')
          const paymentDetailsWithToken = {
            ...paymentDetails,
            maskedPan: maskedPan.replace(/x/g, '*')
          }

          if (isWSPay) {
            paymentDetailsWithToken.wsPayToken = response.result.pan_token
          } else {
            paymentDetailsWithToken.monriToken = response.result.pan_token
          }

          callBookReservationApi(guestDetails, paymentDetailsWithToken)
        } catch (e) {
          this.setState({ monriErrorMessage: 'There was an error during booking' })

          logErrorWithJsonData(e, response, {
            critical: 'true',
            'payment-method': isWSPay ? 'WSPay' : 'Monri',
            description:
              'Transaction confirmed (response.result.status was approved), but an error occurred handling the response in Rentlio. Reservation may not be created.'
          })

          return onMonriPaymentFailed()
        }
      } else if (response.error) {
        this.setState({ monriErrorMessage: response.error.message })
        return onMonriPaymentFailed()
      } else if (response?.result?.status === 'declined') {
        this.setState({ monriErrorMessage: response?.result?.response_message || 'There was an error during booking' })
        return onMonriPaymentFailed()
      } else {
        logErrorWithJsonData(`Unexpected response from ${isWSPay ? 'WSPay' : 'Monri'}`, response, {
          critical: 'true',
          'payment-method': isWSPay ? 'WSPay' : 'Monri',
          description: 'Error occurred when handling the response in Rentlio. Reservation is not created.',
          'possible-reason': isWSPay ? 'It is possible that user is still in WSPay test mode. ' : null
        })

        this.setState({ monriErrorMessage: response?.result?.response_message || 'There was an error during booking' })
        return onMonriPaymentFailed()
      }
    })
  }

  initializeMonriComponents = () => {
    const components = this.monri.components({ clientSecret: this.props.monri.clientSecret })
    const card = components.create('card', { style: getCustomMonriFormStyle(), tokenizePan: true })
    card.mount('card-element')

    card.addChangeListener('card_number', event => {
      if (this.state.cardComponentLoading) {
        this.setState({ cardComponentLoading: false })
      }
      const { formErrorMessage } = this.state
      if (event.message !== formErrorMessage) {
        this.setState({ formErrorMessage: event.message })
      }
    })
    card.addChangeListener('expiry_date', event => {
      const { formErrorMessage } = this.state
      if (event.message !== formErrorMessage) {
        this.setState({ formErrorMessage: event.message })
      }
    })
    card.addChangeListener('cvv', event => {
      const { formErrorMessage } = this.state
      if (event.message !== formErrorMessage) {
        this.setState({ formErrorMessage: event.message })
      }
    })

    // we are setting card in state because after we press book in parent we need to call submitPayment method which needs card and monri references
    this.setState({ card: card })
  }

  render() {
    const { translate } = this.context
    const {
      cardholderSameAsGuest,
      handleSameAsGuest,
      cardholderName,
      handleCardHolderName,
      getValidationError,
      handleAddress,
      handleCity,
      handlePostalCode,
      address,
      city,
      postalCode
    } = this.props
    const { monriErrorMessage } = this.state
    return (
      <>
        <Loading isLoading={this.state.cardComponentLoading} />
        <MonriRow>
          <Checkbox
            isChecked={cardholderSameAsGuest}
            click={handleSameAsGuest}
            text={translate('Card holder same as booker')}
          />
        </MonriRow>
        {!cardholderSameAsGuest && (
          <MonriRow>
            <FormGroup fullWidth={true}>
              <FormLabel>
                <label htmlFor='monriCardHolder'>{translate('Card holder')}</label>
              </FormLabel>
              <Input id='monriCardHolder' value={cardholderName} onChange={handleCardHolderName} />
              <FormError>{getValidationError('name')}</FormError>
            </FormGroup>
          </MonriRow>
        )}
        <MonriRow>
          <FormGroup fullWidth={true}>
            <FormLabel>
              <label htmlFor='monriAddress'>{translate('Address')}</label>
            </FormLabel>
            <Input id='monriAddress' value={address} onChange={handleAddress} />
            <FormError>{getValidationError('address')}</FormError>
          </FormGroup>
        </MonriRow>
        <MonriRow>
          <FormGroup>
            <FormLabel>
              <label htmlFor='monriCity'>{translate('City')}</label>
            </FormLabel>
            <Input id='monriCity' value={city} onChange={handleCity} />
            <FormError>{getValidationError('city')}</FormError>
          </FormGroup>
          <FormGroup>
            <FormLabel>
              <label htmlFor='monriZipCode'>{translate('Zip code')}</label>
            </FormLabel>
            <Input id='monriZipCode' value={postalCode} onChange={handlePostalCode} />
            <FormError>{getValidationError('postalCode')}</FormError>
          </FormGroup>
        </MonriRow>
        {
          // under are monri components inside iframe for which we can not see if cvv was entered or not, if input is wrong, on loose focus it will become red.
          // if confirm monri payment fails, error will be displayed on monri error message under
        }
        <div>
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <form action='' method='post' id='payment-form'>
            <div className='form-row'>
              <div id='card-element' />
            </div>
          </form>
        </div>
        <MonriErrorMessage>{translate(monriErrorMessage)}</MonriErrorMessage>
      </>
    )
  }
}

MonriPayment.propTypes = {
  currency: PropTypes.string,
  getMonriClientSecret: PropTypes.func,
  monri: PropTypes.object,
  paymentMethodSettings: PropTypes.object,
  locale: PropTypes.string,
  cardholderName: PropTypes.string,
  handleCardHolderName: PropTypes.func,
  cardholderSameAsGuest: PropTypes.bool,
  handleSameAsGuest: PropTypes.func,
  getValidationError: PropTypes.func,
  receipt: PropTypes.object,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  country: PropTypes.string,
  handleAddress: PropTypes.func,
  handleCity: PropTypes.func,
  handlePostalCode: PropTypes.func,
  address: PropTypes.string,
  city: PropTypes.string,
  postalCode: PropTypes.string,
  callBookReservationApi: PropTypes.func,
  onMonriPaymentFailed: PropTypes.func,
  isWSPay: PropTypes.bool
}

export default MonriPayment
