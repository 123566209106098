import React from 'react'
const Clock = () => (
  <svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.5 17C13.6421 17 17 13.6421 17 9.5C17 5.35786 13.6421 2 9.5 2C5.35786 2 2 5.35786 2 9.5C2 13.6421 5.35786 17 9.5 17ZM9.5 19C14.7467 19 19 14.7467 19 9.5C19 4.25329 14.7467 0 9.5 0C4.25329 0 0 4.25329 0 9.5C0 14.7467 4.25329 19 9.5 19Z'
      fill='#0973A0'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 5C10 4.44772 9.55229 4 9 4C8.44772 4 8 4.44772 8 5V11C8 11.5523 8.44772 12 9 12C9.01645 12 9.03281 11.9996 9.04907 11.9988C9.06533 11.9996 9.08169 12 9.09815 12H13.0981C13.6504 12 14.0981 11.5523 14.0981 11C14.0981 10.4477 13.6504 10 13.0981 10H10L10 5Z'
      fill='#0973A0'
    />
  </svg>
)

export default Clock
