import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import ReceiptItemRow from './ReceiptItemRow'
import Card from './Card'
import {
  BookingSummaryWrapper,
  TotalCost,
  TotalCostLabel,
  TotalCostValue,
  VatInfoRow,
  TotalCostValuesWrapper,
  TotalCostSecondaryValue
} from './style'
import { formatPrice } from 'Rentlio/components/utils/Price/price'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import { CurrenciesContext } from 'Rentlio/context/CurrenciesContext'
import { shouldShowHrkTotal } from 'Rentlio/helper/currency'

class BookingSummary extends React.Component {
  static contextType = LanguageContext
  render() {
    const {
      receipt,
      showCard,
      cardHolderName,
      maskedPan,
      selectedPaymentMethod,
      createdAt,
      showPricePerDayModal,
      bookingEngine
    } = this.props
    const { items, taxes } = receipt
    const { translate } = this.context

    return (
      <CurrenciesContext.Consumer>
        {({ currencyConversion }) => {
          const { value: totalBookingCost, currency: totalBookingCostCurrency } = currencyConversion(
            receipt.totalBookingCost,
            receipt.currency
          )

          return (
            <BookingSummaryWrapper>
              {items.map((item, i) => (
                <Fragment key={i}>
                  <ReceiptItemRow
                    showPricePerDayModal={showPricePerDayModal}
                    index={i}
                    item={item}
                    selectedPaymentMethod={selectedPaymentMethod}
                    createdAt={createdAt}
                  />
                </Fragment>
              ))}
              <TotalCost>
                <TotalCostLabel>{translate('Total cost')}</TotalCostLabel>
                <TotalCostValuesWrapper>
                  <TotalCostValue>
                    {formatPrice(totalBookingCost, totalBookingCostCurrency)} {totalBookingCostCurrency}
                  </TotalCostValue>
                  {shouldShowHrkTotal(receipt.currency) && (
                    <TotalCostSecondaryValue>
                      {formatPrice(receipt.totalBookingCost * (bookingEngine?.settingsExchangeRate || 1), 'HRK')}{' '}
                      {'HRK'}
                    </TotalCostSecondaryValue>
                  )}
                </TotalCostValuesWrapper>
              </TotalCost>
              {receipt.isVatRegistered &&
                taxes.map(tax => {
                  return (
                    <VatInfoRow key={tax.label}>
                      <div>
                        {tax.label} ({tax.rate}%) {translate('included in price')}
                      </div>
                    </VatInfoRow>
                  )
                })}
              {!receipt.isVatRegistered && (
                <VatInfoRow>
                  <div>{translate('Property is not VAT registered, so VAT is not applicable')}</div>
                </VatInfoRow>
              )}
              {showCard && <Card cardHolderName={cardHolderName} maskedPan={maskedPan} />}
            </BookingSummaryWrapper>
          )
        }}
      </CurrenciesContext.Consumer>
    )
  }
}

BookingSummary.propTypes = {
  receipt: PropTypes.object,
  hasTitle: PropTypes.bool,
  showCard: PropTypes.bool,
  cardHolderName: PropTypes.string,
  maskedPan: PropTypes.string,
  selectedPaymentMethod: PropTypes.number,
  createdAt: PropTypes.number,
  showPricePerDayModal: PropTypes.func,
  bookingEngine: PropTypes.object
}

BookingSummary.defaultProps = {
  showCard: false
}

export default BookingSummary
