const ACCOMMODATION = 1
const SERVICE = 2
const REFUND = 3
const FOOD_AND_BEVERAGE = 4
const TAX = 5

export default {
  ACCOMMODATION,
  SERVICE,
  REFUND,
  FOOD_AND_BEVERAGE,
  TAX
}
