import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ButtonWrapper, OfferFooterInnerWrapper, OfferFooterLabel, OfferFooterValue, OfferFooterWrapper } from './style'
import { formatPrice } from 'Rentlio/components/utils/Price/price'
import Wrapper from 'Rentlio/components/UI/wrapper'
import { SecondaryButton } from 'Rentlio/components/UI/button'
import { CurrenciesContext } from 'Rentlio/context/CurrenciesContext'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

class OfferFooter extends Component {
  static contextType = LanguageContext
  getRoomsNumber = () => this.props.booking.reservations.length || 0

  getTotalCost = () => {
    const {
      booking: { reservations }
    } = this.props

    let sum = 0

    reservations.map(reservation => (sum += reservation.totalReservationPrice))
    return sum
  }

  getCurrency = () => {
    const {
      booking: { reservations }
    } = this.props

    if (reservations.length === 0) {
      return 'EUR'
    }

    return reservations[0].currency
  }

  continue = () => {
    const { history, location } = this.props

    history.push(`${location.pathname}/payment`)
  }

  render() {
    const { translate } = this.context

    return (
      <CurrenciesContext.Consumer>
        {({ currencyConversion }) => {
          const { value, currency } = currencyConversion(this.getTotalCost(), this.getCurrency())
          return (
            <OfferFooterWrapper>
              <Wrapper>
                <OfferFooterInnerWrapper>
                  <OfferFooterLabel>{translate('Selected rooms')}:</OfferFooterLabel>
                  <OfferFooterValue>{this.getRoomsNumber()}</OfferFooterValue>
                  <OfferFooterLabel>{translate('Total cost')}:</OfferFooterLabel>
                  <OfferFooterValue>
                    {formatPrice(value, currency)} {currency}
                  </OfferFooterValue>
                  <ButtonWrapper onClick={this.continue}>
                    <SecondaryButton isLarge>{translate('Continue')}</SecondaryButton>
                  </ButtonWrapper>
                </OfferFooterInnerWrapper>
              </Wrapper>
            </OfferFooterWrapper>
          )
        }}
      </CurrenciesContext.Consumer>
    )
  }
}

OfferFooter.propTypes = {
  booking: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object
}

export default OfferFooter
