import React from 'react'
import PropTypes from 'prop-types'

const ArrowRight = ({ stroke }) => (
  <span>
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.00001 16.0711L16.0711 8.99999L8.99998 1.92897'
        stroke={stroke || 'white'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </span>
)

ArrowRight.propTypes = {
  stroke: PropTypes.string
}

export default ArrowRight
