import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import { BookingErrorsWrapper } from 'Rentlio/screen/payment/components/BookingErrors/style'

const badRequestMessageExists = (errors, msg) => {
  if (!errors.status === 400 || !Array.isArray(errors.response)) {
    return false
  }

  const found = errors.response.find(item => item.message === msg)

  return typeof found !== 'undefined'
}

const BookingErrors = ({ errors }) => {
  const { translate } = useContext(LanguageContext)

  if (Object.keys(errors).length === 0) {
    return null
  }

  if (badRequestMessageExists(errors, 'Email doesnt exist')) {
    return <BookingErrorsWrapper>{translate('Email doesnt exist')}</BookingErrorsWrapper>
  }

  if (badRequestMessageExists(errors, 'UnitType is not available')) {
    return <BookingErrorsWrapper>{translate('UnitType is not available')}</BookingErrorsWrapper>
  }

  return <BookingErrorsWrapper>{translate('There was an error during booking')}</BookingErrorsWrapper>
}

const mapStateToProps = state => ({
  errors: state.bookingErrors
})

BookingErrors.propTypes = {
  errors: PropTypes.object
}

export default connect(mapStateToProps)(BookingErrors)
