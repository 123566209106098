import { FETCH_MONRI_CLIENT_SECRET_COMPLETED } from 'Rentlio/state/types'
import createReducer from 'Rentlio/state/utils/createReducer'

const initialState = {}

const monri = createReducer(initialState)({
  [FETCH_MONRI_CLIENT_SECRET_COMPLETED]: (state, action) => {
    return { ...action.payload }
  }
})

export default monri
