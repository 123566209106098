import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import RatePreview from './RatePreview'
import { PaddingContainer, ReservationGrid } from './style'
import Information from './Information'
import UnitTypeModal from 'Rentlio/screen/offer/components/Offer/Reservation/UnitTypeModal'
import Modal from 'Rentlio/components/Modal'
import Animation from 'Rentlio/components/UI/Animation'
import RatePlan from 'Rentlio/screen/offer/components/Offer/Reservation/RatePlan'
import Images from 'Rentlio/components/UI/Images'

class Reservation extends Component {
  state = {
    showModal: false
  }

  showModalHandler = () => {
    this.setState({ showModal: true })
  }

  hideModalHandler = () => {
    this.setState({ showModal: false })
    document.body.classList.remove('modal-open')
  }

  render() {
    const { reservation, bookingEngine } = this.props
    const { images, unitType } = reservation
    const { showModal } = this.state
    return (
      <>
        <Animation show={showModal} animationType={'fadeInOut'} isModal>
          <Modal hideModalHandler={this.hideModalHandler} show={true}>
            <UnitTypeModal
              hasThumbnails
              images={images}
              amenities={unitType.amenities}
              hideModalHandler={this.hideModalHandler}
              maxOccupancy={unitType.maxOccupancy}
              standardOccupancy={unitType.standardOccupancy}
              description={unitType.description}
              name={unitType.name}
              latitude={unitType.latitude}
              longitude={unitType.longitude}
              primaryColor={bookingEngine.primaryColor}
              unitTypeName={unitType.name}
              isGroupBookingEngine={false}
            />
          </Modal>
        </Animation>
        <ReservationGrid>
          <PaddingContainer>
            <Images images={reservation.images} showModalHandler={this.showModalHandler} />
          </PaddingContainer>
          <Information reservation={reservation} showUnitTypeModal={this.showModalHandler} />
          <RatePreview
            numberOfRooms={reservation.unitsNumber || 1}
            totalCost={reservation.totalReservationPrice * (reservation.unitsNumber || 1)}
            currency={reservation.currency}
          />
        </ReservationGrid>
        <RatePlan reservation={reservation} includedExtras={reservation.includedExtras} />
      </>
    )
  }
}

Reservation.propTypes = {
  reservation: PropTypes.object,
  bookingEngine: PropTypes.object
}

export default Reservation
