import PropTypes from 'prop-types'
import React from 'react'
import { InputWrapper, Label, OccupancySelectWrapper, Inputs, OccupancySelectContainer, InputDivider } from './style'
import ChildrenAge from './ChildrenAgeSelector'
import NumberInput from 'Rentlio/components/UI/NumberInput'
import { LanguageContext } from 'Rentlio/context/LanguageContext'

class OccupancySelect extends React.Component {
  static contextType = LanguageContext

  wrapperRef = React.createRef()
  state = {
    editing: false
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick)
  }

  toggleEditing = () => {
    const { editing } = this.state
    if (editing) {
      return
    }
    this.setState({ editing: true })
  }

  onAdultsChange = adults => {
    const { onOccupancyChange, childrenAges } = this.props
    onOccupancyChange(adults, childrenAges)
  }

  onChildrenChange = newValue => {
    const { onOccupancyChange, adults, childrenAges } = this.props
    const change = newValue - childrenAges.length
    let newChildren = [...childrenAges]

    if (change > 0) {
      for (let i = 0; i < change; i++) {
        newChildren.push(0)
      }
    }
    if (change < 0) {
      for (let i = 0; i < Math.abs(change); i++) {
        newChildren.pop(0)
      }
    }
    if (newValue === 0) {
      newChildren = []
    }

    onOccupancyChange(adults, newChildren)
  }

  updateChildren = children => {
    const { onOccupancyChange, adults } = this.props

    onOccupancyChange(adults, children)
  }

  handleOutsideClick = event => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ editing: false })
    }
  }

  render() {
    const { editing } = this.state
    const { adults, childrenAges, isDarkStyle } = this.props

    const showChildrenSelector = childrenAges.length > 0 && editing
    const { translate } = this.context

    return (
      <OccupancySelectContainer editing={editing}>
        <OccupancySelectWrapper
          onClick={this.toggleEditing}
          editing={editing}
          isDarkStyle={isDarkStyle}
          ref={this.wrapperRef}
        >
          <Inputs>
            <InputWrapper noBorder={true}>
              <Label editing={editing} isDarkStyle={isDarkStyle}>
                {translate('Adults')}
              </Label>
              <NumberInput
                value={adults}
                editing={editing}
                minValue={0}
                maxValue={99}
                onChange={this.onAdultsChange}
                bigFontSize={true}
                isDarkStyle={isDarkStyle}
              />
              <InputDivider />
            </InputWrapper>
            <InputWrapper noBorder={true} isRight={true}>
              <Label isDarkStyle={isDarkStyle} editing={editing}>
                {translate('Children')}
              </Label>
              <NumberInput
                value={childrenAges.length}
                editing={editing}
                minValue={0}
                maxValue={99}
                onChange={this.onChildrenChange}
                bigFontSize={true}
                isDarkStyle={isDarkStyle}
              />
            </InputWrapper>
          </Inputs>
          {showChildrenSelector && (
            /* eslint-disable-next-line react/no-children-prop */
            <ChildrenAge children={childrenAges} updateChildren={this.updateChildren} isDarkStyle={isDarkStyle} />
          )}
        </OccupancySelectWrapper>
      </OccupancySelectContainer>
    )
  }
}

OccupancySelect.propTypes = {
  adults: PropTypes.number,
  childrenAges: PropTypes.array,
  rooms: PropTypes.number,
  onOccupancyChange: PropTypes.func,
  fontColor: PropTypes.bool,
  isDarkStyle: PropTypes.bool
}

export default OccupancySelect
