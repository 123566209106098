import {
  ACCEPT_BOOKING_COMPLETED,
  ACCEPT_BOOKING_FAILED,
  BOOK_RESERVATION_COMPLETED,
  BOOK_RESERVATION_FAILED,
  CANCEL_RESERVATION_COMPLETED,
  CANCEL_RESERVATION_FAILED
} from 'Rentlio/state/types'
import createReducer from 'Rentlio/state/utils/createReducer'

const initialState = {}

const bookingErrors = createReducer(initialState)({
  [BOOK_RESERVATION_FAILED]: (state, { payload }) => {
    return { ...payload }
  },
  [CANCEL_RESERVATION_FAILED]: (state, { payload }) => ({ ...payload }),
  [CANCEL_RESERVATION_COMPLETED]: () => initialState,
  [ACCEPT_BOOKING_FAILED]: (state, { payload }) => ({ ...payload }),
  [BOOK_RESERVATION_COMPLETED]: () => initialState,
  [ACCEPT_BOOKING_COMPLETED]: () => initialState
})

export default bookingErrors
