import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'
import defaultHero from 'Images/dummy/defaultHero.png'

export const HeroWrapper = styled.div`
  width: 100%;
  height: ${props => (props.isCheckIn ? '300px' : props.isSearchShown ? '400px' : '100px')};
  position: relative;

  &::before {
    background-image: ${props =>
        props.isSearchShown
          ? ''
          : `linear-gradient(90deg, ${props.theme.colors.primary} 18.16%, rgba(9, 115, 160, 0) 64.76%),`}
      url(${props => (props.backgroundImage ? props.backgroundImage : defaultHero)});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    animation: fadeIn-from-bottom 1.5s cubic-bezier(0, 0.5, 0, 1) forwards;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  @keyframes fadeIn-from-bottom {
    0% {
      opacity: 0;
      transform: translateY(15px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media ${device.tablet} {
    height: ${props => (props.isCheckIn ? '200px' : props.isSearchShown ? '530px' : '100px')};
  }
`

export const ReservationId = styled.div`
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.fonts._24};
  line-height: ${props => props.theme.fonts._28};
  color: ${props => props.theme.colors.white};
  font-weight: ${props => props.theme.fonts.mediumWeight};

  @media ${device.tablet} {
    margin: 0 auto;
    text-shadow: 1px 1px 0px ${props => props.theme.colors.lightGray};
  }

  @media ${device.tabletAndWider} {
    font-size: ${props => props.theme.fonts._28};
    line-height: ${props => props.theme.fonts._37};
  }
`

export const OfferId = styled.div`
  display: flex;
  align-items: center;

  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fonts._28};
  line-height: ${props => props.theme.fonts._37};
  font-weight: ${props => props.theme.fonts.mediumWeight};

  @media ${device.mobile} {
    text-align: center;
    margin: 0 auto;
  }

  @media ${device.tablet} {
    padding-left: 30px;
    padding-right: 30px;
    font-size: ${props => props.theme.fonts._14};
    line-height: ${props => props.theme.fonts._16};
    text-shadow: 1px 1px 0px ${props => props.theme.colors.lightGray};
  }
`

export const Wrapper = styled.div`
  max-width: 1180px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;

  @media ${device.onlyMobile} {
    justify-content: center;
  }

  @media ${device.laptop} {
    max-width: 900px;
  }
`
