import React from 'react'

export const InstagramIcon = () => (
  <svg width='18' height='18' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.70433 1.54622C3.73208 1.54622 2.12757 3.15576 2.12757 5.13408V12.9049C2.12757 14.8833 3.73208 16.4928 5.70433 16.4928H13.4506C15.423 16.4928 17.0275 14.8833 17.0275 12.9049V5.13408C17.0275 3.15576 15.423 1.54622 13.4506 1.54622H5.70433ZM13.4506 18.0389H5.70433C2.88209 18.0389 0.586182 15.7359 0.586182 12.9049V5.13408C0.586182 2.30322 2.88209 0 5.70433 0H13.4506C16.2728 0 18.5689 2.30322 18.5689 5.13408V12.9049C18.5689 15.7359 16.2728 18.0389 13.4506 18.0389Z'
      fill='#333333'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.58624 6.00801C7.9356 6.00801 6.59259 7.35511 6.59259 9.01093C6.59259 10.6668 7.9356 12.0139 9.58624 12.0139C11.2369 12.0139 12.5798 10.6668 12.5798 9.01093C12.5798 7.35511 11.2369 6.00801 9.58624 6.00801ZM9.58624 13.525C7.10499 13.525 5.08624 11.5001 5.08624 9.01093C5.08624 6.5219 7.10499 4.49683 9.58624 4.49683C12.0675 4.49683 14.0862 6.5219 14.0862 9.01093C14.0862 11.5001 12.0675 13.525 9.58624 13.525Z'
      fill='#333333'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.9862 4.49686C14.9862 4.99551 14.5833 5.39964 14.0863 5.39964C13.5892 5.39964 13.1862 4.99551 13.1862 4.49686C13.1862 3.99821 13.5892 3.59399 14.0863 3.59399C14.5833 3.59399 14.9862 3.99821 14.9862 4.49686Z'
      fill='#333333'
    />
  </svg>
)

export const TwitterIcon = () => (
  <svg width='23' height='18' viewBox='0 0 23 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.5113 13.88C5.19788 13.801 3.7508 12.1703 3.36747 10.8462C4.01147 10.9665 4.64588 10.9421 5.27647 10.7897C5.29372 10.786 5.30905 10.7766 5.34163 10.7615C4.08622 10.4737 3.12022 9.81731 2.44747 8.75461C2.0028 8.04929 1.78813 7.27813 1.7958 6.43173C2.42063 6.759 3.07613 6.94145 3.77763 6.96026C2.83463 6.29443 2.20213 5.42358 1.94338 4.30822C1.68655 3.19662 1.8533 2.14145 2.42255 1.14458C4.83372 3.93016 7.88505 5.45932 11.6091 5.71136C11.5823 5.53643 11.5555 5.38596 11.5382 5.23173C11.4136 4.20289 11.6226 3.24552 12.188 2.37468C12.8971 1.28377 13.9091 0.604772 15.2086 0.397875C16.7075 0.157123 18.0146 0.576559 19.1091 1.61668C19.1819 1.68816 19.2432 1.70885 19.3448 1.68816C20.261 1.49067 21.1254 1.16715 21.9361 0.70822C21.9553 0.696935 21.9745 0.687531 21.9956 0.678126C21.9994 0.676245 22.007 0.676245 22.0243 0.676245C21.6735 1.69192 21.0315 2.47813 20.1172 3.05556C20.9797 2.96151 21.8039 2.73769 22.5993 2.39913C22.6051 2.40665 22.6108 2.41418 22.6166 2.41982C22.446 2.638 22.2811 2.86371 22.1048 3.07625C21.6314 3.64803 21.089 4.15023 20.4852 4.58659C20.4335 4.62233 20.4105 4.65806 20.4105 4.72389C20.4354 5.3728 20.4105 6.01982 20.328 6.66308C20.1575 8.02295 19.7703 9.317 19.18 10.5546C18.5705 11.8336 17.7751 12.9866 16.7726 14.0004C15.0764 15.7214 13.0313 16.833 10.6508 17.3502C9.8343 17.5252 9.0063 17.6136 8.17063 17.638C5.6023 17.7114 3.22755 17.085 1.04255 15.7609C1.0138 15.744 0.986967 15.7251 0.937134 15.6951C2.49155 15.8587 3.97122 15.6631 5.39338 15.0932C6.14855 14.7866 6.84813 14.3878 7.5113 13.88Z'
      fill='#333333'
    />
  </svg>
)

export const FacebookIcon = () => (
  <svg width='10' height='18' viewBox='0 0 10 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.28333 3.22868C7.825 3.20862 8.36667 3.22367 8.91 3.22367H9.13333V0.394826C8.845 0.366404 8.54167 0.324607 8.23833 0.30956C7.68333 0.284482 7.12667 0.257731 6.57 0.269434C5.72 0.287825 4.91667 0.49514 4.21333 1.00005C3.40833 1.58187 2.96667 2.39775 2.79833 3.36076C2.72833 3.757 2.71 4.16996 2.7 4.57455C2.685 5.2082 2.69833 5.84185 2.69833 6.47717V6.71625H0V9.87445H2.68167V17.8159H5.96V9.88783H8.63333C8.77 8.83621 8.90333 7.79629 9.04333 6.71458C8.82833 6.71458 8.63667 6.7129 8.44333 6.71458C7.68333 6.71792 5.93833 6.71458 5.93833 6.71458C5.93833 6.71458 5.945 5.14801 5.965 4.46923C5.99 3.53965 6.54 3.25543 7.28333 3.22868Z'
      fill='#333333'
    />
  </svg>
)
