import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const MobileInformationWrapper = styled.div`
  display: none;

  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }

  @media ${device.mobile} {
    padding: 20px 30px;
    margin-bottom: 0px;
    transition: background-color 0.35s ease-in-out;

    &.sticky {
      position: sticky;
      top: 0;
      width: 100%;
      padding: 20px 30px;
      background-color: white;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
      transition: background-color 0.35s ease-in-out;
      z-index: 19;
    }
  }
`
export const Name = styled.h2`
  margin-top: 25px;
  font-weight: ${props => props.theme.fonts.mediumWeight};
  font-size: ${props => props.theme.fonts._24};
  line-height: ${props => props.theme.fonts._28};
`
