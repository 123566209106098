import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Services from 'Rentlio/screen/services/components/Services'
import { removeItemFromBasket, updateServiceQuantity } from 'Rentlio/state/actions/ui'
import { getTotalCost } from 'Rentlio/state/selectors/basket'
import { getNights } from 'Rentlio/state/selectors/request'

const ServicesContainer = ({
  request,
  basket,
  removeItemFromBasket,
  updateServiceQuantity,
  totalCost,
  ratePlanNames,
  serviceNames,
  selectedCurrency
}) => (
  <Services
    request={request}
    basket={basket}
    removeItemFromBasket={removeItemFromBasket}
    updateServiceQuantity={updateServiceQuantity}
    totalCost={totalCost}
    ratePlanNames={ratePlanNames}
    serviceNames={serviceNames}
    selectedCurrency={selectedCurrency}
  />
)

const mapStateToProps = state => {
  const {
    request: { adults, children },
    configuration: { currency }
  } = state

  return {
    request: { adults, children, nights: getNights(state.request) },
    basket: state.basket,
    totalCost: getTotalCost(state),
    selectedCurrency: currency,
    ratePlanNames: state.unitTypes.reduce(
      (accumulator, item) => [
        ...accumulator,
        ...item.rates.map(rate => ({ id: rate.ratePlanId, name: rate.ratePlanName }))
      ],
      []
    ),
    serviceNames: state.services.map(({ id, name }) => ({ id, name }))
  }
}

const mapDispatchToProps = dispatch => ({
  removeItemFromBasket: basketId => dispatch(removeItemFromBasket(basketId)),
  updateServiceQuantity: (unitTypeId, serviceId, newQuantity, isMandatoryQuantityUpdate) =>
    dispatch(updateServiceQuantity(unitTypeId, serviceId, newQuantity, isMandatoryQuantityUpdate))
})

ServicesContainer.propTypes = {
  basket: PropTypes.array,
  totalCost: PropTypes.object,
  request: PropTypes.shape({
    nights: PropTypes.number,
    adults: PropTypes.number,
    children: PropTypes.array
  }),
  removeItemFromBasket: PropTypes.func,
  updateServiceQuantity: PropTypes.func,
  ratePlanNames: PropTypes.array,
  serviceNames: PropTypes.array,
  selectedCurrency: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesContainer)
